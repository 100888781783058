import React, { useContext } from "react"
import { givenNumberOfDigits } from "src/common/utilityFunctions"
import { CreatePatientData, PatientDataOverviewLabelValuePairs, PatientFormData } from "src/types"
import styles from "./PatientDataOverview.module.css"
import FormDataReviewBlock from "./FormDataReviewBlock"
import ShowUploadedMedia from "src/common-components/ShowUploadedMedia/ShowUploadedMedia"
import ShowImageFromServer from "src/common-components/ShowImageFromServer/ShowImageFromServer"

interface Props {
  values: CreatePatientData
}

const PatientDataOverview: React.FC<Props> = ({ values }) => {
  const drugAllergyNameValuePair: PatientDataOverviewLabelValuePairs = {
    label: "Gyógyszerallergiák",
    value: "",
  }
  if (values.drugAllergy) {
    values.drugAllergy.forEach((element, index) => {
      if (index !== 0 && element) drugAllergyNameValuePair.value += ", "
      drugAllergyNameValuePair.value += element
    })
  }

  const hospitalVisitNameValuePair: PatientDataOverviewLabelValuePairs[] = []
  if (values.hospitalVisits) {
    values.hospitalVisits.forEach((element, index) => {
      hospitalVisitNameValuePair.push({
        label: `Kórházi kezelés (${element.year}. ${givenNumberOfDigits(2, element.month)}.)`,
        value: [
          <div key={index}>
            {element.diagnose}
            {element.mediaAtCreation && element.mediaAtCreation.length > 0 ? (
              <ShowUploadedMedia file={element.mediaAtCreation[0].file} />
            ) : null}
            {element.media ? <ShowImageFromServer file={element.media} /> : null}
          </div>,
        ],
      })
    })
  }

  const persistentDiseases: PatientDataOverviewLabelValuePairs[] = [
    { label: "Állandó betegségek", value: "" },
  ]
  if (values.illnesses.length > 0) {
    values.illnesses.forEach((element, index) => {
      if (index !== 0) persistentDiseases[0].value += ", "
      if (element.illnessName) {
        persistentDiseases[0].value += element.illnessName
        if (element.drugs && element.drugs !== []) {
          if (element.drugs && element.drugs.length > 0) {
            const drugs = [...element.drugs]
            persistentDiseases.push({
              label: `${element.illnessName} okán szedett gyógyszerek`,
              value: drugs.map(
                (
                  {
                    agentUnit,
                    name: drugName,
                    agentAmount,
                    dose,
                    mediaAtCreation: drugMediaAtCreation,
                    media,
                  },
                  index,
                ) => {
                  return (
                    <div key={index}>
                      <p>
                        {drugName} ({agentAmount} {agentUnit}, {dose})
                      </p>
                      {drugMediaAtCreation[0] ? (
                        <ShowUploadedMedia file={drugMediaAtCreation[0].file} />
                      ) : null}
                      {media ? <ShowImageFromServer file={media} /> : null}
                    </div>
                  )
                },
              ),
            })
          }
        }
      }
    })
  }

  const nameValuePairs = [
    {
      label: "Teljes név",
      value: `${values.lastName} ${values.firstName} ${values.foreName ? values.foreName : ""}`,
    },
    { label: "Születési név", value: values.birthName },
    {
      label: "Születési dátum",
      value: `${values.birthYear}. ${givenNumberOfDigits(
        2,
        values.birthMonth,
      )}. ${givenNumberOfDigits(2, values.birthDay)}.`,
    },
    {
      label: "Anyja neve",
      value: values.mothersName,
    },
    { label: "Telefonszám", value: values.phone },
    {
      label: "Lakcím",
      value: `${values.address.country}, ${values.address.region} megye, ${values.address.zipCode} ${values.address.city}, ${values.address.street} ${values.address.streetNum}`,
    },
    { label: "TAJ-szám", value: values.tajNum },
    { label: "Tömeg", value: values.weight + " kg" },
    { label: "Magasság", value: values.height ? values.height + " cm" : "" },
    { label: "Háziorvos", value: values.GP?.name },
    { label: "Nem", value: values.sex === "male" ? "Férfi" : "Nő" },
    drugAllergyNameValuePair,
    ...hospitalVisitNameValuePair,
    ...persistentDiseases,
  ]

  return <FormDataReviewBlock labelValuePairs={nameValuePairs} />
}

export default PatientDataOverview
