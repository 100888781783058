import React from "react"

export const createFormContext = <T>() => {
  return React.createContext(
    {} as {
      setFormState: React.Dispatch<React.SetStateAction<number>>
      setFormValues: React.Dispatch<React.SetStateAction<T>>
      formState: number
      maxFormState: number
      formValues: T
      setShouldSubmit?: React.Dispatch<React.SetStateAction<boolean>>
      loaded?: boolean
    },
  )
}
