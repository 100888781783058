import React from "react"
import { DateRangePicker as DRP } from "react-date-range"
import locale from "date-fns/locale/hu/index.js"
import styles from "./DateRangePicker.module.css"
import { useIsMobileSize } from "../../common/hooks/useIsMobileSize"

interface Props {
  startDate: Date
  endDate: Date
  setEndDate: React.Dispatch<React.SetStateAction<Date>>
  setStartDate: React.Dispatch<React.SetStateAction<Date>>
  maxDate?: Date
}

const DateRangePicker: React.FC<Props> = ({
  startDate,
  endDate,
  setEndDate,
  setStartDate,
  maxDate,
}) => {
  const isMobile = useIsMobileSize()

  const handleSelect = (ranges: any) => {
    const endDate = ranges.selection.endDate as Date
    const startDate = ranges.selection.startDate as Date
    setStartDate(startDate)
    setEndDate(endDate)
  }

  return (
    <DRP
      ranges={[{ startDate, endDate, key: "selection" }]}
      onChange={(ranges) => handleSelect(ranges)}
      months={2}
      direction={isMobile ? "vertical" : "horizontal"}
      locale={locale}
      rangeColors={["var(--blue)"]}
      color="var(--blue)"
      maxDate={maxDate}
    />
  )
}

export default React.memo(DateRangePicker)
