import { Formik } from "formik"
import React from "react"
import MyTextInput from "src/common-components/TextInput/TextInput"
import { logTitlePlaceholderDict } from "src/common/consts"
import { LogBookBaseEntryTitles } from "src/types"
import styles from "./AddEventDescription.module.css"

interface Props {
  logType: string | null
  disabled?: boolean
}

const AddEventDescription: React.FC<Props> = ({ logType, disabled }) => {
  const placeholder = logType ? logTitlePlaceholderDict[logType as LogBookBaseEntryTitles] : null

  return (
    <div className={styles.container}>
      <i className={["material-icons", "notranslate"].join(" ")}>description</i>
      <MyTextInput
        disabled={disabled}
        name="description"
        className={styles.input}
        label="Leírás"
        placeholder={placeholder || ""}
      />
    </div>
  )
}
export default AddEventDescription
