import React from "react"
import styles from "./DateChooserBlock.module.css"
import DayChooser from "./DayChooser/DayChooser"
import TimeChooser from "./TimeChooser/TimeChooser"

interface Props {
  date: Date
  setDate: React.Dispatch<React.SetStateAction<Date>>
  disabled?: boolean
}

const DateChooserBlock: React.FC<Props> = ({ date, setDate, disabled }) => {
  return (
    <div className={styles.dateChooserBlock}>
      <i className={["material-icons", "notranslate"].join(" ")}>today</i>
      <DayChooser disabled={disabled} date={date} setDate={setDate} />
      <i className={["material-icons", "notranslate"].join(" ")}>access_time</i>
      <TimeChooser disabled={disabled} date={date} setDate={setDate} maxDate={new Date()} />
    </div>
  )
}

export default DateChooserBlock
