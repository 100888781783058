import React, { useEffect, useState, useRef, MutableRefObject } from "react"
import styles from "./Popup.module.scss"
import GenericButton from "src/common-components/GenericButton/GenericButton"
import { useOnClickOutside } from "src/common/hooks/useOnClickOutside"
import { openDocument } from "../../common/openDocuments"
import ReactDOM from "react-dom"
import { useIsMobileSize } from "../../common/hooks/useIsMobileSize"

type PopupProps = {
  title?: string
  onClose: () => void
  confirmButton: {
    title: string
    buttonStyle?: "blue" | "white" | "grey" | "whiteBlue" | "red"
    className?: string
    action?: () => void // defaults to onClose
  }
  cancelButton?: {
    title: string
    className?: string
    buttonStyle?: "blue" | "white" | "grey" | "whiteBlue" | "red"
    action?: () => void // defaults to onClose
  }
  fullscreenContainer?: boolean
  panelStyle?: React.CSSProperties
  loading?: boolean
  aszf?: boolean
  dataManagement?: boolean
  mobileFullScreen?: boolean
}

const Popup: React.FC<PopupProps> = ({
  title = "Adatok megerősítése",
  onClose,
  confirmButton,
  cancelButton,
  fullscreenContainer,
  panelStyle,
  children,
  loading = false,
  aszf,
  mobileFullScreen = false,
  dataManagement,
}) => {
  const [overlayVisible, setOverlayVisible] = useState<boolean>(false)
  const isMobile = useIsMobileSize()

  useEffect(() => {
    setOverlayVisible(true)
  }, [])

  const panelRef = useOnClickOutside<HTMLDivElement>(onClose)

  const containerClassname = isMobile
    ? mobileFullScreen
      ? styles.mobileFullScreenContainer
      : styles.container
    : fullscreenContainer
    ? styles.containerFullscreen
    : styles.container

  return ReactDOM.createPortal(
    <div
      className={
        overlayVisible ? [containerClassname, styles.visible].join(" ") : containerClassname
      }
    >
      <div
        ref={panelRef}
        className={styles.panel}
        style={isMobile ? { ...panelStyle, minWidth: 0, width: "100%" } : panelStyle} // TODO: fix this
      >
        <div className={styles.top}>
          <h1>{title}</h1>
          <i onClick={onClose} className={["material-icons", "notranslate"].join(" ")}>
            close
          </i>
        </div>

        {children ? (
          children
        ) : (
          <p className={styles.popupContent}>
            A megerősítés gombra kattintva kijelenti, hogy az Ön által megadott adatok helyesek, a
            valóságnak megfelelnek. Kijelenti, hogy elfogadja az OrvosVálasz adatkezelési
            nyilatkozatában foglaltakat.
          </p>
        )}

        <div className={styles.docsContainer}>
          {aszf ? (
            <p onClick={() => openDocument("aszf")} className="linkText">
              Általános Szerződési Feltételek
            </p>
          ) : null}
          {dataManagement ? (
            <p onClick={() => openDocument("data")} className="linkText">
              Adatkezelési tájékoztató
            </p>
          ) : null}
        </div>

        <div className={styles.buttonContainer}>
          <GenericButton
            action={confirmButton.action ? confirmButton.action : onClose}
            text={confirmButton.title}
            style={confirmButton.buttonStyle ? confirmButton.buttonStyle : "blue"}
            loading={loading}
            className={confirmButton.className}
          />
          {cancelButton ? (
            <GenericButton
              action={cancelButton.action ? cancelButton.action : onClose}
              text={cancelButton.title}
              style={cancelButton.buttonStyle ? cancelButton.buttonStyle : "grey"}
              className={cancelButton.className}
            />
          ) : null}
        </div>
      </div>
    </div>,
    document.body,
  )
}

export default Popup
