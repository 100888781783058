import React from "react"
import { getDisplayNameFromNames, givenNumberOfDigits } from "../../../common/utilityFunctions"
import { Ticket } from "../../../types"
import getDisplayAge from "./getDisplayAge"

interface Props {
  ticket: Ticket
  patientFullName?: string
}

const PatientNameWithAge: React.FC<Props> = ({ patientFullName, ticket }) => {
  const displayName =
    patientFullName || getDisplayNameFromNames(ticket.patientProfile, "Törölt páciens")

  const age = ticket.patientProfile ? `(${getDisplayAge(ticket.patientProfile)})` : ""

  const id = givenNumberOfDigits(3, ticket.ticketID)

  return <h3>{`#${id} - ${displayName} ${age} `}</h3>
}

export default PatientNameWithAge
