import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import SimplePeer, { SimplePeerData } from "simple-peer"
import { patchTicket } from "src/apicalls"
import { GlobalContext } from "src/App"
import Popup from "src/common-components/Popup/Popup"
import { getVideoCallTask } from "src/common/utilityFunctions"
import { ConsultationTaskData } from "src/types"
import styles from "./VideoSettings.module.css"

interface Props {
  stream: React.MutableRefObject<MediaStream | undefined>
  isCallEnded: boolean
  endCall: () => void
  videoCallID: number | null
  SimplePeerInstance: SimplePeer.Instance
}

const VideoSettings: React.FC<Props> = ({
  stream,
  endCall,
  isCallEnded,
  videoCallID,
  SimplePeerInstance,
}) => {
  const [, setCounter] = useState<number>(0)
  const { doctor, userToken, setShouldUpdateUserDataFromServer } = useContext(GlobalContext)
  const navigate = useNavigate()
  const [isEndingCall, setIsEndingCall] = useState<boolean>(false)
  const isMuted = !stream.current?.getAudioTracks()[0].enabled

  const handleMute = () => {
    if (stream.current) {
      const enabled = stream.current.getAudioTracks()[0].enabled
      stream.current.getAudioTracks()[0].enabled = !enabled
      SimplePeerInstance.send(enabled ? "muted" : "unmuted")
    }
    setCounter((c) => c + 1)
  }

  useEffect(() => {
    window.onbeforeunload = handleEndCall
    return () => {
      handleEndCall()
      window.onbeforeunload = null
    }
  }, [])

  const handleEndCall = () => {
    if (isEndingCall) endCall()
    stream.current?.getTracks().forEach((track) => track.stop())
    if (Object.keys(doctor).length > 0 && videoCallID) {
      const { taskIndex, ticketData, ticketID } = getVideoCallTask(
        videoCallID,
        doctor.tickets.inProgress,
      )
      if (!ticketData || !taskIndex || !ticketID) return
      const task = ticketData.tasks[taskIndex] as ConsultationTaskData
      task.finishedAt = Math.round(new Date().getTime() / 1000)
      task.state = "ready"
      patchTicket(userToken, { ticketID, ticketData }).then(() =>
        setShouldUpdateUserDataFromServer(true),
      )
    }
    navigate({ pathname: "/" })
  }

  return (
    <div className={styles.container}>
      <div className={styles.button} onClick={handleMute}>
        <i className={["material-icons", "notranslate"].join(" ")}>{isMuted ? "mic_off" : "mic"}</i>
      </div>
      <div
        onClick={() => setIsEndingCall(true)}
        className={[styles.button, styles.endCall].join(" ")}
      >
        <i className={["material-icons", "notranslate"].join(" ")}>call_end</i>
      </div>
      {isEndingCall || isCallEnded ? (
        <Popup
          title={"Videóhívás"}
          confirmButton={{
            title: isEndingCall ? "Hívás befejezése" : "Vissza a főoldalra",
            action: handleEndCall,
          }}
          onClose={() => setIsEndingCall(false)}
          fullscreenContainer
        >
          <p>{isCallEnded ? "A hívást befejezték" : "Biztosan be akarja fejezni a hívást?"}</p>
        </Popup>
      ) : null}
    </div>
  )
}
export default VideoSettings
