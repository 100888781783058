import React from "react"
import styles from "./AddEventHeader.module.css"

interface Props {
  onClose: () => void
  title: string
}

const AddEventHeader: React.FC<Props> = ({ onClose, title }) => {
  return (
    <div className={styles.title}>
      {title}
      <i onClick={onClose} className={["material-icons", "notranslate"].join(" ")}>
        clear
      </i>
    </div>
  )
}

export default AddEventHeader
