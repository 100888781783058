import { Image } from "src/types"
import { APIBASEURL, headersBase } from "."

export const getImage = async (userToken: string, imageID: number) => {
  const res = await fetch(APIBASEURL + "/image", {
    method: "GET",
    headers: {
      ...headersBase,
      "User-Token": userToken,
      "Image-ID": imageID.toString(),
    },
  })
  const result: {
    status: { result: string; message: Image }
  } = await res.json()
  return result
}
