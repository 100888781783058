import { useFormikContext } from "formik"
import React, { useEffect, useRef, useState } from "react"

interface Params {
  checkBoxValName: string
  checkBoxVal?: boolean
  initialValues?: { fieldName: string; values: any }
  initialNumOfFields?: number //if a component is rerendered the numoffields shouldnt start from 0
}

export const useAddForm = ({
  checkBoxValName,
  checkBoxVal,
  initialNumOfFields,
  initialValues,
}: Params) => {
  const [numOfFields, setNumOfFields] = useState(initialNumOfFields || 0)
  const { values, setFieldValue, resetForm } = useFormikContext<any>()
  const addButtonRef = useRef<HTMLDivElement>(null)
  const initialLoadRef = useRef<boolean>(true)

  if (checkBoxVal === undefined) {
    checkBoxVal = values[checkBoxValName]
  }

  useEffect(() => {
    addButtonRef.current?.scrollIntoView({ behavior: "smooth" })
    if (numOfFields === 0 && checkBoxVal === true && !initialLoadRef) {
      setFieldValue(checkBoxValName, false)
    }
  }, [numOfFields])

  useEffect(() => {
    initialLoadRef.current = false
  }, [])

  return { numOfFields, setNumOfFields, addButtonRef }
}
