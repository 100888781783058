import React, { useRef, useState } from "react"
import { Calendar } from "react-date-range"
import { useOnClickOutside } from "src/common/hooks/useOnClickOutside"
import { getDateFromInt } from "src/common/utilityFunctions"
import styles from "./SingleDateChooser.module.scss"
import locale from "date-fns/locale/hu/index.js"
import ReactDOM from "react-dom"
import OVIcon from "../Icon/OVIcon"

interface Props {
  date: Date | null
  setDate: (value: Date | null) => void
  unknownText?: string
  minDate?: Date
  maxDate?: Date
}

const Cal = Calendar as any //Props are not typed well

const SingleDateChooser: React.FC<Props> = ({ date, setDate, minDate, maxDate, unknownText }) => {
  const [calendarVisible, setCalendarVisible] = useState(false)
  const buttonRef = useRef<HTMLDivElement>(null)
  const ref = useOnClickOutside<HTMLDivElement>(() => setCalendarVisible(false), buttonRef)

  return (
    <div className={styles.container}>
      <span className={styles.dateContainer}>
        <p ref={calendarVisible ? buttonRef : undefined} onClick={() => setCalendarVisible(true)}>
          <OVIcon name="today" />
          {date ? getDateFromInt(date.getTime()) : unknownText || null}
        </p>
      </span>

      {calendarVisible
        ? ReactDOM.createPortal(
            <div ref={ref} className={styles.calendarContainer}>
              <Cal
                date={date || new Date()}
                onChange={(value: string) => setDate(value as any)}
                maxDate={maxDate}
                minDate={minDate}
                locale={locale}
              />
              {date ? (
                <p
                  className={styles.resetButton}
                  onClick={() => {
                    setCalendarVisible(false)
                    setDate(null)
                  }}
                >
                  {unknownText || "Nincs megadva"}
                </p>
              ) : null}
            </div>,
            document.body,
          )
        : null}
    </div>
  )
}

export default SingleDateChooser
