import React, { useContext } from "react"
import IntervalChooser from "src/common-components/IntervalChooser/IntervalChooser"
import { symptomsMap } from "src/common/symptoms"
import { newTicketFormContext } from "../NewTicketPage"
import TaskCardBase from "../TaskCardBase"
import styles from "./SymptomStartDateCard.module.css"

const SymptomStartDateCard: React.FC = () => {
  const context = useContext(newTicketFormContext)
  const { formValues } = context

  const createHandleChange = (index: number, type: "start" | "end") => {
    if (type === "start") {
      return (value: Date) => {
        const newFormValues = formValues
        if (newFormValues.symptoms)
          newFormValues.symptoms[index].symptomInterval[type] = value.getTime()
        context.setFormValues({ ...newFormValues })
      }
    } else {
      return (value: Date | null) => {
        const newFormValues = formValues
        if (newFormValues.symptoms)
          newFormValues.symptoms[index].symptomInterval[type] = value ? value.getTime() : 0
        context.setFormValues({ ...newFormValues })
      }
    }
  }

  if (!formValues.symptoms) return null

  const content = formValues.symptoms.map((symptom, index) => {
    let { end, start } = formValues.symptoms[index].symptomInterval
    return (
      <div className={styles.container} key={index}>
        <p>{symptomsMap.get(symptom.symptomID)}</p>
        <IntervalChooser
          unknownText="Még mindig tart"
          endDate={end ? new Date(end) : null}
          startDate={new Date(start)}
          setEndDate={createHandleChange(index, "end") as any}
          setStartDate={createHandleChange(index, "start")}
          maxDate={new Date()}
        />
      </div>
    )
  })

  return (
    <TaskCardBase
      dontSaveToFormContext
      title="Mikor kezdődtek a tünetek"
      initialValues={{}}
      formContext={context}
      gap
    >
      {content}
    </TaskCardBase>
  )
}

export default SymptomStartDateCard
