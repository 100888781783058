import { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import {
  createTicket as createTicketApiCall,
  patchTicket as patchTicketApiCall,
} from "../../../apicalls"
import { patchUserProfile } from "../../../apicalls/patchUserProfile"
import { GlobalContext } from "../../../App"
import { useOVNavigator } from "../../../common/hooks/useOVNavigator"
import {
  ApiResponse,
  ImageMeta,
  Patient,
  Task,
  Ticket,
  TicketData,
  UserProfile,
} from "../../../types"
import { getNextTaskId } from "../TaskChooseCard/utils"

export const useNewTicketSubmit = (
  files: { file: File; meta: ImageMeta }[],
  formValues: TicketData,
  updatedUserData: Partial<UserProfile>,
  patient: Patient,
  ticket: Ticket | undefined,
) => {
  const OVNavigator = useOVNavigator()
  const { userToken, user, setShouldUpdateUserDataFromServer } = useContext(GlobalContext)

  const [loading, setLoading] = useState<boolean>(false)

  const shouldUpdateUserData = formValues.tasks.filter(({ name }) => name === "sickPay").length > 0

  /* ************* HELPERS ************/
  const createTicket = () => {
    return createTicketApiCall(
      userToken,
      {
        patientID: patient?.id,
        ticketData: formValues,
      },
      files,
    )
  }

  const patchTicket = (ticket: Ticket) => {
    const newTicketData = addNewTasksToTicket(ticket)
    return patchTicketApiCall(userToken, {
      ticketID: ticket.ticketID,
      ticketData: newTicketData,
    })
  }

  const addNewTasksToTicket = (ticket: Ticket | undefined) => {
    const newTasks: Task[] = ticket ? ticket.ticketData.tasks : []
    formValues.tasks.forEach((task) => {
      task.taskID = getNextTaskId(newTasks)
      newTasks.push(task)
    })
    return { ...ticket?.ticketData, tasks: newTasks }
  }
  /* ************* HELPERS ************/

  const submit = async () => {
    setLoading(true)
    let res: Promise<ApiResponse<any>>
    if (!ticket) {
      res = createTicket()
    } else {
      res = patchTicket(ticket)
    }
    const promises = [res]
    if (shouldUpdateUserData) {
      promises.push(patchUserProfile(userToken, updatedUserData))
    }
    const resultArray = await Promise.all(promises)
    if (resultArray.every((res) => res.status.result === "ok")) {
      setShouldUpdateUserDataFromServer(true)
      if (formValues.symptoms?.length > 0) {
        const ticketID = resultArray[0].status.message.ticketID
        OVNavigator.toArticles(ticketID)
      } else OVNavigator.toHome()
    }
  }

  return {
    submit,
    loading,
  }
}
