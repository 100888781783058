import React from "react"
import Loader from "../Loader/Loader"
import styles from "./SidebarButton.module.scss"

interface Props {
  action: () => any
  icon: string
  text: string
  loading?: boolean
  disabled?: boolean
  className?: string
  title?: string
}

const SidebarButton: React.FC<Props> = ({
  action,
  icon,
  text,
  disabled,
  className,
  title,
  loading = false,
}) => {
  return (
    <div
      className={`${className} ${styles.container} ${disabled ? styles.disabled : ""} `}
      onClick={disabled ? undefined : action}
      title={title}
    >
      {loading ? (
        <div className={styles.loaderContainer}>
          <Loader scale={0.3} color="white" />
        </div>
      ) : null}
      <p className={loading ? styles.hidden : ""}>{text}</p>
      <i className={["material-icons", "notranslate", loading ? styles.hidden : ""].join(" ")}>
        {icon}
      </i>
    </div>
  )
}

export default SidebarButton
