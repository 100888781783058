import React, { useContext } from "react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import { GlobalContext } from "./App"
import styles from "./App.module.css"
import PageBaseView from "./common-components/PageBaseView/PageBaseView"
import VideoPage from "./common-components/VideoPage/VideoPage"
import urls from "./common/urls"
import { VideoCall } from "./types"
import ArticlePage from "./user-components/ArticlePage/ArticlePage"
import CallPopup from "./user-components/CallPopup/CallPopup"
import CreateUserExtendedProfPage from "./user-components/CreateUserExtendedProfPage/CreateUserExtendedProfPage"
import LogBookPage from "./user-components/LogBookPage/LogBookPage"
import NewPatient from "./user-components/NewPatientPage/NewPatientPage"
import NewTicketPage from "./user-components/NewTicketPage/NewTicketPage"
import UserClosedTicketsPage from "./user-components/UserClosedTicketsPage/UserClosedTicketsPage"
import EditProfilePage from "./user-components/UserEditProfilePage/UserEditProfilePage"
import UserHomePage from "./user-components/UserHomePage/UserHomePage"
import UserProfilePage from "./user-components/UserProfilePage/UserProfilePage"
import UserSidebar from "./common-components/Sidebar/UserSidebar"
import UserTicketPage from "./user-components/UserTicketPage/UserTicketPage"

interface Props {
  activeVideoCall: VideoCall | null
}

const UserApp: React.FC<Props> = ({ activeVideoCall }) => {
  const context = useContext(GlobalContext)

  let content

  if (!context.user.userProfile) {
    content = <CreateUserExtendedProfPage />
  } else {
    content = (
      <PageBaseView Sidebar={UserSidebar}>
        <Routes>
          <Route path={urls.userNewPatientPageUrl + "/*"} element={<NewPatient />} />
          <Route path={urls.userNewTicketPageUrl + "/:patientId/*"} element={<NewTicketPage />} />
          <Route path={"/modify_ticket" + "/:ticketId/*"} element={<NewTicketPage />} />
          <Route path={"/ticket/:ticketID/*"} element={<UserTicketPage />} />
          <Route path={urls.profilePageUrl + "/*"} element={<UserProfilePage />} />
          <Route path={urls.editProfilePageUrl + "/*"} element={<EditProfilePage />} />
          <Route path={urls.userClosedTicketsPageUrl + "/*"} element={<UserClosedTicketsPage />} />
          <Route path={urls.logBookUrl + "/*"} element={<LogBookPage />} />
          <Route path={urls.articlePageUrl + "/:ticketID/*"} element={<ArticlePage />} />
          <Route path={"/videocall/*"} element={<VideoPage />} />
          <Route path={"/*"} element={<UserHomePage />} />
        </Routes>
      </PageBaseView>
    )
  }

  return (
    <Router basename="/">
      {activeVideoCall && !window.location.href.includes("/videocall") ? (
        <div className={styles.overlay}>
          <CallPopup videoCall={activeVideoCall} />
        </div>
      ) : null}
      {content}
    </Router>
  )
}

export default UserApp
