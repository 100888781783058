import { ApiResponse, TicketAssignee } from "src/types"
import { APIBASEURL, headersBase } from "."

export const patchTicketAssignee = async (
  userToken: string,
  {
    ticketID,
    assignee,
    blocked,
  }: {
    ticketID: number
    assignee: TicketAssignee
    blocked?: boolean
  },
) => {
  const res = await fetch(`${APIBASEURL}/ticket/${ticketID}/assignee`, {
    method: "PATCH",
    headers: {
      ...headersBase,
      "User-Token": userToken,
      "content-type": "application/json",
    },
    body: JSON.stringify({
      assignee,
      blocked,
    }),
  })
  return (await res.json()) as ApiResponse<{ assignee: TicketAssignee; blocked: boolean }>
}
