import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { useOnClickOutside } from "../../common/hooks/useOnClickOutside"
import { useOpenOverlay } from "../SidebarButtonsOverlayOpening/sidebarButtonOverlayOpening_utils"

export const useMobileSidebar = (
  hideSidebar: () => void,
  menuButtonRef: React.MutableRefObject<HTMLButtonElement | null>,
) => {
  const location = useLocation()

  useEffect(() => {
    hideSidebar()
  }, [location])

  const openOverlay = useOpenOverlay()

  const sidebarRef = useOnClickOutside(hideSidebar, menuButtonRef, !!openOverlay)
  return sidebarRef
}
