import { NavigateFunction, useNavigate } from "react-router-dom"
import { useRef } from "react"

export const useOVNavigator = () => {
  const navigate = useNavigate()
  const ref = useRef<OVNavigator>()
  if (!ref.current) ref.current = new OVNavigator(navigate)
  return ref.current
}

class OVNavigator {
  private navigate: NavigateFunction

  constructor(navigate: NavigateFunction) {
    this.navigate = navigate
  }

  toTicket(ticketID: number) {
    this.navigate({ pathname: "/ticket/" + ticketID })
  }

  toTicketNewWindow(ticketID: number) {
    window.open("ticket/" + ticketID)
  }

  toHome() {
    this.navigate({ pathname: "/" })
  }

  toPatientData(ticketID: number) {
    this.navigate({ pathname: "/ticket/" + ticketID + "/patient_data/" })
  }

  toArticles(ticketID: number) {
    this.navigate({ pathname: "/articles/" + ticketID })
  }

  toProfile() {
    this.navigate({ pathname: "/profile/" })
  }

  toNewTicketPage(patientId: number) {
    this.navigate({ pathname: "/new_ticket/" + patientId })
  }

  toModifyTicketPage(ticketId: number) {
    this.navigate({ pathname: "/modify_ticket/" + ticketId })
  }
}
