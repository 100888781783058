export function getAddFormFunctions(
  setNumOfFields: (value: number) => void,
  numOfFields: number,
  setFieldValue: (fieldName: string, value: any) => void,
  fieldName: string,
  checkBoxValName: string,
  defaultValue: any,
) {
  function addField() {
    setNumOfFields(numOfFields + 1)
  }

  function deleteAllEntries() {
    setFieldValue(fieldName, defaultValue)
    setFieldValue(checkBoxValName, false)
    setNumOfFields(0)
  }

  function handleRadioClick(value: boolean) {
    if (!value) deleteAllEntries()
    else if (numOfFields === 0) addField()
  }
  return { addField, deleteAllEntries, handleRadioClick }
}
