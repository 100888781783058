import { Formik } from "formik"
import React, { useContext, useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { patchTicket } from "src/apicalls/patchTicket"
import { GlobalContext } from "src/App"
import GenericButton from "src/common-components/GenericButton/GenericButton"
import OutpatientSheetDataTable from "src/common-components/OutpatientSheetDataTable/OutpatientSheetDataTable"
import Popup from "src/common-components/Popup/Popup"
import MyTextInput from "src/common-components/TextInput/TextInput"
import { getDateFromInt, getSymptomName, givenNumberOfDigits } from "src/common/utilityFunctions"
import { necessary } from "src/common/validationFunctions"
import { OutpatientFormData, Ticket } from "src/types"
import ReactDOM from "react-dom"
import urls from "../../common/urls"
import styles from "./OutpatientSheet.module.scss"

type SymptomListProps = {
  name: string
  date: { start: number; end: number | null }
}

const SymptomListItem: React.FC<SymptomListProps> = ({ name, date }) => {
  const endDateDisplayFormat = date.end ? getDateFromInt(date.end) : null
  const startDateDisplayFormat = getDateFromInt(date.start)
  return (
    <div title="Kattintson a másoláshoz" className={styles.tableDataRow}>
      <p onClick={() => navigator.clipboard.writeText(name)}>{name}</p>
      <p
        onClick={() => navigator.clipboard.writeText(startDateDisplayFormat)}
        className={styles.tableData}
      >
        {startDateDisplayFormat + "- "}
      </p>
      {endDateDisplayFormat ? (
        <p
          onClick={() => navigator.clipboard.writeText(endDateDisplayFormat)}
          className={styles.tableData}
        >
          {endDateDisplayFormat}
        </p>
      ) : null}
    </div>
  )
}

type OutpatientSheetProps = {
  ticket: Ticket
  required?: boolean
  onClose: () => void
  initialValues: OutpatientFormData
}

const validateForm = (values: OutpatientFormData) => {
  let errors = {}
  necessary(values, "diagnosis", errors)
  necessary(values, "opinion", errors)
  necessary(values, "status", errors)
  necessary(values, "therapy", errors)
  return errors
}

const OutpatientSheet: React.FC<OutpatientSheetProps> = ({
  ticket,
  onClose,
  required,
  initialValues,
}) => {
  const [overlayVisible, setOverlayVisible] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const outpatientSheetRef = useRef<HTMLDivElement>(null)
  const { userToken, setShouldUpdateUserDataFromServer } = useContext(GlobalContext)
  const [popupVisible, setPopupVisible] = useState<boolean>(false)
  const navigate = useNavigate()

  const formikRef = useRef<any>(null)

  useEffect(() => {
    setOverlayVisible(true)
  }, [])

  const onSubmit = async (values: OutpatientFormData) => {
    setLoading(true)
    await patchTicket(userToken, {
      ticketID: ticket.ticketID,
      ticketData: {
        ...ticket.ticketData,
        outpatientData: { ...values },
      },
      ready: true,
    })
    setShouldUpdateUserDataFromServer(true)
    navigate({ pathname: urls.userHomepageUrl }) //TODO: handle communication error
  }

  const symptomList: JSX.Element[] = ticket.ticketData.symptoms.map((s) => {
    return (
      <SymptomListItem
        key={s.symptomID}
        name={getSymptomName(s.symptomID)}
        date={s.symptomInterval}
      />
    )
  })

  return ReactDOM.createPortal(
    <div
      className={overlayVisible ? [styles.container, styles.visible].join(" ") : styles.container}
    >
      <div className={styles.panel} ref={outpatientSheetRef}>
        <div className={styles.top}>
          <h1>#{givenNumberOfDigits(3, ticket.ticketID)} - Összefoglaló lap</h1>
          <i onClick={onClose} className={["material-icons", "notranslate"].join(" ")}>
            close
          </i>
        </div>

        <div className={styles.tableTitle}>
          <h2>Páciens adatai</h2>
          <p>adatok másolásához kattintson a mezőkre</p>
        </div>

        <div className={styles.tableDataContainer}>
          <OutpatientSheetDataTable
            ticket={ticket}
            patientProfile={ticket.patientProfile}
            userProfile={ticket.userProfile}
          />
        </div>

        {symptomList?.length > 0 && (
          <>
            <div className={styles.tableTitle}>
              <h2>Tünetek</h2>
              <p>adatok másolásához kattintson a mezőkre</p>
            </div>
            <div className={styles.tableDataContainer}>
              <div className={styles.tableDataRow}>
                <p>Tünet neve</p>
                <p className={styles.tableData}>
                  <b>Tünet fennállásának időtartama</b>
                </p>
              </div>
              {symptomList}
            </div>
          </>
        )}

        <div className={styles.tableTitle}>
          <h2>Egyéb adatok</h2>
          <p>adatok másolásához kattintson a mezőkre</p>
        </div>
        <div className={styles.tableDataContainer}>
          <div
            onClick={() => navigator.clipboard.writeText("telemedicinális")}
            className={styles.tableDataRow}
          >
            <p>Ellátás formája</p>
            <p className={styles.tableData}>
              <b>Telemedicinális</b>
            </p>
          </div>
        </div>

        <div className={styles.form}>
          <Formik
            innerRef={formikRef}
            initialValues={initialValues}
            onSubmit={(data) => onSubmit(data)}
            validate={required ? (values) => validateForm(values) : undefined}
          >
            {({ values }) => (
              <>
                <h2
                  onClick={() => navigator.clipboard.writeText(values.status)}
                  title="Kattintson a másoláshoz"
                >
                  Jelen panasz
                  <i className={["material-icons", "notranslate"].join(" ")}>content_copy</i>
                </h2>
                <MyTextInput type="text" rows={3} name="status" />
                <h2
                  onClick={() => navigator.clipboard.writeText(values.diagnosis)}
                  title="Kattintson a másoláshoz"
                  className={styles.formLabel}
                >
                  Diagnózis
                  <i className={["material-icons", "notranslate"].join(" ")}>content_copy</i>
                </h2>
                <MyTextInput type="text" rows={3} name="diagnosis" />
                <h2
                  onClick={() => navigator.clipboard.writeText(values.therapy)}
                  title="Kattintson a másoláshoz"
                  className={styles.formLabel}
                >
                  Terápia
                  <i className={["material-icons", "notranslate"].join(" ")}>content_copy</i>
                </h2>
                <MyTextInput type="text" rows={3} name="therapy" />
                <h2
                  onClick={() => navigator.clipboard.writeText(values.opinion)}
                  title="Kattintson a másoláshoz"
                  className={styles.formLabel}
                >
                  Vélemény
                  <i className={["material-icons", "notranslate"].join(" ")}>content_copy</i>
                </h2>
                <MyTextInput type="text" rows={3} name="opinion" />
              </>
            )}
          </Formik>
        </div>

        <div className={styles.buttonContainer}>
          <GenericButton action={onClose} text="Vissza" style="grey" />
          <GenericButton
            action={() => {
              formikRef.current.validateForm().then((r: Object) => {
                if (Object.keys(r).length == 0) setPopupVisible(true)
                else formikRef.current.submitForm()
              })
            }}
            text="Ügy lezárása"
            style="blue"
            loading={loading}
          />
        </div>
      </div>
      {popupVisible ? (
        <Popup
          title="Összefoglaló lap véglegesítése"
          onClose={() => setPopupVisible(false)}
          confirmButton={{
            title: "Ügy lezárása",
            action: () => {
              setPopupVisible(false)
              formikRef.current.submitForm()
            },
          }}
          cancelButton={{
            title: "Áttekintés",
          }}
        >
          <p className={styles.popupContent}>
            Ha az Összefoglaló lap bejegyzéseit más szoftverben is rögzíteni kívánja, az ügy
            lezárása előtt másolja le az adatokat a táblázatokból!
          </p>
        </Popup>
      ) : null}
    </div>,
    document.body,
  )
}

export default OutpatientSheet
