import React from "react"
import { useIsMobileSize } from "../../common/hooks/useIsMobileSize"
import styles from "./Deck.module.scss"

interface Props {
  formState: number
  className?: string
}

const Deck: React.FC<Props> = ({ formState, children, className }) => {
  const isMobile = useIsMobileSize()
  const translateValue = isMobile ? "-105%" : "-60vw"

  /*****************************************************************/

  const getStyle = (index: number): React.CSSProperties => {
    const placeInStack = index - formState
    if (0 > placeInStack) {
      return { transform: `translateX(${translateValue})`, zIndex: 50 - index }
    }
    return {
      transform: isMobile
        ? undefined
        : `translateY(${-2 * placeInStack}px) translateX(${10 * placeInStack}px)`,
      zIndex: 50 - index,
    }
  }

  /**************************************************************** */

  const elements = React.Children.map(children, (child, index) => {
    return (
      <div className={`${styles.child} ${className}`} style={getStyle(index)}>
        {child}
      </div>
    )
  })

  return <div className={styles.container}>{elements}</div>
}

export default Deck
