import React from "react"
import { useNavigate } from "react-router-dom"
import { useOVNavigator } from "../../common/hooks/useOVNavigator"
import NewNotiCirc from "src/dr-components/DrHomePage/PageSelector/NewNotiCirc"

import logoImg from "../../imgs/logo-new-black.png"
import OVIcon from "../Icon/OVIcon"
import styles from "./NavBar.module.scss"
import { useNumOfRelevantNotifications } from "../SidebarButtonsOverlayOpening/sidebarButtonOverlayOpening_utils"

interface Props {
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>
  menuButtonRef: React.MutableRefObject<HTMLButtonElement | null>
}

const NavBar: React.FC<Props> = ({ setSidebarOpen, menuButtonRef }) => {
  const navigate = useNavigate()
  const OVNavigator = useOVNavigator()
  const numOfNotifications = useNumOfRelevantNotifications()

  return (
    <div className={styles.container}>
      <OVIcon className={styles.backButton} name="arrow_back" onClick={() => navigate(-1)} />
      <img src={logoImg} onClick={() => OVNavigator.toHome()} />
      <OVIcon
        className={styles.menuButton}
        name={"menu"}
        onClick={() => setSidebarOpen((s) => !s)}
        buttonRef={menuButtonRef}
      />
      <NewNotiCirc
        numOfNotifications={numOfNotifications}
        circleOnly
        className={styles.notificationCircle}
        color="orange"
      />
    </div>
  )
}

export default NavBar
