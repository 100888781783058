import { useContext } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { GlobalContext } from "../../App"
import { getActiveUser } from "../../common/user/user"
import { Doctor, Notification, OverlayType, overlayTypes, User } from "../../types"
import { isOldConsultation } from "../VideoCallsLayout/ConsultationsTab"

export const useOpenOverlay = () => {
  const location = useLocation()
  const path = location.pathname
  let openOverlay = null
  overlayTypes.forEach((o) => {
    if (path.includes(o)) openOverlay = o
  })
  return openOverlay as null | OverlayType
}

export const addOverlayToPath = (path: string, overlay: OverlayType) => {
  if (path.slice(-1) === "/") return path + overlay
  return path + "/" + overlay
}

export const removeOverlayFromPath = (path: string) => {
  let newPath = path
  overlayTypes.forEach((o) => {
    if (newPath.includes(o)) {
      newPath = newPath.replace(o, "")
    }
  })
  return newPath
}

export const useCloseOverlays = () => {
  const location = useLocation()
  const navigate = useNavigate()
  return () => navigate({ pathname: removeOverlayFromPath(location.pathname) })
}

const getUnseenMessageNotifications = (n: { seen: Notification[]; unseen: Notification[] }) => {
  return n.unseen.filter((n) => n.subject === "new-message")
}

const getUnseenBasicNotifications = (n: { seen: Notification[]; unseen: Notification[] }) => {
  return n.unseen.filter((n) => n.subject !== "new-message")
}

const getNumOfRelevantConsultations = (doctor: Doctor, user: User) => {
  return (
    getActiveUser(doctor, user).videoCalls.filter((v) => v.answer == null && !isOldConsultation(v))
      .length +
    getActiveUser(doctor, user).personalConsultations.filter((c) => !isOldConsultation(c)).length
  )
}

export const useNumOfRelevantNotifications = (type?: OverlayType) => {
  const { notifications, user, doctor } = useContext(GlobalContext)

  if (!type)
    return (
      getUnseenBasicNotifications(notifications).length +
      getUnseenMessageNotifications(notifications).length +
      getNumOfRelevantConsultations(doctor, user)
    )

  if (type === "consultations-overlay") return getNumOfRelevantConsultations(doctor, user)
  if (type === "msg-overlay") return getUnseenMessageNotifications(notifications).length
  return getUnseenBasicNotifications(notifications).length
}

export const useIsVideoCallHappeningSoon = () => {
  const { user, doctor } = useContext(GlobalContext)

  return getActiveUser(doctor, user)
    .videoCalls.filter((v) => v.answer == null)
    .find((v) => {
      const seconds = 5 * 60
      return v.date - seconds < new Date().getTime() / 1000
    })
}
