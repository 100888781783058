import React, { useContext, useState } from "react"
import { createPatient, patchPatient } from "src/apicalls"
import { GlobalContext } from "src/App"
import GenericButton from "src/common-components/GenericButton/GenericButton"
import {
  CreatePatientData,
  PatchPatientData,
  PatientProfileData,
  Patient,
  ApiResponse,
  NewPatientHistoryType,
} from "src/types"
import PatientDataOverview from "./PatientDataOverview"
import Popup from "src/common-components/Popup/Popup"
import styles from "./FormDataReview.module.css"
import { Location, useLocation, useNavigate } from "react-router-dom"
import urls from "../../../../common/urls"

interface Props {
  backAction: React.Dispatch<React.SetStateAction<number>>
  patientData: PatientProfileData
  setKey: React.Dispatch<React.SetStateAction<number>>
}

const clearThePlaceHolders = (values: CreatePatientData) => {
  let newValues = values
  const newDrugAllergies = values.drugAllergy?.filter((string) => string !== "")
  newValues.drugAllergy = newDrugAllergies

  const newHospitalVisits = values.hospitalVisits.filter((visit) => visit.diagnose !== "")
  newValues.hospitalVisits = newHospitalVisits

  const newIllnesses = values.illnesses.filter((illness) => {
    const newDrugs = illness.drugs.filter((drug) => drug.name)
    illness.drugs = newDrugs
    return illness.illnessName != undefined && illness.illnessName !== ""
  })
  newValues.illnesses = newIllnesses

  return newValues
}

const FormDataReview: React.FC<Props> = ({ backAction, patientData, setKey }) => {
  const { userToken, setShouldUpdateUserDataFromServer } = useContext(GlobalContext)

  const cleanValues = clearThePlaceHolders(patientData)
  const [confirmPopupVisible, setConfirmPopupVisible] = useState<boolean>(false)
  const [patientCreated, setPatientCreated] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const navigate = useNavigate()
  const location = useLocation() as Location & { state: NewPatientHistoryType }

  const isPatching = !!location.state?.patientID

  const handleSubmit = async () => {
    let res: ApiResponse | null = null
    setLoading(true)

    if (isPatching) {
      res = await patchPatient(userToken, {
        patientID: location.state?.patientID,
        patientProfile: cleanValues as CreatePatientData,
      } as PatchPatientData)
    } else {
      res = await createPatient(userToken, cleanValues as CreatePatientData)
    }

    setLoading(false)

    if (res && res.status?.result === "ok") {
      setShouldUpdateUserDataFromServer(true)
      setPatientCreated(true)
    } else console.log(res.status) //TODO: hibaüzenet mutatása
  }

  return (
    <div className={styles.container}>
      <PatientDataOverview values={cleanValues as any} />
      <div className={styles.buttonCont}>
        <GenericButton
          action={() => backAction((value) => value - 1)}
          text="Vissza"
          style="whiteBlue"
        />
        <GenericButton
          action={() => {
            setConfirmPopupVisible(true)
          }}
          text="Befejezés"
          style="blue"
        />
      </div>
      {confirmPopupVisible ? (
        <Popup
          title={
            patientCreated
              ? isPatching
                ? "Páciens adatok frissítve"
                : "Páciens rögzítve"
              : "Adatok megerősítése"
          }
          onClose={
            patientCreated ? () => setKey((s) => s + 1) : () => setConfirmPopupVisible(false)
          }
          confirmButton={{
            title: patientCreated ? "Főoldal" : "Megerősítés",
            action: patientCreated
              ? () => navigate({ pathname: urls.userHomepageUrl })
              : () => handleSubmit(),
          }}
          cancelButton={
            isPatching
              ? undefined
              : {
                  title: patientCreated ? "További páciens felvétele" : "Áttekintés",
                }
          }
          panelStyle={{
            minWidth: "30rem",
          }}
          loading={loading}
          aszf
          dataManagement
        >
          {patientCreated ? (
            isPatching ? (
              <p></p>
            ) : (
              <>
                <p className={styles.note}>
                  További páciensek felvételére később is lehetősége van a profil menüpontban.
                </p>
              </>
            )
          ) : undefined}
        </Popup>
      ) : null}
    </div>
  )
}

export default FormDataReview
