import { InvalidRequestError, ServerError } from "../common/errors"
import { ApiResponse } from "../types"
import { config } from "src/config"

export const APIBASEURL = config.API_URL

export const headersBase = {
  "X-Api-Key": config.API_KEY,
}

abstract class RestMethod<Message> {
  url: string
  defaultHeaders: { [key: string]: string }

  constructor(url: string, userToken: string) {
    this.url = url
    this.defaultHeaders = {
      ...headersBase,
      "User-Token": userToken,
    }
  }
}

export class Getter<
  SearchParams extends { [key: string]: string } | null,
  Message = null,
> extends RestMethod<Message> {
  async get(searchParams: SearchParams) {
    const params = searchParams ? new URLSearchParams(searchParams!) : ""
    const res = await fetch(APIBASEURL + "/" + this.url + params, {
      method: "GET",
      headers: { ...this.defaultHeaders },
    })
    const result: ApiResponse<Message> = await res.json()

    if (result?.status?.result !== "ok") throw result

    return result
  }
}

export class Poster<
  Body extends { [key: string]: any },
  Message = null,
> extends RestMethod<Message> {
  async post(body: Body) {
    try {
      const res = await fetch(APIBASEURL + "/" + this.url, {
        method: "POST",
        headers: {
          ...this.defaultHeaders,
          "content-type": "application/json",
        },
        body: JSON.stringify(body),
      })
      const result: ApiResponse<Message> = await res.json()

      if (result?.status?.result !== "ok") throw new InvalidRequestError(result)

      return result
    } catch (e) {
      if (!(e instanceof InvalidRequestError)) throw new ServerError(e)
      throw e
    }
  }
}

export abstract class Service<
  GetSearchParams extends { [key: string]: string } | null,
  GetResponse,
  PostBody,
  PostResponse,
> {
  private baseUrl: string
  protected poster: Poster<PostBody, PostResponse>
  protected getter: Getter<GetSearchParams, GetResponse>

  constructor(userToken: string, baseUrl: string) {
    this.baseUrl = baseUrl
    this.poster = new Poster(this.baseUrl, userToken)
    this.getter = new Getter(this.baseUrl, userToken)
  }
}

export { createExtendedUserProfile } from "./createExtendedUserProfile"
export { createPatient } from "./createPatient"
export { createTicket } from "./createTicket"
export { patchTicket } from "./patchTicket"
export { getUserData } from "./getUserData"
export { patchPatient } from "./patchPatient"
export { deletePatient } from "./deletePatient"
