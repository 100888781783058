import React from "react"
import { updateEmail } from "../../apicalls/updateEmail"
import CheckboxFormik from "../../common-components/Checkbox/CheckboxFormik"
import MyTextInput from "../../common-components/TextInput/TextInput"
import { openDocument } from "../../common/openDocuments"
import { necessary } from "../../common/validationFunctions"
import { DoctorExtProfCreation } from "./CreateDoctorExtendedProfilePage"
import styles from "./CreateDrExtProfFourthCard.module.css"
import Header from "./Header"

export const validateFourthPage = async (values: DoctorExtProfCreation, userToken: string) => {
  const errors = {} as { [key in keyof DoctorExtProfCreation]: string }
  if (!values.termsOfUseAccepted) errors.termsOfUseAccepted = "Kötelező elfogadni"
  if (!values.dataHandlingAccepted) errors.dataHandlingAccepted = "Kötelező elfogadni"
  if (!values.wageAccepted) errors.wageAccepted = "Kötelező elfogadni"
  necessary(values, "email", errors)
  if (!errors.email) {
    const res = await updateEmail(userToken, { email: values.email })
    if (res.status.result !== "ok") errors.email = "A megadott e-mail cím már használatban van"
  }
  return errors
}

export interface DrExtProfCreationLastCard {
  termsOfUseAccepted: boolean
  dataHandlingAccepted: boolean
  email: string
  wageAccepted: boolean
}

interface Props {
  setFormState: React.Dispatch<React.SetStateAction<number>>
}

const CreateDrExtProfFourthCard: React.FC<Props> = ({ setFormState }) => {
  return (
    <>
      <Header title="Elfogadás" setFormState={setFormState} />
      <div className={styles.container}>
        <CheckboxFormik
          label={
            <p>
              Elolvastam és megértettem az{" "}
              <strong onClick={() => openDocument("data")}>adatkezelési tájékoztatóban</strong>{" "}
              foglaltakat
            </p>
          }
          name="dataHandlingAccepted"
        />
        <CheckboxFormik
          label={
            <p>
              Elolvastam és elfogadtam az <strong onClick={() => openDocument("aszf")}>ÁSZF</strong>
              -et
            </p>
          }
          name="termsOfUseAccepted"
        />
        <CheckboxFormik label="Elfogadom a díjazás feltételeit" name="wageAccepted" />
        <MyTextInput name="email" label="Erősítse meg az email címét" />
      </div>
    </>
  )
}

export default CreateDrExtProfFourthCard
