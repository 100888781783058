import { toBase64 } from "src/common/utilityFunctions"
import {
  ApiResponse,
  AssignedMedia,
  CreateTicketApiData,
  Image,
  MediaAtCreation,
  TicketData,
} from "src/types"
import { APIBASEURL, headersBase } from "."
import { createImage } from "./createImage"

export const uploadMedia = async (
  userToken: string,
  files: MediaAtCreation[],
): Promise<AssignedMedia[]> => {
  const base64Files = await Promise.all(
    files.map(async (file): Promise<Image> => {
      const res = await toBase64(file.file)
      return { data: res, meta: file.meta }
    }),
  )

  const imageCreationResult = await Promise.all(
    base64Files.map((base64File) => {
      return createImage(userToken, {
        data: base64File.data,
        meta: base64File.meta,
      })
    }),
  )

  const mediaData = imageCreationResult.map((apiResp, index) => {
    return {
      id: apiResp.status!.message.imageID,
      name: files[index].meta.description || files[index].meta.name,
    }
  })

  return mediaData
}

export const addUploadedMediaToTicketData = (media: AssignedMedia[], ticketData: TicketData) => {
  const updatedTicketData: TicketData = {
    ...ticketData,
    files: [...ticketData.files, ...media],
  }
  return updatedTicketData
}

export const createTicket = async (
  userToken: string,
  body: CreateTicketApiData,
  files: MediaAtCreation[],
) => {
  const base64Files = await Promise.all(
    files.map(async (file): Promise<Image> => {
      const res = await toBase64(file.file)
      return { data: res, meta: file.meta }
    }),
  )
  body = { ...body, mediaArray: base64Files }

  const res = await fetch(APIBASEURL + "/ticket", {
    method: "POST",
    headers: {
      ...headersBase,
      "User-Token": userToken,
      "content-type": "application/json",
    },
    body: JSON.stringify(body),
  })
  const result: ApiResponse = await res.json()
  return result
}
