import React from "react"
import MyTextInput from "../../common-components/TextInput/TextInput"
import { createFormikPathsObj } from "../../common/utilityFunctions"
import { necessary } from "../../common/validationFunctions"
import { DoctorProfile, DoctorProfileThirdCard } from "../../types"
import { DrExtProfCreationLastCard } from "./CreateDrExtProfFourthCard"
import Header from "./Header"

export const validateCompany = (values: DoctorProfile & DrExtProfCreationLastCard) => {
  let errors = {} as any
  necessary(values, "company.name", errors)
  necessary(values, "company.seat", errors)
  necessary(values, "company.companyNumber", errors)
  necessary(values, "company.rep", errors)
  necessary(values, "company.VATnumber", errors)
  necessary(values, "company.bankAccountNumber", errors)
  return errors
}

interface Props {
  initialValues: DoctorProfileThirdCard
  setFormState?: React.Dispatch<React.SetStateAction<number>>
}

const CreateDrExtProfThirdCard: React.FC<Props> = ({ initialValues, setFormState }) => {
  return (
    <>
      {setFormState ? <Header title="Cégadatok" setFormState={setFormState} /> : null}
      <MyTextInput name="company.name" label="Cégnév" />
      <MyTextInput name="company.seat" label="Székhely" />
      <MyTextInput name="company.companyNumber" label="Cégjegyzékszám" />
      <MyTextInput name="company.VATnumber" label="Adószám" />
      <MyTextInput name="company.rep" label="Képviselő" />
      <MyTextInput name="company.bankAccountNumber" label="Bankszámlaszám" />
    </>
  )
}
export default CreateDrExtProfThirdCard
