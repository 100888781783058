import React, { useContext, useRef, useState } from "react"
import { GlobalContext } from "src/App"
import DateRangePicker from "src/common-components/DateRangePicker/DateRangePicker"
import GenericButton from "src/common-components/GenericButton/GenericButton"
import Search from "src/common-components/Search/Search"
import { useOnClickOutside } from "src/common/hooks/useOnClickOutside"
import { drHomePages } from "../../types"
import styles from "./DrHomePage.module.scss"
import PageSelector from "./PageSelector/PageSelector"
import TicketFilterOptions from "./TicketOverviewPage/TicketFilterOptions/TicketFilterOptions"
import TicketOverviewPage from "./TicketOverviewPage/TicketOverviewPage"
import {
  useClosedTickets,
  useRememberScrollPosition,
  useTicketsToShow,
  useTooLongIntervalHandling,
} from "./utils"

const DrHomePage: React.FC = () => {
  const { doctor, activePage, setActivePage } = useContext(GlobalContext)
  const [endDate, setEndDate] = useState<Date>(new Date(new Date()))
  const [startDate, setStartDate] = useState<Date>(new Date(new Date().getTime() - 2592000000))
  const [shouldShowDatePicker, setShouldShowDatePicker] = useState<boolean>(false)
  const buttonRef = useRef<HTMLDivElement>(null)

  const ref = useOnClickOutside<HTMLDivElement>(() => setShouldShowDatePicker(false), buttonRef)

  const [closedTickets, loadingTickets] = useClosedTickets(startDate, endDate)
  const ticketsToShow = useTicketsToShow(closedTickets)
  useTooLongIntervalHandling(startDate, endDate, setEndDate)
  useRememberScrollPosition()

  return (
    <div className={styles.container}>
      <PageSelector
        activePage={activePage}
        setActivePage={setActivePage}
        cases={{
          inProgress: doctor.tickets.inProgress.length,
          incoming: doctor.tickets.incoming.length,
          open: doctor.tickets.open.length,
        }}
      />
      <div className={styles.topContainer}>
        <div>
          <TicketFilterOptions />
          <Search />
        </div>
        {activePage === drHomePages.closed ? (
          <>
            <div className={styles.showCalendarContainer}>
              <p>
                Egyszerre maximum <b>60</b> napot tud kiválasztani
              </p>
              <div ref={buttonRef}>
                <GenericButton
                  action={() => setShouldShowDatePicker((s) => !s)}
                  style="whiteBlue"
                  text={shouldShowDatePicker ? "Bezárás" : "Naptár mutatása"}
                />
              </div>
            </div>
            {shouldShowDatePicker ? (
              <div ref={ref} className={styles.datePickerContainer}>
                <DateRangePicker
                  endDate={endDate}
                  startDate={startDate}
                  setEndDate={setEndDate}
                  setStartDate={setStartDate}
                  maxDate={new Date()}
                />
              </div>
            ) : null}
          </>
        ) : null}
      </div>
      <TicketOverviewPage
        tickets={ticketsToShow}
        loading={loadingTickets && activePage === drHomePages.closed}
      />
    </div>
  )
}

export default DrHomePage
