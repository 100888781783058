import { FormikBag } from "formik"
import React, { useContext, useRef, useState } from "react"
import GenericButton from "src/common-components/GenericButton/GenericButton"
import MyTextInput from "src/common-components/TextInput/TextInput"
import { necessary } from "src/common/validationFunctions"
import { Patient, PrescriptionFormData, Task } from "src/types"
import { newTicketFormContext } from "../NewTicketPage"
import TaskCardBase from "../TaskCardBase"
import WithoutSicknessInputs from "./WithoutSicknessInputs"
import YesPatientIllness from "./YesPatientIllness"
import styles from "./PrescriptionTaskCard.module.css"

interface Props {
  patient: Patient
  taskID: number
}

const initialValues: PrescriptionFormData = {
  drugIndex: "",
  illnessIndex: "",
  drugName: "",
  prescriptionReason: "",
  amount: "",
  additionalInfo: "",
}

const initialErrors = initialValues

const validate = (values: PrescriptionFormData, illnesses: any) => {
  let errors = {} as PrescriptionFormData
  necessary(values, "illnessIndex", errors)
  if (values.illnessIndex !== "Egyéb") necessary(values, "drugIndex", errors)
  if (values.illnessIndex === "Egyéb" || !illnesses) {
    necessary(values, "drugName", errors)
    necessary(values, "prescriptionReason", errors)
  }
  return errors
}

const RecipeTaskCard: React.FC<Props> = ({ patient, taskID }) => {
  const formContext = useContext(newTicketFormContext)
  const patientProfile = patient.profile
  const [formikBag, setFormikBag] = useState<any>(null)
  const taskIndex = formContext.formValues.tasks.findIndex((task) => task.taskID === taskID)

  const createNewPrescriptionTask = () => {
    formikBag.current.handleSubmit()
    if (formikBag.current.isValid) {
      let tasks = formContext.formValues.tasks
      const newTaskID =
        tasks.map((task) => task.taskID).reduce((acc, curr) => Math.max(acc, curr)) + 1
      const prescriptionTask: Task = {
        amount: "",
        drugIndex: "",
        drugName: "",
        illnessIndex: "",
        prescriptionComment: "",
        prescriptionReason: "",
        doctorData: {
          comment: null,
          files: [],
          receivable: false,
          receivableOnline: false,
        },
        finishedAt: null,
        name: "prescription",
        state: "wip",
        taskID: newTaskID,
        additionalInfo: "",
      }
      const tasksCopy = tasks.slice()
      const actualTaskIndex = tasks.findIndex((task) => task.taskID === taskID)
      tasksCopy.splice(actualTaskIndex + 1, 0, prescriptionTask)
      formContext.setFormValues((formValues) => {
        return { ...formValues, tasks: tasksCopy }
      })
    }
  }

  return (
    <TaskCardBase
      initialErrors={initialErrors}
      initialValues={initialValues}
      title="Recept kéréséhez szükséges adatok"
      formikValidate={(values: PrescriptionFormData) => validate(values, patientProfile.illnesses)}
      formContext={formContext}
      setFormikBagToParentState={setFormikBag}
      path={`tasks[${taskIndex}]`}
      gap
    >
      {!patientProfile.illnesses ? (
        <WithoutSicknessInputs />
      ) : (
        <YesPatientIllness illnesses={patientProfile.illnesses} />
      )}
      <MyTextInput name="amount" label="Hány heti adagot kér?" />
      <div className={styles.newPrButtonCont}>
        <GenericButton
          text="További recept kérése"
          action={createNewPrescriptionTask}
          style="grey"
        />
      </div>
    </TaskCardBase>
  )
}

export default RecipeTaskCard
