import React, { useContext } from "react"
import { GlobalContext } from "src/App"
import GenericButton from "src/common-components/GenericButton/GenericButton"
import {
  getDateFromInt,
  getDisplayNameFromNames,
  getSymptomNames,
  getTaskDisplayName,
} from "src/common/utilityFunctions"
import { Ticket } from "src/types"
import { useIsMobileSize } from "../../../common/hooks/useIsMobileSize"
import { useOVNavigator } from "../../../common/hooks/useOVNavigator"
import styles from "./ClosedTicketView.module.scss"
import TicketLabel from "./TicketLabel"

const ClosedTicketView: React.FC<{ ticket: Ticket }> = ({ ticket }) => {
  const ticketData = ticket.ticketData
  const tasks = ticketData.tasks

  const isMobile = useIsMobileSize()

  const OVNavigator = useOVNavigator()
  const { user } = useContext(GlobalContext)

  const labels = tasks.map((task, index) => {
    return <TicketLabel taskName={getTaskDisplayName(task)} key={index} />
  })

  return (
    <div
      className={styles.container}
      onClick={() => isMobile && OVNavigator.toTicket(ticket.ticketID)}
    >
      <div className={styles.ticketContainer}>
        {ticket.priority ? <i className={`material-icons ${styles.priorIcon}`}>report</i> : null}
        <div className={styles.topRow}>
          <p id={styles.title}>
            #{("000" + ticket.ticketID).substr(-3)} -{" "}
            {getDisplayNameFromNames(user.patients.find((p) => p.id == ticket.patientID)?.profile!)}
          </p>
          <div className={styles.labelsCont}>{labels}</div>
        </div>

        <div className={styles.secondRow}>
          <i className={["material-icons", "notranslate"].join(" ")}>person</i>
          <p>{getDisplayNameFromNames(ticket.doctor?.name)} </p>
          <i className={["material-icons", "notranslate"].join(" ")}>access_time</i>
          <p> {getDateFromInt(ticket.ticketData.createdAt!)} </p>
          <i className={["material-icons", "notranslate"].join(" ")}>assignment_turned_in</i>
          <p> {getDateFromInt(ticket.ticketData.closedAt!)} </p>
        </div>

        <div id={styles.lastRow}>
          <p>{getSymptomNames(ticket.ticketData.symptoms)?.join(", ")}</p>
          {!isMobile && (
            <div className={styles.buttonCont}>
              <GenericButton
                style="whiteBlue"
                text="Megnyitás"
                action={() => OVNavigator.toTicket(ticket.ticketID)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ClosedTicketView
