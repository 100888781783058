import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import FileUpload from "src/common-components/FileUpload/FileUpload"
import MyTextInput from "src/common-components/TextInput/TextInput"
import { necessary } from "src/common/validationFunctions"
import { ImageMeta, SickNoteTaskData, SickPayTaskData, Task } from "src/types"
import { newTicketFormContext } from "../NewTicketPage"
import TaskCardBase from "../TaskCardBase"
import styles from "./FinalCommentCard.module.css"
import IntervalChooser from "../../../common-components/IntervalChooser/IntervalChooser"
import Info from "src/common-components/Info/Info"
import TicketFilterOptions from "../../../dr-components/DrHomePage/TicketOverviewPage/TicketFilterOptions/TicketFilterOptions"
import { toServerTime } from "src/common/utilityFunctions"

interface Props {
  tasks: Task[]
  files: { file: File; meta: ImageMeta }[]
  setFiles: React.Dispatch<React.SetStateAction<{ file: File; meta: ImageMeta }[]>>
  isOngoingTicket: boolean
}

const validate = (values: { comment: string }, shouldValidate: boolean) => {
  let errors = {}
  if (shouldValidate) necessary(values, "comment", errors)
  return errors
}

const FinalCommentCard: React.FC<Props> = ({ tasks, files, setFiles, isOngoingTicket }) => {
  const formContext = useContext(newTicketFormContext)
  const today = useRef(new Date().getTime())

  // FIXME: This always overrides symptom start time (?)
  //   const firstSymptomStart = formContext.formValues.symptoms.reduce(
  //     (acc, curr) => Math.min(acc, curr.symptomInterval.start),
  //     today
  //   )

  // Mayhaps?
  const firstSymptomStart = Math.min(
    today.current,
    ...formContext.formValues.symptoms.map((s) => s.symptomInterval.start),
  )

  const [sickPayStartDate, setSickPayStartDate] = useState<Date>(new Date())
  const [sickPayEndDate, setSickPayEndDate] = useState<Date | null>(null)
  const [sickNoteStartDate, setSickNoteStartDate] = useState<Date>(new Date())
  const [sickNoteEndDate, setSickNoteEndDate] = useState<Date | null>(null)

  const taskNames = tasks.map(({ name }) => name)

  const sickPayTask = !!taskNames.find((name) => name === "sickPay")
  const sickNoteTask = !!taskNames.find((name) => name === "sickNote")
  const diagnosisTask = !!taskNames.find((name) => name === "diagnosis")

  const shouldValidate = !isOngoingTicket && (diagnosisTask || sickNoteTask)

  const maxDate = new Date()
  maxDate.setDate(maxDate.getDate() + 5)

  useEffect(() => {
    setSickPayStartDate(new Date(firstSymptomStart))
    setSickNoteStartDate(new Date(firstSymptomStart))
  }, [firstSymptomStart])

  useEffect(() => {
    const { formValues, setFormValues } = formContext
    let formValuesCopy = { ...formValues }
    if (sickPayTask) {
      const index = tasks.findIndex((task) => task.name === "sickPay")
      let task = formValuesCopy.tasks[index] as SickPayTaskData
      task.startDate = toServerTime(sickPayStartDate)
      task.endDate = sickPayEndDate ? toServerTime(sickPayEndDate) : null
    }
    if (sickNoteTask) {
      const index = tasks.findIndex((task) => task.name === "sickNote")
      let task = formValuesCopy.tasks[index] as SickNoteTaskData
      task.startDate = toServerTime(sickNoteStartDate)
      task.endDate = sickNoteEndDate ? toServerTime(sickNoteEndDate) : null
    }
    setFormValues({ ...formValuesCopy })
  }, [
    sickPayEndDate,
    sickPayStartDate,
    sickNoteStartDate,
    sickNoteEndDate,
    sickPayTask,
    sickNoteTask,
  ])

  return (
    <TaskCardBase
      formContext={formContext}
      initialValues={{ comment: "" }}
      title="Összefoglaló"
      formikValidate={(values: { comment: string }) => validate(values, shouldValidate)}
      gap
    >
      {isOngoingTicket ? null : (
        <MyTextInput
          label={
            diagnosisTask || sickNoteTask
              ? "Írja le a páciens állapotát 3-5 mondatban!"
              : "Megjegyzés"
          }
          name="comment"
          rows={8}
          className={styles.textareaContainer}
          maxLength={400}
          focusedByDefault
          placeholder="Kérjük írja le részletesen a páciens állapotát és tüneteinek részleteit, esetleg korábban nem említett további tüneteket! Amennyiben a naplóban is felvezeti, az segíthet a megfelelő diagnózisban. "
        />
      )}
      {sickNoteTask ? (
        <>
          <p className={styles.dateTitle}>Az igazolást a következő intervallumra kérem</p>
          <IntervalChooser
            endDate={sickNoteEndDate}
            setStartDate={setSickNoteStartDate}
            startDate={sickNoteStartDate}
            setEndDate={setSickNoteEndDate}
            unknownText="Még nem tudom"
            maxDate={maxDate}
          />
        </>
      ) : null}
      {sickPayTask ? (
        <>
          <p className={styles.dateTitle}>A táppénzt a következő intervallumra kérem:</p>
          <IntervalChooser
            endDate={sickPayEndDate}
            setStartDate={setSickPayStartDate}
            startDate={sickPayStartDate}
            setEndDate={setSickPayEndDate}
            unknownText="Még nem tudom"
            maxDate={maxDate}
          />
        </>
      ) : null}
      {isOngoingTicket ? null : (
        <>
          <div className={styles.rowflex}>
            <p className={styles.uploadTitle}>Képek / dokumentumok feltöltése</p>
            <Info text="Szükség esetén fel tud tölteni dokumentumokat és képeket, amik segítik orvosa munkáját (pl.: kép egy adott tünetről)" />
          </div>
          <FileUpload
            files={files}
            setFiles={setFiles}
            relatedTicketID={null}
            maxNumberOfFiles={5}
            shouldGiveDescription
          />
        </>
      )}
    </TaskCardBase>
  )
}

export default FinalCommentCard
