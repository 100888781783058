import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { GlobalContext } from "src/App"
import GenericButton from "src/common-components/GenericButton/GenericButton"
import Loader from "src/common-components/Loader/Loader"
import { Article, ArticlePageHistory, TicketData } from "src/types"
import { getAssetData } from "../../apicalls/getAssetData"
import { useGetTicketByID } from "../../common/hooks/useGetTicketByID"
import { useOVNavigator } from "../../common/hooks/useOVNavigator"
import ArticleBlock from "./ArticleBlock/ArticleBlock"
import styles from "./ArticlePage.module.scss"
import { useMediaQuery } from "react-responsive"
import PDFDisplay from "./PDFDisplay"
import PdfSlider from "./PdfSlider"

export interface Description {
  name: string
  title: string
}

const ArticlePage: React.FC = () => {
  const { userToken } = useContext(GlobalContext)

  const ticketID = parseInt(useParams<{ ticketID: string }>().ticketID!)

  const [articles, setArticles] = useState<(Article | null)[]>([])
  const [articlesLoading, setArticlesLoading] = useState<boolean>(true)
  const [descriptions, setDescriptions] = useState<Description[]>([])

  const { ticket, loading: ticketLoading } = useGetTicketByID(ticketID)
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" })

  const ticketData = ticket?.ticketData

  const OVNavigator = useOVNavigator()

  useEffect(() => {
    if (ticketData) {
      const isCovid = !!ticketData.tasks.find((task) => task.name === "diagnosis" && task.covid)
      const symptomIDArray = ticketData.symptoms.map(({ symptomID }) => symptomID)

      getAssetData(symptomIDArray, userToken, "description", isCovid).then(({ status }) => {
        setDescriptions(status.message || [])
        setArticlesLoading(false)
      })

      getAssetData(symptomIDArray, userToken, "article", isCovid).then(({ status }) => {
        setArticles(status.message || [])
      })
    }
  }, [ticketData])

  if (articlesLoading || ticketLoading) return <Loader />

  const articleDisplayElements = (articles || []).map((article, index) => {
    return <ArticleBlock article={article} key={index} />
  })

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.titleContainer}>
          <i className={["material-icons", "notranslate"].join(" ")}>fact_check</i>
          <h2>Ügy elindítva!</h2>
        </div>
        <p className={styles.text}>
          Kérését/kérdését továbbítottuk az orvosnak. Az ügy előrehaladtával értesítéseket kap majd,
          esetleges előzetes információkkal. Amennyiben orvosának egyéb információra lenne szüksége
          üzenetben felveheti Önnel a kapcsolatot, ezért javasoljuk, hogy időközönként ellenőrizze a
          vezérlőpultot.
        </p>
        <strong>
          Ha úgy érzi, hogy potenciális életet veszélyeztető állapot áll fenn, azonnal keressen fel
          egy egészségügyi ellátót vagy hívjon mentőt a 104-es telefonszámon.
        </strong>
        <div className={styles.buttonContainer}>
          <GenericButton
            style="blue"
            text="Megnyitás"
            action={() => {
              if (ticketID) OVNavigator.toTicket(ticketID)
            }}
          />
          <GenericButton style="whiteBlue" text="Főoldal" action={() => OVNavigator.toHome()} />
        </div>
      </div>
      {isMobile ? (
        <div className={styles.innerContainer}>
          <div className={styles.titleContainer}>
            <i className={["material-icons", "notranslate"].join(" ")}>health_and_safety</i>
            <h2>Orvosi tanácsok</h2>
          </div>
          <div className={styles.mobilePdfButtonContainer}>
            {descriptions.length > 0
              ? descriptions.map((d, id) => (
                  <a key={id} href={"https://static.orvosvalasz.hu/pdf/" + d.name} target="_blank">
                    <i className={["material-icons", "notranslate"].join(" ")}>description</i>
                    {d.title}
                  </a>
                ))
              : null}
            <a href="https://static.orvosvalasz.hu/pdf/01_altalanos_tanacsok.pdf" target="_blank">
              <i className={["material-icons", "notranslate"].join(" ")}>description</i>
              Általános tanácsok
            </a>
            <h3>
              A gombokra kattintva megnyithatja az ügyhöz kapcsolódó orvosi tanácsokat és hasznos
              információkat tartalmazó leírásokat.
            </h3>
          </div>
        </div>
      ) : (
        <>
          {descriptions.length > 0 ? <PdfSlider pdfs={descriptions} /> : null}
          <PDFDisplay pdfUrl={"https://static.orvosvalasz.hu/pdf/01_altalanos_tanacsok.pdf"} />
        </>
      )}

      {articleDisplayElements.length > 0 ? (
        <div className={styles.innerContainer}>
          <div className={styles.titleContainer}>
            <i className={["material-icons", "notranslate"].join(" ")}>receipt</i>
            <h2>Ajánlott cikkek</h2>
          </div>
        </div>
      ) : null}

      {articleDisplayElements}
    </div>
  )
}

export default ArticlePage
