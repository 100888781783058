import { Formik } from "formik"
import React from "react"
import { useState } from "react"
import { useContext } from "react"
import { PasswordChanger } from "../../apicalls/PasswordChanger"
import { GlobalContext } from "../../App"
import { InvalidRequestError } from "../../common/errors"
import { necessary } from "../../common/validationFunctions"
import GenericButton from "../GenericButton/GenericButton"
import MyTextInput from "../TextInput/TextInput"
import styles from "./PasswordChange.module.scss"

interface Props {}

export interface ChangePasswordPayload {
  oldPassword: string
  newPassword: string
}

interface ChangePasswordFormData extends ChangePasswordPayload {
  newPasswordAgain: string
}

const initialValues: ChangePasswordFormData = {
  oldPassword: "",
  newPassword: "",
  newPasswordAgain: "",
}

const validateData = (values: ChangePasswordFormData) => {
  let errors = {}
  if (!values.newPassword && !values.newPasswordAgain && !values.oldPassword) return
  necessary(values, "oldPassword", errors)
  necessary(values, "newPassword", errors)
  necessary(values, "newPasswordAgain", errors)
  if (Object.keys(errors).length > 0) return errors
  if (values.newPassword.length < 8) return { newPassword: "A jelszó túl rövid" }
  if (values.newPassword !== values.newPasswordAgain)
    return { newPassword: "A megadott jelszavak nem egyeznek" }
  return
}

const PasswordChange: React.FC<Props> = () => {
  const { userToken } = useContext(GlobalContext)

  const [success, setSuccess] = useState(false)

  const submit = async (
    { newPassword, oldPassword }: ChangePasswordFormData,
    setError: (e: Partial<ChangePasswordPayload>) => void,
    resetForm: () => void,
  ) => {
    const passwordChanger = new PasswordChanger(userToken)
    try {
      await passwordChanger.post({ newPassword, oldPassword })
      setSuccess(true)
      resetForm()
      setTimeout(() => setSuccess(false), 5000)
    } catch (e) {
      if (
        e instanceof InvalidRequestError &&
        e.serverResponse.status.message === "invalid credentials"
      ) {
        setError({ oldPassword: "Helytelen jelszó" })
      } else throw e
    }
  }

  return (
    <div className={styles.container}>
      <h1>Jelszó megváltoztatása</h1>
      <Formik
        initialValues={initialValues}
        validate={(v) => validateData(v)}
        onSubmit={(values, { setErrors, resetForm }) => submit(values, setErrors, resetForm)}
      >
        {({ submitForm }) => (
          <div className={styles.form}>
            <MyTextInput name="oldPassword" label="Jelszó" type="password" />
            <MyTextInput name="newPassword" label="Új jelszó" type="password" />
            <MyTextInput name="newPasswordAgain" label="Új jelszó mégegyszer" type="password" />
            {success ? (
              <p className={styles.success}>
                Jelszó megváltoztatva <i className="material-icons notranslate">check</i>
              </p>
            ) : null}
            <GenericButton text="Jelszó mentése" style="blue" action={submitForm} />
          </div>
        )}
      </Formik>
    </div>
  )
}

export default PasswordChange
