import React, { useContext } from "react"
import { GlobalContext } from "src/App"
import Tooltip from "../Tooltip/Tooltip"
import styles from "./TicketStatus.module.scss"

interface Props {
  status: "notTaken" | "wip" | "ready" | "archived"
}

const TicketStatus: React.FC<Props> = ({ status }) => {
  const { doctor } = useContext(GlobalContext)

  const isDoctor = Object.keys(doctor).length > 0

  let statusIcon
  if (status === "notTaken") {
    const text = isDoctor
      ? "Még senki nem foglalkozik az üggyel"
      : "Az orvosa hamarosan elkezd foglalkozni az ügyével"
    statusIcon = (
      <Tooltip className={styles.tooltip} tooltipText={text}>
        <i className={`material-icons ${styles.grey}`}>label_important</i>
      </Tooltip>
    )
  } else if (status === "wip") {
    const text = isDoctor ? "Folyamatban" : "Az orvosa már dolgozik az ügyön"
    statusIcon = (
      <Tooltip className={styles.tooltip} tooltipText={text}>
        <i className={`material-icons ${styles.orange}`}>label_important</i>
        <i className={`material-icons ${styles.orange}`}>label_important</i>
      </Tooltip>
    )
  } else {
    const text = isDoctor ? "Lezárt ügy" : "Az orvosa lezárta az ügyet"
    statusIcon = (
      <Tooltip className={styles.tooltip} tooltipText={text}>
        <i className={`material-icons ${styles.blue}`}>label_important</i>
        <i className={`material-icons ${styles.blue}`}>label_important</i>
        <i className={`material-icons ${styles.blue}`}>label_important</i>
      </Tooltip>
    )
  }

  return (
    <div onClick={(e) => e.stopPropagation()} className={styles.statusCont}>
      {statusIcon}
    </div>
  )
}

export default TicketStatus
