import { useFormikContext } from "formik"
import React from "react"
import Select from "src/common-components/Select/Select"
import MyTextInput from "src/common-components/TextInput/TextInput"
import { monthOptions } from "src/common/consts"
import { getDayOptions } from "src/common/utilityFunctions"
import { UserProfile, UserProfileFirstCard } from "src/types"
import { isValidPhoneNumber, isValidTajNum, necessary } from "../../common/validationFunctions"
import styles from "./UserExtProfFirstCard.module.scss"

export const validateUserExtProfFirstPage = (values: UserProfile) => {
  let errors = {} as UserProfile
  necessary(values, "firstName", errors)
  necessary(values, "lastName", errors)
  necessary(values, "birthName", errors)
  necessary(values, "birthYear", errors)
  necessary(values, "birthMonth", errors)
  necessary(values, "birthDay", errors)
  if (
    (values.birthYear && parseInt(values.birthYear) < 1900) ||
    parseInt(values.birthYear) > new Date().getFullYear()
  )
    errors.birthYear = "Helytelen születséi év"
  if (new Date().getFullYear() - parseInt(values.birthYear) < 18)
    errors.birthYear = "A regisztrációhoz 18 betöltött életév szükséges"

  if (values.tajNum && !isValidTajNum(values.tajNum)) errors.tajNum = "Helytelen TAJ-szám"
  if (values.phone && !isValidPhoneNumber(values.phone)) errors.phone = "Helytelen telefonszám"
  return errors
}

interface Props {
  editing?: boolean
}

const UserExtProfFirstCard: React.FC<Props> = ({ editing = false }) => {
  const { values } = useFormikContext<UserProfileFirstCard>()

  return (
    <>
      <div className={styles.title}>
        <h1>Személyes profil</h1>
        <h2>A szülő adatai</h2>
      </div>
      <MyTextInput label="Vezetéknév" type="text" name="lastName" />
      <div className={`${styles.nameContainer} `}>
        <MyTextInput label="Keresztnév" type="text" name="firstName" />
        <MyTextInput label="Utónév (opc)" type="text" name="foreName" />
      </div>
      <MyTextInput label="Születési név" type="text" name="birthName" />
      <div className={styles.dateContainer}>
        <MyTextInput label="Születési év" type="text" name="birthYear" className={styles.year} />
        <Select
          label="Hónap"
          name="birthMonth"
          selectOptions={monthOptions}
          className={styles.month}
        />
        <Select
          label="Nap"
          name="birthDay"
          selectOptions={getDayOptions(values.birthYear, values.birthMonth)}
          className={styles.day}
        />
      </div>
      <MyTextInput
        label="Anyja neve (opc)"
        type="text"
        name="mothersName"
        tooltip="Egyes kérések teljesítéséhez (pl. Táppénz) szükség van az édesanyja nevének megadására."
      />
      <MyTextInput
        label="TAJ-szám (opc)"
        type="text"
        name="tajNum"
        tooltip="Ahhoz, hogy egészségével kapcsolatosan bármit (ellátás, recept, táppénz)  intézni tudjon, szükség lesz a TAJ számára."
        placeholder="pl.: 123456789"
      />
      {editing ? null : (
        <p className={"small-info-text"}>
          * Az opcionális mezők kitöltése később (pl.: táppénzes paír igénylése esetén) kötelező
          lesz. Amennyiben most megadja az adatokat később már nem kell foglalkoznia vele!
        </p>
      )}
    </>
  )
}

export default UserExtProfFirstCard
