import React, { useContext } from "react"
import { logout } from "src/apicalls/logout"
import { GlobalContext } from "src/App"
import SidebarButton from "src/common-components/SidebarButton/SidebarButton"
import Tooltip from "src/common-components/Tooltip/Tooltip"
import { useOVNavigator } from "../../common/hooks/useOVNavigator"
import { getDisplayNameFromNames } from "../../common/utilityFunctions"
import { config } from "../../config"
import logoImg from "../../imgs/logo-new-black.png"
import OverlayOpeningSingleButton from "../SidebarButtonsOverlayOpening/SidebarButtonOverlayOpening"
import { SidebarProps } from "./DrSidebar"
import styles from "./Sidebar.module.scss"
import { useMobileSidebar } from "./useMobileSidebar"

const UserSidebar: React.FC<SidebarProps> = ({ sidebarOpen, hideSidebar, menuButtonRef }) => {
  const { user, userToken } = useContext(GlobalContext)
  const name = getDisplayNameFromNames(user.userProfile)

  const sidebarRef = useMobileSidebar(hideSidebar, menuButtonRef)

  const OVNavigator = useOVNavigator()

  return (
    <div
      ref={sidebarRef}
      className={sidebarOpen ? styles.unhiddenContainer : styles.hiddenContainer}
    >
      <img id={styles.logoimg} src={logoImg} alt="logó" />
      <SidebarButton action={() => OVNavigator.toHome()} icon="home" text="Főoldal" />

      <OverlayOpeningSingleButton overlay="noti-overlay" />
      <OverlayOpeningSingleButton overlay="msg-overlay" />
      <OverlayOpeningSingleButton overlay="consultations-overlay" />

      <div className={`${styles.bottomPart}`}>
        <hr className={styles.texthr} content-text={name} />
        <SidebarButton action={() => OVNavigator.toProfile()} icon="person" text="Profil" />
        <Tooltip tooltipText="Ha javaslata van, elakadt, vagy hibát észlelt, kattintson ide!">
          <SidebarButton
            action={() => {
              window.open("mailto:" + config.SUPPORT_EMAIL, "blank")
            }}
            icon="support"
            text="Segítség"
          />
        </Tooltip>
        <SidebarButton action={() => logout(userToken)} icon="exit_to_app" text="Kijelentkezés" />
      </div>
    </div>
  )
}

export default UserSidebar
