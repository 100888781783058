import React from "react"
import MyTextInput from "../../common-components/TextInput/TextInput"
import Header from "../../dr-components/CreateDoctorExtendedProfilePage/Header"
import AddressTextInputs from "../NewPatientPage/NewPatientForm/AddressFormSlide/AddressTextInputs"
import styles from "./UserExtProfSecondCard.module.css"

interface Props {
  setFormState: (value: React.SetStateAction<number>) => void
}

const UserExtProfSecondCard: React.FC<Props> = ({ setFormState }) => {
  return (
    <>
      <Header title="Elérhetőség" setFormState={setFormState} />
      <AddressTextInputs />
      <MyTextInput label="Telefonszám" type="text" name="phone" placeholder="pl.: +36123456789" />
      <MyTextInput
        label="Foglalkozás"
        type="text"
        name="job"
        tooltip="A NEAK a táppénzes papír kiállításához igényli a munkakört/foglalkozást, amennyiben orvosa ezt nem tudja nem tud Önnek táppénzes papírt kiállítani."
      />
      <p className={"small-info-text"}>
        * Az opcionális mezők kitöltése később (pl.: táppénzes paír igénylése esetén) kötelező lesz.
        Amennyiben most megadja az adatokat később már nem kell foglalkoznia vele!
      </p>
    </>
  )
}
export default UserExtProfSecondCard
