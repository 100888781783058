import { ApiResponse, PatchTicketTaskDatesApiData } from "src/types"
import { APIBASEURL, headersBase } from "."

export const patchTicketTaskDates = async (
  userToken: string,
  ticketID: number,
  taskID: number,
  body: PatchTicketTaskDatesApiData,
) => {
  const res = await fetch(APIBASEURL + `/ticket-task-dates/${ticketID}/${taskID}`, {
    method: "PATCH",
    headers: {
      ...headersBase,
      "User-Token": userToken,
      "content-type": "application/json",
    },
    body: JSON.stringify(body),
  })
  const result: ApiResponse = await res.json()
  return result
}
