import { ApiResponse, DoctorTickets, Ticket } from "src/types"
import { APIBASEURL, headersBase } from "."

export const getSingleTicket = async (userToken: string, ticketID: number) => {
  const res = await fetch(APIBASEURL + "/ticket", {
    headers: {
      ...headersBase,
      "User-Token": userToken,
      "Ticket-ID": ticketID.toString(),
    },
  })
  const result: ApiResponse<Ticket> = await res.json()
  return result && result.status.result !== "error" ? result : null
}
