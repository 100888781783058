import React, { useEffect, useRef } from "react"
import { FieldAttributes, useField } from "formik"
import styles from "./TextInput.module.css"
import { InputAdornment, TextField } from "@material-ui/core"
import Tooltip from "../Tooltip/Tooltip"

type MyTextInputProps = {
  tooltip?: string
  label?: string
  optional?: boolean
  className?: string
  unit?: string
  manualError?: string
  customOnChange?: Function
  customValue?: string
  rows?: number
  focusedByDefault?: boolean
} & FieldAttributes<{}>

const MyTextInput: React.FC<MyTextInputProps> = ({
  label,
  optional,
  className,
  unit,
  manualError,
  customOnChange,
  customValue,
  tooltip,
  rows,
  focusedByDefault,
  ...props
}) => {
  const [field, meta] = useField<{}>(props)
  const shouldShowError = !!((meta.touched && meta.error) || manualError)
  const inputRef = useRef<HTMLInputElement>(null)

  const tooltipIcon = (
    <InputAdornment position="end" className={styles.above}>
      <Tooltip tooltipText={tooltip || ""}>
        <i className={["material-icons", "notranslate"].join(" ")}>info</i>
      </Tooltip>
    </InputAdornment>
  )

  return (
    <div className={styles.inputContainer}>
      <TextField
        variant={rows ? "outlined" : "standard"}
        fullWidth
        label={label ? label + (optional ? " (opc)" : "") : ""}
        error={shouldShowError}
        multiline={!!rows}
        rows={rows || undefined}
        {...(field as any)}
        value={customValue || field.value || ""}
        type="text"
        focused={focusedByDefault}
        {...props}
        ref={inputRef}
        onChange={
          customOnChange
            ? (event) => customOnChange(event.target.value)
            : (event) => field.onChange(event)
        }
        helperText={(meta.touched && meta.error) || manualError || " "}
        InputProps={{
          endAdornment: (
            <>
              {unit ? unit : null}
              {tooltip ? tooltipIcon : null}
            </>
          ),
        }}
      />
    </div>
  )
}

export default MyTextInput
