import React from "react"
import MyTextInput from "src/common-components/TextInput/TextInput"
import styles from "./ExtraInfoInput.module.css"

const ExtraInfoInput: React.FC = () => {
  return (
    <MyTextInput
      label="Megjegyzés"
      name="additionalInfo"
      rows={5}
      className={styles.textarea}
      maxLength={200}
    />
  )
}

export default ExtraInfoInput
