import React from "react"
import { symptomsMap } from "src/common/symptoms"
import { getDateFromInt } from "src/common/utilityFunctions"
import { TicketData } from "src/types"
import styles from "./SymptomsTable.module.scss"

interface Props {
  symptoms: TicketData["symptoms"]
}

const SymptomsTable: React.FC<Props> = ({ symptoms }) => {
  if (symptoms.length === 0) return null

  const rows = symptoms.map((symptom, index) => {
    return (
      <div key={index} className={styles.row}>
        <div className={styles.item}>{symptomsMap.get(symptom.symptomID)}</div>
        <div className={styles.item}>{getDateFromInt(symptom.symptomInterval.start)}</div>
        <div className={styles.item}>
          {symptom.symptomInterval.end ? getDateFromInt(symptom.symptomInterval.end) : "-"}
        </div>
      </div>
    )
  })

  return (
    <div className={styles.container}>
      <h6>Tünetek</h6>
      <div className={styles.table}>
        <div className={`${styles.row} ${styles.header}`}>
          <div className={styles.item}>Tünet neve</div>
          <div className={styles.item}>Megjelenés</div>
          <div className={styles.item}>Megszűnés</div>
        </div>
        {rows}
      </div>
    </div>
  )
}

export default SymptomsTable
