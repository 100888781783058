import { ApiResponse, LogBookEntryClient, LogBookEntryServer } from "src/types"
import { APIBASEURL, headersBase } from "."
import { convertSecondsToJSDate } from "src/common/utilityFunctions"

export const patchLog = async (
  userToken: string,
  body: {
    eventID: number
    data: LogBookEntryServer
    startTime: number
    endTime: number
  },
) => {
  const res = await fetch(APIBASEURL + "/journal", {
    method: "PATCH",
    headers: {
      ...headersBase,
      "User-Token": userToken,
      "content-type": "application/json",
    },
    body: JSON.stringify(body),
  })
  const result: ApiResponse<LogBookEntryServer[]> = await res.json()
  const resultWithmsTime: LogBookEntryClient[] = result.status.message.map((event) => {
    const start = convertSecondsToJSDate(event.start)
    const end = convertSecondsToJSDate(event.end)
    return { ...event, start, end }
  })
  return resultWithmsTime
}
