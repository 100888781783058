import React, { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { GlobalContext } from "src/App"
import TicketStatus from "src/common-components/TicketStatus/TicketStatus"
import {
  getDateFromInt,
  getDisplayNameFromNames,
  getTicketStatusFromTicket,
} from "src/common/utilityFunctions"
import TicketAssigneeDisplay from "src/dr-components/TicketAssigneeDisplay/TicketAssigneeDisplay"
import dricon from "src/imgs/dricon.png"
import { Ticket } from "src/types"
import PatientNameWithAge from "./PatientNameWithAge/PatientNameWithAge"
import styles from "./TicketHead.module.scss"

interface Props {
  ticket: Ticket
  patientFullName?: string
}

const TicketHead: React.FC<Props> = ({ ticket, patientFullName }) => {
  const navigate = useNavigate()
  const { doctor } = useContext(GlobalContext)

  const isDoctor = Object.keys(doctor).length > 0

  const bottomName = isDoctor
    ? getDisplayNameFromNames(ticket.userProfile || null)
    : getDisplayNameFromNames(ticket.doctor.name, "")
  return (
    <div className={styles.basicData}>
      <i className={`${styles.backArrow} material-icons`} onClick={() => navigate(-1)}>
        arrow_back
      </i>

      <div>
        <div className={styles.firstRow}>
          <PatientNameWithAge ticket={ticket} patientFullName={patientFullName} />
          <TicketStatus status={getTicketStatusFromTicket(ticket)} />
          <TicketAssigneeDisplay
            ticketID={ticket.ticketID}
            initialState={{
              assignee: ticket.ticketData.assignee?.assignee || "doctor",
              blocked: !!ticket.ticketData.assignee?.blocked,
            }}
            menuPosition="bottom"
            disabled={!!ticket.ticketData.closedAt}
          />
        </div>
        <div className={styles.secondRow}>
          <div className={styles.time}>
            <i className={["material-icons", "notranslate"].join(" ")}>access_time</i>
            <p>{getDateFromInt(ticket.ticketData.createdAt!)}</p>
          </div>
          <p>
            {isDoctor || !ticket.doctor.id ? null : <img src={dricon} alt=""></img>}
            {bottomName}
          </p>
        </div>
      </div>
    </div>
  )
}
export default TicketHead
