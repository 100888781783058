import { Formik } from "formik"
import React, { useContext, useState } from "react"
import { patchTicket } from "src/apicalls/patchTicket"
import { GlobalContext } from "src/App"
import GenericButton from "src/common-components/GenericButton/GenericButton"
import MyTextInput from "src/common-components/TextInput/TextInput"
import { necessary } from "src/common/validationFunctions"
import { DiagnosisTaskData, Task, Ticket } from "src/types"
import styles from "./FinishDiagnosisTask.module.css"

interface Props {
  ticket: Ticket
  taskIndex: number
  setOpenFinishTaskWindow: React.Dispatch<React.SetStateAction<"finish" | "reject" | null>>
}

const validate = (values: { diagnosis: string }) => {
  let errors = {}
  necessary(values, "diagnosis", errors)
  return errors
}

const FinishDiagnosisTask: React.FC<Props> = ({ ticket, taskIndex, setOpenFinishTaskWindow }) => {
  const { userToken, setShouldUpdateUserDataFromServer } = useContext(GlobalContext)
  const [loading, setLoading] = useState<boolean>(false)

  const task = ticket.ticketData.tasks[taskIndex] as DiagnosisTaskData

  const onSubmit = async (doctorData: { diagnosis: string }) => {
    setLoading(true)
    let newTasks: Task[] = ticket.ticketData.tasks
    newTasks[taskIndex].doctorData = {
      ...newTasks[taskIndex].doctorData,
      ...doctorData,
    }
    newTasks[taskIndex].state = "ready"
    await patchTicket(userToken, {
      ticketID: ticket.ticketID,
      ticketData: { tasks: newTasks },
    })
    setOpenFinishTaskWindow(null)
    setLoading(false)
    setShouldUpdateUserDataFromServer(true)
  }

  if (ticket.ticketData.tasks[taskIndex].name !== "diagnosis") {
    return null
  }

  return (
    <Formik
      initialValues={{
        diagnosis: task.doctorData.diagnosis || "",
        therapy: task.doctorData.therapy || "",
      }}
      onSubmit={(doctorData) => onSubmit(doctorData)}
      validate={(values) => validate(values)}
    >
      {({ handleSubmit }) => {
        return (
          <>
            <MyTextInput
              label="Valószínűsíthető diagnózis"
              name="diagnosis"
              disabled={ticket.ready}
              className={styles.textField}
            />
            <MyTextInput
              label="Javasolt terápia (opc)"
              name="therapy"
              disabled={ticket.ready}
              className={styles.textField}
            />
            {ticket.ready ? (
              <GenericButton
                style="blue"
                text="Bezárás"
                action={() => setOpenFinishTaskWindow(null)}
              />
            ) : (
              <GenericButton
                style="blue"
                text="Befejezés"
                className={styles.button}
                action={() => handleSubmit()}
                loading={loading}
              />
            )}
          </>
        )
      }}
    </Formik>
  )
}

export default FinishDiagnosisTask
