import React, { useRef, useState } from "react"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { GlobalContext } from "../../../App"
import SidebarButton from "../../../common-components/SidebarButton/SidebarButton"
import Tooltip from "../../../common-components/Tooltip/Tooltip"
import { useOVNavigator } from "../../../common/hooks/useOVNavigator"
import { getPatientByIdFromUser } from "../../../common/patient/patient"
import urls from "../../../common/urls"
import { LogBookPageHistoryType, Ticket } from "../../../types"
import NewTicketStart, { ticketStartData } from "../../UserHomePage/PatientBlock/NewTicketStart"
import styles from "./UserTicketMenu.module.scss"

interface Props {
  ticket: Ticket
  setVisibleOverlay: (v: "consultation" | "outpatient") => void
}

const UserTicketMenu: React.FC<Props> = ({ ticket, setVisibleOverlay }) => {
  const { user } = useContext(GlobalContext)
  const navigate = useNavigate()
  const OVNavigator = useOVNavigator()

  const buttonRef = useRef<HTMLDivElement>(null)

  const toModifyTicketPage = () => {
    OVNavigator.toModifyTicketPage(ticket.ticketID)
  }

  const handleLogBookClick = () => {
    const patient = getPatientByIdFromUser(user, ticket.patientID)
    const state: LogBookPageHistoryType = patient ? { patient } : undefined
    navigate({ pathname: urls.logBookUrl }, { state })
  }

  const isVideoCallButtonVisible =
    !ticket.ticketData.tasks.find(({ name }) => name === "consultation") && !ticket.ready

  const isArticlesButtonVisible = ticket.ticketData.tasks.find(({ name }) =>
    ["diagnosis", "sickNote"].includes(name),
  )

  const showArticlesPage = () => OVNavigator.toArticles(ticket.ticketID)

  return (
    <>
      {!ticket.ready ? (
        <div ref={buttonRef} className={styles.newTicketButton}>
          <SidebarButton text="Új igény" icon="add_to_photos" action={toModifyTicketPage} />
        </div>
      ) : null}
      <SidebarButton text="Napló" icon="perm_contact_calendar" action={handleLogBookClick} />

      {isVideoCallButtonVisible ? (
        <SidebarButton
          text="Konzultációs időpont"
          icon="access_time"
          action={() => setVisibleOverlay("consultation")}
        />
      ) : null}

      {isArticlesButtonVisible ? (
        <SidebarButton text="Orvosi tanácsok" icon="speaker_notes" action={showArticlesPage} />
      ) : null}

      <div className={styles.lastButtonContainer}>
        <Tooltip tooltipText="Az ügy még nincs lezárva">
          <SidebarButton
            text="Összefoglaló lap"
            icon="medical_services"
            action={() => setVisibleOverlay("outpatient")}
            className={styles.blueButton}
            disabled={!ticket.ready}
          />
        </Tooltip>
      </div>
    </>
  )
}

export default UserTicketMenu
