import React from "react"
import Checkbox from "src/common-components/Checkbox/Checkbox"
import { TaskLabels, TaskTypesArray } from "src/types"
import { useTaskFiltering, useIsFilterActive } from "../../useFilters"
import styles from "./TicketFilterOptions.module.scss"

interface Props {}

const TicketFilterOptions: React.FC<Props> = () => {
  const isFilterActive = useIsFilterActive()
  const toggleFilter = useTaskFiltering()

  const boxes: JSX.Element[] = TaskTypesArray.map((taskName, i) => {
    return (
      <Checkbox
        key={i}
        value={isFilterActive(taskName)}
        label={TaskLabels[taskName]}
        onClick={() => toggleFilter(taskName)}
      />
    )
  })

  return (
    <>
      <h5>Szűrés</h5>
      <div className={styles.container}>{boxes}</div>
    </>
  )
}

export default TicketFilterOptions
