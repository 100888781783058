import { useFormikContext } from "formik"
import React, { useEffect, useState } from "react"
import FieldDeleteButton from "src/common-components/FieldDeleteButton/FieldDeleteButton"
import GenericButton from "src/common-components/GenericButton/GenericButton"
import RadioButton from "src/common-components/RadioButton/RadioButton"
import MyTextInput from "src/common-components/TextInput/TextInput"
import { useAddForm } from "src/common/hooks/useAddForm"
import { illnessForm, PatientFormData } from "src/types"
import { getAddFormFunctions } from "../../getAddFormFunctions"
import DiseaseField, { drugDefaultValue } from "./DiseaseField"
import styles from "./SixthFormSlide.module.scss"

const SixthFormSlide: React.FC<{
  initialNumOfFields?: number
  wrapperNumOfFields: number[]
  wrapperSetNumOfFields: React.Dispatch<React.SetStateAction<number[]>>
}> = ({ initialNumOfFields, wrapperSetNumOfFields, wrapperNumOfFields }) => {
  const {
    addButtonRef: addDiseaseButtonRef,
    numOfFields: numOfDiseaseFields,
    setNumOfFields: setNumOfDiseaseFields,
  } = useAddForm({ checkBoxValName: "hasPersistentDisease" }) //core logic
  const [shownIllness, setShownIllness] = useState<number | undefined>(undefined) //we are only showing one illness field to not make the ui cluttered, the shown illness' number is stored in this state
  const { values, setFieldValue } = useFormikContext<PatientFormData>()

  useEffect(() => {
    setShownIllness(numOfDiseaseFields - 1)
  }, [numOfDiseaseFields]) //anytime the number of fields changes we show the last added field

  useEffect(() => {
    if (initialNumOfFields) setNumOfDiseaseFields(initialNumOfFields)
  }, [initialNumOfFields])

  const { addField, deleteAllEntries } = getAddFormFunctions(
    setNumOfDiseaseFields,
    numOfDiseaseFields,
    setFieldValue,
    "illnesses",
    "hasPersistentDisease",
    [],
  )

  function modifiedDeleteAllEntries() {
    deleteAllEntries()
    wrapperSetNumOfFields([])
  }

  function handleRadioClick(value: boolean) {
    if (!value) modifiedDeleteAllEntries()
    else if (numOfDiseaseFields === 0) addField()
  }

  useEffect(() => {
    if (!values["illnesses"][numOfDiseaseFields]) {
      setFieldValue(`illnesses[${numOfDiseaseFields}]`, { drugs: [drugDefaultValue] })
    } else if (
      !values["illnesses"][numOfDiseaseFields]["drugs"] ||
      values["illnesses"][numOfDiseaseFields]["drugs"].length === 0
    ) {
      setFieldValue(`illnesses[${numOfDiseaseFields}][drugs]`, [drugDefaultValue])
    }
  }, [numOfDiseaseFields]) //we set these field values to avoid property of undefined errors

  let additionalButton = null //creating the button that adds fields. it is only shown if there is at least one field
  if (numOfDiseaseFields >= 1) {
    additionalButton = (
      <div className={styles.addButton} ref={addDiseaseButtonRef}>
        <GenericButton
          text="További állandó betegség"
          action={() => {
            addField()
            setShownIllness(numOfDiseaseFields)
          }}
          style="grey"
        />
      </div>
    )
  }

  const defaultValue: illnessForm = {
    drugs: [],
    illnessName: "",
    takesDrugs: undefined,
  }

  let diseaseFields = [] //creating the fields depending on the numOfFields
  for (let i = 1; i <= numOfDiseaseFields; i++) {
    if (shownIllness === i - 1) {
      diseaseFields.push(
        <div key={i} className={styles.cont}>
          <div className={styles.nameDelCont}>
            <MyTextInput label="Betegség neve" name={`illnesses[${i - 1}][illnessName]`} />
            <FieldDeleteButton
              fieldArrayName="illnesses"
              index={i - 1}
              numOfFields={numOfDiseaseFields}
              setNumOfFields={setNumOfDiseaseFields}
              className={styles.deleteButton}
              defaultValue={defaultValue}
              onDeleteAllEntries={modifiedDeleteAllEntries}
            />
          </div>
          <DiseaseField
            wrapperNumOfFields={wrapperNumOfFields}
            wrapperSetNumOfFields={wrapperSetNumOfFields}
            diseaseFieldNumber={i - 1}
          />
          {additionalButton}
        </div>,
      )
    } else {
      let name = "Betegség(" + i + ")"
      if (
        values["illnesses"] &&
        values["illnesses"][i - 1] &&
        values["illnesses"][i - 1]["illnessName"]
      ) {
        name = values["illnesses"][i - 1]["illnessName"] //if the illness' field is NOT shown we only show its name if it is already give. if not we show "betegség(x)"
      }
      diseaseFields.push(
        <GenericButton
          key={i}
          action={() => setShownIllness(i - 1)}
          text={name}
          style="whiteBlue"
          className={styles.showIllnessButton}
        />,
      )
    }
  }

  return (
    <>
      <p className={styles.top}>Van állandó betegsége?</p>
      <RadioButton
        name="hasPersistentDisease"
        options={[
          { label: "Igen", value: true },
          { label: "Nem", value: false },
        ]}
        callback={handleRadioClick}
      />
      {diseaseFields}
    </>
  )
}

export default SixthFormSlide
