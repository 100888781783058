import { checkIfApiError } from "src/common/utilityFunctions"
import { ApiResponse, Notification, NotificationsObj } from "src/types"
import { APIBASEURL, headersBase } from "."

export const convertToUsedNotificationFormat = (
  rawNotificationData: Notification[],
): NotificationsObj => {
  const seen = rawNotificationData.filter((notification) => notification.seen === true)
  const unseen = rawNotificationData.filter((notification) => notification.seen === false)
  return { seen, unseen }
}

export const getNotifications = async (userToken: string) => {
  const res = await fetch(APIBASEURL + "/notification", {
    headers: {
      ...headersBase,
      "User-Token": userToken,
    },
  })
  const result: ApiResponse<Notification[]> = await res.json()

  if (checkIfApiError(result)) return "error"

  return convertToUsedNotificationFormat(result.status!.message)
}
