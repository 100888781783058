import {
  ConsultationTaskData,
  PersonalConsultationTaskData,
  Ticket,
  VideoConsultationTaskData,
} from "../../types"

/* BASIC ***********************************/
export function getNewTaskId(ticket: Ticket) {
  return ticket.ticketData.tasks.reduce((acc, { taskID }) => Math.max(acc, taskID), 0) + 1
}

/* CONSULTATION ****************************/
export const createConsultationObject = <T extends ConsultationTaskData["type"]>(
  taskID: number,
  type: T,
): ConsultationTaskData => {
  return {
    name: "consultation",
    doctorData: type === "personal" ? { date: null } : { videoCallID: null },
    finishedAt: null,
    state: null,
    taskID,
    type,
  } as unknown as T extends VideoConsultationTaskData["type"]
    ? VideoConsultationTaskData
    : PersonalConsultationTaskData
}
