import { ApiResponse, Message } from "src/types"
import { APIBASEURL, headersBase } from "."

export const createMessage = async (
  userToken: string,
  ticketID: number,
  body: { content: string },
) => {
  const res = await fetch(APIBASEURL + "/messaging", {
    method: "POST",
    headers: {
      ...headersBase,
      "User-Token": userToken,
      "Ticket-ID": ticketID.toString(),
      "content-type": "application/json",
    },
    body: JSON.stringify(body),
  })
  const result: ApiResponse<Message[]> = await res.json()
  return result
}
