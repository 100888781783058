import React, { useContext, useEffect, useRef, useState } from "react"
import { NotificationPreferencesSetter } from "../../apicalls/NotificationPreferencesService"
import { GlobalContext } from "../../App"
import { NotificationPreferences as NotificationPreferencesType } from "../../types"
import Checkbox from "../Checkbox/Checkbox"
import styles from "./NotificationPreferences.module.scss"

const useNotificationPreferences = (userToken: string) => {
  const [preferences, setPreferences] = useState<NotificationPreferencesType | null>(null)
  const preferencesService = useRef(new NotificationPreferencesSetter(userToken))

  useEffect(() => {
    preferencesService.current
      .get()
      .then((result) => setPreferences(result.status.message))
      .catch((e) => console.log(e))
  }, [])

  const updatePreferences = async (newValue: Partial<NotificationPreferencesType>) => {
    try {
      const result = await preferencesService.current.post(newValue)
      setPreferences(result.status.message)
    } catch (e) {
      console.log(e)
    }
  }

  return [preferences, updatePreferences] as const
}

const NotificationPreferences: React.FC = () => {
  const { userToken } = useContext(GlobalContext)

  const [preferences, updatePreferences] = useNotificationPreferences(userToken)

  return (
    <div className={styles.container}>
      <h4>Email értesítési beállítások</h4>
      <Checkbox
        value={preferences?.lowPrio || false}
        onClick={(value) => updatePreferences({ ...preferences, lowPrio: value })}
        label="Tájékoztató értesítések"
        className={styles.firstBox}
        disabled={!preferences}
      />
      <Checkbox
        value={preferences?.highPrio || false}
        onClick={(value) => updatePreferences({ ...preferences, highPrio: value })}
        label="Fontos értesítések"
        disabled={!preferences}
      />
    </div>
  )
}
export default NotificationPreferences
