import React from "react"
import { useNavigate } from "react-router-dom"
import styles from "./BackButton.module.css"

const BackButton: React.FC = () => {
  const navigate = useNavigate()
  return (
    <i onClick={() => navigate(-1)} className={`material-icons ${styles.button}`}>
      arrow_back
    </i>
  )
}

export default BackButton
