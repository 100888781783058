import { Form, Formik, FormikValues } from "formik"
import _get from "lodash/get"
import _set from "lodash/set"
import React, { PropsWithChildren, useEffect, useRef } from "react"
import GenericButton from "src/common-components/GenericButton/GenericButton"
import { mergeDeep } from "src/common/utilityFunctions"
import styles from "./TaskCardBase.module.scss"

interface Props<T> {
  initialValues: T
  formikValidate?: (v: T) => Partial<T> | void
  initialErrors?: T
  title?: string
  dontSaveToFormContext?: true
  formContext: {
    setFormState: React.Dispatch<React.SetStateAction<number>>
    setFormValues: React.Dispatch<React.SetStateAction<any>>
    formState: number
    maxFormState: number
    formValues: any
    setShouldSubmit?: React.Dispatch<React.SetStateAction<boolean>>
  }
  setFormikBagToParentState?: Function
  justifyContent?: "top" | "space-between"
  loading?: boolean
  customSubmit?: Function
  path?: string
  subtitle?: string
  gap?: boolean
  customValidate?: () => boolean
}

const TaskCardBase = <T extends FormikValues>({
  initialValues,
  formikValidate,
  title,
  children,
  formContext,
  dontSaveToFormContext,
  justifyContent = "top",
  setFormikBagToParentState,
  loading = false,
  customSubmit,
  path,
  subtitle,
  gap,
  customValidate,
}: PropsWithChildren<Props<T>>) => {
  const isLastCard = formContext.formState === formContext.maxFormState - 1
  const isFirstCard = formContext.formState === 0
  const formikRef = useRef<any>(null)

  useEffect(() => {
    if (setFormikBagToParentState && formikRef) {
      setFormikBagToParentState(formikRef)
    }
  }, [formikRef])

  const onSubmit = (data: any) => {
    if (!dontSaveToFormContext) {
      if (!path) {
        const newFormValues = mergeDeep(formContext.formValues, data)
        formContext.setFormValues({ ...newFormValues })
      } else {
        let newFormValues = { ...formContext.formValues }
        const oldData = _get(newFormValues, path)
        _set(newFormValues, path, { ...oldData, ...data })
        formContext.setFormValues({ ...newFormValues })
      }
    }
    if (!isLastCard) {
      if (!customValidate || customValidate()) formContext.setFormState((state) => state + 1)
      if (customSubmit) customSubmit(data)
    } else if (formContext.setShouldSubmit) formContext.setShouldSubmit(true)
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(data) => onSubmit(data)}
        validate={formikValidate ? (values) => formikValidate(values) : undefined}
        initialErrors={formikValidate ? initialValues : undefined}
        innerRef={formikRef}
        enableReinitialize
      >
        {(formikBag) => (
          <Form
            className={`${styles.card} ${
              justifyContent === "space-between" ? styles.spaceBetween : null
            } ${gap ? styles.gap : null}`}
          >
            {title ? <h3>{title}</h3> : null}
            {subtitle ? <p className={styles.subtitle}>{subtitle}</p> : null}
            {children}
            <div
              className={`${styles.buttonCont} ${
                justifyContent === "top" ? styles.marginTop : null
              }`}
            >
              <GenericButton
                text={isLastCard ? "Befejezés" : "Tovább"}
                style="blue"
                action={formikBag.submitForm}
                className={styles.nextButton}
                loading={loading}
              />
              {!isFirstCard ? (
                <GenericButton
                  style="grey"
                  text="Vissza"
                  action={() => {
                    if (!isFirstCard) formContext.setFormState(formContext.formState - 1)
                  }}
                />
              ) : null}
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default TaskCardBase
