import { ApiResponse, AssignedMedia, MediaAtCreation, PatchTicketApiData } from "src/types"
import { APIBASEURL, headersBase } from "."
import { uploadMedia } from "./createTicket"

const getBodyToPatchTicket = (
  media: AssignedMedia[] | null,
  body: PatchTicketApiData,
): PatchTicketApiData => {
  if (media) {
    if (body.ticketData) {
      const uploadedFiles = body.ticketData.files || []
      const updatedFiles = [...uploadedFiles, ...media]
      body.ticketData.files = updatedFiles
      return body
    }

    body.ticketData = { files: media }
    return body
  }

  return body
}

export const patchTicket = async (
  userToken: string,
  body: PatchTicketApiData,
  files?: MediaAtCreation[],
) => {
  const media = files ? await uploadMedia(userToken, files) : null
  const mediaUpdatedBody = getBodyToPatchTicket(media, body)

  const res = await fetch(APIBASEURL + "/ticket", {
    method: "PATCH",
    headers: {
      ...headersBase,
      "User-Token": userToken,
      "content-type": "application/json",
    },
    body: JSON.stringify(mediaUpdatedBody),
  })
  const result: ApiResponse = await res.json()
  return result
}
