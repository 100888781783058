import React from "react"
import {
  displayAddress,
  getDisplayNameFromNames,
  givenNumberOfDigits,
} from "../../common/utilityFunctions"
import { UserProfile } from "../../types"
import styles from "./UserBasicData.module.scss"

interface Props {
  profile: UserProfile
}

const UserBasicData: React.FC<Props> = ({ profile }) => {
  return (
    <div className={styles.profileContainer}>
      <h1>Név</h1>
      <h2>{getDisplayNameFromNames(profile)}</h2>
      <h1>E-mail</h1>
      <h2>{profile.email || "Nem elérhető"}</h2>
      <h1>Születési név</h1>
      <h2>{profile.birthName}</h2>
      <h1>Születési dátum</h1>
      <h2>
        {[
          profile.birthYear,
          givenNumberOfDigits(2, profile.birthMonth),
          givenNumberOfDigits(2, profile.birthDay),
        ].join(". ") + "."}
      </h2>
      <h1>Taj-szám</h1>
      <h2>{profile.tajNum}</h2>
      <h1>Lakcím</h1>
      <h2>{displayAddress(profile.address)}</h2>
      <h1>Telefonszám</h1>
      <h2>{profile.phone || "Nincs megadva"}</h2>
      <h1>Foglalkozás</h1>
      <h2>{profile.job || "Nincs megadva"}</h2>
    </div>
  )
}

export default UserBasicData
