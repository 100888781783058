import React from "react"
import { PatientDataOverviewLabelValuePairs } from "src/types"
import styles from "./FormDataReviewBlock.module.css"
import FormDataReviewRow from "./FormDataReviewRow"

interface Props {
  labelValuePairs: PatientDataOverviewLabelValuePairs[]
}

const FormDataReviewBlock: React.FC<Props> = ({ labelValuePairs }) => {
  let counter = 0
  const rows = labelValuePairs.map((el, index) => {
    if (el.value && el.value !== "" && el.value !== "default") counter += 1
    return (
      <FormDataReviewRow labelValuePair={el} key={index} color={counter % 2 ? "white" : "blue"} />
    )
  })

  return <div className={styles.block}>{rows}</div>
}

export default FormDataReviewBlock
