import React, { useContext } from "react"
import { symptomsMap } from "src/common/symptoms"
import {
  addSymptomToNewTicketFormContext,
  arrayIncludesObjectWithGivenAttribute,
} from "src/common/utilityFunctions"
import { newTicketFormContext } from "../NewTicketPage"
import styles from "./AddBasicSymptoms.module.css"

interface Props {
  baseSymptomIDs: number[]
}

const AddBasicSymptoms: React.FC<Props> = ({ baseSymptomIDs }) => {
  const context = useContext(newTicketFormContext)

  const content = baseSymptomIDs.map((symptomID, index) => {
    return (
      <p
        key={index}
        onClick={() => {
          addSymptomToNewTicketFormContext(context, symptomID)
        }}
      >
        {symptomsMap.get(symptomID)}
      </p>
    )
  })

  return <div className={styles.labelCont}>{content}</div>
}

export default AddBasicSymptoms
