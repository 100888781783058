import datefns from "@date-io/date-fns"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import React, { useRef, useState } from "react"
import { useOnClickOutside } from "src/common/hooks/useOnClickOutside"
import styles from "./TimeChooser.module.css"
import TimeChooserClock from "./TimeChooserClock"

interface Props {
  date: Date
  setDate: React.Dispatch<React.SetStateAction<Date>>
  maxDate?: Date
  minDate?: Date
  disabled?: boolean
}

const TimeChooser: React.FC<Props> = ({ date, setDate, disabled, maxDate, minDate }) => {
  const [shouldShowTimePicker, setShouldShowTimePicker] = useState<boolean>(false)
  const [activeClock, setActiveClock] = useState<"hours" | "minutes">("hours")

  const timePickerButtonRef = useRef(null)
  const timePickerRef = useOnClickOutside(() => setShouldShowTimePicker(false), timePickerButtonRef)

  const handleTimePickerButtonClick = () => {
    if (disabled) return
    if (!shouldShowTimePicker) setActiveClock("hours")
    setShouldShowTimePicker(!shouldShowTimePicker)
  }

  const TimeChooserClockProps = {
    activeClock,
    date,
    setActiveClock,
    setDate,
    setShouldShowTimePicker,
    maxDate,
    minDate,
  }

  return (
    <>
      <p ref={timePickerButtonRef} onClick={handleTimePickerButtonClick} className={styles.text}>
        {date.toLocaleTimeString()}
      </p>
      {shouldShowTimePicker ? (
        <div ref={timePickerRef} className={styles.timePickerContainer}>
          <MuiPickersUtilsProvider utils={datefns}>
            <TimeChooserClock {...TimeChooserClockProps} />
          </MuiPickersUtilsProvider>
        </div>
      ) : null}
    </>
  )
}

export default TimeChooser
