import { useFormikContext } from "formik"
import React, { useEffect } from "react"
import FieldDeleteButton from "src/common-components/FieldDeleteButton/FieldDeleteButton"
import GenericButton from "src/common-components/GenericButton/GenericButton"
import RadioButton from "src/common-components/RadioButton/RadioButton"
import MyTextInput from "src/common-components/TextInput/TextInput"
import { useAddForm } from "src/common/hooks/useAddForm"
import { getAddFormFunctions } from "../../getAddFormFunctions"
import styles from "./FourthFormSlide.module.scss"

const FourthFormSlideView: React.FC<{
  setNumOfFieldsInParent: React.Dispatch<React.SetStateAction<number>>
  initialNumOfFields?: number
}> = ({ setNumOfFieldsInParent, initialNumOfFields = 0 }) => {
  const { addButtonRef, numOfFields, setNumOfFields } = useAddForm({
    checkBoxValName: "hasDrugAllergy",
  }) //useAddForm contains core logic

  const { setErrors, errors, setFieldValue } = useFormikContext()

  useEffect(() => {
    setNumOfFieldsInParent(numOfFields)
    setErrors({ ...errors, defaultError: "" })
  }, [numOfFields])

  useEffect(() => {
    setNumOfFields(initialNumOfFields)
  }, [initialNumOfFields])

  const { addField, handleRadioClick, deleteAllEntries } = getAddFormFunctions(
    setNumOfFields,
    numOfFields,
    setFieldValue,
    "drugAllergy",
    "hasDrugAllergy",
    [],
  )

  let drugFields = []
  for (let i = 1; i <= numOfFields; i++) {
    //creating the needed fields depending on the number of fields
    drugFields.push(
      <div key={i} className={styles.inputCont}>
        <MyTextInput name={`drugAllergy[${i - 1}]`} type="text" label="Gyógyszer neve" />
        <FieldDeleteButton
          fieldArrayName="drugAllergy"
          index={i - 1}
          numOfFields={numOfFields}
          setNumOfFields={setNumOfFields}
          className={styles.deleteButton}
          onDeleteAllEntries={deleteAllEntries}
        />
      </div>,
    )
  }

  let additionalAllergyButton = null //creating the button to add more fields, only show it when there is at least one field (checkbox is set to true)
  if (numOfFields >= 1) {
    additionalAllergyButton = (
      <div className={styles.buttonCont} ref={addButtonRef}>
        <GenericButton text="További gyógyszerallergia" action={addField} style="grey" />
      </div>
    )
  }
  return (
    <>
      <div className={styles.allergyCheckCont}>
        <p>Allergiás valamilyen gyógyszerre vagy hatóanyagra?</p>
        <RadioButton
          name="hasDrugAllergy"
          options={[
            { label: "Igen", value: true },
            { label: "Nem", value: false },
          ]}
          callback={handleRadioClick}
        />
      </div>
      <>{drugFields}</>
      {additionalAllergyButton}
    </>
  )
}

export default FourthFormSlideView
