import React from "react"
import { LogBookPageHistoryType, Patient, Ticket } from "src/types"
import styles from "./PatientBlock.module.scss"
import dricon from "../../../imgs/dricon.png"
import InProgressTicket from "./InProgressTicket"
import NewTicketStart, { ticketStartData } from "./NewTicketStart"
import { useNavigate } from "react-router-dom"
import GenericButton from "src/common-components/GenericButton/GenericButton"
import urls from "../../../common/urls"
import { useOVNavigator } from "../../../common/hooks/useOVNavigator"

interface Props {
  patient: Patient
  activeTickets: Ticket[]
}

const PatientBlock: React.FC<Props> = ({ patient, activeTickets }) => {
  const patientProfile = patient.profile
  let patientName: string = ""
  if (patientProfile?.lastName && patientProfile?.firstName) {
    patientName = patientProfile.lastName + " " + patientProfile.firstName
    if (patientProfile?.foreName) patientName += ` ${patientProfile?.foreName}`
  }

  const navigate = useNavigate()

  const OVNavigator = useOVNavigator()

  const onNewTicketButtonClick = () => {
    OVNavigator.toNewTicketPage(patient.id)
  }

  const navigateToLogBook = () => {
    const state: LogBookPageHistoryType = { patient }
    navigate({ pathname: urls.logBookUrl }, { state })
  }

  // Check for non-closed active ticket
  const canCreateNewTicket = activeTickets.find((t) => !t.ready) === undefined

  return (
    <div className={styles.container}>
      <div className={styles.patientBlockContainer}>
        <div className={styles.left}>
          <div className={styles.name}>
            <h3>{patientName || "Ismeretlen páciens"} </h3>
          </div>
          <div className={styles.drCont}>
            <div className={styles.drContTitle}>
              <img alt="" src={dricon} />
              <p>Háziorvos: </p>
            </div>
            <p> {patientProfile?.GP?.name || "nincs megadva"}</p>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <GenericButton
            className={styles.button}
            style="grey"
            action={navigateToLogBook}
            text="Napló"
          />
          {canCreateNewTicket ? (
            <GenericButton
              className={styles.button}
              style="whiteBlue"
              action={onNewTicketButtonClick}
              text="Ügy indítása"
            />
          ) : null}
        </div>
      </div>
      {activeTickets.length > 0
        ? activeTickets.map((ticket, index) => <InProgressTicket ticket={ticket} key={index} />)
        : null}
    </div>
  )
}

export default PatientBlock
