import React, { useState } from "react"
import { useContext } from "react"
import { getTaskDisplayName, toServerTime } from "src/common/utilityFunctions"
import { Ticket } from "src/types"
import { patchTicket } from "../../apicalls"
import { GlobalContext } from "../../App"
import styles from "./DoctorTasks.module.scss"
import { didPersonalConsultationHappen, DoctorTasksComponents } from "./DoctorTasksComponents"
import { FinishTaskDialog } from "./TaskFinishDialogs"
import RejectTask from "./TaskFinishDialogs/RejectTask"
import { getTaskSpecificData } from "./TaskSpecificData"

interface Props {
  taskIndex: number
  ticket: Ticket
}

const DoctorTasks: React.FC<Props> = ({ taskIndex, ticket }) => {
  const { userToken, setShouldUpdateUserDataFromServer } = useContext(GlobalContext)

  const [showFinishTaskWindow, setShowFinishTaskWindow] = useState<"reject" | "finish" | null>(null)

  const task = ticket.ticketData.tasks[taskIndex]

  const doesTicketContainConsultationTask = !!ticket.ticketData.tasks.find(
    ({ name }) => name === "consultation",
  )

  const mainAction = (() => {
    if (
      task.name === "consultation" &&
      task.type === "personal" &&
      didPersonalConsultationHappen(task)
    ) {
      return async () => {
        task.state = "ready"
        task.finishedAt = toServerTime(new Date())

        const res = await patchTicket(userToken, {
          ticketID: ticket.ticketID,
          ticketData: ticket.ticketData,
        })

        setShouldUpdateUserDataFromServer(true)
      }
    }
    return () => setShowFinishTaskWindow("finish")
  })()

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{getTaskDisplayName(task)}</h3>
      <DoctorTasksComponents.TaskState taskState={task.state} />
      {showFinishTaskWindow === "finish" ? (
        <FinishTaskDialog
          ticket={ticket}
          taskIndex={taskIndex}
          task={task}
          setShowFinishTaskWindow={setShowFinishTaskWindow}
        />
      ) : showFinishTaskWindow === "reject" ? (
        <RejectTask
          ticket={ticket}
          taskIndex={taskIndex}
          setOpenTaskFinishWindow={setShowFinishTaskWindow}
        />
      ) : null}
      {getTaskSpecificData(task, ticket)}
      <div className={styles.buttonContainer}>
        <DoctorTasksComponents.OpenRejectWindowButton
          isTicketReady={ticket.ready}
          task={task}
          openRejectWindow={() => setShowFinishTaskWindow("reject")}
        />
        <DoctorTasksComponents.MainActionButton
          doesTicketContainConsultationTask={doesTicketContainConsultationTask}
          task={task}
          mainAction={mainAction}
        />
      </div>
    </div>
  )
}

export default DoctorTasks
