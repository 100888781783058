import React, { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import { GlobalContext } from "src/App"
import GenericButton from "src/common-components/GenericButton/GenericButton"
import DeleteUserProfilePopup from "../../common-components/DeleteUserProfilePopup"
import NotificationPreferences from "../../common-components/NotificationPreferences/NotificationPreferences"
import PasswordChange from "../../common-components/PasswordChange/PasswordChange"
import urls from "../../common/urls"
import PatientDataEditBlock from "./PatientDataEditBlock"
import UserBasicData from "./UserBasicData"
import styles from "./UserProfilePage.module.scss"

const UserProfilePage: React.FC = () => {
  const { user, userToken } = useContext(GlobalContext)
  const [userDeletePopupOpen, setUserDeletePopupOpen] = useState<boolean>(false)

  const navigate = useNavigate()
  const profile = user.userProfile

  const patientDisplayElements = user.patients
    ?.filter((patient) => {
      return !patient.profile.archived
    })
    .map((patient, index) => {
      return <PatientDataEditBlock patient={patient} key={index} />
    })

  return (
    <>
      <UserBasicData profile={profile} />
      <PasswordChange />
      <NotificationPreferences />
      <div className={styles.buttonsContainer}>
        <GenericButton
          action={() => navigate({ pathname: urls.userNewPatientPageUrl })}
          style="whiteBlue"
          text="Új páciens felvétele"
        />
        <GenericButton
          action={() => navigate({ pathname: urls.editProfilePageUrl })}
          style="whiteBlue"
          text="Profil szerkesztése"
        />
        <GenericButton
          text="Profil törlése"
          action={() => setUserDeletePopupOpen(true)}
          style="red"
        />
      </div>
      <div className={styles.patientBlocksContainer}>
        <h3>Felvett páciensek</h3>
        {patientDisplayElements.length > 0 ? patientDisplayElements : "Még nem vett fel pácienst"}
      </div>
      {userDeletePopupOpen ? (
        <DeleteUserProfilePopup
          setUserDeletePopupOpen={setUserDeletePopupOpen}
          userToken={userToken}
        />
      ) : null}
    </>
  )
}

export default UserProfilePage
