import { Formik } from "formik"
import React from "react"
import { necessary } from "../../common/validationFunctions"
import { MediaAtCreation } from "../../types"
import Popup from "../Popup/Popup"
import MyTextInput from "../TextInput/TextInput"
import styles from "./DescriptionOverlay.module.scss"

const validate = (values: { description: string }) => {
  let errors = {}
  necessary(values, "description", errors)
  return errors
}

interface Props {
  formikRef: any
  files: MediaAtCreation[]
  setFiles: (files: MediaAtCreation[]) => void
  exitPopup: () => void
  newFile: MediaAtCreation
  resetNewFile: () => void
}

const DescriptionOverlay: React.FC<Props> = ({
  files,
  formikRef,
  setFiles,
  exitPopup,
  resetNewFile,
  newFile,
}) => {
  return (
    <Popup
      confirmButton={{
        title: "Kész",
        action: () => {
          if (formikRef.current?.submitForm) formikRef.current.submitForm()
        },
      }}
      onClose={exitPopup}
    >
      <div className={styles.inputContainer}>
        <p>Segítsen az orvosának és írja le, hogy mi látható ezen a képen</p>
        <Formik
          innerRef={formikRef}
          initialValues={{ description: "" }}
          onSubmit={(values) => {
            newFile.meta.description = values.description
            files.push(newFile)
            setFiles([...files])
            resetNewFile()
            exitPopup()
          }}
          validate={(values) => validate(values)}
        >
          <MyTextInput name="description" label={"Leírás"} />
        </Formik>
      </div>
    </Popup>
  )
}
export default DescriptionOverlay
