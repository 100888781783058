import { ApiResponse } from "src/types"
import { APIBASEURL, headersBase } from "."

export const deleteUser = async (userToken: string) => {
  const res = await fetch(APIBASEURL + "/profile", {
    method: "DELETE",
    headers: {
      ...headersBase,
      "User-Token": userToken,
    },
  })
  const result: ApiResponse = await res.json()
  return result
}
