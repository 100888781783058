import React from "react"
import { useState } from "react"
import { useIsMobileSize } from "../../common/hooks/useIsMobileSize"
import GenericButton from "../GenericButton/GenericButton"
import styles from "./MobileTicketMenu.module.scss"

interface Props {}

const MobileTicketMenu: React.FC<Props> = ({ children }) => {
  const isMobile = useIsMobileSize()

  const [ticketMenuShown, setTicketMenuShown] = useState(false)

  if (!isMobile) return null

  return (
    <>
      <GenericButton
        className={styles.openTicketMenuButton}
        text={ticketMenuShown ? "Ügy menü bezárása" : "Ügy menü mutatása"}
        style={ticketMenuShown ? "grey" : "blue"}
        action={() => setTicketMenuShown((s) => !s)}
      />
      {ticketMenuShown && <div className={styles.mobileMenuContainer}>{children}</div>}
    </>
  )
}

export default MobileTicketMenu
