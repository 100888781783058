import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { getDisplayNameFromNames } from "src/common/utilityFunctions"
import { VideoCall, VideoPageHistoryType } from "src/types"
import { useOVNavigator } from "../../common/hooks/useOVNavigator"
import styles from "./DrVideoCall.module.css"

interface Props {
  videoCall: VideoCall
}

const DrVideoCall: React.FC<Props> = ({ videoCall }) => {
  const navigate = useNavigate()
  const [date, setDate] = useState<number>(new Date().getTime() / 1000)

  const OVNavigator = useOVNavigator()

  useEffect(() => {
    const interval = setInterval(() => setDate(new Date().getTime() / 1000), 2000)
    return () => clearInterval(interval)
  }, [])

  const startVideoCall = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    const state: VideoPageHistoryType = {
      videoCallID: videoCall.id,
    }
    navigate({ pathname: "/videocall" }, { state })
  }

  const isButtonDisabled = date < videoCall.date - 30

  return (
    <div onClick={() => OVNavigator.toTicket(videoCall.ticketID)} className={styles.container}>
      <div>
        <p>{getDisplayNameFromNames(videoCall.userName)}</p>
        <p>{new Date(videoCall.date * 1000).toLocaleString()}</p>
      </div>
      <button
        className={[styles.button, isButtonDisabled ? "disabled" : ""].join(" ")}
        disabled={isButtonDisabled}
        onClick={(e) => startVideoCall(e)}
      >
        <i className={["material-icons", "notranslate"].join(" ")}>local_phone</i>
      </button>
    </div>
  )
}
export default DrVideoCall
