import { TextField } from "@material-ui/core"
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete"
import { useFormikContext } from "formik"
import React, { useContext, useEffect } from "react"
import { Location, useLocation } from "react-router-dom"
import { deleteMediaFromPatientProfile } from "src/apicalls/deleteMediaFromPatientProfile"
import { GlobalContext } from "src/App"
import FieldDeleteButton from "src/common-components/FieldDeleteButton/FieldDeleteButton"
import FileUpload from "src/common-components/FileUpload/FileUpload"
import GenericButton from "src/common-components/GenericButton/GenericButton"
import RadioButton from "src/common-components/RadioButton/RadioButton"
import Select from "src/common-components/Select/Select"
import MyTextInput from "src/common-components/TextInput/TextInput"
import { baseHospitalDiagnosises, monthOptions } from "src/common/consts"
import { useAddForm } from "src/common/hooks/useAddForm"
import { HospitalVisit, ImageMeta, NewPatientHistoryType, PatientFormData } from "src/types"
import { getAddFormFunctions } from "../../getAddFormFunctions"
import styles from "./FifthFormSlide.module.scss"
import { hospitalVisitsInitialValuesBase } from "./FifthFormSlideWrapper"

const defaultValue: HospitalVisit = {
  diagnose: "",
  month: "",
  year: "",
  media: null,
  mediaAtCreation: [],
}

const FifthFormSlide: React.FC<{
  setNumOfFieldsInParent: React.Dispatch<React.SetStateAction<number>>
  initialNumOfFields?: number
}> = ({ setNumOfFieldsInParent, initialNumOfFields = 0 }) => {
  const { userToken, setShouldUpdateUserDataFromServer, user } = useContext(GlobalContext)
  const location = useLocation() as Location & { state: NewPatientHistoryType }
  const patientID = location.state?.patientID
  const patient = user.patients.find(({ id }) => id === patientID)

  const { addButtonRef, numOfFields, setNumOfFields } = useAddForm({
    checkBoxValName: "beenToHospital",
    initialNumOfFields,
  }) //useAddForm contains the core logic

  const { values, setErrors, errors, setFieldValue } = useFormikContext<PatientFormData>()
  useEffect(() => {
    setNumOfFieldsInParent(numOfFields)
    setErrors({ ...errors, defaultError: "" })
  }, [numOfFields])

  const { addField, deleteAllEntries, handleRadioClick } = getAddFormFunctions(
    setNumOfFields,
    numOfFields,
    setFieldValue,
    "hospitalVisits",
    "beenToHospital",
    hospitalVisitsInitialValuesBase.hospitalVisits,
  )

  const fields = Array.from(Array(numOfFields).keys()).map((i: number) => {
    return (
      <div key={i} className={styles.fieldsCont}>
        <div className={styles.dateCont}>
          <MyTextInput name={`hospitalVisits[${i}][year]`} label="Év" />
          <Select name={`hospitalVisits[${i}][month]`} label="Hónap" selectOptions={monthOptions} />
          <FieldDeleteButton
            index={i}
            fieldArrayName="hospitalVisits"
            numOfFields={numOfFields}
            setNumOfFields={setNumOfFields}
            defaultValue={defaultValue}
            onDeleteAllEntries={deleteAllEntries}
          />
        </div>
        <Autocomplete
          value={values.hospitalVisits[i].diagnose}
          onChange={(_, newValue) => {
            if (typeof newValue === "string") {
              setFieldValue(`hospitalVisits[${i}][diagnose]`, newValue)
            }
          }}
          filterOptions={(options, params) => {
            const filtered = createFilterOptions<string>()(options, params)
            if (params.inputValue !== "") {
              filtered.push(`${params.inputValue}`)
            }
            return filtered
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={baseHospitalDiagnosises}
          getOptionLabel={(option) => option}
          renderOption={(option) => option}
          className={styles.inputContainer}
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              label="Zárójelentésben szereplő diagnózis"
              variant="standard"
              size="small"
            />
          )}
        />
        <div className={styles.fileUploadContainer}>
          <p>Fénykép / pdf feltöltése a Zárójelentésről (opc)</p>
          <FileUpload
            relatedTicketID={null}
            files={values.hospitalVisits[i].mediaAtCreation}
            setFiles={(files: { file: File; meta: ImageMeta }[]) => {
              setFieldValue(`hospitalVisits[${i}].mediaAtCreation`, files)
            }}
            previouslyUploadedFiles={
              values.hospitalVisits[i].media
                ? [values.hospitalVisits[i].media as { id: number; name: string }]
                : undefined
            }
            deletePreviouslyUploadedFiles={
              patient
                ? async () => {
                    await deleteMediaFromPatientProfile(
                      userToken,
                      patient,
                      patient.profile.hospitalVisits[i],
                      `hospitalVisits[${i}].media`,
                      setFieldValue,
                    )
                    setShouldUpdateUserDataFromServer(true)
                  }
                : undefined
            }
          />
        </div>
      </div>
    )
  })

  let additionalButton = null //creating the button which ads more fields
  if (numOfFields >= 1) {
    additionalButton = (
      <div className={styles.addButton} ref={addButtonRef}>
        <GenericButton text="További kórházi kezelések" action={addField} style="grey" />
      </div>
    )
  }

  return (
    <>
      <div className={styles.allergyCheckCont}>
        <p>Kezelték már kórházban?</p>
        <RadioButton
          name="beenToHospital"
          options={[
            { label: "Igen", value: true },
            { label: "Nem", value: false },
          ]}
          callback={(value) => handleRadioClick(value)}
        />
      </div>
      <>{fields}</>
      {additionalButton}
    </>
  )
}

export default FifthFormSlide
