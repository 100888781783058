import React from "react"
import { TaskDataBase } from "src/types"
import styles from "./TaskStateCornerMarker.module.scss"

interface Props {
  state: TaskDataBase["state"]
}

const TaskStateCornerMarker: React.FC<Props> = ({ state }) => {
  return (
    <>
      {state === "ready" ? (
        <div className={styles.icon}>
          <i className={["material-icons", "notranslate"].join(" ")}>checked</i>
        </div>
      ) : null}
      {state === "rejected" ? (
        <div className={`${styles.icon} ${styles.rejected}`}>
          <i className={["material-icons", "notranslate"].join(" ")}>close</i>
        </div>
      ) : null}
    </>
  )
}
export default TaskStateCornerMarker
