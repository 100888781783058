import { toBase64, toImageData } from "src/common/utilityFunctions"
import { ApiResponse, CreatePatientData, DrugForm, HospitalVisit, MediaAtCreation } from "src/types"
import { APIBASEURL, headersBase } from "."
import { createImage } from "./createImage"

const setSingleMedia = async (
  userToken: string,
  target: HospitalVisit | DrugForm,
  file: MediaAtCreation,
) => {
  const imageData = await toImageData(file)
  const mediaCreationResponse = await createImage(userToken, imageData)
  target.media = {
    id: mediaCreationResponse.status.message.imageID,
    name: file.meta.description || file.meta.name,
  }
  if ((target as DrugForm).mediaAtCreation !== undefined) {
    ;(target as DrugForm).mediaAtCreation = []
  } else (target as HospitalVisit).mediaAtCreation = []
}

export const createMediaInPatientData = (userToken: string, patientData: CreatePatientData) => {
  const promises: Promise<void>[] = []
  patientData.hospitalVisits.forEach((visitData) => {
    if (visitData.mediaAtCreation.length > 0) {
      visitData.mediaAtCreation.forEach(async (file) => {
        promises.push(setSingleMedia(userToken, visitData, file))
      })
    }
  })
  patientData.illnesses.forEach((illness) => {
    if (illness.drugs.length > 0) {
      illness.drugs.forEach((drug) => {
        drug.mediaAtCreation.forEach(async (file) => {
          promises.push(setSingleMedia(userToken, drug, file))
        })
      })
    }
  })
  return promises
}

export const createPatient = async (userToken: string, body: CreatePatientData) => {
  const patientData = body
  const mediaCreationPromises = createMediaInPatientData(userToken, patientData)
  await Promise.all(mediaCreationPromises)
  const res = await fetch(APIBASEURL + "/patient", {
    method: "POST",
    headers: {
      ...headersBase,
      "User-Token": userToken,
      "content-type": "application/json",
    },
    body: JSON.stringify(body),
  })
  const result: ApiResponse = await res.json()
  return result
}
