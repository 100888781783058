import React from "react"
import Select, { Styles } from "react-select"
import { FieldAttributes, useField, useFormikContext } from "formik"

const SearchableSelect: React.FC<
  FieldAttributes<{}> & {
    options: any[]
    customOnChange?: Function
    customValue?: any
    placeholder?: string
  }
> = ({ options, customOnChange, customValue, placeholder, ...props }) => {
  const [field] = useField<{}>(props)
  const { setFieldValue } = useFormikContext()

  const customStyles: Partial<Styles<{}, false>> = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      "&:hover": {
        border: "#47cdff 1px solid",
      },
      border: state.isFocused ? "#47cdff 1px solid" : "",
    }),
  }

  return (
    <Select
      options={options}
      name={field.name}
      value={
        options.find(
          (option) => option.value === (customValue === undefined ? field.value : customValue),
        ) || ""
      }
      onChange={
        customOnChange
          ? (option) => customOnChange(option.value)
          : (option) => {
              setFieldValue(field.name, option.value)
            }
      }
      onBlur={field.onBlur}
      placeholder={placeholder}
      isDisabled={props.disabled}
      styles={customStyles}
    />
  )
}

export default SearchableSelect
