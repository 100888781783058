import React, { useContext } from "react"
import { GlobalContext } from "src/App"
import { formatAddressString, getDisplayDateFromDate } from "src/common/utilityFunctions"
import { PatientProfileData, Ticket, UserProfile } from "src/types"
import styles from "./OutpatientSheetDataTable.module.scss"

type TableRowProps = {
  title: string
  value: string
}

export const TableRow: React.FC<TableRowProps> = ({ title, value }) => {
  const { doctor } = useContext(GlobalContext)
  const isDoctor = Object.keys(doctor).length > 0
  return (
    <div
      title={isDoctor ? "Kattintson a másoláshoz" : undefined}
      onClick={isDoctor ? () => navigator.clipboard.writeText(value) : undefined}
      className={styles.tableDataRow}
    >
      <p>{title}</p>
      <p className={styles.tableData}>{value}</p>
    </div>
  )
}

interface Props {
  patientProfile?: PatientProfileData
  userProfile?: UserProfile
  ticket: Ticket
}

const OutpatientSheetDataTable: React.FC<Props> = ({
  patientProfile: p,
  userProfile: u,
  ticket,
}) => {
  return p && u ? (
    <>
      <TableRow title="Név:" value={[p.lastName, p.firstName, p.foreName].join(" ")} />
      <TableRow
        title="Születési dátum:"
        value={getDisplayDateFromDate({ day: p.birthDay, month: p.birthMonth, year: p.birthYear })}
      />
      <TableRow title="Lakcím:" value={formatAddressString(p.address)} />
      <TableRow title="Anyja neve:" value={p.mothersName} />
      <TableRow title="TAJ-szám:" value={p.tajNum} />
      <TableRow
        title="Felvételi ideje:"
        value={
          ticket.ticketData.createdAt
            ? new Date(ticket.ticketData.createdAt * 1000).toLocaleString("hu")
            : "nincs megadva"
        }
      />
    </>
  ) : (
    <div className={styles.tableDataRow}>
      <p>Nem elérhető</p>
    </div>
  )
}

export default OutpatientSheetDataTable
