import React, { useContext, useEffect, useState } from "react"
import Select from "src/common-components/Select/Select"
import MyTextInput from "src/common-components/TextInput/TextInput"
import { monthOptions } from "src/common/consts"
import { howManyDaysInMonth } from "src/common/utilityFunctions"
import { isValidBirthYear, necessary } from "src/common/validationFunctions"
import { CreatePatientDataFirstCard } from "src/types"
import TaskCardBase from "src/user-components/NewTicketPage/TaskCardBase"
import { newPatientFormContext } from "../NewPatientForm"
import styles from "./FirstFormSlide.module.scss"

let initialValuesBase: CreatePatientDataFirstCard = {
  birthDay: "",
  birthMonth: "",
  birthName: "",
  birthYear: "",
  firstName: "",
  foreName: "",
  lastName: "",
  mothersName: "",
}

const validate = (values: CreatePatientDataFirstCard) => {
  let errors = {} as CreatePatientDataFirstCard
  necessary(values, "firstName", errors)
  necessary(values, "lastName", errors)
  necessary(values, "birthName", errors)
  necessary(values, "mothersName", errors)
  necessary(values, "birthYear", errors)
  necessary(values, "birthMonth", errors)
  necessary(values, "birthDay", errors)
  if (!isValidBirthYear(values.birthYear)) errors["birthYear"] = "Helytelen születési év"

  return errors
}

const FirstFormSlide: React.FC = () => {
  const formContext = useContext(newPatientFormContext)
  const [loaded, setLoaded] = useState<boolean>(false)
  const [initialValues, setInitialValues] = useState(initialValuesBase)
  const { formValues } = formContext
  const daysInMonth = howManyDaysInMonth(formValues.birthMonth, formValues.birthYear)

  useEffect(() => {
    if (formValues.firstName !== "" && !loaded) {
      let newInitialValues = {
        birthDay: formValues.birthDay,
        birthMonth: formValues.birthMonth,
        birthName: formValues.birthName,
        mothersName: formValues.mothersName,
        birthYear: formValues.birthYear,
        firstName: formValues.firstName,
        foreName: formValues.foreName,
        lastName: formValues.lastName,
        phone: formValues.phone,
      }
      setInitialValues(newInitialValues)
      setLoaded(true)
    }
  }, [formValues])

  let dayOptions: Array<{ value: number }> = []

  for (let i = 1; i <= daysInMonth; i++) {
    dayOptions.push({ value: i })
  }

  return (
    <TaskCardBase
      formContext={formContext}
      initialValues={initialValues}
      initialErrors={initialValues}
      title="Páciens (gyermek) adatok"
      justifyContent="space-between"
      formikValidate={validate}
    >
      <MyTextInput label="Vezetéknév" name="lastName" type="text" />
      <div className={styles.names}>
        <MyTextInput label="Keresztnév" name="firstName" type="text" />
        <MyTextInput label="Utónév" name="foreName" type="text" optional />
      </div>
      <MyTextInput label="Születési név" name="birthName" type="text" />
      <MyTextInput label="Anyja neve" name="mothersName" type="text" />
      <div className={styles.date}>
        <MyTextInput label="Születési év" name="birthYear" type="text" />
        <Select label="Hónap" name="birthMonth" selectOptions={monthOptions} />
        <Select label="Nap" name="birthDay" selectOptions={dayOptions} />
      </div>
    </TaskCardBase>
  )
}

export default FirstFormSlide
