import { ApiResponse, DeletePatientData } from "src/types"
import { APIBASEURL, headersBase } from "."

export const deletePatient = async (userToken: string, body: DeletePatientData) => {
  const res = await fetch(APIBASEURL + "/patient", {
    method: "DELETE",
    headers: {
      ...headersBase,
      "User-Token": userToken,
      "content-type": "application/json",
    },
    body: JSON.stringify(body),
  })
  const result: ApiResponse = await res.json()
  return result
}
