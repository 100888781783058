import { ApiResponse } from "src/types"
import { APIBASEURL, headersBase } from "."

export const deleteMedia = async (userToken: string, imageID: number) => {
  const res = await fetch(APIBASEURL + "/image", {
    method: "DELETE",
    headers: {
      ...headersBase,
      "User-Token": userToken,
      "Image-ID": imageID.toString(),
    },
  })
  const result: ApiResponse<"image deleted" | "failed to delete image"> = await res.json()
  return result
}
