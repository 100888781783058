import React, { useContext, useEffect, useRef, useState } from "react"
import styles from "./CreateUserExtendedProfPage.module.css"
import { UserProfile } from "src/types"
import { GlobalContext } from "src/App"
import CreateExtendedProfilePage from "src/common-components/CreateExtendedProfile/CreateExtendedProfile"
import { isValidPhoneNumber, isValidTajNum, necessary } from "src/common/validationFunctions"
import { Formik } from "formik"
import ExtProfFirstCard, { validateUserExtProfFirstPage } from "./UserExtProfFirstCard"
import UserExtProfFirstCard from "./UserExtProfFirstCard"
import UserExtProfSecondCard from "./UserExtProfSecondCard"
import {
  UserExtProfFirstPageInitialValues,
  UserExtProfSecondPageInitialValues,
} from "./initialValues"
import { createExtendedUserProfile } from "../../apicalls"

const CreateUserExtendedProfPage: React.FC = () => {
  const [formState, setFormState] = useState(0)
  const formikRef = useRef<any>(null)
  const [confirmPopupVisible, setConfirmPopupVisible] = useState<boolean>(false)
  const { userToken, setShouldUpdateUserDataFromServer } = useContext(GlobalContext)

  const page1 = <UserExtProfFirstCard />
  const page2 = <UserExtProfSecondCard setFormState={setFormState} />
  const pages = [page1, page2]
  const isLastPage = formState === pages.length - 1

  const validations = [validateUserExtProfFirstPage, undefined]

  const initialValues: UserProfile = {
    ...UserExtProfSecondPageInitialValues,
    ...UserExtProfFirstPageInitialValues,
  }

  const submit = async (formikRef: React.MutableRefObject<any>) => {
    const res = await createExtendedUserProfile(userToken, formikRef.current.values)
    if (res.status?.result === "ok") {
      setShouldUpdateUserDataFromServer(true)
    } else console.log("Error at communication with server") //TODO: show user the error
    return res
  }

  const onNext = async (
    values: any,
    actions: {
      setSubmitting: (val: boolean) => void
      setTouched: (val: any) => void
    },
  ) => {
    actions.setSubmitting(false)
    actions.setTouched({})
    if (!isLastPage) setFormState(formState + 1)
    else setConfirmPopupVisible(true)
  }

  return (
    <Formik
      innerRef={formikRef}
      onSubmit={onNext}
      initialValues={initialValues}
      validate={validations[formState]}
    >
      {({ submitForm }) => (
        <CreateExtendedProfilePage
          next={submitForm}
          setConfirmPopupVisible={setConfirmPopupVisible}
          confirmPopupVisible={confirmPopupVisible}
          submit={() => submit(formikRef)}
        >
          {pages[formState]}
        </CreateExtendedProfilePage>
      )}
    </Formik>
  )
}

export default CreateUserExtendedProfPage
