import { APIBASEURL, headersBase } from "."
import { ApiResponse, Article } from "../types"
import { Description } from "../user-components/ArticlePage/ArticlePage"

export async function getAssetData(
  symptoms: number[],
  userToken: string,
  type: "description",
  covid: boolean,
): Promise<ApiResponse<Description[] | null>>
export async function getAssetData(
  symptoms: number[],
  userToken: string,
  type: "article",
  covid: boolean,
): Promise<ApiResponse<(Article | null)[] | null>>

export async function getAssetData(
  symptoms: number[],
  userToken: string,
  type: "article" | "description",
  covid: boolean,
) {
  const res = await fetch(APIBASEURL + "/asset", {
    headers: {
      ...headersBase,
      "User-Token": userToken,
      "content-type": "application/json",
    },
    body: JSON.stringify({ symptoms, type, covid: covid }),
    method: "POST",
  })
  const result = await res.json()
  return result
}
