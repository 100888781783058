import { UserProfileFirstCard, UserProfileSecondCard } from "../../types"

export const UserExtProfFirstPageInitialValues: UserProfileFirstCard = {
  birthDay: "",
  birthMonth: "",
  birthName: "",
  birthYear: "",
  firstName: "",
  foreName: "",
  job: "",
  lastName: "",
  mothersName: "",
  tajNum: "",
}

export const UserExtProfSecondPageInitialValues: UserProfileSecondCard = {
  address: {
    city: "",
    country: "",
    region: "",
    street: "",
    streetNum: "",
    zipCode: "",
  },
  phone: "",
}
