const boySymptoms = {
  "6": [
    {
      ID: 9,
      Name: "Headache",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 22],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 11,
      Name: "Fever",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 47, 6, 22],
      ProfName: "",
      Synonyms: ["Temperature"],
    },
    {
      ID: 13,
      Name: "Sore throat",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 24],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 14,
      Name: "Runny nose",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 24],
      ProfName: "",
      Synonyms: ["Nasal discharge"],
    },
    {
      ID: 15,
      Name: "Cough",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 24, 25, 15, 31],
      ProfName: "",
      Synonyms: ["Wet cough", "Productive cough"],
    },
    {
      ID: 228,
      Name: "Cough with spotum",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 24, 25, 15, 31],
      ProfName: "",
      Synonyms: ["Wet cough", "Productive cough"],
    },
    {
      ID: 16,
      Name: "Tiredness",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 47, 6, 22],
      ProfName: "",
      Synonyms: ["Feeling of weakness", "Fatigue", "Weakness", "Lack of energy"],
    },
    {
      ID: 24,
      Name: "Blackhead",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 23, 17, 46],
      ProfName: "",
      Synonyms: ["Blackheads", "Comedones"],
    },
    {
      ID: 28,
      Name: "Stuffy nose",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 24],
      ProfName: "",
      Synonyms: ["Blocked nose", "Nasal congestion"],
    },
    {
      ID: 30,
      Name: "Wheezing",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 24, 15, 31],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 33,
      Name: "Eye redness",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 23],
      ProfName: "",
      Synonyms: ["Red eye", "Pink eye", "Pinkeye"],
    },
    {
      ID: 35,
      Name: "Lip swelling",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 25],
      ProfName: "",
      Synonyms: ["Angiooedema", "Angioedema", "Quincke's edema", "Swollen lip"],
    },
    {
      ID: 38,
      Name: "Nosebleed",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 24],
      ProfName: "",
      Synonyms: ["Epistaxis"],
    },
    {
      ID: 40,
      Name: "Increased thirst",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 24, 25],
      ProfName: "",
      Synonyms: ["Polydipsia", "Chronic thirst"],
    },
    {
      ID: 43,
      Name: "Drowsiness",
      HasRedFlag: true,
      HealthSymptomLocationIDs: [6, 22, 17, 47],
      ProfName: "",
      Synonyms: [
        "Somnolence",
        "Daze",
        "Light-headedness",
        "Lightheadedness",
        "Lightheadness",
        "Feeling of lightheadness",
        "Feeling of lightheadedness",
      ],
    },
    {
      ID: 44,
      Name: "Nausea",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 22, 15, 31, 16, 36, 17, 47],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 45,
      Name: "Heartburn",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 24, 15, 31, 16, 36],
      ProfName: "",
      Synonyms: ["Pyrosis"],
    },
    {
      ID: 46,
      Name: "Burning in the throat",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 24],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 49,
      Name: "Cravings",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 25, 16, 36],
      ProfName: "",
      Synonyms: ["Binge eating"],
    },
    {
      ID: 52,
      Name: "Sleeplessness",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 22, 17, 47],
      ProfName: "",
      Synonyms: ["Insomnia"],
    },
    {
      ID: 53,
      Name: "Difficulty to concentrate",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 22, 17, 47],
      ProfName: "",
      Synonyms: [
        "Difficulty with concentration",
        "Concentration difficulty",
        "Concentration difficulties",
        "Concentration problem",
        "Concentration problems",
        "Difficulty concentrating",
      ],
    },
    {
      ID: 54,
      Name: "Reduced appetite",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 25, 17, 47, 36],
      ProfName: "",
      Synonyms: ["Loss of appetite", "Decreased appetite", "Decrease in hunger"],
    },
    {
      ID: 57,
      Name: "Blackening of vision",
      HasRedFlag: true,
      HealthSymptomLocationIDs: [6, 22, 23],
      ProfName: "",
      Synonyms: ["Fainting", "Going black before the eyes", "Short vision blackout"],
    },
    {
      ID: 62,
      Name: "Formation of blisters on a skin area",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 25, 17, 46],
      ProfName: "",
      Synonyms: [
        "Vesicle",
        "Formation of blisters on a place of the skin",
        "Formation of blisters on an area of the skin",
        "Formation of blisters on an area of skin",
        "Blisters",
      ],
    },
    {
      ID: 64,
      Name: "Sputum",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [15, 6, 31, 25],
      ProfName: "",
      Synonyms: ["Phlegm"],
    },
    {
      ID: 66,
      Name: "Visual field loss",
      HasRedFlag: true,
      HealthSymptomLocationIDs: [6, 23],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 68,
      Name: "Vision impairment",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 23],
      ProfName: "",
      Synonyms: ["Visual impairment", "Impaired vision", "Vision loss"],
    },
    {
      ID: 69,
      Name: "Sensitivity to glare",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 23],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 70,
      Name: "Impaired light-dark adaptation",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 23],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 71,
      Name: "Double vision",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 23],
      ProfName: "",
      Synonyms: ["Diplopia"],
    },
    {
      ID: 72,
      Name: "Halo",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 23],
      ProfName: "",
      Synonyms: ["See circles around lights"],
    },
    {
      ID: 73,
      Name: "Itching eyes",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 23],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 75,
      Name: "Burning eyes",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 23],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 76,
      Name: "Feeling of foreign body in the eye",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 23],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 77,
      Name: "Blurred vision",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 23],
      ProfName: "",
      Synonyms: ["Distorted vision"],
    },
    {
      ID: 78,
      Name: "Ringing in the ear",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 24],
      ProfName: "",
      Synonyms: ["Tinnitus", "Noise in the ear", "Ringing in the ears", "Ear noise"],
    },
    {
      ID: 85,
      Name: "Mood swings",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 22, 17, 47],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 86,
      Name: "Feeling of pressure in the ear",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 24],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 87,
      Name: "Earache",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 24],
      ProfName: "",
      Synonyms: [
        "Otalgia",
        "Ear pain",
        "Pain in the ear",
        "Pain around the ear",
        "Pain in ear",
        "Pain around ear",
        "Discomfort in ear",
        "Discomfort around ear",
      ],
    },
    {
      ID: 88,
      Name: "Itching in the ear",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 24],
      ProfName: "",
      Synonyms: ["Itch in the ear", "Pruritus"],
    },
    {
      ID: 90,
      Name: "Impaired hearing",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 24],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 93,
      Name: "Difficulty in swallowing",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 24, 25],
      ProfName: "",
      Synonyms: ["Dysphagia", "Difficulties in swallowing"],
    },
    {
      ID: 95,
      Name: "Sneezing",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 24],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 96,
      Name: "Itching in the nose",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 24],
      ProfName: "",
      Synonyms: ["Itch in the nose", "Pruritus"],
    },
    {
      ID: 97,
      Name: "Mouth ulcers",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 25],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 98,
      Name: "Difficulty in speaking",
      HasRedFlag: true,
      HealthSymptomLocationIDs: [6, 25, 24],
      ProfName: "",
      Synonyms: [
        "Difficulty speaking",
        "Difficulty talking",
        "Trouble speaking",
        "Trouble talking",
        "Dysarthria",
        "Trouble with speech",
      ],
    },
    {
      ID: 101,
      Name: "Vomiting",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 25, 16, 36],
      ProfName: "",
      Synonyms: ["Throwing up"],
    },
    {
      ID: 102,
      Name: "Sensitivity to noise",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 24],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 106,
      Name: "Yellowish discoloration of the white part of the eye",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 23],
      ProfName: "",
      Synonyms: ["Yellow colored eyes"],
    },
    {
      ID: 114,
      Name: "Nervousness",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 22, 17, 47],
      ProfName: "",
      Synonyms: ["Stress", "Feeling of tension", "Irritability"],
    },
    {
      ID: 120,
      Name: "Impaired balance",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 22, 17, 47],
      ProfName: "",
      Synonyms: ["Loss of balance"],
    },
    {
      ID: 121,
      Name: "Hoarseness",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 24],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 122,
      Name: "Hiccups",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 24, 25],
      ProfName: "",
      Synonyms: ["Singultus"],
    },
    {
      ID: 125,
      Name: "Forgetfulness",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 22],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 126,
      Name: "Difficulty in finding words",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 22, 25],
      ProfName: "",
      Synonyms: ["Word-finding disorder", "Anomia", "Amnesic aphasia"],
    },
    {
      ID: 128,
      Name: "Disorientation regarding time or place",
      HasRedFlag: true,
      HealthSymptomLocationIDs: [6, 22, 17, 47],
      ProfName: "",
      Synonyms: ["Confusion", "Temporal or local disorientation"],
    },
    {
      ID: 129,
      Name: "Problems with the sense of touch in the face",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 23],
      ProfName: "",
      Synonyms: [
        "Sensory disturbances in the face",
        "Dysesthesia in the face",
        "Dysaesthesia in the face",
      ],
    },
    {
      ID: 131,
      Name: "Increased appetite",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 25, 17, 47, 36],
      ProfName: "",
      Synonyms: ["Increase in hunger"],
    },
    {
      ID: 133,
      Name: "Night cough",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 24, 25, 15, 31],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 135,
      Name: "Mouth pain",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 25],
      ProfName: "",
      Synonyms: ["Mouth burning", "Pain in mouth", "Burning in mouth"],
    },
    {
      ID: 136,
      Name: "Neck pain",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 24],
      ProfName: "",
      Synonyms: ["Neck ache", "Pain in neck", "Painful neck"],
    },
    {
      ID: 137,
      Name: "Oversensitivity to light",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 23],
      ProfName: "",
      Synonyms: ["Light sensitivity", "Photophobia", "Sensitivity to light"],
    },
    {
      ID: 144,
      Name: "Unconsciousness, short",
      HasRedFlag: true,
      HealthSymptomLocationIDs: [6, 22, 17, 47],
      ProfName: "",
      Synonyms: ["Shock", "Short unconsciousness", "Syncope", "Faint", "Blackout"],
    },
    {
      ID: 152,
      Name: "Hair loss",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 21],
      ProfName: "",
      Synonyms: ["Hair thinning", "Thinning hair", "Diffuse hair loss"],
    },
    {
      ID: 153,
      Name: "Fast, deepened breathing",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 24, 25, 15, 31, 16, 36],
      ProfName: "",
      Synonyms: ["Fast breathing", "Hyperventilation"],
    },
    {
      ID: 166,
      Name: "Vision impairment for near objects",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [23, 6],
      ProfName: "",
      Synonyms: [
        "Hyperopia",
        "Hypermetropia",
        "Farsightedness",
        "Far-sightedness",
        "Longsightedness",
        "Long-sightedness",
        "Weak vision for near objects",
        "Weak sight for near objects",
        "Visual impairment for near objects",
        "Vision loss for near objects",
        "Vision impairment for close objects",
      ],
    },
    {
      ID: 169,
      Name: "Swollen glands in the neck",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 24],
      ProfName: "",
      Synonyms: [
        "Cervical lymph nodes",
        "Swollen glands in the neck",
        "Swollen lymph nodes in the neck",
        "Enlarged lymph nodes in the neck",
        "Enlarged glands in the neck",
        "Swollen glands on the neck",
        "Swollen lymph nodes on the neck",
        "Enlarged lymph nodes on the neck",
        "Enlarged glands on the neck",
      ],
    },
    {
      ID: 170,
      Name: "Cheek swelling",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 23],
      ProfName: "",
      Synonyms: ["Swollen cheek"],
    },
    {
      ID: 176,
      Name: "Bleeding in the conjunctiva of the eye",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 23],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 181,
      Name: "Vomiting blood",
      HasRedFlag: true,
      HealthSymptomLocationIDs: [6, 25, 16, 36],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 203,
      Name: "Pain on swallowing",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 24, 25],
      ProfName: "",
      Synonyms: ["Painful swallowing"],
    },
    {
      ID: 204,
      Name: "Increased salivation",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 25],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 205,
      Name: "Lockjaw",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 25],
      ProfName: "",
      Synonyms: ["Limited mouth opening", "Trismus", "Difficulty opening mouth"],
    },
    {
      ID: 206,
      Name: "Hearing loss",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 207,
      Name: "Dizziness",
      HasRedFlag: true,
      HealthSymptomLocationIDs: [6, 22, 17, 47],
      ProfName: "",
      Synonyms: [
        "Vertigo",
        "Light-headedness",
        "Lightheadedness",
        "Lightheadness",
        "Feeling of lightheadness",
        "Feeling of lightheadedness",
      ],
    },
    {
      ID: 208,
      Name: "Eyelid swelling",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 23],
      ProfName: "",
      Synonyms: ["Swollen eyelid", "Eye lid swelling", "Swollen eye lid"],
    },
    {
      ID: 209,
      Name: "Eyelids sticking together",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 23],
      ProfName: "",
      Synonyms: [
        "Eye lids sticking together",
        "Dried pus on the eyelids",
        "Dried pus on the eyelashes",
        "Eye discharge",
        "Discharge from eye",
        "Pus from eye",
        "Pus in eye",
      ],
    },
    {
      ID: 210,
      Name: "Loss of eye lashes",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 23],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 211,
      Name: "Tears",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 23],
      ProfName: "",
      Synonyms: [
        "Lacrimation",
        "Tearing",
        "Lachrymation",
        "Secretion of tears",
        "Tear flow",
        "Epiphora",
        "Tearing eyes",
        "Teary eyes",
        "Watery eyes",
      ],
    },
    {
      ID: 213,
      Name: "Vision impairment for far objects",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [23, 6],
      ProfName: "",
      Synonyms: [
        "Myopia",
        "Vision impairment for distant objects",
        "Nearsightedness",
        "Near-sightedness",
        "Shortsightedness",
        "Short-sightedness",
        "Weak vision for far objects",
        "Weak sight for far objects",
        "Visual impairment for far objects",
        "Vision loss for far objects",
        "Vision impairment for distant objects",
      ],
    },
    {
      ID: 219,
      Name: "Face pain",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 23],
      ProfName: "",
      Synonyms: ["Pain in the face"],
    },
    {
      ID: 233,
      Name: "Bloody cough",
      HasRedFlag: true,
      HealthSymptomLocationIDs: [15, 31, 6, 25],
      ProfName: "",
      Synonyms: ["Bloody sputum", "Coughing up blood", "Hemoptysis"],
    },
    {
      ID: 234,
      Name: "Neck stiffness",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 24],
      ProfName: "",
      Synonyms: ["Stiff neck", "Meningismus", "Neck rigidity"],
    },
    {
      ID: 238,
      Name: "Anxiety",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 22, 17, 47],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 239,
      Name: "Bold area among hair on the head",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 21],
      ProfName: "",
      Synonyms: ["Round patches of hair loss", "Spot baldness", "Circular hair loss"],
    },
    {
      ID: 241,
      Name: "Sleepiness with spontaneous falling asleep",
      HasRedFlag: true,
      HealthSymptomLocationIDs: [6, 22, 17, 47],
      ProfName: "",
      Synonyms: ["Spontaneous falling asleep", "Falling asleep spontaneously"],
    },
    {
      ID: 242,
      Name: "Eye blinking",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 22, 23],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 243,
      Name: "Tic",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 22, 23, 25],
      ProfName: "",
      Synonyms: ["Twitch"],
    },
    {
      ID: 244,
      Name: "Drooping eyelid",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [22, 6, 23],
      ProfName: "",
      Synonyms: ["Ptosis"],
    },
    {
      ID: 247,
      Name: "Itching on head",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [21, 6],
      ProfName: "",
      Synonyms: ["Itching on the head", "Itch on head", "Itching on head", "Pruritus"],
    },
    {
      ID: 258,
      Name: "Protrusion of the eyes",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 23],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 264,
      Name: "Shiny red tongue",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 25],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 269,
      Name: "Scalp redness",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 21],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 270,
      Name: "Double vision, acute-onset",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 23],
      ProfName: "",
      Synonyms: ["Acute Diplopia", "Vision double acute"],
    },
    {
      ID: 272,
      Name: "Dry mouth",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 25],
      ProfName: "",
      Synonyms: ["Xerostomia"],
    },
    {
      ID: 273,
      Name: "Dry eyes",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 23],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 287,
      Name: "Eye pain",
      HasRedFlag: true,
      HealthSymptomLocationIDs: [6, 23],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 288,
      Name: "Burning nose",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 23, 24],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 970,
      Name: "Facial swelling",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 23, 25, 22],
      ProfName: "",
      Synonyms: ["Swollen face", "Face swelling", "Big face"],
    },
    {
      ID: 973,
      Name: "Itching in the mouth or throat",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 25],
      ProfName: "",
      Synonyms: ["Itch in the mouth or throat", "Pruritus"],
    },
    {
      ID: 974,
      Name: "Aggressiveness",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 22, 17, 47],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 975,
      Name: "Sadness",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 22, 17, 47],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 976,
      Name: "Hallucination",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 22, 17, 47],
      ProfName: "",
      Synonyms: ["Delusion"],
    },
    {
      ID: 977,
      Name: "Tongue swelling",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 25],
      ProfName: "",
      Synonyms: ["Swollen tongue"],
    },
    {
      ID: 980,
      Name: "Tongue burning",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 25],
      ProfName: "",
      Synonyms: ["Burning tongue"],
    },
    {
      ID: 982,
      Name: "Feeling faint",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 22, 17, 47],
      ProfName: "",
      Synonyms: ["Fainting", "Feel faint"],
    },
    {
      ID: 984,
      Name: "Absence of a pulse",
      HasRedFlag: true,
      HealthSymptomLocationIDs: [6, 24, 22, 7, 29, 10, 41, 16, 38],
      ProfName: "",
      Synonyms: ["Absence of the pulse", "Acrotism", "Pulselessness"],
    },
    {
      ID: 988,
      Name: "Trouble understanding speech",
      HasRedFlag: true,
      HealthSymptomLocationIDs: [6, 24, 22],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 991,
      Name: "Blue colored skin",
      HasRedFlag: true,
      HealthSymptomLocationIDs: [6, 25, 7, 30, 17, 46],
      ProfName: "",
      Synonyms: [
        "Blue colored lips",
        "Blue colored nails",
        "Blue colored fingertips",
        "Cyanosis",
        "Bluish discoloration of the skin",
        "Bluish skin",
        "Blue skin",
      ],
    },
    {
      ID: 1007,
      Name: "Difficulty to learn",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 22, 47],
      ProfName: "",
      Synonyms: ["Learning difficulty", "Learning difficulties", "Difficulty with learning"],
    },
    {
      ID: 1008,
      Name: "Toothache",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 25],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 1014,
      Name: "Feeling ill",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 22, 17, 47],
      ProfName: "",
      Synonyms: ["Feeling sick", "Feeling unwell", "Generally feeling unwell"],
    },
  ],
  "7": [
    {
      ID: 12,
      Name: "Pain in the limbs",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 48, 10, 49, 17, 47],
      ProfName: "",
      Synonyms: [
        "Body aches",
        "Ache all over",
        "Diffuse muscle aches",
        "Aching limbs",
        "Pain in the extremities",
      ],
    },
    {
      ID: 27,
      Name: "Joint pain",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 48, 10, 49, 45, 17, 47],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 91,
      Name: "Changes in the nails",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 30, 10, 44, 45],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 94,
      Name: "Cramps",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 10, 49, 48],
      ProfName: "",
      Synonyms: ["Spasms", "Tetany"],
    },
    {
      ID: 115,
      Name: "Tremor at rest",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 29, 10, 44, 17, 47],
      ProfName: "",
      Synonyms: ["Tremble at rest", "Trembling at rest"],
    },
    {
      ID: 119,
      Name: "Muscle stiffness",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 10, 48, 49],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 132,
      Name: "Tremor on movement",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 29, 17, 47, 10, 44],
      ProfName: "",
      Synonyms: ["Tremble on movement", "Trembling on movement"],
    },
    {
      ID: 140,
      Name: "Paralysis",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 47, 10, 7, 49, 48],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 148,
      Name: "Hand swelling",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 29],
      ProfName: "",
      Synonyms: ["Swollen hand"],
    },
    {
      ID: 155,
      Name: "Pain in the bones",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 16, 39, 10, 48, 49],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 156,
      Name: "Bone fracture",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 16, 39, 10, 17, 47, 48, 49],
      ProfName: "",
      Synonyms: ["Fracture"],
    },
    {
      ID: 168,
      Name: "Muscular weakness in the arm",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 48],
      ProfName: "",
      Synonyms: ["Muscle weakness in the arm"],
    },
    {
      ID: 177,
      Name: "Muscle pain",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 48, 10, 49, 17, 47, 46],
      ProfName: "",
      Synonyms: ["Myalgia"],
    },
    {
      ID: 178,
      Name: "Limited mobility of the fingers",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 30],
      ProfName: "",
      Synonyms: ["Limited finger mobility"],
    },
    {
      ID: 186,
      Name: "Hand pain",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 28, 29],
      ProfName: "",
      Synonyms: ["Wrist pain", "Pain in the hand", "Pain in the wrist"],
    },
    {
      ID: 194,
      Name: "Joint effusion",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 47, 10, 7, 49, 48],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 197,
      Name: "Immobilization",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 47, 10, 7, 48, 49],
      ProfName: "",
      Synonyms: ["Akinesia", "Immobility"],
    },
    {
      ID: 198,
      Name: "Joint instability",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 47, 10, 7, 49, 48],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 200,
      Name: "Numbness of the hands",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 29],
      ProfName: "",
      Synonyms: [
        "Going to sleep of the hands",
        "Impaired sense of feeling in the hands",
        "Numbness of the hand",
      ],
    },
    {
      ID: 201,
      Name: "Tingling",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 29, 30, 10, 44, 45],
      ProfName: "",
      Synonyms: ["Paresthesia", "Pins and needles feeling", "Tingling in the affected area"],
    },
    {
      ID: 202,
      Name: "Muscular atrophy of the arm",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 48],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 216,
      Name: "Discoloration of nails",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 29, 10, 44, 45],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 221,
      Name: "Numbness in the arm",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 48],
      ProfName: "",
      Synonyms: ["Impaired sense of feeling in the arm"],
    },
    {
      ID: 230,
      Name: "Joint redness",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 47, 10, 7, 49, 48, 45],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 248,
      Name: "Swollen glands in the armpit",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 48, 15, 32],
      ProfName: "",
      Synonyms: [
        "Axillary lymph nodes",
        "Swollen lymph nodes in the armpit",
        "Enlarged lymph nodes in the armpit",
        "Enlarged glands in the armpit",
        "Swollen glands in the armpits",
        "Swollen lymph nodes in the armpits",
        "Enlarged lymph nodes in the armpits",
        "Enlarged glands in the armpits",
      ],
    },
    {
      ID: 251,
      Name: "Pain radiating to the arm",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [33, 15, 48, 7],
      ProfName: "",
      Synonyms: ["Pain in the shoulders", "Pain in shoulders", "Pain radiating to arm"],
    },
    {
      ID: 257,
      Name: "Involuntary movements",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 29, 10, 49, 44, 17, 47],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 971,
      Name: "Arm swelling",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 48, 26, 28],
      ProfName: "",
      Synonyms: ["Swollen arm"],
    },
    {
      ID: 972,
      Name: "Weakness or numbness on right or left side of body",
      HasRedFlag: true,
      HealthSymptomLocationIDs: [7, 48, 10, 49, 17, 47],
      ProfName: "",
      Synonyms: [
        "One-sided weakness or numbness of body",
        "Right-sided weakness of body",
        "Left-sided weakness of body",
        "Right-sided numbness of body",
        "Left-sided numbness of body",
        "Weakness on right side of body",
        "Weakness on left side of body",
        "Numbness on right side of body",
        "Numbness on left side of body",
        "Weakness or numbness in face",
        "Weakness or numbness in the face",
        "Weakness in face",
        "Numbness in face",
      ],
    },
    {
      ID: 978,
      Name: "Cold hands",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 29],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 979,
      Name: "Brittleness of nails",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 29, 10, 44, 45],
      ProfName: "",
      Synonyms: ["Brittle nails", "Fragility of nails", "Onychorrhexis", "Onychoschisis"],
    },
    {
      ID: 984,
      Name: "Absence of a pulse",
      HasRedFlag: true,
      HealthSymptomLocationIDs: [6, 24, 22, 7, 29, 10, 41, 16, 38],
      ProfName: "",
      Synonyms: ["Absence of the pulse", "Acrotism", "Pulselessness"],
    },
    {
      ID: 987,
      Name: "Muscle weakness",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 48, 10, 49, 17, 47, 46],
      ProfName: "",
      Synonyms: ["Muscular weakness", "Myasthenia"],
    },
    {
      ID: 991,
      Name: "Blue colored skin",
      HasRedFlag: true,
      HealthSymptomLocationIDs: [6, 25, 7, 30, 17, 46],
      ProfName: "",
      Synonyms: [
        "Blue colored lips",
        "Blue colored nails",
        "Blue colored fingertips",
        "Cyanosis",
        "Bluish discoloration of the skin",
        "Bluish skin",
        "Blue skin",
      ],
    },
    {
      ID: 995,
      Name: "Finger deformity",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 30],
      ProfName: "",
      Synonyms: ["Finger joint deformity"],
    },
    {
      ID: 1009,
      Name: "Arm pain",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 48],
      ProfName: "",
      Synonyms: ["Pain in the arm", "Pain in arm"],
    },
    {
      ID: 1011,
      Name: "Elbow pain",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 28],
      ProfName: "",
      Synonyms: ["Pain in the elbow", "Pain in elbow"],
    },
    {
      ID: 1012,
      Name: "Finger swelling",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 30],
      ProfName: "",
      Synonyms: ["Swollen finger"],
    },
    {
      ID: 1013,
      Name: "Finger pain",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 30],
      ProfName: "",
      Synonyms: ["Pain in the finger"],
    },
  ],
  "10": [
    {
      ID: 12,
      Name: "Pain in the limbs",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 48, 10, 49, 17, 47],
      ProfName: "",
      Synonyms: [
        "Body aches",
        "Ache all over",
        "Diffuse muscle aches",
        "Aching limbs",
        "Pain in the extremities",
      ],
    },
    {
      ID: 27,
      Name: "Joint pain",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 48, 10, 49, 45, 17, 47],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 89,
      Name: "Cold feet",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [10, 44],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 91,
      Name: "Changes in the nails",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 30, 10, 44, 45],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 94,
      Name: "Cramps",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 10, 49, 48],
      ProfName: "",
      Synonyms: ["Spasms", "Tetany"],
    },
    {
      ID: 103,
      Name: "Pain radiating to the leg",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [10, 49, 15, 33, 16, 40, 39],
      ProfName: "",
      Synonyms: ["Pain radiating to leg"],
    },
    {
      ID: 115,
      Name: "Tremor at rest",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 29, 10, 44, 17, 47],
      ProfName: "",
      Synonyms: ["Tremble at rest", "Trembling at rest"],
    },
    {
      ID: 118,
      Name: "Physical inactivity",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [10, 49, 16, 39, 17, 47],
      ProfName: "",
      Synonyms: ["Lack of exercise", "Hypokinesia"],
    },
    {
      ID: 119,
      Name: "Muscle stiffness",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 10, 48, 49],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 130,
      Name: "Problems with the sense of touch in the feet",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [10, 44],
      ProfName: "",
      Synonyms: [
        "Sensory disturbances in the feet",
        "Dysesthesia in the feet",
        "Dysaesthesia in the feet",
      ],
    },
    {
      ID: 132,
      Name: "Tremor on movement",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 29, 17, 47, 10, 44],
      ProfName: "",
      Synonyms: ["Tremble on movement", "Trembling on movement"],
    },
    {
      ID: 140,
      Name: "Paralysis",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 47, 10, 7, 49, 48],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 142,
      Name: "Pain in the calves",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [10, 43],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 143,
      Name: "Leg ulcer",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [10, 43],
      ProfName: "",
      Synonyms: ["Open sore", "Ulcus cruris"],
    },
    {
      ID: 145,
      Name: "Feeling of tension in the legs",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [10, 49, 43, 41],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 146,
      Name: "Leg cramps",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [10, 44, 49, 43, 41],
      ProfName: "",
      Synonyms: ["Foot cramps", "Muscle cramps", "Leg muscle cramps"],
    },
    {
      ID: 147,
      Name: "Ankle swelling",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [10, 43],
      ProfName: "",
      Synonyms: ["Ankle edema", "Swollen ankle"],
    },
    {
      ID: 155,
      Name: "Pain in the bones",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 16, 39, 10, 48, 49],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 156,
      Name: "Bone fracture",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 16, 39, 10, 17, 47, 48, 49],
      ProfName: "",
      Synonyms: ["Fracture"],
    },
    {
      ID: 177,
      Name: "Muscle pain",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 48, 10, 49, 17, 47, 46],
      ProfName: "",
      Synonyms: ["Myalgia"],
    },
    {
      ID: 185,
      Name: "Stress-related leg pain",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [10, 49],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 193,
      Name: "Joint swelling",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 47, 10, 48, 49],
      ProfName: "",
      Synonyms: ["Swollen joint"],
    },
    {
      ID: 194,
      Name: "Joint effusion",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 47, 10, 7, 49, 48],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 195,
      Name: "Limited mobility of the leg",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [10, 49, 16, 39, 17, 47],
      ProfName: "",
      Synonyms: ["Limited leg mobility", "Leg stiffness"],
    },
    {
      ID: 197,
      Name: "Immobilization",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 47, 10, 7, 48, 49],
      ProfName: "",
      Synonyms: ["Akinesia", "Immobility"],
    },
    {
      ID: 198,
      Name: "Joint instability",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 47, 10, 7, 49, 48],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 201,
      Name: "Tingling",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 29, 30, 10, 44, 45],
      ProfName: "",
      Synonyms: ["Paresthesia", "Pins and needles feeling", "Tingling in the affected area"],
    },
    {
      ID: 216,
      Name: "Discoloration of nails",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 29, 10, 44, 45],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 230,
      Name: "Joint redness",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 47, 10, 7, 49, 48, 45],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 231,
      Name: "Leg swelling",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [10, 49],
      ProfName: "",
      Synonyms: ["Swollen leg", "Leg edema"],
    },
    {
      ID: 232,
      Name: "Marked veins",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [10, 43],
      ProfName: "",
      Synonyms: ["Increased marked veins"],
    },
    {
      ID: 252,
      Name: "Muscular atrophy in the leg",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [10, 49],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 253,
      Name: "Muscular weakness in the leg",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [10, 49],
      ProfName: "",
      Synonyms: ["Muscle weakness in the leg"],
    },
    {
      ID: 254,
      Name: "Numbness in the leg",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [10, 49],
      ProfName: "",
      Synonyms: ["Impaired sense of feeling in the leg"],
    },
    {
      ID: 255,
      Name: "Foot pain",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [44, 10],
      ProfName: "",
      Synonyms: ["Pain in the foot"],
    },
    {
      ID: 256,
      Name: "Knee pain",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [10, 41],
      ProfName: "",
      Synonyms: ["Pain in the knee"],
    },
    {
      ID: 257,
      Name: "Involuntary movements",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 29, 10, 49, 44, 17, 47],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 972,
      Name: "Weakness or numbness on right or left side of body",
      HasRedFlag: true,
      HealthSymptomLocationIDs: [7, 48, 10, 49, 17, 47],
      ProfName: "",
      Synonyms: [
        "One-sided weakness or numbness of body",
        "Right-sided weakness of body",
        "Left-sided weakness of body",
        "Right-sided numbness of body",
        "Left-sided numbness of body",
        "Weakness on right side of body",
        "Weakness on left side of body",
        "Numbness on right side of body",
        "Numbness on left side of body",
        "Weakness or numbness in face",
        "Weakness or numbness in the face",
        "Weakness in face",
        "Numbness in face",
      ],
    },
    {
      ID: 979,
      Name: "Brittleness of nails",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 29, 10, 44, 45],
      ProfName: "",
      Synonyms: ["Brittle nails", "Fragility of nails", "Onychorrhexis", "Onychoschisis"],
    },
    {
      ID: 981,
      Name: "Agitation",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [48, 29, 10, 49, 44, 17, 47],
      ProfName: "",
      Synonyms: ["Restlessness", "Psychomotor agitation", "Unrest"],
    },
    {
      ID: 984,
      Name: "Absence of a pulse",
      HasRedFlag: true,
      HealthSymptomLocationIDs: [6, 24, 22, 7, 29, 10, 41, 16, 38],
      ProfName: "",
      Synonyms: ["Absence of the pulse", "Acrotism", "Pulselessness"],
    },
    {
      ID: 987,
      Name: "Muscle weakness",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 48, 10, 49, 17, 47, 46],
      ProfName: "",
      Synonyms: ["Muscular weakness", "Myasthenia"],
    },
    {
      ID: 992,
      Name: "Limited mobility of the ankle",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [10, 44, 43],
      ProfName: "",
      Synonyms: ["Limited ankle mobility"],
    },
    {
      ID: 994,
      Name: "Knee deformity",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [10, 43],
      ProfName: "",
      Synonyms: ["Knee joint deformity"],
    },
    {
      ID: 996,
      Name: "Ankle deformity",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [10, 43],
      ProfName: "",
      Synonyms: ["Ankle joint deformity"],
    },
    {
      ID: 997,
      Name: "Toe deformity",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [10, 45],
      ProfName: "",
      Synonyms: ["Toe joint deformity"],
    },
    {
      ID: 1002,
      Name: "Foot swelling",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [10, 44, 43],
      ProfName: "",
      Synonyms: ["Swollen foot"],
    },
    {
      ID: 1003,
      Name: "Toe swelling",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [10, 44, 45],
      ProfName: "",
      Synonyms: ["Swollen toe"],
    },
    {
      ID: 1005,
      Name: "Difficulty with gait",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [10, 49, 16, 39, 17, 47],
      ProfName: "",
      Synonyms: [
        "Gait disorder",
        "Difficulty with walking",
        "Difficulty with movement",
        "Having problems with movement",
      ],
    },
    {
      ID: 1006,
      Name: "Enlarged calf",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [10, 49, 43],
      ProfName: "",
      Synonyms: ["Enlarged calves", "Enlarged calf muscles", "Calf enlargement"],
    },
    {
      ID: 1010,
      Name: "Leg pain",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [10, 49, 41, 43, 16, 39],
      ProfName: "",
      Synonyms: ["Pain in the leg", "Pain in leg"],
    },
  ],
  "15": [
    {
      ID: 15,
      Name: "Cough",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 24, 25, 15, 31],
      ProfName: "",
      Synonyms: ["Wet cough", "Productive cough"],
    },
    {
      ID: 17,
      Name: "Chest pain",
      HasRedFlag: true,
      HealthSymptomLocationIDs: [15, 31],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 29,
      Name: "Shortness of breath",
      HasRedFlag: true,
      HealthSymptomLocationIDs: [15, 31],
      ProfName: "",
      Synonyms: [
        "Dyspnea",
        "Breathlessness",
        "Breathing difficulty",
        "Difficulty to breathe",
        "Difficulty breathing",
        "Difficulty in breathing",
        "Difficult breathing",
        "Labored breathing",
      ],
    },
    {
      ID: 30,
      Name: "Wheezing",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 24, 15, 31],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 31,
      Name: "Chest tightness",
      HasRedFlag: true,
      HealthSymptomLocationIDs: [15, 31],
      ProfName: "",
      Synonyms: ["Tightness in chest", "Oppressive feeling in the chest"],
    },
    {
      ID: 37,
      Name: "Palpitations",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [15, 31],
      ProfName: "",
      Synonyms: ["Heart-pounding", "Rapid heart beat"],
    },
    {
      ID: 44,
      Name: "Nausea",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 22, 15, 31, 16, 36, 17, 47],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 45,
      Name: "Heartburn",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 24, 15, 31, 16, 36],
      ProfName: "",
      Synonyms: ["Pyrosis"],
    },
    {
      ID: 64,
      Name: "Sputum",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [15, 6, 31, 25],
      ProfName: "",
      Synonyms: ["Phlegm"],
    },
    {
      ID: 103,
      Name: "Pain radiating to the leg",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [10, 49, 15, 33, 16, 40, 39],
      ProfName: "",
      Synonyms: ["Pain radiating to leg"],
    },
    {
      ID: 104,
      Name: "Back pain",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [15, 33],
      ProfName: "",
      Synonyms: ["Pain in back", "Dorsalgia", "Backache"],
    },
    {
      ID: 133,
      Name: "Night cough",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 24, 25, 15, 31],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 153,
      Name: "Fast, deepened breathing",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 24, 25, 15, 31, 16, 36],
      ProfName: "",
      Synonyms: ["Fast breathing", "Hyperventilation"],
    },
    {
      ID: 167,
      Name: "Limited mobility of the back",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [15, 33],
      ProfName: "",
      Synonyms: [
        "Limited mobility of the spine",
        "Limited spine mobility",
        "Limited back mobility",
        "Back stiffness",
      ],
    },
    {
      ID: 183,
      Name: "Side pain",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [15, 32],
      ProfName: "",
      Synonyms: ["Flank pain"],
    },
    {
      ID: 233,
      Name: "Bloody cough",
      HasRedFlag: true,
      HealthSymptomLocationIDs: [15, 31, 6, 25],
      ProfName: "",
      Synonyms: ["Bloody sputum", "Coughing up blood", "Hemoptysis"],
    },
    {
      ID: 248,
      Name: "Swollen glands in the armpit",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 48, 15, 32],
      ProfName: "",
      Synonyms: [
        "Axillary lymph nodes",
        "Swollen lymph nodes in the armpit",
        "Enlarged lymph nodes in the armpit",
        "Enlarged glands in the armpit",
        "Swollen glands in the armpits",
        "Swollen lymph nodes in the armpits",
        "Enlarged lymph nodes in the armpits",
        "Enlarged glands in the armpits",
      ],
    },
    {
      ID: 250,
      Name: "Breathing-related pains",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [15, 31],
      ProfName: "",
      Synonyms: [
        "Pain during respiration",
        "Pain during inspiration",
        "Painful respiration",
        "Painful inspiration",
        "Chest pain when breathing",
        "Breathing-related chest pain",
        "Chest pain during inspiration",
        "Pleuritic chest pain",
      ],
    },
    {
      ID: 251,
      Name: "Pain radiating to the arm",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [33, 15, 48, 7],
      ProfName: "",
      Synonyms: ["Pain in the shoulders", "Pain in shoulders", "Pain radiating to arm"],
    },
    {
      ID: 260,
      Name: "Curvature of the spine",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [15, 33],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 263,
      Name: "Lower-back pain",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [15, 33],
      ProfName: "",
      Synonyms: ["Low-back pain", "Lower back pain", "Lumbago", "Waist pain"],
    },
    {
      ID: 985,
      Name: "Heart murmur",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [15, 31],
      ProfName: "",
      Synonyms: ["Abnormal heart sound", "Cardiac murmur"],
    },
    {
      ID: 986,
      Name: "Irregular heartbeat",
      HasRedFlag: true,
      HealthSymptomLocationIDs: [15, 31],
      ProfName: "",
      Synonyms: ["Arrhythmia"],
    },
    {
      ID: 998,
      Name: "Back deformity",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [15, 33],
      ProfName: "",
      Synonyms: [],
    },
  ],
  "16": [
    {
      ID: 10,
      Name: "Abdominal pain",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [16, 36],
      ProfName: "",
      Synonyms: [" Stomach ache"],
    },
    {
      ID: 39,
      Name: "Increased urine quantity",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [16, 38, 37],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 44,
      Name: "Nausea",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 22, 15, 31, 16, 36, 17, 47],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 45,
      Name: "Heartburn",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 24, 15, 31, 16, 36],
      ProfName: "",
      Synonyms: ["Pyrosis"],
    },
    {
      ID: 48,
      Name: "Bloated feeling in the stomach",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [16, 36],
      ProfName: "",
      Synonyms: ["Feeling full"],
    },
    {
      ID: 49,
      Name: "Cravings",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 25, 16, 36],
      ProfName: "",
      Synonyms: ["Binge eating"],
    },
    {
      ID: 50,
      Name: "Diarrhea",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [16, 36, 40],
      ProfName: "",
      Synonyms: ["Diarrhoea", "Loose stool", "Watery stool"],
    },
    {
      ID: 59,
      Name: "Frequent urination",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [16, 38, 37],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 79,
      Name: "Difficult defecation",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [16, 40],
      ProfName: "",
      Synonyms: [
        "Difficulty passing stool",
        "Difficult evacuation of the bowels",
        "Obstipation",
        "Constipation",
      ],
    },
    {
      ID: 80,
      Name: "Hard defecation",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [16, 40],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 81,
      Name: "Incomplete defecation",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [16, 40],
      ProfName: "",
      Synonyms: ["Incomplete evacuation of the bowels"],
    },
    {
      ID: 82,
      Name: "Less than 3 defecations per week",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [16, 40],
      ProfName: "",
      Synonyms: ["Less than 3 times passing stool", "Obstipation", "Constipation"],
    },
    {
      ID: 83,
      Name: "Fatty defecation",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [16, 40],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 84,
      Name: "Foul smelling defecation",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [16, 40],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 92,
      Name: "Early satiety",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [16, 36],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 101,
      Name: "Vomiting",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 25, 16, 36],
      ProfName: "",
      Synonyms: ["Throwing up"],
    },
    {
      ID: 103,
      Name: "Pain radiating to the leg",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [10, 49, 15, 33, 16, 40, 39],
      ProfName: "",
      Synonyms: ["Pain radiating to leg"],
    },
    {
      ID: 107,
      Name: "Burning sensation when urinating",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [16, 38, 37],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 108,
      Name: "Dark urine",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [16, 38, 37],
      ProfName: "",
      Synonyms: [
        "Discoloured urine",
        "Discolored urine",
        "Unusual color of urine",
        "Blood in urine",
        "Bloody urine",
        "Haematuria",
        "Hematuria",
      ],
    },
    {
      ID: 109,
      Name: "Painful urination",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [16, 38, 37],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 110,
      Name: "Genital warts",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [16, 38, 37],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 118,
      Name: "Physical inactivity",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [10, 49, 16, 39, 17, 47],
      ProfName: "",
      Synonyms: ["Lack of exercise", "Hypokinesia"],
    },
    {
      ID: 153,
      Name: "Fast, deepened breathing",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 24, 25, 15, 31, 16, 36],
      ProfName: "",
      Synonyms: ["Fast breathing", "Hyperventilation"],
    },
    {
      ID: 154,
      Name: "Flatulence",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [16, 36],
      ProfName: "",
      Synonyms: ["Wind"],
    },
    {
      ID: 155,
      Name: "Pain in the bones",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 16, 39, 10, 48, 49],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 156,
      Name: "Bone fracture",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 16, 39, 10, 17, 47, 48, 49],
      ProfName: "",
      Synonyms: ["Fracture"],
    },
    {
      ID: 160,
      Name: "Urge to urinate",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [16, 38, 37],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 161,
      Name: "Urination during the night",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [16, 38, 37],
      ProfName: "",
      Synonyms: ["Nocturia"],
    },
    {
      ID: 163,
      Name: "Decreased urine stream",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [16, 38, 37],
      ProfName: "",
      Synonyms: ["Decreased urine", "Decreased urinary output", "Decreased urine output"],
    },
    {
      ID: 164,
      Name: "Feeling of residual urine",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [16, 38, 37],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 165,
      Name: "Delayed start to urination",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [16, 38, 37],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 172,
      Name: "Purulent discharge from the urethra",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [16, 38],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 174,
      Name: "Lower abdominal pain",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [16],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 179,
      Name: "Stomach burning",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [16, 36],
      ProfName: "",
      Synonyms: ["Burning abdominal pain", "Burning stomach ache"],
    },
    {
      ID: 180,
      Name: "Black stools",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [16, 40],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 181,
      Name: "Vomiting blood",
      HasRedFlag: true,
      HealthSymptomLocationIDs: [6, 25, 16, 36],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 188,
      Name: "Abdominal guarding",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [16, 36],
      ProfName: "",
      Synonyms: ["Hard belly", "Tension of abdominal wall muscles"],
    },
    {
      ID: 189,
      Name: "Painful defecation",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [16, 40],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 190,
      Name: "Blood in stool",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [16, 40],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 191,
      Name: "Rebound tenderness",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [16, 36],
      ProfName: "Blumberg sign",
      Synonyms: ["Blumberg sign"],
    },
    {
      ID: 192,
      Name: "Bulging abdominal wall",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [16, 36],
      ProfName: "",
      Synonyms: ["Abdomen deformity", "Stomach deformity", "Stomach bump"],
    },
    {
      ID: 195,
      Name: "Limited mobility of the leg",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [10, 49, 16, 39, 17, 47],
      ProfName: "",
      Synonyms: ["Limited leg mobility", "Leg stiffness"],
    },
    {
      ID: 196,
      Name: "Hip pain",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [16, 39],
      ProfName: "",
      Synonyms: ["Pain in the hips"],
    },
    {
      ID: 222,
      Name: "Testicular pain",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [16, 38],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 236,
      Name: "Swelling in the genital area",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [16, 38, 37],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 249,
      Name: "Swollen glands in the groin",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [38, 16],
      ProfName: "",
      Synonyms: [
        "Inguinal lymph nodes",
        "Swollen lymph nodes in the groin",
        "Enlarged lymph nodes in the groin",
        "Enlarged glands in the groin",
      ],
    },
    {
      ID: 265,
      Name: "Uncontrolled defecation",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [16, 40],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 266,
      Name: "Malposition of the testicles",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [16, 38],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 267,
      Name: "Swelling of the testicles",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [16, 38],
      ProfName: "",
      Synonyms: ["Swollen testicles"],
    },
    {
      ID: 268,
      Name: "Itching or burning in the genital area",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [16, 37, 38],
      ProfName: "",
      Synonyms: ["Itching in the genital area", "Burning in the genital area"],
    },
    {
      ID: 984,
      Name: "Absence of a pulse",
      HasRedFlag: true,
      HealthSymptomLocationIDs: [6, 24, 22, 7, 29, 10, 41, 16, 38],
      ProfName: "",
      Synonyms: ["Absence of the pulse", "Acrotism", "Pulselessness"],
    },
    {
      ID: 989,
      Name: "Distended abdomen",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [16, 36],
      ProfName: "",
      Synonyms: ["Distended stomach", "Abdominal distension", "Stomach bloating"],
    },
    {
      ID: 990,
      Name: "Pain of the anus",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [16, 40],
      ProfName: "",
      Synonyms: ["Anal pain"],
    },
    {
      ID: 993,
      Name: "Hip deformity",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [16, 39],
      ProfName: "",
      Synonyms: ["Hip joint deformity"],
    },
    {
      ID: 999,
      Name: "Itching of the anus",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [16, 40],
      ProfName: "",
      Synonyms: ["Anal itching", "Itchy rectum", "Itching of the rectum"],
    },
    {
      ID: 1000,
      Name: "Limited mobility of the hip",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [16, 39],
      ProfName: "",
      Synonyms: ["Hip stiffness"],
    },
    {
      ID: 1005,
      Name: "Difficulty with gait",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [10, 49, 16, 39, 17, 47],
      ProfName: "",
      Synonyms: [
        "Gait disorder",
        "Difficulty with walking",
        "Difficulty with movement",
        "Having problems with movement",
      ],
    },
    {
      ID: 1010,
      Name: "Leg pain",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [10, 49, 41, 43, 16, 39],
      ProfName: "",
      Synonyms: ["Pain in the leg", "Pain in leg"],
    },
  ],
  "17": [
    {
      ID: 11,
      Name: "Fever",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 47, 6, 22],
      ProfName: "",
      Synonyms: ["Temperature"],
    },
    {
      ID: 12,
      Name: "Pain in the limbs",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 48, 10, 49, 17, 47],
      ProfName: "",
      Synonyms: [
        "Body aches",
        "Ache all over",
        "Diffuse muscle aches",
        "Aching limbs",
        "Pain in the extremities",
      ],
    },
    {
      ID: 16,
      Name: "Tiredness",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 47, 6, 22],
      ProfName: "",
      Synonyms: ["Feeling of weakness", "Fatigue", "Weakness", "Lack of energy"],
    },
    {
      ID: 21,
      Name: "Itching of skin",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 46],
      ProfName: "",
      Synonyms: ["Itch", "Pruritus", "Skin itching"],
    },
    {
      ID: 22,
      Name: "Weight loss",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 47],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 23,
      Name: "Weight gain",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 47],
      ProfName: "",
      Synonyms: ["Increase in weight"],
    },
    {
      ID: 24,
      Name: "Blackhead",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 23, 17, 46],
      ProfName: "",
      Synonyms: ["Blackheads", "Comedones"],
    },
    {
      ID: 25,
      Name: "Skin nodules",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 46],
      ProfName: "",
      Synonyms: [
        "Papula",
        "Solid elevation of skin",
        "Thick elevation of skin",
        "Elevation of skin",
        "Skin elevation",
      ],
    },
    {
      ID: 26,
      Name: "Skin lesion",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 46],
      ProfName: "",
      Synonyms: ["Pimple", "Small blister", "Pustule", "Skin injury"],
    },
    {
      ID: 27,
      Name: "Joint pain",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 48, 10, 49, 45, 17, 47],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 34,
      Name: "Skin wheal",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 46],
      ProfName: "",
      Synonyms: ["Hives", "Skin edema", "Skin oedema"],
    },
    {
      ID: 43,
      Name: "Drowsiness",
      HasRedFlag: true,
      HealthSymptomLocationIDs: [6, 22, 17, 47],
      ProfName: "",
      Synonyms: [
        "Somnolence",
        "Daze",
        "Light-headedness",
        "Lightheadedness",
        "Lightheadness",
        "Feeling of lightheadness",
        "Feeling of lightheadedness",
      ],
    },
    {
      ID: 44,
      Name: "Nausea",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 22, 15, 31, 16, 36, 17, 47],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 47,
      Name: "Joylessness",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 47],
      ProfName: "",
      Synonyms: ["Listlessness"],
    },
    {
      ID: 52,
      Name: "Sleeplessness",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 22, 17, 47],
      ProfName: "",
      Synonyms: ["Insomnia"],
    },
    {
      ID: 53,
      Name: "Difficulty to concentrate",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 22, 17, 47],
      ProfName: "",
      Synonyms: [
        "Difficulty with concentration",
        "Concentration difficulty",
        "Concentration difficulties",
        "Concentration problem",
        "Concentration problems",
        "Difficulty concentrating",
      ],
    },
    {
      ID: 54,
      Name: "Reduced appetite",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 25, 17, 47, 36],
      ProfName: "",
      Synonyms: ["Loss of appetite", "Decreased appetite", "Decrease in hunger"],
    },
    {
      ID: 60,
      Name: "Sensitivity to cold",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 47],
      ProfName: "",
      Synonyms: ["Feeling cold", "Cold intolerance"],
    },
    {
      ID: 61,
      Name: "Skin redness",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 46],
      ProfName: "",
      Synonyms: ["Erythema", "Red skin", "Red colored skin"],
    },
    {
      ID: 62,
      Name: "Formation of blisters on a skin area",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 25, 17, 46],
      ProfName: "",
      Synonyms: [
        "Vesicle",
        "Formation of blisters on a place of the skin",
        "Formation of blisters on an area of the skin",
        "Formation of blisters on an area of skin",
        "Blisters",
      ],
    },
    {
      ID: 63,
      Name: "Non-healing skin wound",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 46],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 65,
      Name: "Irregular mole",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 46],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 85,
      Name: "Mood swings",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 22, 17, 47],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 105,
      Name: "Yellow colored skin",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 46],
      ProfName: "",
      Synonyms: [
        "Jaundice",
        "Icterus",
        "Yellowish discoloration of the skin",
        "Yellowish skin",
        "Yellow skin",
      ],
    },
    {
      ID: 114,
      Name: "Nervousness",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 22, 17, 47],
      ProfName: "",
      Synonyms: ["Stress", "Feeling of tension", "Irritability"],
    },
    {
      ID: 115,
      Name: "Tremor at rest",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 29, 10, 44, 17, 47],
      ProfName: "",
      Synonyms: ["Tremble at rest", "Trembling at rest"],
    },
    {
      ID: 116,
      Name: "Underweight",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 47],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 118,
      Name: "Physical inactivity",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [10, 49, 16, 39, 17, 47],
      ProfName: "",
      Synonyms: ["Lack of exercise", "Hypokinesia"],
    },
    {
      ID: 120,
      Name: "Impaired balance",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 22, 17, 47],
      ProfName: "",
      Synonyms: ["Loss of balance"],
    },
    {
      ID: 124,
      Name: "Skin rash",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 46],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 128,
      Name: "Disorientation regarding time or place",
      HasRedFlag: true,
      HealthSymptomLocationIDs: [6, 22, 17, 47],
      ProfName: "",
      Synonyms: ["Confusion", "Temporal or local disorientation"],
    },
    {
      ID: 131,
      Name: "Increased appetite",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 25, 17, 47, 36],
      ProfName: "",
      Synonyms: ["Increase in hunger"],
    },
    {
      ID: 132,
      Name: "Tremor on movement",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 29, 17, 47, 10, 44],
      ProfName: "",
      Synonyms: ["Tremble on movement", "Trembling on movement"],
    },
    {
      ID: 134,
      Name: "Crusting",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 46],
      ProfName: "",
      Synonyms: ["Encrustation", "Crust formation"],
    },
    {
      ID: 138,
      Name: "Sweating",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 47, 46],
      ProfName: "",
      Synonyms: ["Perspiration", "Diaphoresis", "Excessive sweating", "Sweating to unusual degree"],
    },
    {
      ID: 139,
      Name: "Cold sweats",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 47, 46],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 140,
      Name: "Paralysis",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 47, 10, 7, 49, 48],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 144,
      Name: "Unconsciousness, short",
      HasRedFlag: true,
      HealthSymptomLocationIDs: [6, 22, 17, 47],
      ProfName: "",
      Synonyms: ["Shock", "Short unconsciousness", "Syncope", "Faint", "Blackout"],
    },
    {
      ID: 149,
      Name: "Hot flushes",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 46, 47],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 150,
      Name: "Pallor",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 46],
      ProfName: "",
      Synonyms: ["Paleness"],
    },
    {
      ID: 151,
      Name: "Dry skin",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 46],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 156,
      Name: "Bone fracture",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 16, 39, 10, 17, 47, 48, 49],
      ProfName: "",
      Synonyms: ["Fracture"],
    },
    {
      ID: 157,
      Name: "Overweight",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 47],
      ProfName: "",
      Synonyms: ["Obesity"],
    },
    {
      ID: 175,
      Name: "Chills",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 47],
      ProfName: "",
      Synonyms: ["Shivering fit", "Ague", "Shivering", "Shivering attack"],
    },
    {
      ID: 177,
      Name: "Muscle pain",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 48, 10, 49, 17, 47, 46],
      ProfName: "",
      Synonyms: ["Myalgia"],
    },
    {
      ID: 184,
      Name: "Hardening of the skin",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 46],
      ProfName: "",
      Synonyms: ["Hardening of skin", "Induration"],
    },
    {
      ID: 187,
      Name: "Wound",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 46],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 193,
      Name: "Joint swelling",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 47, 10, 48, 49],
      ProfName: "",
      Synonyms: ["Swollen joint"],
    },
    {
      ID: 194,
      Name: "Joint effusion",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 47, 10, 7, 49, 48],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 195,
      Name: "Limited mobility of the leg",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [10, 49, 16, 39, 17, 47],
      ProfName: "",
      Synonyms: ["Limited leg mobility", "Leg stiffness"],
    },
    {
      ID: 197,
      Name: "Immobilization",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 47, 10, 7, 48, 49],
      ProfName: "",
      Synonyms: ["Akinesia", "Immobility"],
    },
    {
      ID: 198,
      Name: "Joint instability",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 47, 10, 7, 49, 48],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 207,
      Name: "Dizziness",
      HasRedFlag: true,
      HealthSymptomLocationIDs: [6, 22, 17, 47],
      ProfName: "",
      Synonyms: [
        "Vertigo",
        "Light-headedness",
        "Lightheadedness",
        "Lightheadness",
        "Feeling of lightheadness",
        "Feeling of lightheadedness",
      ],
    },
    {
      ID: 214,
      Name: "Flaking skin",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 46],
      ProfName: "",
      Synonyms: ["Scaling of skin", "Flaking", "Desquamation"],
    },
    {
      ID: 215,
      Name: "Moist and softened skin",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 46],
      ProfName: "",
      Synonyms: ["Softened skin"],
    },
    {
      ID: 217,
      Name: "Skin thickening",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 46],
      ProfName: "",
      Synonyms: ["Hyperkeratosis", "Thickening of skin"],
    },
    {
      ID: 218,
      Name: "Coarsening of the skin structure",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 46],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 220,
      Name: "Increased touch sensitivity",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 46],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 230,
      Name: "Joint redness",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 47, 10, 7, 49, 48, 45],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 238,
      Name: "Anxiety",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 22, 17, 47],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 240,
      Name: "Blue spot on skin",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 46],
      ProfName: "",
      Synonyms: ["Hematoma"],
    },
    {
      ID: 241,
      Name: "Sleepiness with spontaneous falling asleep",
      HasRedFlag: true,
      HealthSymptomLocationIDs: [6, 22, 17, 47],
      ProfName: "",
      Synonyms: ["Spontaneous falling asleep", "Falling asleep spontaneously"],
    },
    {
      ID: 257,
      Name: "Involuntary movements",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 29, 10, 49, 44, 17, 47],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 262,
      Name: "Increased drive",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [47, 17],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 972,
      Name: "Weakness or numbness on right or left side of body",
      HasRedFlag: true,
      HealthSymptomLocationIDs: [7, 48, 10, 49, 17, 47],
      ProfName: "",
      Synonyms: [
        "One-sided weakness or numbness of body",
        "Right-sided weakness of body",
        "Left-sided weakness of body",
        "Right-sided numbness of body",
        "Left-sided numbness of body",
        "Weakness on right side of body",
        "Weakness on left side of body",
        "Numbness on right side of body",
        "Numbness on left side of body",
        "Weakness or numbness in face",
        "Weakness or numbness in the face",
        "Weakness in face",
        "Numbness in face",
      ],
    },
    {
      ID: 974,
      Name: "Aggressiveness",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 22, 17, 47],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 975,
      Name: "Sadness",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 22, 17, 47],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 976,
      Name: "Hallucination",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 22, 17, 47],
      ProfName: "",
      Synonyms: ["Delusion"],
    },
    {
      ID: 981,
      Name: "Agitation",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [48, 29, 10, 49, 44, 17, 47],
      ProfName: "",
      Synonyms: ["Restlessness", "Psychomotor agitation", "Unrest"],
    },
    {
      ID: 982,
      Name: "Feeling faint",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 22, 17, 47],
      ProfName: "",
      Synonyms: ["Fainting", "Feel faint"],
    },
    {
      ID: 983,
      Name: "Morning stiffness",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 47],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 987,
      Name: "Muscle weakness",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [7, 48, 10, 49, 17, 47, 46],
      ProfName: "",
      Synonyms: ["Muscular weakness", "Myasthenia"],
    },
    {
      ID: 991,
      Name: "Blue colored skin",
      HasRedFlag: true,
      HealthSymptomLocationIDs: [6, 25, 7, 30, 17, 46],
      ProfName: "",
      Synonyms: [
        "Blue colored lips",
        "Blue colored nails",
        "Blue colored fingertips",
        "Cyanosis",
        "Bluish discoloration of the skin",
        "Bluish skin",
        "Blue skin",
      ],
    },
    {
      ID: 1001,
      Name: "Scar",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 46],
      ProfName: "",
      Synonyms: [],
    },
    {
      ID: 1004,
      Name: "Night sweats",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [17, 47, 46],
      ProfName: "",
      Synonyms: [
        "Night sweat",
        "Nocturnal hyperhidrosis",
        "Sleep hyperhidrosis",
        "Sweating at night",
      ],
    },
    {
      ID: 1005,
      Name: "Difficulty with gait",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [10, 49, 16, 39, 17, 47],
      ProfName: "",
      Synonyms: [
        "Gait disorder",
        "Difficulty with walking",
        "Difficulty with movement",
        "Having problems with movement",
      ],
    },
    {
      ID: 1014,
      Name: "Feeling ill",
      HasRedFlag: false,
      HealthSymptomLocationIDs: [6, 22, 17, 47],
      ProfName: "",
      Synonyms: ["Feeling sick", "Feeling unwell", "Generally feeling unwell"],
    },
  ],
}

export let boyLocationIDSymptomIDsMap = new Map<number, number[]>()
for (const [key, value] of Object.entries(boySymptoms)) {
  const symptomIDs = value.map((symptom) => symptom.ID)
  boyLocationIDSymptomIDsMap.set(parseInt(key), symptomIDs)
}
