import { Form, Formik } from "formik"
import React, { useContext, useEffect, useState } from "react"
import { createLog } from "src/apicalls/createLog"
import { deleteLog } from "src/apicalls/deleteLog"
import { patchLog } from "src/apicalls/patchLog"
import { GlobalContext, overlayChildrenContext } from "src/App"
import GenericButton from "src/common-components/GenericButton/GenericButton"
import Popup from "src/common-components/Popup/Popup"
import { addHourToDate, getMonthBoundary } from "src/common/utilityFunctions"
import { necessary } from "src/common/validationFunctions"
import { LogBookEntryClient, LogBookEntryFormValues } from "src/types"
import styles from "./AddEvent.module.scss"
import AddEventHeader from "./AddEventHeader/AddEventHeader"
import AddMedication from "./AddMedication/AddMedication"
import DateChooserBlock from "./DateChooserBlock/DateChooserBlock"
import AddEventDescription from "./DescriptionBlock/AddEventDescription"
import LogTypeChooser from "./LogTypeChooserBlock/LogTypeChooser"

const validate = (values: LogBookEntryFormValues, tookMedication: boolean) => {
  let errors: { medication?: any } = { medication: {} }
  necessary(values, "description", errors)
  if (tookMedication) {
    necessary(values.medication, "drugIndex", errors.medication)
    if (values.medication?.drugIndex === "Egyéb") {
      necessary(values.medication, "drugName", errors.medication)
      necessary(values.medication, "agentAmount", errors.medication)
      necessary(values.medication, "agentUnit", errors.medication)
    }
  }
  if (Object.keys(errors.medication).length === 0) delete errors.medication
  return errors
}

interface Props {
  event: LogBookEntryClient | null
  selectedDate: Date
  onClose: () => void
  patientID: number
  closeNewEventWindow: () => void
  setEvents: (events: LogBookEntryClient[]) => void
}

const AddEvent: React.FC<Props> = ({
  selectedDate,
  onClose,
  patientID,
  closeNewEventWindow,
  setEvents,
  event,
}) => {
  const [date, setDate] = useState<Date>(selectedDate)
  const [logType, setLogType] = useState<string | null>(null)
  const [tookMedication, setTookMedication] = useState<boolean>(false)
  const [logTypeError, setLogTypeError] = useState<boolean>(false)
  const [deletePopupDisplayed, setDeletePopupDisplayed] = useState(false)
  const [isLoading, setLoading] = useState<boolean>(false)

  const { userToken, setShouldUpdateUserDataFromServer, doctor } = useContext(GlobalContext)

  let initialValues: LogBookEntryFormValues = {
    description: event ? event.description : "",
    medication: event
      ? event.medication
      : {
          agentAmount: "",
          agentUnit: null,
          drugIndex: null,
          drugName: "",
        },
  }

  useEffect(() => {
    setDate(selectedDate)
  }, [selectedDate])

  useEffect(() => {
    if (event) {
      initialValues.medication = event.medication
      setDate(event.start!)
      setLogType(event.title)
      setTookMedication(!!event.medication)
    }
  }, [])

  const submit = async (values: LogBookEntryFormValues) => {
    setLoading(true)
    const { startTime, endTime } = getMonthBoundary(date)
    let res
    if (event && event.id) {
      res = await patchLog(userToken, {
        eventID: event.id,
        startTime,
        endTime,
        data: {
          description: values.description,
          start: Math.round(date.getTime() / 1000),
          end: Math.round(addHourToDate(date, 1).getTime() / 1000),
          title: logType,
          medication: values.medication,
        },
      })
      setEvents(res)
      setLoading(false)
      setShouldUpdateUserDataFromServer(true)
      closeNewEventWindow()
    } else {
      res = await createLog(userToken, {
        patientID,
        startTime,
        endTime,
        data: {
          description: values.description,
          start: Math.round(date.getTime() / 1000),
          end: Math.round(addHourToDate(date, 1).getTime() / 1000),
          title: logType,
          medication: tookMedication ? values.medication : null,
        },
      })
      setEvents(res)
      setLoading(false)
      setShouldUpdateUserDataFromServer(true)
      closeNewEventWindow()
    }
  }

  const deleteEvent = async () => {
    setLoading(true)
    const { startTime, endTime } = getMonthBoundary(date)
    const res = await deleteLog(userToken, {
      eventID: event!.id!,
      startTime,
      endTime,
    })
    setEvents(res)
    setLoading(false)
    setShouldUpdateUserDataFromServer(true)
    closeNewEventWindow()
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={(values) => submit(values)}
      validate={(values) => validate(values, tookMedication)}
    >
      {({ submitForm }) => (
        <Form className={styles.container}>
          <AddEventHeader
            onClose={onClose}
            title={
              doctor.doctor
                ? "Bejegyzés"
                : event
                ? "Bejegyzés szerkesztése"
                : "Bejegyzés hozzáadása"
            }
          />
          <div className={styles.body}>
            <LogTypeChooser
              disabled={doctor.doctor}
              logType={logType}
              setLogType={setLogType}
              error={logTypeError}
              onFocus={() => setLogTypeError(false)}
              setError={setLogTypeError}
              setTookMedication={setTookMedication}
            />
            <AddEventDescription disabled={doctor.doctor} logType={logType} />
            <DateChooserBlock disabled={doctor.doctor} date={date} setDate={setDate} />
            <AddMedication
              disabled={doctor.doctor}
              patientID={patientID}
              tookMedication={tookMedication}
              setTookMedication={setTookMedication}
              medication={event ? event.medication : null}
            />
            <div className={styles.buttonContainer}>
              {event && !doctor.doctor ? (
                <GenericButton
                  action={() => setDeletePopupDisplayed(true)}
                  style="red"
                  disabled={isLoading}
                  loading={isLoading}
                  text={"Törlés"}
                />
              ) : null}
              <GenericButton
                action={doctor.doctor ? onClose : submitForm}
                style="blue"
                disabled={isLoading}
                loading={isLoading}
                text={doctor.doctor ? "Bezárás" : event ? "Mentés" : "Rögzítés"}
              />
            </div>
          </div>
          {deletePopupDisplayed && (
            <Popup
              title={"Bejegyzés törlése"}
              children={
                <p className={styles.popupContent}>
                  A bejegyzés törlése nem vonható vissza. Biztosan törölni szeretné?
                </p>
              }
              confirmButton={{
                title: "Törlés",
                action: () => {
                  deleteEvent()
                  setDeletePopupDisplayed(false)
                },
                buttonStyle: "red",
              }}
              cancelButton={{
                title: "Mégse",
              }}
              onClose={() => setDeletePopupDisplayed(false)}
            />
          )}
        </Form>
      )}
    </Formik>
  )
}

export default AddEvent
