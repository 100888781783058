import { cloneDeep } from "lodash"
import {
  DoctorProfileSecondCard,
  DoctorProfileFirstCard,
  DoctorProfileThirdCard,
} from "../../types"
import { DrExtProfCreationLastCard } from "./CreateDrExtProfFourthCard"

export const initialValuesDrExtProfFirstPage: DoctorProfileFirstCard = {
  birthDay: "",
  birthMonth: "",
  birthName: "",
  birthYear: "",
  firstName: "",
  foreName: "",
  lastName: "",
  title: "",
  antszLicenseNumber: "",
}

export const initialValueDrExtProfSecondPage: DoctorProfileSecondCard = {
  officeAddress: {
    city: "",
    country: "",
    region: "",
    street: "",
    streetNum: "",
    zipCode: "",
  },
  zone: "",
}

export const initialValueDrExtProfThirdPage: DoctorProfileThirdCard = {
  company: {
    VATnumber: "",
    bankAccountNumber: "",
    companyNumber: "",
    name: "",
    rep: "",
    seat: "",
  },
}

export const initialValuesDrExtProfFourthPage: DrExtProfCreationLastCard = {
  dataHandlingAccepted: false,
  email: "",
  termsOfUseAccepted: false,
  wageAccepted: false,
}
