import React from "react"
import Tooltip from "../Tooltip/Tooltip"
import styles from "./Info.module.css"

interface InfoProps {
  text: string | React.ReactChild
}

const Info: React.FC<InfoProps> = ({ text }) => {
  return (
    <Tooltip tooltipText={text}>
      <i className={["material-icons", styles.icon, "notranslate"].join(" ")}>info</i>
    </Tooltip>
  )
}
export default Info
