import React, { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import { patchTicket } from "../../apicalls"
import { GlobalContext } from "../../App"
import SidebarButton from "../../common-components/SidebarButton/SidebarButton"
import { useOVNavigator } from "../../common/hooks/useOVNavigator"
import urls from "../../common/urls"
import { LogBookPageHistoryType, Ticket } from "../../types"
import styles from "./DrTicketMenu.module.scss"

interface Props {
  ticket: Ticket
  setOutpatientSheetVisible: (v: boolean) => void
}

const DrTicketMenu: React.FC<Props> = ({ ticket, setOutpatientSheetVisible }) => {
  const [loading, setLoading] = useState<boolean>(false)

  const { userToken, setShouldUpdateUserDataFromServer, doctor, updating } =
    useContext(GlobalContext)
  const OVNavigator = useOVNavigator()
  const navigate = useNavigate()

  const isArticlesButtonVisible = ticket.ticketData.tasks.find(({ name }) =>
    ["diagnosis", "sickNote"].includes(name),
  )

  let allTasksFinished = true
  ticket.ticketData.tasks.forEach((task) => {
    if (task.state !== "ready" && task.state !== "rejected") allTasksFinished = false
  })

  const handleLogBookClick = () => {
    if (ticket.patientProfile) {
      const state: LogBookPageHistoryType = {
        patient: { id: ticket.patientID, profile: ticket.patientProfile },
      }
      navigate({ pathname: urls.logBookUrl }, { state })
    }
  }

  return (
    <>
      <SidebarButton text="Beteg napló" icon="perm_contact_calendar" action={handleLogBookClick} />
      <SidebarButton
        text="Beteg adatai"
        icon="person"
        action={() => OVNavigator.toPatientData(ticket.ticketID)}
      />
      {isArticlesButtonVisible ? (
        <SidebarButton
          text="Orvosi tanácsok"
          icon="speaker_notes"
          action={() => OVNavigator.toArticles(ticket.ticketID)}
        />
      ) : null}
      {ticket.doctor.id === null ? (
        <SidebarButton
          action={async () => {
            setLoading(true)
            const res = await patchTicket(userToken, {
              ticketID: ticket.ticketID,
              doctor: {
                id: doctor.userID,
                name: {
                  firstName: doctor.userProfile.firstName,
                  lastName: doctor.userProfile.lastName,
                  foreName: doctor.userProfile.foreName,
                  title: doctor.userProfile.title,
                },
              },
            })
            setShouldUpdateUserDataFromServer(true)
            setLoading(false)
          }}
          text="Ügy elvállalása"
          icon="assignment_ind"
          className={styles.finishButton}
          loading={loading}
        />
      ) : ticket.ready ? (
        <SidebarButton
          action={() => setOutpatientSheetVisible(true)}
          text="Összefoglaló lap"
          icon="medical_services"
          className={styles.finishButton}
        />
      ) : (
        <SidebarButton
          action={() => setOutpatientSheetVisible(true)}
          text="Ügy befejezése"
          icon="done_all"
          className={styles.finishButton}
          disabled={!allTasksFinished}
          title={!allTasksFinished ? "Még vannak befejezetlen feladatok" : undefined}
        />
      )}
    </>
  )
}

export default DrTicketMenu
