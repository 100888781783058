import { ApiResponse, Image } from "src/types"
import { APIBASEURL, headersBase } from "."

export const createImage = async (userToken: string, imageData: Image) => {
  console.log(imageData.meta)
  const res = await fetch(APIBASEURL + "/image", {
    method: "POST",
    headers: {
      ...headersBase,
      "User-Token": userToken,
      "content-type": "application/json",
    },
    body: JSON.stringify(imageData),
  })
  const result: ApiResponse<{ imageID: number }> = await res.json()
  return result
}
