import React, { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { patchNotification } from "src/apicalls/patchNotification"
import { GlobalContext } from "src/App"
import {
  getDisplayNameFromNames,
  getNotiDateFromInt,
  limitStringLength,
} from "src/common/utilityFunctions"
import { Notification } from "src/types"
import { useOVNavigator } from "../../common/hooks/useOVNavigator"
import styles from "./SingleNotification.module.css"

interface Props {
  notification: Notification
  closeNotifications: () => void
}

const SingleNotification: React.FC<Props> = ({ notification, closeNotifications }) => {
  const { setNotifications, userToken } = useContext(GlobalContext)

  const OVNavigator = useOVNavigator()

  const notificationTexts: {
    [key in Notification["subject"]]: string
  } = {
    "new-message": "Új üzenet",
    "new-targeted-ticket": "Új ügy",
    "new-ticket-subscriber": "Ügy kézbesítve",
    "ticket-closed": "Ügy lezárva",
    "new-task": "Új igény",
    "task-updated": "Igény módosítva",
    "videocall-started": "Bejövő videóhívás",
    "videocall-date-added": "Konzultációs időpont",
    "videocall-date-changed": "Konzultációs időpont változás",
  }

  const handleClick = async () => {
    closeNotifications()
    OVNavigator.toTicket(notification.ticketID)
    const notifications = await patchNotification(userToken, notification.id)
    if (notifications !== "error") setNotifications(notifications)
  }

  return (
    <div className={styles.container} onClick={handleClick}>
      <div className={styles.firstRow}>
        <p>{notificationTexts[notification.subject]}</p>
        {!notification.seen ? (
          <i className={["material-icons", "notranslate"].join(" ")}>stop_circle</i>
        ) : null}
      </div>
      <div className={styles.secondRow}>
        <p>{limitStringLength(getDisplayNameFromNames(notification.sender), 34)}</p>
        <p className={styles.time}>{getNotiDateFromInt(notification.timestamp)}</p>
      </div>
    </div>
  )
}

export default SingleNotification
