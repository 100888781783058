import React, { useContext, useRef, useState } from "react"
import Deck from "src/common-components/Deck/Deck"
import ExtProfFirstCard from "../CreateUserExtendedProfPage/UserExtProfFirstCard"
import TaskCardBase from "../NewTicketPage/TaskCardBase"
import { GlobalContext } from "src/App"
import AddressTextInputs from "../NewPatientPage/NewPatientForm/AddressFormSlide/AddressTextInputs"
import styles from "./UserEditProfilePage.module.css"
import Popup from "src/common-components/Popup/Popup"
import { patchUserProfile } from "src/apicalls/patchUserProfile"
import { useNavigate } from "react-router-dom"
import { prefillInitialValues } from "../../common/utilityFunctions"
import {
  UserExtProfFirstPageInitialValues,
  UserExtProfSecondPageInitialValues,
} from "../CreateUserExtendedProfPage/initialValues"
import MyTextInput from "../../common-components/TextInput/TextInput"
import urls from "../../common/urls"

const initialValues = {
  first: { ...UserExtProfFirstPageInitialValues },
  second: { ...UserExtProfSecondPageInitialValues },
}

const UserEditProfilePage: React.FC = () => {
  const [formState, setFormState] = useState<number>(0)
  const { user, userToken, setShouldUpdateUserDataFromServer } = useContext(GlobalContext)
  const [formValues, setFormValues] = useState(getInitialValues)
  const [shouldSubmit, setShouldSubmit] = useState<boolean>(false)
  const initialValuesRef = useRef(formValues)
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  function getInitialValues() {
    const iv1 = prefillInitialValues(initialValues.first, user.userProfile)
    const iv2 = prefillInitialValues(initialValues.second, user.userProfile)
    return { first: iv1, second: iv2 }
  }

  const onSubmit = async () => {
    setLoading(true)
    const res = await patchUserProfile(userToken, {
      ...formValues.first,
      ...formValues.second,
    })
    setShouldUpdateUserDataFromServer(true)
    navigate({ pathname: urls.profilePageUrl })
  }

  return (
    <div className={styles.container}>
      <Deck formState={formState}>
        <TaskCardBase
          initialValues={initialValuesRef.current.first}
          formContext={{
            formState,
            formValues,
            maxFormState: 2,
            setFormState,
            setFormValues,
          }}
          path="first"
          gap
        >
          <ExtProfFirstCard editing />
        </TaskCardBase>
        <TaskCardBase
          formContext={{
            formState,
            formValues,
            maxFormState: 2,
            setFormState,
            setFormValues,
            setShouldSubmit,
          }}
          initialValues={initialValuesRef.current.second}
          title="Lakcím"
          path="second"
          gap
        >
          <MyTextInput
            label="Telefonszám"
            type="text"
            name="phone"
            placeholder="pl.: +36123456789"
          />
          <AddressTextInputs baseName="address" />
        </TaskCardBase>
      </Deck>
      {shouldSubmit ? (
        <Popup
          loading={loading}
          title="Adatok megerősítése"
          onClose={() => setShouldSubmit(false)}
          confirmButton={{ title: "Megerősítés", action: () => onSubmit() }}
          cancelButton={{
            title: "Vissza",
          }}
          panelStyle={{
            width: "30rem",
          }}
          aszf
          dataManagement
        />
      ) : null}
    </div>
  )
}

export default UserEditProfilePage
