import React, { useContext, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { patchUserProfile } from "src/apicalls/patchUserProfile"
import { GlobalContext } from "src/App"
import Deck from "src/common-components/Deck/Deck"
import Popup from "src/common-components/Popup/Popup"
import TaskCardBase from "src/user-components/NewTicketPage/TaskCardBase"
import urls from "../../common/urls"
import { prefillInitialValues } from "../../common/utilityFunctions"
import CreateDrExtProfFirstCard, {
  validateFirstPage,
} from "../CreateDoctorExtendedProfilePage/CreateDrExtProfFirstCard"
import CreateDrExtProfSecondCard, {
  validateSecondPage,
} from "../CreateDoctorExtendedProfilePage/CreateDrExtProfSecondCard"
import CreateDrExtProfThirdCard from "../CreateDoctorExtendedProfilePage/CreateDrExtProfThirdCard"
import {
  initialValueDrExtProfSecondPage,
  initialValueDrExtProfThirdPage,
  initialValuesDrExtProfFirstPage,
} from "../CreateDoctorExtendedProfilePage/initialValues"
import styles from "./DoctorEditProfilePage.module.css"

const initialValues = [
  { ...initialValuesDrExtProfFirstPage },
  { ...initialValueDrExtProfSecondPage },
  { ...initialValueDrExtProfThirdPage },
]

const validations = [validateFirstPage, validateSecondPage, undefined]

const DoctorEditProfilePage: React.FC = () => {
  const { doctor, userToken, setShouldUpdateUserDataFromServer } = useContext(GlobalContext)
  const [formState, setFormState] = useState<number>(0)
  const [formValues, setFormValues] = useState(getInitialValues)
  const [shouldShowPopup, setShouldShowPopup] = useState<boolean>(false)
  const initialValuesRef = useRef(formValues)
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  function getInitialValues() {
    const iv1 = prefillInitialValues(initialValues[0], doctor.userProfile)
    const iv2 = prefillInitialValues(initialValues[1], doctor.userProfile)
    const iv3 = prefillInitialValues(initialValues[2], doctor.userProfile)
    const iv4 = prefillInitialValues(initialValues[3], doctor.userProfile)
    return [iv1, iv2, iv3, iv4]
  }

  const pageFields = [
    <CreateDrExtProfFirstCard editing />,
    <CreateDrExtProfSecondCard />,
    <CreateDrExtProfThirdCard initialValues={initialValuesRef.current[2]} />,
  ]

  const pageTitles = ["Személyes adatok", "Rendelő / kórház adatok", "Cégadatok"]

  const formContext = {
    formState,
    formValues,
    setShouldSubmit: setShouldShowPopup,
    setFormValues,
    setFormState,
    maxFormState: pageFields.length,
  }

  const pages = pageFields.map((fields, index) => {
    return (
      <TaskCardBase
        formContext={formContext}
        initialValues={initialValuesRef.current[index]}
        path={index.toString()}
        formikValidate={validations[index]}
        justifyContent="space-between"
        title={pageTitles[index]}
      >
        {fields}
      </TaskCardBase>
    )
  })

  const submit = async () => {
    setLoading(true)
    await patchUserProfile(userToken, { ...formValues[0], ...formValues[1], ...formValues[2] })
    setShouldUpdateUserDataFromServer(true)
    navigate({ pathname: urls.profilePageUrl })
  }

  return (
    <div className={styles.container}>
      <Deck formState={formState}>
        {pages[0]}
        {pages[1]}
        {pages[2]}
      </Deck>
      {shouldShowPopup ? (
        <Popup
          loading={loading}
          confirmButton={{
            title: "Megerősítés",
            buttonStyle: "blue",
            action: () => submit(),
          }}
          onClose={() => setShouldShowPopup(false)}
          panelStyle={{
            width: "30rem",
          }}
        />
      ) : null}
    </div>
  )
}

export default DoctorEditProfilePage
