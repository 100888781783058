import React, { useState } from "react"
import styles from "./CreateExtendedProfile.module.scss"
import logo from "../../imgs/logo-new-black.png"
import LogOutButton from "src/common-components/LogOutButton/LogOutButton"
import Popup from "src/common-components/Popup/Popup"
import GenericButton from "../GenericButton/GenericButton"
import { ApiResponse } from "../../types"
import { openDocument } from "../../common/openDocuments"
import { useIsMobileSize } from "../../common/hooks/useIsMobileSize"

const NextButton: React.FC<{ action: () => void }> = ({ action }) => (
  <GenericButton action={action} style="blue" text="Tovább" className={styles.button} />
)

interface Props {
  next: () => void
  confirmPopupVisible: boolean
  setConfirmPopupVisible: React.Dispatch<React.SetStateAction<boolean>>
  submit: () => Promise<ApiResponse>
}

const CreateExtendedProfilePage: React.FC<Props> = ({
  children,
  next,
  confirmPopupVisible,
  setConfirmPopupVisible,
  submit,
}) => {
  const isMobile = useIsMobileSize()
  return (
    <>
      <div className={styles.container}>
        <div className={styles.form}>
          {children}
          {isMobile && (
            <div className={styles.buttonContainer}>
              <NextButton action={next} />
              <LogOutButton />
            </div>
          )}
        </div>
        <div className={styles.leftSide}>
          <img src={logo} alt="OrvosVálasz logó" />
          <h3>Majdnem kész</h3>
          <p>Adjon meg még néhány adatot, hogy a profilja teljes legyen!</p>
          <h5 onClick={() => openDocument("data")}>Adatkezelési tájékoztató</h5>
          <div className={styles.buttonContainer}>
            <NextButton action={next} />
            <LogOutButton />
          </div>
        </div>
        {confirmPopupVisible ? (
          <Popup
            title="Adatok megerősítése"
            onClose={() => setConfirmPopupVisible(false)}
            confirmButton={{
              title: "Megerősítés",
              action: submit,
            }}
            cancelButton={{
              title: "Javítás",
            }}
            panelStyle={{
              minWidth: "30rem",
            }}
            fullscreenContainer
            aszf
            dataManagement
          />
        ) : null}
      </div>
    </>
  )
}

export default CreateExtendedProfilePage
