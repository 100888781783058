import React from "react"
import styles from "./DeletableLabel.module.css"

interface Props {
  action: Function
}

const DeletableLabel: React.FC<Props> = ({ action, children }) => {
  return (
    <div className={styles.label}>
      {children}
      <i onClick={() => action()} className={["material-icons", "notranslate"].join(" ")}>
        clear
      </i>
    </div>
  )
}

export default DeletableLabel
