import React, { useContext, useEffect, useRef, useState } from "react"
import ReactDOM from "react-dom"
import { getImage } from "src/apicalls/getImage"
import { GlobalContext } from "src/App"
import { useOnClickOutside } from "src/common/hooks/useOnClickOutside"
import { Image } from "src/types"
import AttachmentLabel from "../AttachmentLabel/AttachmentLabel"
import Loader from "../Loader/Loader"
import styles from "./ShowImageFromServer.module.scss"

interface Props {
  file: { id: number; name: string }
  deleteAction?: () => void
}

const ShowImageFromServer: React.FC<Props> = ({ file, deleteAction }) => {
  const { userToken } = useContext(GlobalContext)
  const [image, setImage] = useState<null | Image>(null)
  const [base64, setBase64] = useState<string | null>(null)
  const [shouldShowImage, setShouldShowImage] = useState(false)
  const downloadButtonRef = useRef<HTMLAnchorElement>(null)

  const imageRef = useOnClickOutside<HTMLImageElement>(
    () => setShouldShowImage(false),
    downloadButtonRef,
  )

  const onClick = async () => {
    setShouldShowImage(true)
    const res = await getImage(userToken, file.id)
    if (res.status.result === "error") {
      setShouldShowImage(false)
      alert("Nincs hozzáférése")
      return
    }
    setBase64(res.status.message.data)
    const byteChars = atob(res.status.message.data.split(",").reverse()[0])
    const byteNumbers = Array.from(byteChars).map((char) => {
      return char.charCodeAt(0)
    })
    const byteArray = new Uint8Array(byteNumbers)
    setImage({ ...res.status.message, data: byteArray as any })
    if (res.status.message.meta.format === "HEIC") {
      downloadButtonRef.current?.click()
      setShouldShowImage(false)
    }
  }

  const extension = image?.meta.format

  useEffect(() => {
    if (extension === "pdf" && base64 && shouldShowImage) {
      let pdfWindow = window.open("")
      if (pdfWindow) {
        pdfWindow.document.write(
          "<html style='overflow: hidden;'><iframe style='position: absolute; top: 0; left: 0; border: none;' width='100%' height='100%' src='" +
            encodeURI(base64) +
            "'></iframe></html>",
        )
        setShouldShowImage(false)
      }
    }
  })

  return (
    <>
      <AttachmentLabel labelText={file.name} onClick={onClick} deleteAction={deleteAction} />
      {shouldShowImage
        ? ReactDOM.createPortal(
            <div className={styles.container}>
              {!image ? (
                <Loader />
              ) : (
                <>
                  <p className={styles.name}>{image.meta.description || image.meta.name}</p>
                  <a
                    download={file.name}
                    href={URL.createObjectURL(
                      new Blob([image.data], {
                        type: `image/${extension}`,
                      }),
                    )}
                    ref={downloadButtonRef}
                    className={styles.download}
                  >
                    <span>Letöltés</span>
                    <i className={["material-icons", "notranslate"].join(" ")}>download</i>
                  </a>
                  <div className={styles.imageContainer}>
                    <img ref={imageRef} className={styles.img} src={base64 || ""} />
                  </div>
                </>
              )}
            </div>,
            document.body,
          )
        : null}
    </>
  )
}

export default ShowImageFromServer
