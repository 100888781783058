import React from "react"
import { getDisplayNameFromNames } from "src/common/utilityFunctions"
import { Name } from "src/types"
import styles from "./Avatar.module.css"

interface Props {
  iconSize?: number
}

const Avatar: React.FC<Props> = ({ iconSize = 20 }) => {
  return (
    <div className={styles.container}>
      <i
        style={{ fontSize: iconSize + "rem" }}
        className={["material-icons", "notranslate"].join(" ")}
      >
        person
      </i>
    </div>
  )
}

export default Avatar
