import { debounce } from "lodash"
import { useContext, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { getClosedTickets } from "../../apicalls/getClosedTickets"
import { GlobalContext } from "../../App"
import { getPageBaseElement } from "../../common/utilityFunctions"
import { drHomePages, Ticket } from "../../types"
import { useFilterTickets } from "./useFilters"

export const useTicketsToShow = (closedTickets: Ticket[]) => {
  const { activePage, doctor } = useContext(GlobalContext)
  const filterTickets = useFilterTickets()

  if (activePage === drHomePages.inProgress) {
    return filterTickets(doctor.tickets.inProgress)
  }
  if (activePage === drHomePages.incoming) {
    return filterTickets(doctor.tickets.incoming)
  }
  if (activePage === drHomePages.open) {
    return filterTickets(doctor.tickets.open)
  }
  if (activePage === drHomePages.closed) {
    return filterTickets(closedTickets)
  }
  return []
}

export const useClosedTickets = (startDate: Date, endDate: Date) => {
  const { userToken } = useContext(GlobalContext)

  const [loadingTickets, setLoadingTickets] = useState<boolean>(false)
  const [closedTickets, setClosedTickets] = useState<Ticket[]>([])

  useEffect(() => {
    const searchTickets = async () => {
      setLoadingTickets(true)
      const res = await getClosedTickets(userToken, startDate, endDate)
      setLoadingTickets(false)
      setClosedTickets(res.status?.message || [])
    }
    searchTickets()
  }, [startDate, endDate, userToken])

  return [closedTickets, loadingTickets] as const
}

export const useTooLongIntervalHandling = (
  startDate: Date,
  endDate: Date,
  setEndDate: (d: Date) => void,
) => {
  useEffect(() => {
    const maxNumOfDays = 60
    const selectedIntervalTooLong =
      endDate.getTime() - startDate.getTime() >= maxNumOfDays * 24 * 3600 * 1000
    if (selectedIntervalTooLong) {
      setEndDate(startDate)
    }
  }, [startDate, endDate, setEndDate])
}

export const useRememberScrollPosition = () => {
  const location = useLocation()
  const storageKey = "ov-scroll"

  useEffect(() => {
    const pageBaseElement = getPageBaseElement()
    const savedPosition = localStorage.getItem(storageKey)
    const scrollPosition = savedPosition ? parseInt(savedPosition, 10) : undefined

    if (scrollPosition) {
      pageBaseElement?.scrollTo({ behavior: "auto", top: scrollPosition })
      localStorage.removeItem(storageKey)
    }

    const setScrollToState = debounce(() => {
      const newScrollPosition = pageBaseElement?.scrollTop
      if (newScrollPosition !== undefined) {
        localStorage.setItem(storageKey, newScrollPosition.toString())
      } else {
        localStorage.removeItem(storageKey)
      }
    }, 100)

    pageBaseElement?.addEventListener("scroll", setScrollToState)

    return () => {
      pageBaseElement?.removeEventListener("scroll", setScrollToState)
    }
  }, [location])
}
