import React, { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { GlobalContext } from "src/App"
import { useSetTicketRelatedNotisSeen } from "src/common/hooks/useSetTicketRelatedNotisSeen"
import { getDisplayNameFromNames } from "src/common/utilityFunctions"
import { Task, Ticket } from "src/types"
import { useOVNavigator } from "../../common/hooks/useOVNavigator"
import MessagesWrapper from "./Messages/MessagesWrapper"
import TicketInfo from "./TicketInfo/TicketInfo"
import BasicTask from "./UserTask/BasicTask"
import ConsultationTask from "./UserTask/ConsultationTask"
import UserOtherTask from "./UserTask/UserOtherTask"
import UserSickNoteTask from "./UserTask/UserSickNoteTask"
import UserTaskBase from "./UserTask/UserTaskBase"
import styles from "./UserTicketPage.module.scss"

const getUserTaskElement = (task: Task, ticket: Ticket) => {
  switch (task.name) {
    case "consultation":
      return <ConsultationTask task={task} isTicketTaken={!!ticket.doctor.id} />
    case "other":
      return <UserOtherTask task={task} />
    case "sickNote":
      return <UserSickNoteTask task={task} ticketID={ticket.ticketID} ready={ticket.ready} />
    default:
      return <BasicTask task={task} ready={ticket.ready} />
  }
}

const UserTicketPage: React.FC = () => {
  const [ticket, setTicket] = useState<Ticket | undefined>(undefined)
  const { user, notifications } = useContext(GlobalContext)

  const ticketID = parseInt(useParams<{ ticketID: string }>().ticketID!)
  const OVNavigator = useOVNavigator()

  useEffect(() => {
    if (!ticketID) OVNavigator.toHome()
    else {
      const ticket = user.tickets.find((ticket) => ticket.ticketID === ticketID)
      setTicket(ticket)
    }
  }, [ticketID])

  useSetTicketRelatedNotisSeen(ticket?.ticketID)

  const getPatientFullName = () => {
    const patientProfile = user.patients?.find(
      (patient) => patient.id === ticket?.patientID,
    )?.profile
    if (patientProfile) return getDisplayNameFromNames(patientProfile)
    else return "Ismeretlen páciens"
  }

  if (!ticket) return null

  const tasks = ticket.ticketData.tasks.map((task, i) => {
    return (
      <UserTaskBase key={i} task={task}>
        {getUserTaskElement(task, ticket)}
      </UserTaskBase>
    )
  })

  return (
    <div className={styles.container}>
      <TicketInfo ticket={ticket} patientFullName={getPatientFullName()} />
      <div className={styles.bottomContainer}>
        <div className={styles.tasksContainer}>{tasks}</div>
        {ticket.doctor.id && <MessagesWrapper ticket={ticket} />}
      </div>
    </div>
  )
}

export default UserTicketPage
