import React, { useState } from "react"
import NewPatientForm from "./NewPatientForm/NewPatientForm"

const NewPatient: React.FC = () => {
  const [key, setKey] = useState(0)

  return <NewPatientForm key={key} setKey={setKey} />
}

export default NewPatient
