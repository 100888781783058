import React from "react"
import MyTextInput from "src/common-components/TextInput/TextInput"
import ExtraInfoInput from "./ExtraInfoInput"
import styles from "./WithoutSicknessInputs.module.css"

export const PrescriptionTypeInput: React.FC = () => {
  return (
    <MyTextInput
      label="Milyen receptet kér?"
      placeholder="Gyógyszer neve és hatóanyag mennyisége"
      name="drugName"
      className={styles.name}
      rows={4}
      maxLength={50}
    />
  )
}

const WithoutSicknessInputs: React.FC = () => {
  return (
    <>
      <PrescriptionTypeInput />
      <MyTextInput
        label="Miért kéri a receptet?"
        name="prescriptionReason"
        rows={4}
        className={styles.reason}
        maxLength={75}
      />
      <ExtraInfoInput />
    </>
  )
}

export default WithoutSicknessInputs
