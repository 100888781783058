import React from "react"
import styles from "./Mobile.module.css"
import logo from "../../imgs/logo-new-black.png"

const Mobile: React.FC = () => {
  return (
    <div className={styles.container}>
      <img src={logo} alt="Orvosválasz logó" />
      <h4>
        A platform használatához kérjük, látogasson el honlapunkra egy notebook-on vagy asztali
        számítógépen, amennyiben osztott képernyőt néz lépjen ki abból.
      </h4>
      <h4>
        Ha Tableten (vagy bizonyos telefonokon) használja, akkor fektetett képernyő mellett
        korlátozott funkciókkal tudja használni! További fejlesztések folyamatban vannak, köszönjük
        türelmét!
      </h4>
    </div>
  )
}

export default Mobile
