import React, { useContext, useRef, useState } from "react"
import DeletableLabel from "src/common-components/DeletableLabel/DeletableLabel"
import { Sex, TicketData } from "src/types"
import { newTicketFormContext } from "../NewTicketPage"
import TaskCardBase from "../TaskCardBase"
import AddBasicSymptoms from "./AddBasicSymptoms"
import styles from "./ChooseSymptomsCard.module.css"
import GenericButton from "src/common-components/GenericButton/GenericButton"
import { symptomsMap } from "src/common/symptoms"
import SymptomChooserByLocation from "./SymptomChooserByLocation"
import { baseSymptomIDs, covidSymptomsIDs } from "../../../common/consts"

interface DiagnoseSickNoteFormValues {}

const initialValues: DiagnoseSickNoteFormValues = {}
const initialErrors = initialValues

const validate = (values: DiagnoseSickNoteFormValues) => {
  let errors: DiagnoseSickNoteFormValues = {}
  return errors
}

interface Props {
  patientSex: Sex | ""
  covid: boolean
}

const DiagnoseSickNoteTaskCard: React.FC<Props> = ({ patientSex, covid }) => {
  const formContext = useContext(newTicketFormContext)
  const { formValues, setFormValues } = formContext
  const [showAddSymptomForm, setShowAddSymptomForm] = useState<boolean>(false)
  const pRef = useRef<HTMLParagraphElement>(null)

  if (!patientSex) {
    console.log("Missing information in patientprofile")
    return null
  }

  const deleteSymptom = (symptomID: number) => {
    if (formValues.symptoms) {
      let newSymptoms: TicketData["symptoms"] = formValues.symptoms.filter((symptom) => {
        return symptom.symptomID !== symptomID
      })
      setFormValues({ ...formValues, symptoms: [...newSymptoms] })
    }
  }

  let activeSymptomLabels: JSX.Element[] = []
  if (formValues.symptoms && formValues.symptoms.length > 0) {
    activeSymptomLabels = formValues.symptoms.map((symptom, index) => {
      return (
        <DeletableLabel key={index} action={() => deleteSymptom(symptom.symptomID)}>
          {symptomsMap.get(symptom.symptomID)}
        </DeletableLabel>
      )
    })
  }

  const customValidate = () => {
    if (formValues.symptoms.length === 0) {
      if (pRef.current) {
        const style = pRef.current.style
        style.transition = "all 0.1s"
        style.fontWeight = "bold"
        const numOfFlashes = 1
        const interval = 400
        for (let i = 1; i < numOfFlashes + 1; i++) {
          setTimeout(() => {
            style.color = "var(--errorRed)"
            setTimeout(() => {
              style.color = "black"
            }, interval / 2)
          }, interval * (i - 1))
        }
      }
      return false
    }
    return true
  }

  return (
    <TaskCardBase
      initialErrors={initialErrors}
      initialValues={initialValues}
      title="Milyen tünetei vannak?"
      formikValidate={validate}
      formContext={formContext}
      gap
      customValidate={customValidate}
    >
      <p ref={pRef}>Válassza ki a tüneteit</p>
      <div className={styles.labelCont}>{activeSymptomLabels}</div>
      <AddBasicSymptoms baseSymptomIDs={covid ? covidSymptomsIDs : baseSymptomIDs} />
      {showAddSymptomForm ? (
        <SymptomChooserByLocation
          patientSex={patientSex}
          hideAddSymptomForm={() => setShowAddSymptomForm(false)}
        />
      ) : null}
      <GenericButton
        style="grey"
        text={showAddSymptomForm ? "Mégsem adok hozzá új tünetet" : "További tünet hozzáadása"}
        action={() => setShowAddSymptomForm(!showAddSymptomForm)}
        className={styles.button}
      />
    </TaskCardBase>
  )
}

export default DiagnoseSickNoteTaskCard
