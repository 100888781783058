import React, { useContext, useEffect, useRef, useState } from "react"
import FourthFormSlideView from "./FourthFormSlide"
import TaskCardBase from "src/user-components/NewTicketPage/TaskCardBase"
import { newPatientFormContext } from "../NewPatientForm"
import { CreatePatientDataFourthCard } from "src/types"
import { necessary } from "src/common/validationFunctions"
import cloneDeep from "lodash/cloneDeep"

const validate = (values: CreatePatientDataFourthCard, numOfFields: number) => {
  let error = {} as CreatePatientDataFourthCard
  necessary(values, "hasDrugAllergy", error)
  for (let i = 0; i < numOfFields; i++) {
    if (!values["drugAllergy"] || !values["drugAllergy"][i]) {
      if (!error["drugAllergy"]) error["drugAllergy"] = []
      error["drugAllergy"][i] = "Mező kitöltése kötelező"
    }
  }
  return error
}

const initialValuesBase: CreatePatientDataFourthCard = {
  hasDrugAllergy: "" as "" | boolean,
  drugAllergy: [] as string[],
}

for (let i = 0; i < 50; i++) {
  initialValuesBase.drugAllergy![i] = ""
}

const FourthFormSlide: React.FC = ({}) => {
  const formContext = useContext(newPatientFormContext)
  const [numOfFields, setNumOfFields] = useState(0)
  const [initialValues, setInitialValues] = useState(cloneDeep(initialValuesBase))
  const initialNumOfFieldsRef = useRef<number | undefined>(0)

  useEffect(() => {
    const { formValues } = formContext
    const initialValuesCopy = { ...initialValues }
    initialValuesCopy.hasDrugAllergy = formValues.hasDrugAllergy
    formValues.drugAllergy?.forEach((drugAllergy, index) => {
      initialValuesCopy.drugAllergy![index] = drugAllergy
    })
    setInitialValues({ ...initialValuesCopy })
    initialNumOfFieldsRef.current = formValues.drugAllergy?.length
  }, [formContext.loaded])

  return (
    <TaskCardBase
      formContext={formContext}
      initialValues={initialValues}
      title="Gyógyszerallergia"
      formikValidate={(values: CreatePatientDataFourthCard) => validate(values, numOfFields)}
    >
      <FourthFormSlideView
        initialNumOfFields={initialNumOfFieldsRef.current}
        setNumOfFieldsInParent={setNumOfFields}
      />
    </TaskCardBase>
  )
}

export default FourthFormSlide
