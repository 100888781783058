import React, { useContext, useEffect, useRef, useState } from "react"
import { createMessage } from "src/apicalls/createMessage"
import { getMessages } from "src/apicalls/getMessages"
import { patchNotification } from "src/apicalls/patchNotification"
import { GlobalContext } from "src/App"
import Loader from "src/common-components/Loader/Loader"
import { Message, Name, Ticket } from "src/types"
import { getDisplayNameFromNames } from "../../../common/utilityFunctions"
import styles from "./Messages.module.scss"

interface Props {
  ticketID: number
  ticketReady?: boolean
  subscriber: Ticket["doctor"]
  partnerName: Name | null
  markSeen: boolean
}

const Messages: React.FC<Props> = ({
  ticketID,
  subscriber,
  ticketReady,
  partnerName,
  markSeen,
}) => {
  const [messageText, setMessageText] = useState<string>("")
  const [messages, setMessages] = useState<Message[]>([])
  const [messagesLoaded, setMessagesLoaded] = useState<boolean>(false)
  const lastMessageRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLTextAreaElement>(null)

  const { userToken, doctor, user, notifications } = useContext(GlobalContext)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = "0"
      const height =
        inputRef.current.scrollHeight < 150 ? `${inputRef.current.scrollHeight}px` : "150px"
      inputRef.current.style.height = height
    }
  }, [messageText])

  useEffect(() => {
    setMessagesLoaded(false)
  }, [ticketID])

  useEffect(() => {
    const setMessagesToState = async (userToken: string) => {
      const apiResponse = await getMessages(userToken, ticketID)
      if (!apiResponse.status || apiResponse.status.result !== "ok") {
        console.log("Error at communication with server")
        console.log(apiResponse)
      } else {
        setMessages(apiResponse.status.message)
      }
    }
    const initializeMessages = async () => {
      if (!messagesLoaded) {
        await setMessagesToState(userToken)
        setMessagesLoaded(true)
      }
    }
    initializeMessages()
    for (let i = notifications.unseen.length - 1; i >= 0; i--) {
      const notification = notifications.unseen[i]
      if (
        markSeen &&
        notification.subject === "new-message" &&
        notification.ticketID === ticketID
      ) {
        setMessagesToState(userToken)
        patchNotification(userToken, notification.id)
        break
      }
    }
  }, [userToken, notifications, messagesLoaded, markSeen, ticketID])

  const getMessageTimestamp = (timestamp: number) => {
    const now = Math.floor(new Date().getTime() / 1000)
    const diff = now - timestamp
    const date = new Date(timestamp * 1000)

    if (diff > 30 * 24 * 3600) {
      // more than a month ago
      return date.toLocaleDateString("hu", { dateStyle: "short" })
    }

    return (
      date.toLocaleDateString("hu", { month: "short" }) +
      " " +
      date.toLocaleDateString("hu", { day: "numeric" }) +
      ". " +
      date.toLocaleTimeString("hu", { timeStyle: "short" })
    )
  }

  const getMessagesElements = () => {
    if (!messages) return null
    const messageElements = messages
      .slice()
      .reverse()
      .map((message, index) => {
        const ref = index === 0 ? lastMessageRef : undefined
        const className =
          message.senderID !== user.userID && message.senderID !== doctor.userID
            ? styles.received
            : styles.sent

        return (
          <div key={index} ref={ref} className={`${styles.msgBlock} ${className}`}>
            <p className={styles.timestamp}>{getMessageTimestamp(message.timestamp)}</p>
            <p className={styles.content}>{message.content}</p>
          </div>
        )
      })
    return messageElements
  }

  const messageElements = getMessagesElements()

  const canSendMessage = (Object.keys(doctor).length !== 0 || messages.length !== 0) && !ticketReady

  const sendMessage = async () => {
    const text = messageText
    if (messageText !== "") {
      setMessageText("")
      const res = await createMessage(userToken, ticketID, {
        content: text,
      })
      if (!res.status || res.status.result !== "ok") {
        console.log("Error at communication with server")
        console.log(res)
      }
      //TODO:
      else setMessages(res.status.message)
    }
  }

  if (!subscriber.id) return null

  if (!messagesLoaded) {
    return (
      <div className={`${styles.container}`}>
        <Loader />
      </div>
    )
  }

  return (
    <div className={styles.wrapper}>
      <div className={`${styles.container} ${!canSendMessage ? styles.disabledChildren : ""}`}>
        <div className={styles.top}>{getDisplayNameFromNames(partnerName)}</div>
        {canSendMessage || ticketReady ? null : (
          <div className={styles.cantSendTextContainer}>
            <i className={["material-icons", "notranslate"].join(" ")}>lock</i>
            <strong>Üzenetváltást csak orvosa kezdeményezhet.</strong>
            <br />
            Ha az ügy megoldásához további információ szükséges, orvosa keresni fogja Önt.
          </div>
        )}
        <div className={styles.messagesContainer}>
          {messageElements}
          <div>&nbsp;</div>
        </div>
        {canSendMessage && !ticketReady ? (
          <div className={styles.bottomPart}>
            <textarea
              ref={inputRef}
              value={messageText}
              onChange={(value) => setMessageText(value.target.value)}
              className={styles.input}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault()
                  sendMessage()
                }
              }}
            />
            <button className={styles.sendButton} onClick={sendMessage}>
              Küldés<i className={["material-icons", "notranslate"].join(" ")}>send</i>
            </button>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default Messages
