import { Task, TaskTypes } from "../../../types"
import { ticketStartData } from "../../UserHomePage/PatientBlock/NewTicketStart"
import { getStartingTaskData } from "../utils and hooks/useNewTicketFormValues"

export function getActiveTasks(tasks: Task[] | undefined) {
  if (!tasks) return null

  // Ugly but works
  const activeTasks: ticketStartData = {
    diagnosis: false,
    prescription: false,
    referral: false,
    sickNote: false,
    sickPay: false,
    other: false,
    covid: false,
  }

  Object.keys(activeTasks).forEach((k) => {
    ;(activeTasks as any)[k] = !!tasks.find((t) => t.name === k)
  })

  // Special conditions for Covid type task
  activeTasks.covid = !!tasks.find((t) => t.name === "diagnosis" && t.covid)
  activeTasks.diagnosis = !!tasks.find((t) => t.name === "diagnosis" && !t.covid)

  return activeTasks
}

export const checkIfTaskExistsInFormValues = (taskName: TaskTypes | "covid", tasks: Task[]) => {
  if (taskName === "covid") return tasks.find((task) => task.name === "diagnosis" && task.covid)
  if (taskName === "diagnosis")
    return tasks.find((task) => task.name === "diagnosis" && !task.covid)
  return tasks.find((task) => task.name === taskName)
}

export const getNextTaskId = (tasks: Task[]) => {
  return tasks.reduce((acc, curr) => Math.max(acc, curr.taskID), 0) + 1
}

export const addNewTaskToTaskList = (taskName: keyof ticketStartData, tasks: Task[]) => {
  const tasksCopy = [...tasks]
  tasksCopy.push(getStartingTaskData(taskName, getNextTaskId(tasks)))
  return tasksCopy
}

export const removeTasksFromTaskList = (
  taskName: keyof ticketStartData,
  tasks: Task[],
  ongoingTasks?: Task[],
) => {
  return tasks.filter((t) => {
    if (ongoingTasks?.find(({ taskID }) => taskID === t.taskID)) return true // task was added previously, we should not remove

    if (taskName === "covid") {
      return t.name !== "diagnosis" || !t.covid
    }
    return t.name !== taskName
  })
}

export const doesTaskExistInFormValues = (taskName: keyof ticketStartData, tasks: Task[]) => {
  return tasks.find((t) => {
    if (taskName === "covid") {
      return t.name === "diagnosis" && t.covid
    }
    return taskName === t.name
  })
}
