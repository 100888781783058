import React, { useContext, useEffect, useRef, useState } from "react"
import ReactDOM from "react-dom"
import { Location, useLocation, useNavigate } from "react-router-dom"
import SimplePeer from "simple-peer"
import { patchVideoCall } from "src/apicalls/patchVideoCall"
import { GlobalContext } from "src/App"
import Loader from "src/common-components/Loader/Loader"
import { getDisplayNameFromNames, isCallInitiator } from "src/common/utilityFunctions"
import { VideoCall, VideoPageHistoryType } from "src/types"
import { useOVNavigator } from "../../common/hooks/useOVNavigator"
import Avatar from "../Avatar/Avatar"
import GenericButton from "../GenericButton/GenericButton"
import { useTryToGetAnswer } from "./useTryToGetAnswer"
import styles from "./VideoPage.module.css"
import VideoSettings from "./VideoSettings"

const getStream = async () => {
  let stream
  try {
    stream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true,
    })
  } catch {
    try {
      stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      })
    } catch {
      stream = undefined
    }
  }
  return stream
}

let p: SimplePeer.Instance

const VideoPage: React.FC = () => {
  const { userToken, doctor, user, setShouldUpdateUserDataFromServer } = useContext(GlobalContext)
  const navigate = useNavigate()
  const location = useLocation() as Location & { state: VideoPageHistoryType }
  const [isConnected, setIsConnected] = useState<boolean>(false)
  const [isCallEnded, setIsCallEnded] = useState<boolean>(false)
  const [partnerMuted, setPartnerMuted] = useState<boolean>(false)
  const videoElement = useRef<HTMLVideoElement | null>(null)
  const streamRef = useRef<MediaStream>()
  const isDoctor = Object.keys(doctor).length > 0
  const videoCalls = isDoctor ? doctor.videoCalls : user.videoCalls
  const [videoCall, setVideoCall] = useState<VideoCall | null>(() => findVideoCall())
  const endCallCode = "mtm6VHPvis974a"
  const isVideoAvailable = !!(
    videoElement.current?.srcObject as MediaStream | undefined
  )?.getVideoTracks()[0]
  const partnerName = isDoctor ? videoCall?.userName : videoCall?.doctorName

  const OVNavigator = useOVNavigator()

  function findVideoCall() {
    let videoCall: VideoCall | null = null
    const videoCallID = location.state?.videoCallID
    if (!videoCallID) {
      navigate({ pathname: "/" })
    } else {
      const { videoCalls: videoCalls } = isDoctor ? doctor : user
      videoCall = videoCalls.find((v) => v.id === videoCallID) || null
      if (!videoCall) navigate({ pathname: "/" })
    }
    return videoCall
  }

  useEffect(() => {
    getStream().then((stream) => {
      streamRef.current = stream
      const initiator = !!(videoCall && isCallInitiator(isDoctor, videoCall))
      createPeer(initiator, videoCall)
    })
  }, [])

  useEffect(() => {
    const videoCall = findVideoCall()
    setVideoCall(videoCall)
  }, [videoCalls])

  const [, setIsWaitingForAnswer] = useTryToGetAnswer({
    p,
    userToken,
    videoCall,
    isDoctor,
    createPeer,
    setVideoCall,
  })

  function createPeer(initiator: boolean, videoCall: VideoCall | null) {
    if (p) p.destroy()

    const shouldReverse = !!(
      !isDoctor &&
      !initiator &&
      !videoCall?.hasVideoStream &&
      streamRef.current?.getVideoTracks()[0]
    )

    if (shouldReverse) initiator = true

    p = new SimplePeer({
      initiator: initiator,
      trickle: false,
      stream: streamRef.current,
    })
    p.on("signal", async (signal) => {
      if (videoCall) {
        const drHasVideoStream = !!streamRef.current?.getVideoTracks()[0] && isDoctor
        const propName = initiator ? "offer" : "answer"
        const res = await patchVideoCall(userToken, {
          id: videoCall.id,
          [propName]: JSON.stringify(signal),
          hasVideoStream: drHasVideoStream || undefined,
          reverseRoles: shouldReverse || undefined,
        })
        setShouldUpdateUserDataFromServer(true)
        if (initiator) setIsWaitingForAnswer(true)
      }
    })
    p.on("error", (err) => console.log("error:", err))
    p.on("connect", () => {
      setIsConnected(true)
    })
    p.on("stream", (stream) => {
      if (videoElement.current) {
        videoElement.current.srcObject = stream
        videoElement.current.play()
      }
    })
    p.on("data", (data) => {
      const d = String(data)
      if (d === endCallCode) {
        setIsCallEnded(true)
        streamRef.current?.getTracks().forEach((t) => t.stop())
      } else if (d === "muted") setPartnerMuted(true)
      else if (d === "unmuted") setPartnerMuted(false)
    })

    if (!initiator && videoCall) p.signal(JSON.parse(videoCall.offer))
  }

  const endCall = () => {
    p.send(endCallCode)
    p.destroy()
    navigate({ pathname: "/" })
  }

  return ReactDOM.createPortal(
    <div className={styles.container}>
      {!isConnected ? (
        <>
          <Loader text="Várakozás a másik félre" />
        </>
      ) : null}
      {!isVideoAvailable && isConnected ? <Avatar /> : null}
      <video ref={videoElement} className={isVideoAvailable ? "" : styles.hidden} />
      {/*do not conditionally render the video element because the ref needs to be attached early on*/}
      {isConnected ? (
        <div className={styles.nameOverlay}>
          <div className={styles.nameDisplay}>
            <p>{partnerName ? getDisplayNameFromNames(partnerName) : null}</p>
            {partnerMuted ? (
              <i className={["material-icons", "notranslate"].join(" ")}>mic_off</i>
            ) : null}
          </div>
        </div>
      ) : null}
      <VideoSettings
        stream={streamRef}
        endCall={endCall}
        isCallEnded={isCallEnded}
        videoCallID={videoCall?.id || null}
        SimplePeerInstance={p}
      />
      {videoCall ? (
        <div className={styles.openTicketContainer}>
          <GenericButton
            style="white"
            text="Ügy megnyitása"
            action={() => OVNavigator.toTicketNewWindow(videoCall?.ticketID)}
          />
        </div>
      ) : null}
    </div>,
    document.body,
  )
}

export default VideoPage
