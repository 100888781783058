import React from "react"
import ShowImageFromServer from "src/common-components/ShowImageFromServer/ShowImageFromServer"
import TicketHead from "src/common-components/TicketHead/TicketHead"
import { Ticket } from "src/types"
import MobileTicketMenu from "../../common-components/MobileTicketMenu/MobileTicketMenu"
import { useIsMobileSize } from "../../common/hooks/useIsMobileSize"
import styles from "./DrTicketData.module.scss"
import DrTicketMenu from "./DrTicketMenu"
import SymptomsTable from "./SymptomsTable"

interface Props {
  ticket: Ticket
  setOutpatientSheetVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const DrTicketData: React.FC<Props> = ({ ticket, setOutpatientSheetVisible }) => {
  const isMobile = useIsMobileSize()

  const showImageLinks = ticket.ticketData.files.map((file) => {
    return <ShowImageFromServer file={file} key={file.id} />
  })

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <TicketHead ticket={ticket} />

        <div className={styles.contentContainer}>
          <SymptomsTable symptoms={ticket.ticketData.symptoms} />
          {!ticket.ticketData.comment || ticket.ticketData.comment === "" ? null : (
            <div className={styles.note}>
              <h6>Megjegyzés</h6>
              <p>{ticket.ticketData.comment}</p>
            </div>
          )}
          {ticket.ticketData.files.length > 0 && ticket.doctor.id ? (
            <div>
              <h6>Az ügyhöz csatolt dokumentumok</h6>
              {showImageLinks}
            </div>
          ) : null}
        </div>
        <MobileTicketMenu>
          <DrTicketMenu ticket={ticket} setOutpatientSheetVisible={setOutpatientSheetVisible} />
        </MobileTicketMenu>
      </div>
      {!isMobile && (
        <div className={styles.rightContainer}>
          <DrTicketMenu ticket={ticket} setOutpatientSheetVisible={setOutpatientSheetVisible} />
        </div>
      )}
    </div>
  )
}
export default DrTicketData
