import React from "react"
import styles from "./OVIcon.module.scss"

interface Props extends React.HTMLProps<HTMLButtonElement> {
  name: string
  buttonRef?: React.MutableRefObject<HTMLButtonElement | null>
  className?: string
}

const OVIcon: React.FC<Props> = ({ className, name, buttonRef, ...props }) => {
  return (
    <i
      {...props}
      ref={buttonRef}
      className={["material-icons", "notranslate", className || ""].join(" ")}
    >
      {name}
    </i>
  )
}

export default OVIcon
