import { useFormikContext } from "formik"
import { DrugForm, HospitalVisit, Patient } from "src/types"
import { deleteMedia } from "./deleteMedia"
import { patchPatient } from "./patchPatient"

export const deleteMediaFromPatientProfile = async (
  userToken: string,
  patient: Patient,
  propertyParent: DrugForm | HospitalVisit,
  formikPath: string,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
) => {
  const mediaID = propertyParent.media?.id

  if (typeof mediaID !== "number") return false

  const mediaDeleteResult = await deleteMedia(userToken, mediaID)
  if (mediaDeleteResult.status.message === "image deleted") {
    propertyParent.media = null
    const patientPatchResult = await patchPatient(userToken, {
      patientID: patient.id,
      patientProfile: patient.profile,
    })
    if (patientPatchResult.status.result === "ok") {
      setFieldValue(formikPath, null)
      return true
    }
  }
  return false
}
