import React from "react"
import ShowImageFromServer from "../../../common-components/ShowImageFromServer/ShowImageFromServer"
import { OtherTaskData } from "../../NewTicketPage/OtherTaskCard/OtherTaskCard"
import styles from "./UserOtherTask.module.scss"

interface Props {
  task: OtherTaskData
}

const UserOtherTask: React.FC<Props> = ({ task }) => {
  if (
    !task.doctorData.comment &&
    !task.doctorData.documentsReceived &&
    task.doctorData.files.length === 0
  ) {
    return <p>Az igényhez kapcsolódó információk itt fognak megjelenni.</p>
  }

  const showImageElements = task.doctorData.files.map((f, i) => {
    return <ShowImageFromServer key={i} file={f} />
  })

  return (
    <div className={styles.container}>
      {task.doctorData.documentsReceived ? <p>Dokumentumok átvéve</p> : null}

      {task.doctorData.comment ? (
        <div>
          <h6>Megjegyzés</h6>
          <p>{task.doctorData.comment}</p>
        </div>
      ) : null}

      {showImageElements.length > 0 ? (
        <div>
          <h6>Orvosa által feltöltött fájlok: </h6>
          {showImageElements}
        </div>
      ) : null}
    </div>
  )
}

export default UserOtherTask
