import React from "react"
import { getDisplayNameFromNames, toServerTime } from "src/common/utilityFunctions"
import { Name } from "src/types"
import { useOVNavigator } from "../../common/hooks/useOVNavigator"
import styles from "./BasicConsultation.module.css"

interface Props {
  partnerName: Name
  dateInSeconds: number
  ticketID: number
  isVideo: boolean
}

const BasicConsultation: React.FC<Props> = ({ ticketID, partnerName, dateInSeconds, isVideo }) => {
  const OVNavigator = useOVNavigator()

  const isPast = dateInSeconds < toServerTime(new Date())

  return (
    <div className={isPast ? styles.containerPast : styles.container}>
      <div className={styles.left} onClick={() => OVNavigator.toTicket(ticketID)}>
        <p>{getDisplayNameFromNames(partnerName)}</p>
        <p className={styles.date}>{new Date(dateInSeconds * 1000).toLocaleString()}</p>{" "}
      </div>
      <p className={styles.type}>{isVideo ? "Videó" : "Személyes"}</p>
    </div>
  )
}

export default BasicConsultation
