import React, { useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { patchVideoCall } from "src/apicalls/patchVideoCall"
import { GlobalContext } from "src/App"
import GenericButton from "src/common-components/GenericButton/GenericButton"
import { getDisplayNameFromNames } from "src/common/utilityFunctions"
import { VideoCall, VideoPageHistoryType } from "src/types"
import { patchNotification } from "../../apicalls/patchNotification"
import styles from "./CallPopup.module.css"

interface Props {
  videoCall: VideoCall
}

const CallPopup: React.FC<Props> = ({ videoCall }) => {
  const navigate = useNavigate()
  const { notifications, userToken, setShouldUpdateUserDataFromServer } = useContext(GlobalContext)

  const setAssociatedNotificationSeen = async () => {
    const associatedNotification = notifications.unseen.find(
      (n) => n.subject === "videocall-started",
    )
    if (associatedNotification) {
      await patchNotification(userToken, associatedNotification.id)
      setShouldUpdateUserDataFromServer(true)
    }
  }

  const handleClick = () => {
    const state: VideoPageHistoryType = {
      videoCallID: videoCall.id,
    }
    setAssociatedNotificationSeen()
    navigate({ pathname: "/videocall" }, { state })
  }

  const callerName = getDisplayNameFromNames(videoCall.doctorName)

  return (
    <div className={styles.container}>
      <h4>Videóhívás</h4>
      <p>{callerName + " elindította a videókonzultációt"}</p>
      <GenericButton
        className={styles.button}
        text="Csatlakozás"
        action={handleClick}
        style="blue"
      />
    </div>
  )
}

export default CallPopup
