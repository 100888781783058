import React from "react"
import { useRef } from "react"
import { useState } from "react"
import NavBar from "../NavBar/NavBar"
import { SidebarProps } from "../Sidebar/DrSidebar"
import styles from "./PageBaseView.module.scss"

interface Props {
  Sidebar: React.FC<SidebarProps>
  className?: string
}

const PageBaseView: React.FC<Props> = ({ Sidebar, children, className = "" }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const menuButtonRef = useRef<HTMLButtonElement>(null)

  return (
    <>
      <Sidebar
        sidebarOpen={sidebarOpen}
        hideSidebar={() => setSidebarOpen(false)}
        menuButtonRef={menuButtonRef}
      />
      <NavBar menuButtonRef={menuButtonRef} setSidebarOpen={setSidebarOpen} />
      <div id="page-base" className={`${styles.container} ${className}`}>
        {children}
      </div>
    </>
  )
}

export default PageBaseView
