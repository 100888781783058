import { useContext, useEffect, useState } from "react"
import { patchTicket } from "../../../apicalls"
import { GlobalContext } from "../../../App"
import { MediaAtCreation, PatchTicketApiData, TicketData } from "../../../types"

type returnType = [MediaAtCreation[], React.Dispatch<React.SetStateAction<MediaAtCreation[]>>]

export const useUploadSingleFile = (
  userToken: string,
  ticketID: number | undefined,
  ticketData: TicketData | undefined,
): returnType => {
  const [files, setFiles] = useState<MediaAtCreation[]>([])
  const { setShouldUpdateUserDataFromServer } = useContext(GlobalContext)

  useEffect(() => {
    const patchTicketWithUserMedia = async () => {
      if (files?.length > 0 && ticketID && ticketData) {
        await patchTicket(userToken, { ticketID, ticketData }, files) //ticketData need to not overwrite all files
        setShouldUpdateUserDataFromServer(true)
        setFiles([])
      }
    }
    patchTicketWithUserMedia()
  }, [files, ticketID])

  return [files, setFiles]
}
