import React, { useContext, useState } from "react"
import { useNavigate, useLocation, useParams } from "react-router-dom"
import { GlobalContext } from "src/App"
import Popup from "src/common-components/Popup/Popup"
import { Patient, Ticket, TicketData } from "src/types"
import Deck from "../../common-components/Deck/Deck"
import { createFormContext } from "../createFormContext"
import styles from "./NewTicketPage.module.scss"
import SickPayErrorPopup from "./SickPayErrorPopup"
import { useNewTicketCards } from "./utils and hooks/useNewTicketCards"
import { useNewTicketFormValues } from "./utils and hooks/useNewTicketFormValues"
import { useNewTicketSubmit } from "./utils and hooks/useNewTicketSubmit"

export const newTicketFormContext = createFormContext<TicketData>()

const usePatientAndTicket = () => {
  const { user } = useContext(GlobalContext)
  const urlData = useParams<{ ticketId?: string; patientId?: string }>()

  let ticket: Ticket | undefined, patient: Patient | undefined
  // If we are editing a ticket (adding new tasks) we have ticketId as a url param
  // If we are creating a new ticket we have patientId as a url param
  const ticketId = urlData.ticketId ? parseInt(urlData.ticketId) : undefined
  if (ticketId) {
    ticket = user.tickets.find((t) => t.ticketID === ticketId)
    patient = user.patients.find(({ id }) => id === ticket?.patientID)
  } else {
    const patientId = urlData.patientId ? parseInt(urlData.patientId) : undefined
    patient = user.patients.find(({ id }) => id === patientId)
  }

  return [patient, ticket] as const
}

const NewTicketPage: React.FC = () => {
  const [patient, ticket] = usePatientAndTicket()

  if (!patient) throw new Error("Invalid url data")

  const [formState, setFormState] = useState(0)
  const [formValues, setFormValues] = useNewTicketFormValues(patient, ticket, {} as any)
  const [shouldSubmit, setShouldSubmit] = useState<boolean>(false)

  const { cards, files, newUserDataForSickPayTask } = useNewTicketCards(
    formValues.tasks,
    ticket,
    patient,
  )

  const { loading, submit } = useNewTicketSubmit(
    files,
    formValues,
    newUserDataForSickPayTask,
    patient,
    ticket,
  )

  if (!patient) return null

  const shouldShowSickPayError =
    formValues.tasks.find((t) => t.name === "sickPay") && !patient.profile.GP?.id

  return (
    <div className={styles.container}>
      {shouldShowSickPayError ? <SickPayErrorPopup /> : null}
      <newTicketFormContext.Provider
        value={{
          setFormValues: setFormValues,
          setFormState: setFormState,
          formState: formState,
          maxFormState: cards.length,
          formValues: formValues,
          setShouldSubmit: setShouldSubmit,
        }}
      >
        <Deck formState={formState}>{cards}</Deck>
        {shouldSubmit ? (
          <Popup
            title="Adatok megerősítése"
            onClose={() => setShouldSubmit(false)}
            confirmButton={{
              title: "Megerősítés",
              action: () => submit(),
            }}
            cancelButton={{
              title: "Javítás",
            }}
            panelStyle={{
              minWidth: "30rem",
            }}
            loading={loading}
            aszf
            dataManagement
          />
        ) : null}
      </newTicketFormContext.Provider>
    </div>
  )
}

export default NewTicketPage
