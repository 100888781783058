import React from "react"
import GenericButton from "src/common-components/GenericButton/GenericButton"
import DayChooser from "src/user-components/LogBookPage/AddEvent/DateChooserBlock/DayChooser/DayChooser"
import TimeChooser from "src/user-components/LogBookPage/AddEvent/DateChooserBlock/TimeChooser/TimeChooser"
import Tooltip from "../../../../common-components/Tooltip/Tooltip"
import styles from "./SetAppointmentDate.module.scss"

interface Props {
  date: Date
  setDate: React.Dispatch<React.SetStateAction<Date>>
  submit: () => void
  isDateChangable: boolean
  isEditing: boolean
}

const SetAppointmentDate: React.FC<Props> = ({
  submit,
  setDate,
  date,
  isDateChangable,
  isEditing,
}) => {
  const props = { date, setDate, minDate: new Date() }

  return (
    <div className={styles.container}>
      <div className={styles.settingsContainer}>
        <i className={["material-icons", "notranslate"].join(" ")}>today</i>
        <DayChooser {...props} />
        <i className={["material-icons", "notranslate"].join(" ")}>access_time</i>
        <TimeChooser {...props} />
      </div>
      <Tooltip tooltipText="A konzultáció időpontja már nem módosítható" disabled={isDateChangable}>
        <GenericButton
          style="blue"
          text={isEditing ? "Módosítás" : "Befejezés"}
          action={submit}
          className={styles.button}
          disabled={!isDateChangable}
        />
      </Tooltip>
    </div>
  )
}

export default SetAppointmentDate
