import { filterVideoCalls, getNameSubset } from "src/common/utilityFunctions"
import {
  ApiResponse,
  Doctor,
  DoctorApi,
  PersonalConsultationNotificationData,
  PersonalConsultationTaskData,
  Task,
  Ticket,
  User,
  UserApi,
} from "src/types"
import { InvalidApiDataError } from "../common/errors"
import sendRequest from "./sendRequest"

const getConsultationPartnerName = (isDoctor: boolean, ticket: Ticket) => {
  if (isDoctor) {
    if (!ticket.userProfile)
      throw new InvalidApiDataError(
        "Ticket data should contain the user's profile when sending to doctor",
      )
    return getNameSubset(ticket.userProfile)
  } else {
    if (!ticket.doctor.name)
      throw new InvalidApiDataError("Doctor name is null in ongoing consultation task")
    return ticket.doctor.name
  }
}

const isValidPersonalConsultationTask = (t: Task) => {
  return (
    t.name === "consultation" &&
    t.type === "personal" &&
    t.doctorData.date &&
    t.doctorData.date < Date.now() / 1000
  )
}

export const getUserData = async (userToken: string) => {
  const res = await sendRequest<ApiResponse<User> | ApiResponse<Doctor>>(userToken, "/user")
  if (res.status.result === "error") return null
  const data = res.status.message as UserApi | DoctorApi
  const relevantTickets = data.doctor ? data.tickets.inProgress : data.tickets

  //getting scheduled videoconsultations
  const filteredVideoCalls = filterVideoCalls(res.status.message.videoCalls, relevantTickets)
  res.status.message.videoCalls = filteredVideoCalls

  //getting scheduled personal consultations
  const personalConsultations: PersonalConsultationNotificationData[] = []
  relevantTickets.forEach((ticket) => {
    const consultationTask = ticket.ticketData.tasks.find((t) =>
      isValidPersonalConsultationTask(t),
    ) as PersonalConsultationTaskData | undefined
    if (consultationTask) {
      let partnerName = getConsultationPartnerName(data.doctor, ticket)
      personalConsultations.push({
        date: consultationTask.doctorData.date!,
        partnerName,
        ticketID: ticket.ticketID,
        finished: consultationTask.state === "ready",
      })
    }
  })
  const filledData: Doctor | User = { ...data, personalConsultations }
  res.status.message = filledData
  return res as ApiResponse<Doctor | User>
}
