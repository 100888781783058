import { useEffect, useRef } from "react"

export const useOnClickOutside = <
  T extends HTMLDivElement | HTMLFormElement | HTMLImageElement = HTMLDivElement,
>(
  action: Function,
  buttonRef?: React.MutableRefObject<
    HTMLFormElement | null | HTMLDivElement | HTMLAnchorElement | HTMLButtonElement
  >,
  disabled?: boolean,
) => {
  const ref = useRef<T>(null)

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      const buttonClick =
        buttonRef && buttonRef?.current && buttonRef.current.contains(event?.target)
      if (ref.current && !ref.current.contains(event.target) && !buttonClick) {
        action()
      }
    }
    !disabled && document.addEventListener("mousedown", handleClickOutside)
    return () => document.removeEventListener("mousedown", handleClickOutside)
  }, [ref, action, disabled])

  return ref
}
