import { ApiResponse, PatchPatientData, Ticket, TicketData } from "src/types"
import { APIBASEURL, headersBase } from "."

export const getClosedTickets = async (userToken: string, startTime: Date, endTime: Date) => {
  const res = await fetch(APIBASEURL + "/closed-tickets", {
    headers: {
      ...headersBase,
      "User-Token": userToken,
      "Start-Time": Math.round(startTime.getTime() / 1000).toString(),
      "End-Time": Math.round(endTime.getTime() / 1000 + 86400).toString(),
    },
  })
  const result: ApiResponse<Ticket[]> = await res.json()
  return result
}
