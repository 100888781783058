const symptoms = [
  {
    ID: 188,
    Name: "Has védelem",
  },
  {
    ID: 10,
    Name: "Hasi fájdalom",
  },
  {
    ID: 223,
    Name: "A menstruációval járó hasi fájdalom",
  },
  {
    ID: 984,
    Name: "Gyenge vagy nem tapintható pulzus",
  },
  {
    ID: 974,
    Name: "Agresszivitás",
  },
  {
    ID: 981,
    Name: "Agitáció",
  },
  {
    ID: 996,
    Name: "A boka deformitása",
  },
  {
    ID: 147,
    Name: "Boka duzzanat",
  },
  {
    ID: 238,
    Name: "Szorongás",
  },
  {
    ID: 1009,
    Name: "Karfájdalom",
  },
  {
    ID: 971,
    Name: "Kar duzzanata",
  },
  {
    ID: 998,
    Name: "A hát deformitása",
  },
  {
    ID: 104,
    Name: "Hátfájás",
  },
  {
    ID: 180,
    Name: "Fekete széklet",
  },
  {
    ID: 57,
    Name: "Elsötétülő látás",
  },
  {
    ID: 24,
    Name: "Miteszer",
  },
  {
    ID: 284,
    Name: "Vaginális vérzés",
  },
  {
    ID: 176,
    Name: "Vérzés a szem kötőhártyájában",
  },
  {
    ID: 48,
    Name: "Hasi puffadás érzete",
  },
  {
    ID: 190,
    Name: "Véres széklet",
  },
  {
    ID: 233,
    Name: "Véres köhögés",
  },
  {
    ID: 991,
    Name: "Kék bőrelszíneződés",
  },
  {
    ID: 240,
    Name: "Kék folt a bőrön",
  },
  {
    ID: 77,
    Name: "Homályos látás",
  },
  {
    ID: 239,
    Name: "Kopasz folt a hajas fejbőrön",
  },
  {
    ID: 156,
    Name: "Csonttörés",
  },
  {
    ID: 250,
    Name: "Légzéshez köthető fájdalom",
  },
  {
    ID: 979,
    Name: "Törékeny körmök",
  },
  {
    ID: 192,
    Name: "Kidomborodó hasfal",
  },
  {
    ID: 75,
    Name: "Irritált szemek",
  },
  {
    ID: 46,
    Name: "Torokfájás",
  },
  {
    ID: 288,
    Name: "Égő orr",
  },
  {
    ID: 107,
    Name: "Csípő, fájdalmas vizelés",
  },
  {
    ID: 91,
    Name: "Elváltozás a körmön",
  },
  {
    ID: 170,
    Name: "Arc duzzanata",
  },
  {
    ID: 17,
    Name: "Mellkasi fájdalom",
  },
  {
    ID: 31,
    Name: "Szoruló mellkas",
  },
  {
    ID: 175,
    Name: "Hidegrázás",
  },
  {
    ID: 218,
    Name: "Durva bőrfelszín kialakulása",
  },
  {
    ID: 89,
    Name: "Hideg lábfejek",
  },
  {
    ID: 978,
    Name: "Hideg kezek",
  },
  {
    ID: 139,
    Name: "Hideg verejték",
  },
  {
    ID: 15,
    Name: "Köhögés",
  },
  {
    ID: 228,
    Name: "Köhögés köpet képződéssel",
  },
  {
    ID: 94,
    Name: "Görcsök",
  },
  {
    ID: 49,
    Name: "Vágyakozás",
  },
  {
    ID: 134,
    Name: "Kérgesedés",
  },
  {
    ID: 260,
    Name: "Gerincferdülés",
  },
  {
    ID: 108,
    Name: "Sötét vizelet",
  },
  {
    ID: 163,
    Name: "Csökkent erősségű vizeletsugár",
  },
  {
    ID: 165,
    Name: "Nehezen meginduló vizelet",
  },
  {
    ID: 50,
    Name: "Hasmenés",
  },
  {
    ID: 79,
    Name: "Nehéz székletürítés",
  },
  {
    ID: 126,
    Name: "Szavak keresése beszéd közben",
  },
  {
    ID: 98,
    Name: "Beszélési nehézség",
  },
  {
    ID: 93,
    Name: "Nyelési nehézség",
  },
  {
    ID: 53,
    Name: "Koncentrációs nehézség",
  },
  {
    ID: 1007,
    Name: "Tanulási nehézség",
  },
  {
    ID: 1005,
    Name: "Járási, testtartási nehézség",
  },
  {
    ID: 216,
    Name: "Körmök elszíneződése",
  },
  {
    ID: 128,
    Name: "Tér- vagy időérzékelési zavar",
  },
  {
    ID: 989,
    Name: "Feszülő has",
  },
  {
    ID: 207,
    Name: "Szédülés",
  },
  {
    ID: 71,
    Name: "Kettős látás",
  },
  {
    ID: 270,
    Name: "Tartósan fennálló kettős látás",
  },
  {
    ID: 162,
    Name: "Vizelés után további csöpögés",
  },
  {
    ID: 244,
    Name: "Lecsukódó szemhéj",
  },
  {
    ID: 43,
    Name: "Álmosság",
  },
  {
    ID: 273,
    Name: "Száraz szemek",
  },
  {
    ID: 272,
    Name: "Száraz száj",
  },
  {
    ID: 151,
    Name: "Száraz bőr",
  },
  {
    ID: 87,
    Name: "Fülfájás",
  },
  {
    ID: 92,
    Name: "Korai jóllakás",
  },
  {
    ID: 1011,
    Name: "Könyökfájás",
  },
  {
    ID: 1006,
    Name: "Megdagadt vádli",
  },
  {
    ID: 242,
    Name: "Pislogás",
  },
  {
    ID: 287,
    Name: "Szemfájdalom",
  },
  {
    ID: 33,
    Name: "Vörös szemek",
  },
  {
    ID: 208,
    Name: "Duzzadt szemhéj",
  },
  {
    ID: 209,
    Name: "Összeragadó szemhéjak",
  },
  {
    ID: 219,
    Name: "Arcfájdalom",
  },
  {
    ID: 246,
    Name: "Arctáji bénulás",
  },
  {
    ID: 970,
    Name: "Duzzadt arc",
  },
  {
    ID: 153,
    Name: "Szapora-, nehézlégzés",
  },
  {
    ID: 83,
    Name: "Zsíros széklet",
  },
  {
    ID: 982,
    Name: "Ájultság érzet",
  },
  {
    ID: 1014,
    Name: "Betegség érzete",
  },
  {
    ID: 76,
    Name: "Idegen test érzete a szemben",
  },
  {
    ID: 86,
    Name: "Nyomás érzete a fülben",
  },
  {
    ID: 164,
    Name: "Visszamaradt vizelet érzete",
  },
  {
    ID: 145,
    Name: "Feszülő érzés a lábakban",
  },
  {
    ID: 11,
    Name: "Láz",
  },
  {
    ID: 995,
    Name: "Ujj deformációja",
  },
  {
    ID: 1013,
    Name: "Ujjfájdalom",
  },
  {
    ID: 1012,
    Name: "Duzzadt ujj",
  },
  {
    ID: 214,
    Name: "Hámló bőr",
  },
  {
    ID: 245,
    Name: "Hámló fejbőr",
  },
  {
    ID: 154,
    Name: "Puffadás",
  },
  {
    ID: 255,
    Name: "Lábfej fájás",
  },
  {
    ID: 1002,
    Name: "Lábfeji fájdalom",
  },
  {
    ID: 125,
    Name: "Feledékenység",
  },
  {
    ID: 62,
    Name: "Vízhólyag kialakulása",
  },
  {
    ID: 84,
    Name: "Erős szagú széklet",
  },
  {
    ID: 59,
    Name: "Gyakori vizelés",
  },
  {
    ID: 110,
    Name: "Szemölcsök a nemiszerv környékén",
  },
  {
    ID: 152,
    Name: "Hajhullás",
  },
  {
    ID: 976,
    Name: "Hallucináció",
  },
  {
    ID: 72,
    Name: "Fényforrás körüli szivárvány látás",
  },
  {
    ID: 186,
    Name: "Kézfájás",
  },
  {
    ID: 148,
    Name: "Duzzadt kéz",
  },
  {
    ID: 80,
    Name: "Nehéz székletürítés",
  },
  {
    ID: 184,
    Name: "Bőr megkeményedése",
  },
  {
    ID: 9,
    Name: "Fejfájás",
  },
  {
    ID: 206,
    Name: "Hallásvesztés",
  },
  {
    ID: 985,
    Name: "Szívzörej",
  },
  {
    ID: 45,
    Name: "Szúró szív",
  },
  {
    ID: 122,
    Name: "Csuklás",
  },
  {
    ID: 993,
    Name: "Deformált csípő",
  },
  {
    ID: 196,
    Name: "Fájó csípő",
  },
  {
    ID: 121,
    Name: "Rekedtség",
  },
  {
    ID: 149,
    Name: "Hőhullámok",
  },
  {
    ID: 197,
    Name: "Mozgásképtelenség",
  },
  {
    ID: 120,
    Name: "Sérült egyensúlyérzet",
  },
  {
    ID: 90,
    Name: "Hallássérülés",
  },
  {
    ID: 70,
    Name: "Csökkent alkalmazódóképesség a világos-sötét közötti váltáskor",
  },
  {
    ID: 113,
    Name: "Csökkent potencia férfiaknál",
  },
  {
    ID: 81,
    Name: "Befejezetlen székletürítés",
  },
  {
    ID: 131,
    Name: "Fokozott étvágy",
  },
  {
    ID: 262,
    Name: "Megnövekedett vágy",
  },
  {
    ID: 204,
    Name: "Fokozott nyáltermelés",
  },
  {
    ID: 40,
    Name: "Fokozott szomjúságérzet",
  },
  {
    ID: 220,
    Name: "Fokozott érzékenység érintésre",
  },
  {
    ID: 39,
    Name: "Fokozott vizelet mennyiség",
  },
  {
    ID: 257,
    Name: "Önkéntelen mozgások",
  },
  {
    ID: 986,
    Name: "Rendszertelen szívverés",
  },
  {
    ID: 65,
    Name: "Szabálytalan alakú anyajegy",
  },
  {
    ID: 73,
    Name: "Viszkető szemek",
  },
  {
    ID: 88,
    Name: "Viszketés a fülben",
  },
  {
    ID: 973,
    Name: "Viszketés a szájban vagy a torokban",
  },
  {
    ID: 96,
    Name: "Viszketés az orrban",
  },
  {
    ID: 21,
    Name: "Viszkető bőr",
  },
  {
    ID: 999,
    Name: "Viszkető végbélnyílás",
  },
  {
    ID: 247,
    Name: "Viszkető fej",
  },
  {
    ID: 268,
    Name: "Viszketés vagy égő érzet a nemi szervek környékén",
  },
  {
    ID: 194,
    Name: "Folyadékgyülem az izületen",
  },
  {
    ID: 198,
    Name: "Ízület instabilitása",
  },
  {
    ID: 27,
    Name: "Ízületi fájdalom",
  },
  {
    ID: 230,
    Name: "Ízület tájék vörös",
  },
  {
    ID: 193,
    Name: "Duzzadt ízület",
  },
  {
    ID: 47,
    Name: "Levertség",
  },
  {
    ID: 994,
    Name: "Térd elváltozás, deformáció",
  },
  {
    ID: 256,
    Name: "Térdfájdalom",
  },
  {
    ID: 146,
    Name: "Görcsölő láb",
  },
  {
    ID: 1010,
    Name: "Lábfájás",
  },
  {
    ID: 231,
    Name: "Duzzadt láb",
  },
  {
    ID: 143,
    Name: "Fekély a lábon",
  },
  {
    ID: 82,
    Name: "Kevesebb mint 3 széklet hetente",
  },
  {
    ID: 992,
    Name: "Csökkent mozgásképességű boka",
  },
  {
    ID: 167,
    Name: "Csökkent mozgásképességű hát",
  },
  {
    ID: 178,
    Name: "Csökkent mozgásképességű ujjak",
  },
  {
    ID: 1000,
    Name: "Csökkent mozgásképességű csípő",
  },
  {
    ID: 195,
    Name: "Csökkent mozgásképességű láb",
  },
  {
    ID: 35,
    Name: "Duzzadt ajkak",
  },
  {
    ID: 205,
    Name: "Görcsölő rágóizmok",
  },
  {
    ID: 210,
    Name: "Szempilla vesztés",
  },
  {
    ID: 174,
    Name: "Alhasi fájdalom",
  },
  {
    ID: 263,
    Name: "Alsó-hát fájás",
  },
  {
    ID: 261,
    Name: "Csomó a mellben",
  },
  {
    ID: 266,
    Name: "Herék kóros elhelyezkedése",
  },
  {
    ID: 232,
    Name: "Visszér",
  },
  {
    ID: 235,
    Name: "Emlékezet kiesés",
  },
  {
    ID: 112,
    Name: "Menstruációs zavar",
  },
  {
    ID: 123,
    Name: "Kimaradt havivérzés",
  },
  {
    ID: 215,
    Name: "Nedves felpuhult bőr",
  },
  {
    ID: 85,
    Name: "Hangulathullámok",
  },
  {
    ID: 983,
    Name: "Reggel nehezen mozduló ízületek",
  },
  {
    ID: 135,
    Name: "Fájdalom a szájüregben",
  },
  {
    ID: 97,
    Name: "Fekély a szájban",
  },
  {
    ID: 177,
    Name: "Izomfájdalom",
  },
  {
    ID: 119,
    Name: "Izommerevség",
  },
  {
    ID: 987,
    Name: "Izomgyengeség",
  },
  {
    ID: 252,
    Name: "Izomsorvadás a lábban",
  },
  {
    ID: 202,
    Name: "Izomsorvadás a karban",
  },
  {
    ID: 168,
    Name: "Izomgyengeség a karban",
  },
  {
    ID: 253,
    Name: "Izomgyengeség a karban",
  },
  {
    ID: 44,
    Name: "Hányinger",
  },
  {
    ID: 136,
    Name: "Nyaki fájdalom",
  },
  {
    ID: 234,
    Name: "Merev nyak",
  },
  {
    ID: 114,
    Name: "Idegesség",
  },
  {
    ID: 133,
    Name: "Éjszakai köhögés",
  },
  {
    ID: 1004,
    Name: "Éjszakai verejtékezés",
  },
  {
    ID: 63,
    Name: "Nem gyógyuló seb a bőrön",
  },
  {
    ID: 38,
    Name: "Orrvérzés",
  },
  {
    ID: 221,
    Name: "Zsibbadás a karban",
  },
  {
    ID: 254,
    Name: "Zsibbadás a lábban",
  },
  {
    ID: 200,
    Name: "Zsibbadt kezek",
  },
  {
    ID: 137,
    Name: "Fényre való túlzott érzékenység",
  },
  {
    ID: 157,
    Name: "Túlsúly",
  },
  {
    ID: 155,
    Name: "Csontfájás",
  },
  {
    ID: 142,
    Name: "Fájdalom a vádlikban",
  },
  {
    ID: 12,
    Name: "Végtagfájdalom",
  },
  {
    ID: 990,
    Name: "Végbélnyílás fájdalom",
  },
  {
    ID: 203,
    Name: "Fájdalmas nyelés",
  },
  {
    ID: 251,
    Name: "Karba kisugárzó fájdalom",
  },
  {
    ID: 103,
    Name: "Lábba kisugárzó fájdalom",
  },
  {
    ID: 286,
    Name: "Fájdalom rágáskor",
  },
  {
    ID: 189,
    Name: "Fájdalmas székletürítés",
  },
  {
    ID: 109,
    Name: "Fádalom vizeléskor",
  },
  {
    ID: 150,
    Name: "Sápadtság",
  },
  {
    ID: 37,
    Name: "Erős szívdobogás érzete",
  },
  {
    ID: 140,
    Name: "Bénulás",
  },
  {
    ID: 118,
    Name: "Fizikai inaktivitás",
  },
  {
    ID: 129,
    Name: "Probléma az érintés érzékelésével az arcban",
  },
  {
    ID: 130,
    Name: "Probléma az érintés érzékelésével a lábfejben",
  },
  {
    ID: 258,
    Name: "Szemek kidülledése",
  },
  {
    ID: 172,
    Name: "Gennyes váladék a húgycsőből",
  },
  {
    ID: 173,
    Name: "Gennyes váladék a hüvelyből",
  },
  {
    ID: 191,
    Name: "Rebound érzékenység, Blumberg jel",
  },
  {
    ID: 54,
    Name: "Csökkent étvágy",
  },
  {
    ID: 78,
    Name: "Cseng a füle",
  },
  {
    ID: 14,
    Name: "Orrfolyás",
  },
  {
    ID: 975,
    Name: "Szomorúság",
  },
  {
    ID: 269,
    Name: "Vöröses fejbőr",
  },
  {
    ID: 1001,
    Name: "Forradás, heg",
  },
  {
    ID: 60,
    Name: "Érzékeny a hidegre",
  },
  {
    ID: 69,
    Name: "Érzékeny az éles fényre",
  },
  {
    ID: 102,
    Name: "Érzékeny a zajra",
  },
  {
    ID: 264,
    Name: "Fényes vörös nyelv",
  },
  {
    ID: 29,
    Name: "Légszomj",
  },
  {
    ID: 183,
    Name: "Fájó oldal",
  },
  {
    ID: 26,
    Name: "Bőrelváltozás",
  },
  {
    ID: 25,
    Name: "Bőrcsomó",
  },
  {
    ID: 124,
    Name: "Bőrkiütés",
  },
  {
    ID: 61,
    Name: "Vörös bőr",
  },
  {
    ID: 217,
    Name: "Bőr megvastagodása",
  },
  {
    ID: 34,
    Name: "Csalánkiütés",
  },
  {
    ID: 241,
    Name: "Álmosság spontán elalvásokkal ",
  },
  {
    ID: 52,
    Name: "Kialvatlanság",
  },
  {
    ID: 95,
    Name: "Tüsszentés",
  },
  {
    ID: 13,
    Name: "Torokfájás",
  },
  {
    ID: 64,
    Name: "Köpet",
  },
  {
    ID: 179,
    Name: "Gyomorégés",
  },
  {
    ID: 185,
    Name: "Stresszhez visszavezethető lábfájás",
  },
  {
    ID: 28,
    Name: "Orrdugulás",
  },
  {
    ID: 138,
    Name: "Izzadás",
  },
  {
    ID: 236,
    Name: "Duzzanat a nemiszervek környékén",
  },
  {
    ID: 267,
    Name: "Herék duzzanata",
  },
  {
    ID: 248,
    Name: "Duzzadt mirigyek a hónaljban",
  },
  {
    ID: 249,
    Name: "Duzzadt ágyéki mirigyek",
  },
  {
    ID: 169,
    Name: "Duzzadt nyaki mirigyek",
  },
  {
    ID: 211,
    Name: "Könnyezés",
  },
  {
    ID: 222,
    Name: "Here fájdalom",
  },
  {
    ID: 243,
    Name: "Arcrángás",
  },
  {
    ID: 201,
    Name: "Bizsergés",
  },
  {
    ID: 16,
    Name: "Fáradtság",
  },
  {
    ID: 997,
    Name: "Lábujj deformitás",
  },
  {
    ID: 1003,
    Name: "Lábujj duzzadás",
  },
  {
    ID: 980,
    Name: "Nyelv égő érzése",
  },
  {
    ID: 977,
    Name: "Duzzadt nyelv",
  },
  {
    ID: 1008,
    Name: "Fogfájás",
  },
  {
    ID: 115,
    Name: "Remegés nyugalomban",
  },
  {
    ID: 132,
    Name: "Remegés mozgáskor",
  },
  {
    ID: 988,
    Name: "Beszédmegértési nehézség",
  },
  {
    ID: 144,
    Name: "Öntudatlan állapot, rövid",
  },
  {
    ID: 265,
    Name: "Irányítatlan székletürítés",
  },
  {
    ID: 116,
    Name: "Átlagon aluli súly",
  },
  {
    ID: 160,
    Name: "Vizelési inger",
  },
  {
    ID: 161,
    Name: "Vizelés éjszaka",
  },
  {
    ID: 68,
    Name: "Sérült látás",
  },
  {
    ID: 213,
    Name: "Sérült távolra látás",
  },
  {
    ID: 166,
    Name: "Sérült közelre látás",
  },
  {
    ID: 66,
    Name: "Látótér vesztés",
  },
  {
    ID: 101,
    Name: "Hányás",
  },
  {
    ID: 181,
    Name: "Vér hányás",
  },
  {
    ID: 972,
    Name: "Gyengeség vagy zsibbadás a test jobb vagy bal oldalán",
  },
  {
    ID: 23,
    Name: "Testtömeg gyarapodás",
  },
  {
    ID: 22,
    Name: "Testtömeg vesztés",
  },
  {
    ID: 30,
    Name: "Zihálás",
  },
  {
    ID: 187,
    Name: "Seb",
  },
  {
    ID: 105,
    Name: "Sárgult színű bőr",
  },
  {
    ID: 106,
    Name: "Sárgás elszíneződés a szem fehér részén",
  },
]

export let symptomsMap = new Map<number, string>()
symptoms.forEach((symptom) => {
  symptomsMap.set(symptom.ID, symptom.Name)
})
