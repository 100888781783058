import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import BackButton from "src/common-components/BackButton/BackButton"
import { PatientProfileData } from "src/types"
import PatientDataOverview from "src/user-components/NewPatientPage/NewPatientForm/FormDataReview/PatientDataOverview"
import { useGetTicketByID } from "../../common/hooks/useGetTicketByID"
import UserBasicData from "../../user-components/UserProfilePage/UserBasicData"
import styles from "./PatientProfilePage.module.scss"

const PatientProfilePage: React.FC = () => {
  const navigate = useNavigate()
  const { ticketID } = useParams<{ ticketID: string }>()

  useEffect(() => {
    if (!ticketID) {
      navigate(-1)
      return
    }
  }, [ticketID])

  const { ticket } = useGetTicketByID(parseInt(ticketID!))

  if (!ticket?.patientProfile || !ticket.userProfile) return null

  return (
    <>
      <div className={styles.backButtonContainer}>
        <BackButton />
      </div>
      <div className={styles.container}>
        <div className={styles.inner}>
          <div className={styles.patientContainer}>
            <h2 className={styles.title}>Páciens adatok</h2>
            <div className={styles.patientContainerInner}>
              <PatientDataOverview values={ticket?.patientProfile} />
            </div>
          </div>
          <div className={styles.parentContainer}>
            <h2 className={styles.title}>Szülő adatok</h2>
            <div className={styles.parentContainerInner}>
              <UserBasicData profile={ticket.userProfile} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PatientProfilePage
