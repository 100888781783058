import { ApiResponse, LogBookEntryClient, LogBookEntryServer } from "src/types"
import { APIBASEURL, headersBase } from "."
import { convertSecondsToJSDate } from "src/common/utilityFunctions"

export const getLogs = async (
  userToken: string,
  patientID: number,
  startTime: number,
  endTime: number,
) => {
  const res = await fetch(APIBASEURL + "/journal", {
    method: "GET",
    headers: {
      ...headersBase,
      "User-Token": userToken,
      "Patient-ID": patientID.toString(),
      "Start-Time": startTime.toString(),
      "End-Time": endTime.toString(),
    },
  })
  const result: ApiResponse<LogBookEntryServer[]> = await res.json()
  if (result.status.result === "error") {
    alert("Nincs hozzáférés")
    return null
  }
  const resultWithmsTime: LogBookEntryClient[] = result.status.message.map((event) => {
    const start = convertSecondsToJSDate(event.start)
    const end = convertSecondsToJSDate(event.end)
    return { ...event, start, end }
  })
  return resultWithmsTime
}
