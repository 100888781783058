import React, { useState, useRef, Dispatch, SetStateAction } from "react"
import styles from "./LogTypeChooser.module.css"
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete"
import { TextField } from "@material-ui/core"
import { LogBookBaseEntryTitles } from "src/types"
import { ReactComponent } from "*.svg"

interface Props {
  logType: string | null
  error?: boolean
  disabled?: boolean
  onFocus?: () => void
  setError?: Dispatch<SetStateAction<boolean>>
  setLogType: React.Dispatch<React.SetStateAction<string | null>>
  setTookMedication: React.Dispatch<React.SetStateAction<boolean>>
}

const logOptions: LogBookBaseEntryTitles[] = [
  "Fejfájás",
  "Gyógyszerezés",
  "Hasfájás",
  "Hányás",
  "Kiütés",
  "Láz",
  "Orrszívás",
  "Széklet",
  "Vizelet",
]

const LogTypeChooser: React.FC<Props> = ({
  logType,
  error,
  disabled,
  onFocus,
  setError,
  setLogType,
  setTookMedication,
}) => {
  const inputRef = useRef<HTMLInputElement>()
  return (
    <div className={styles.container}>
      <i className={["material-icons", "notranslate"].join(" ")}>subject</i>
      <Autocomplete
        disabled={disabled}
        value={logType}
        onChange={(_, newValue) => {
          if (typeof newValue === "string") {
            setLogType(newValue)
            if (newValue === "Gyógyszerezés") setTookMedication(true)
          } else {
            setLogType(newValue)
          }
        }}
        filterOptions={(options, params) => {
          const filtered = createFilterOptions<string>()(options, params)
          if (params.inputValue !== "") {
            filtered.push(`${params.inputValue}`)
          }
          return filtered
        }}
        onBlur={() => {
          if (!inputRef.current?.value || !inputRef.current?.value.trim()) {
            setError?.(true)
          } else {
            setError?.(false)
          }
        }}
        selectOnFocus
        autoSelect
        onFocus={onFocus}
        options={logOptions}
        getOptionLabel={(option) => option}
        renderOption={(option) => option}
        className={styles.inputContainer}
        freeSolo
        fullWidth
        renderInput={(params) => (
          <TextField
            inputRef={inputRef}
            {...params}
            error={error}
            helperText={error && "Mező kitöltése kötelező"}
            label="Bejegyzés típusa"
            variant="standard"
            size="small"
          />
        )}
      />
    </div>
  )
}

export default LogTypeChooser
