import React from "react"
import { Article } from "src/types"
import Tooltip from "@material-ui/core/Tooltip"
import styles from "./ArticleBlock.module.scss"
import { limitStringLength } from "../../../common/utilityFunctions"

interface Props {
  article: Article | null
}

const ArticleBlock: React.FC<Props> = ({ article }) => {
  if (!article) return null

  const handleClick = () => window.open(article.url, "_blank")

  return (
    <div className={styles.container} onClick={handleClick} title="Cikk megnyitása">
      <img src={article.image}></img>
      <div className={styles.rightContainer}>
        <div className={styles.titleContainer}>
          {article.sponsored ? (
            <Tooltip
              title="Szponzorált tartalom"
              arrow
              placement="top"
              classes={{ tooltip: styles.tooltip, arrow: styles.arrow }}
            >
              <i className={["material-icons", "notranslate"].join(" ")}>info</i>
            </Tooltip>
          ) : null}
          <h5>{article.title}</h5>
        </div>
        <p className={styles.keywords}>{article.keywords.join(" | ")}</p>
        <p>{limitStringLength(article.description, 180)}</p>
      </div>
    </div>
  )
}

export default ArticleBlock
