import { useFormikContext } from "formik"
import React, { useContext, useEffect, useState } from "react"
import { GlobalContext } from "src/App"
import Select from "src/common-components/Select/Select"
import MyTextInput from "src/common-components/TextInput/TextInput"
import { unitSelectOptions } from "src/common/consts"
import { illnessForm, LogBookEntryClient, LogBookEntryFormValues } from "src/types"
import styles from "./AddMedication.module.scss"

const getDrugOptions = (illnesses?: illnessForm[]) => {
  if (!illnesses || illnesses.length === 0) return null

  const options: { value: string }[] = []
  illnesses.forEach((illness) => {
    illness.drugs.forEach(({ name }) => options.push({ value: name }))
  })

  if (options.length === 0) return null

  options.push({ value: "Egyéb" })
  return options
}

interface Props {
  patientID: number
  medication: LogBookEntryClient["medication"]
  tookMedication: boolean
  disabled?: boolean
  setTookMedication: React.Dispatch<React.SetStateAction<boolean>>
}

const AddMedication: React.FC<Props> = ({
  patientID,
  setTookMedication,
  tookMedication,
  disabled,
  medication,
}) => {
  const { user } = useContext(GlobalContext)
  const { values, setFieldValue } = useFormikContext<LogBookEntryFormValues>()

  const patient = user.patients?.find((patient) => patient.id === patientID)

  const drugOptions = getDrugOptions(patient?.profile.illnesses)

  useEffect(() => {
    if (!drugOptions && patient) {
      setFieldValue("medication.drugIndex", "Egyéb")
    }
  }, [drugOptions, patient])

  return (
    <>
      {disabled ? (
        tookMedication ? (
          <h6 className={styles.medTitle}>Gyógyszerezés</h6>
        ) : (
          <br />
        )
      ) : (
        <p onClick={() => setTookMedication((s) => !s)} className={styles.link}>
          <i className={["material-icons", "notranslate"].join(" ")}>medical_services</i>
          <span>Gyógyszerezés {tookMedication ? "eltávolítása" : "hozzáadása"}</span>
        </p>
      )}
      {tookMedication ? (
        <div className={styles.formContainer}>
          <div>
            {disabled && medication?.drugIndex !== "Egyéb" ? (
              <div>{medication?.drugIndex || medication?.drugName}</div>
            ) : null}
            {drugOptions ? (
              <Select
                label="Gyógyszer neve"
                name="medication.drugIndex"
                selectOptions={drugOptions}
              />
            ) : null}
          </div>
          {values.medication?.drugIndex === "Egyéb" ? (
            <div className={styles.medicationContainer}>
              <MyTextInput disabled={disabled} label="Gyógyszer neve" name="medication.drugName" />
              <div className={styles.amountCont}>
                <MyTextInput
                  disabled={disabled}
                  name="medication.agentAmount"
                  label="Hatóanyag mennyissége"
                />
                <Select
                  disabled={disabled}
                  label="Mértékegység"
                  name="medication.agentUnit"
                  selectOptions={unitSelectOptions}
                />
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  )
}

export default AddMedication
