import React, { useRef, useState } from "react"
import { ImageMeta, MediaAtCreation } from "src/types"
import DeletableLabel from "../DeletableLabel/DeletableLabel"
import ShowImageFromServer from "../ShowImageFromServer/ShowImageFromServer"
import DescriptionOverlay from "./DescriptionOverlay"
import styles from "./FileUpload.module.css"

interface Props {
  files: MediaAtCreation[]
  setFiles: (files: MediaAtCreation[]) => void
  relatedTicketID: number | null
  shouldGiveDescription?: boolean
  previouslyUploadedFiles?: { id: number; name: string }[]
  deletePreviouslyUploadedFiles?: () => void
  maxNumberOfFiles?: number
  multiple?: boolean
  maxSize?: number
  buttonText?: string
}

const FileUpload: React.FC<Props> = ({
  files,
  setFiles,
  multiple = false,
  maxNumberOfFiles = 1,
  maxSize = 5,
  previouslyUploadedFiles,
  deletePreviouslyUploadedFiles,
  shouldGiveDescription = false,
  buttonText = "Feltöltés",
  relatedTicketID,
}) => {
  const [error, setError] = useState<boolean>(false)
  const [newFile, setNewFile] = useState<MediaAtCreation | null>(null) //if description is required the file is stored here while the user is giving the description
  const inputRef = useRef<HTMLInputElement>(null)
  const timeRef = useRef<number>(new Date().getTime())
  const formikRef = useRef<any>(null)

  const [descriptionOverlayVisible, setDescriptionOverlayVisible] = useState<boolean>(false)

  const canUpload =
    (previouslyUploadedFiles ? files.length + previouslyUploadedFiles.length : files.length) <
    maxNumberOfFiles

  // Creating the labels for uploaded files
  const attachments: JSX.Element[] = []

  previouslyUploadedFiles?.forEach((file, index) => {
    attachments.push(
      <ShowImageFromServer deleteAction={deletePreviouslyUploadedFiles} key={index} file={file} />,
    )
  })

  files?.forEach((file, index) => {
    const { name, description } = file.meta
    let mutatedFiles = [...files]
    mutatedFiles.splice(index, 1)
    attachments.push(
      <DeletableLabel
        key={index}
        action={() => {
          setFiles([...mutatedFiles])
          if (inputRef.current) inputRef.current.value = ""
        }}
      >
        {description || (name.length < 18 ? name : name.slice(0, 16) + "...")}
      </DeletableLabel>,
    )
  })

  return (
    <div className={styles.container}>
      <div className={styles.uploadContainer}>
        {!canUpload ? (
          <p>Elérte a feltöltési korlátot ({maxNumberOfFiles} fájl)</p>
        ) : (
          <label htmlFor={timeRef.current.toString()} className={styles.inputLabel}>
            <i className={["material-icons", "notranslate"].join(" ")}>cloud_upload</i>
            {buttonText}
          </label>
        )}
        <div className={styles.uploadedLabelsContainer}>{files ? attachments : null}</div>
      </div>
      <input
        ref={inputRef}
        id={timeRef.current.toString()}
        name="input"
        type="file"
        onChange={({ target }: any) => {
          if (target.files && target.files?.length > 0) {
            setError(false)
            const fileArray = Array.from(target.files)
            let formattedFiles: { file: File; meta: ImageMeta }[] = []
            fileArray.forEach((file: any) => {
              if (file.size < maxSize * 1048576) {
                formattedFiles.push({
                  file: file,
                  meta: {
                    format: file.name.split(".").reverse()[0],
                    createdAt: null,
                    name: file.name,
                    ticketID: relatedTicketID,
                  },
                })
                const newFiles = [...files, ...formattedFiles]
                if (shouldGiveDescription) {
                  setNewFile(formattedFiles[0]) //if description is required there can only be one file
                  setDescriptionOverlayVisible(true)
                  return
                }
                setFiles(newFiles)
              } else setError(true)
            })
            target.value = ""
          }
        }}
        accept={".jpeg, .jpg, .pdf, .png, .heic"}
        multiple={shouldGiveDescription ? false : multiple}
      />
      {error ? (
        <p className={styles.errorMsg}>
          A megadott fájlok valamelyike meghaladja a megengedett {maxSize} MB-ot
        </p>
      ) : null}
      {descriptionOverlayVisible && newFile ? (
        <DescriptionOverlay
          files={files}
          setFiles={setFiles}
          formikRef={formikRef}
          exitPopup={() => setDescriptionOverlayVisible(false)}
          newFile={newFile}
          resetNewFile={() => setNewFile(null)}
        />
      ) : null}
    </div>
  )
}

export default FileUpload
