import { ApiResponse, Message } from "src/types"
import { APIBASEURL, headersBase } from "."

export const getMessages = async (userToken: string, ticketID: number) => {
  const res = await fetch(APIBASEURL + "/messaging", {
    method: "GET",
    headers: {
      ...headersBase,
      "User-Token": userToken,
      "Ticket-ID": ticketID.toString(),
    },
  })
  const result: ApiResponse<Message[]> = await res.json()
  return result
}
