import { useFormikContext } from "formik"
import React, { useContext } from "react"
import CheckboxFormik from "../../../common-components/Checkbox/CheckboxFormik"
import MyTextInput from "../../../common-components/TextInput/TextInput"
import { necessary } from "../../../common/validationFunctions"
import { TaskDataBase } from "../../../types"
import { newTicketFormContext } from "../NewTicketPage"
import TaskCardBase from "../TaskCardBase"

export interface OtherTaskData extends TaskDataBase {
  name: "other"
  doctorData: {
    comment: string | null
    files: { id: number; name: string }[]
    documentsReceived: boolean
  }
  predefinedTypes: { [key in predefinedOtherTaskTypes]: boolean }
  userDefinedType: string
}

interface OtherTaskFormData {
  predefinedTypes: OtherTaskData["predefinedTypes"]
  userDefinedType: OtherTaskData["userDefinedType"]
}

const predefinedOtherTaskTypesArray = [
  "newborn",
  "clinical evidence",
  "dr opinion",
  "driving license",
  "other",
] as const
type predefinedOtherTaskTypesTuple = typeof predefinedOtherTaskTypesArray
export type predefinedOtherTaskTypes = predefinedOtherTaskTypesTuple[number]

export const predefinedTypesLabels: { [key in predefinedOtherTaskTypes]: string } = {
  newborn: "Újszülött (születés, Zárójelentés, TAJ szám)",
  "clinical evidence":
    "Lelet megosztás/kiértékelés (pl. Laborvizsgálat, UH, ügyeleti ambuláns lap stb.)",
  "dr opinion": "Szakorvosi vélemény feltöltése",
  "driving license": "Jogosítvány igénylése",
  other: "Egyéb",
}

const initialValues: OtherTaskFormData = {
  predefinedTypes: {
    "clinical evidence": false,
    other: false,
    "driving license": false,
    "dr opinion": false,
    newborn: false,
  },
  userDefinedType: "",
}

const validate = (v: OtherTaskFormData) => {
  let errors: Partial<OtherTaskData> = {}
  let found = false
  for (let x of Object.values(v.predefinedTypes)) {
    if (x) {
      found = true
      break
    }
  }
  if (!found) errors.predefinedTypes = {} as any
  if (v.predefinedTypes.other) necessary(v, "userDefinedType", errors)
  if (Object.keys(errors).length === 0) return
  return errors
}

interface Props {
  taskID: number
}

const OtherTaskCard: React.FC<Props> = ({ taskID }) => {
  const formContext = useContext(newTicketFormContext)
  const taskIndex = formContext.formValues.tasks.findIndex((task) => task.taskID === taskID)

  return (
    <TaskCardBase
      initialErrors={initialValues}
      initialValues={initialValues}
      title="Egyéb ügy"
      formikValidate={(v) => validate(v)}
      formContext={formContext}
      path={`tasks[${taskIndex}]`}
      gap
    >
      <OtherTaskCardContent />
    </TaskCardBase>
  )
}

const OtherTaskCardContent: React.FC = () => {
  const { values } = useFormikContext<OtherTaskFormData>()

  const checkboxElements = predefinedOtherTaskTypesArray.map((v, i) => {
    return <CheckboxFormik key={i} label={predefinedTypesLabels[v]} name={"predefinedTypes." + v} />
  })

  return (
    <>
      {checkboxElements}
      {values.predefinedTypes.other ? <MyTextInput name="userDefinedType" /> : null}
    </>
  )
}

export default OtherTaskCard
