import { PatientProfileData } from "../../../types"

const milliSecondsInADay = 24 * 60 * 60 * 1000
const milliSecondsInAWeek = 7 * milliSecondsInADay

const dayCutoffInMilliseconds = 4 * milliSecondsInADay
const weekCutoffInMilliseconds = 8 * milliSecondsInAWeek
const monthCutoff = 18

const getDateDifferenceInDays = (dEarlier: Date, dLater: Date) =>
  Math.round((dLater.getTime() - dEarlier.getTime()) / milliSecondsInADay)

const getDateDifferenceInWeeks = (dEarlier: Date, dLater: Date) =>
  Math.round((dLater.getTime() - dEarlier.getTime()) / milliSecondsInAWeek)

const getDateDifferenceInMonths = (dEarlier: Date, dLater: Date) =>
  (dLater.getFullYear() - dEarlier.getFullYear()) * 12 + dLater.getMonth() - dEarlier.getMonth()

const getDateDifferenceInYears = (dEarlier: Date, dLater: Date) => {
  const diffDate = new Date(dLater.getTime() - dEarlier.getTime())
  const fullYearDiff = diffDate.getFullYear() - 1970
  return diffDate.getMonth() < 6 ? fullYearDiff : fullYearDiff + 1
}

const getPatientBornDate = (profile: PatientProfileData) =>
  new Date(
    parseInt(profile.birthYear),
    parseInt(profile.birthMonth) - 1,
    parseInt(profile.birthDay),
  )

const getDisplayAge = (profile: PatientProfileData) => {
  const now = new Date()
  const bornAt = getPatientBornDate(profile)
  const ageInMilliSeconds = Date.now() - bornAt.getTime()

  if (ageInMilliSeconds <= dayCutoffInMilliseconds) {
    return getDateDifferenceInDays(bornAt, now) + " napos"
  }
  if (ageInMilliSeconds <= weekCutoffInMilliseconds) {
    return getDateDifferenceInWeeks(bornAt, now) + " hetes"
  }
  if (getDateDifferenceInMonths(bornAt, now) <= monthCutoff) {
    return getDateDifferenceInMonths(bornAt, now) + " hónapos"
  }
  if (getDateDifferenceInMonths(bornAt, now) < 21) {
    return "1,5 éves"
  }
  return getDateDifferenceInYears(bornAt, now) + " éves"
}

export default getDisplayAge
