import React, { useContext, useState } from "react"
import { GlobalContext } from "src/App"
import Loader from "src/common-components/Loader/Loader"
import { drHomePages, SelectedFilters, Ticket } from "src/types"
import DrTicketMinified from "./DrTicketMinified/DrTicketMinified"
import TicketFilterOptions from "./TicketFilterOptions/TicketFilterOptions"
import styles from "./TicketOverviewPage.module.css"

interface Props {
  tickets: Ticket[]
  loading?: boolean
}

const TicketOverviewPage: React.FC<Props> = ({ tickets, loading = false }) => {
  const { activePage } = useContext(GlobalContext)

  const ticketsToShow = tickets.map((ticket, index) => {
    return <DrTicketMinified key={index} ticket={ticket} />
  })

  if (loading) return <Loader />
  return activePage === drHomePages.closed && !tickets[0] ? (
    <div className={styles.noTicketMsg}>A kijelölt időszakban nem található ügy</div>
  ) : (
    <>{ticketsToShow}</>
  )
}

export default TicketOverviewPage
