import React, { useState, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { GlobalContext } from "src/App"
import GenericButton from "src/common-components/GenericButton/GenericButton"
import { deletePatient } from "src/apicalls"
import { getDisplayNameFromNames } from "src/common/utilityFunctions"
import { DeletePatientData, NewPatientHistoryType, Patient } from "src/types"
import Popup from "src/common-components/Popup/Popup"
import styles from "./PatientDataEditBlock.module.scss"
import urls from "../../common/urls"

interface Props {
  patient: Patient
}

const PatientDataEditBlock: React.FC<Props> = ({ patient }) => {
  const navigate = useNavigate()
  const [confirmPopupVisible, setConfirmPopupVisible] = useState<boolean>(false)
  const { userToken, setShouldUpdateUserDataFromServer } = useContext(GlobalContext)

  const onDelete = async () => {
    setConfirmPopupVisible(false)
    const res = await deletePatient(userToken, { patientID: patient.id } as DeletePatientData)

    if (res && res.status?.result === "ok") {
      setShouldUpdateUserDataFromServer(true)
    } else console.log(res.status) //TODO: hibaüzenet mutatása
  }

  const historyState: NewPatientHistoryType = {
    patientID: patient.id,
  }

  return (
    <div className={styles.container}>
      <p className={styles.blockTitle}>{getDisplayNameFromNames(patient.profile)}</p>
      <div className={styles.buttonContainer}>
        <GenericButton
          text="Lezárt ügyek"
          action={() =>
            navigate(
              {
                pathname: urls.userClosedTicketsPageUrl,
              },
              { state: { patient } },
            )
          }
          style="whiteBlue"
        />
        <GenericButton
          text="Adatok szerkesztése"
          action={() =>
            navigate(
              {
                pathname: urls.userNewPatientPageUrl,
              },
              { state: historyState },
            )
          }
          style="whiteBlue"
        />
        <GenericButton text="Törlés" action={() => setConfirmPopupVisible(true)} style="red" />
      </div>
      {confirmPopupVisible ? (
        <Popup
          title="Páciens törlése"
          onClose={() => setConfirmPopupVisible(false)}
          confirmButton={{
            title: "Törlés",
            buttonStyle: "red",
            action: onDelete,
          }}
          cancelButton={{
            title: "Mégse",
          }}
          panelStyle={{ minWidth: "25rem" }}
          fullscreenContainer
        >
          <p className={styles.popupContent}>Biztosan törölni szeretné a páciens adatait?</p>
        </Popup>
      ) : null}
    </div>
  )
}

export default PatientDataEditBlock
