import React from "react"
import TaskStateCornerMarker from "src/common-components/TaskStateCornerMarker/TaskStateCornerMarker"
import { Task } from "src/types"
import { getTaskDisplayName } from "../../../common/utilityFunctions"
import styles from "./UserTaskBase.module.scss"

interface Props {
  task: Task
}

const UserTaskBase: React.FC<Props> = ({ task, children }) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <p>{getTaskDisplayName(task)}</p>
      </div>
      {children}
      <TaskStateCornerMarker state={task.state} />
    </div>
  )
}

export default UserTaskBase
