import { Poster, Service } from "."
import { ChangePasswordPayload } from "../common-components/PasswordChange/PasswordChange"
import sha256 from "crypto-js/sha256"

export class PasswordChanger extends Service<any, any, ChangePasswordPayload, any> {
  constructor(userToken: string) {
    super(userToken, "change-password")
  }

  async post(payload: ChangePasswordPayload) {
    payload.newPassword = sha256(payload.newPassword).toString()
    payload.oldPassword = sha256(payload.oldPassword).toString()
    return await this.poster.post(payload)
  }
}
