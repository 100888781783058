import { useContext, useEffect, useState } from "react"
import { getSingleTicket } from "../../apicalls/getSingleTicket"
import { GlobalContext } from "../../App"
import { Doctor, Ticket } from "../../types"
import { getLoadedDrTicketByID } from "../utilityFunctions"

export const useGetTicketByID = (ticketID: number) => {
  const [loading, setLoading] = useState(false)
  const [ticket, setTicket] = useState<Ticket | null>(null)
  const { userToken, doctor, user } = useContext(GlobalContext)

  useEffect(() => {
    let ticket: Ticket | undefined
    if (Object.keys(doctor).length !== 0) ticket = getLoadedDrTicketByID(doctor.tickets, ticketID)
    else ticket = user.tickets.find((t) => t.ticketID === ticketID)

    if (ticket) {
      setTicket(ticket)
      return
    }
    setLoading(true)
    getSingleTicket(userToken, ticketID).then((res) => {
      if (res?.status.result === "ok") setTicket(res.status.message)
      setLoading(false)
    })
  }, [ticketID])

  return { loading, ticket }
}
