import { ApiResponse, PatchPatientData } from "src/types"
import { APIBASEURL, headersBase } from "."
import { createMediaInPatientData } from "./createPatient"

export const patchPatient = async (userToken: string, body: PatchPatientData) => {
  const patientData = body.patientProfile
  const mediaCreationPromises = createMediaInPatientData(userToken, patientData)
  await Promise.all(mediaCreationPromises)
  const res = await fetch(APIBASEURL + "/patient", {
    method: "PATCH",
    headers: {
      ...headersBase,
      "User-Token": userToken,
      "content-type": "application/json",
    },
    body: JSON.stringify(body),
  })
  const result: ApiResponse = await res.json()
  return result
}
