import { ApiResponse, VideoCall } from "src/types"
import { APIBASEURL, headersBase } from "."

export const getVideoCall = async (userToken: string, videoCallID: number) => {
  const res = await fetch(APIBASEURL + "/videocall", {
    method: "GET",
    headers: {
      ...headersBase,
      "User-Token": userToken,
      "VideoCall-ID": videoCallID.toString(),
    },
  })
  const result: ApiResponse<VideoCall> = await res.json()
  return result
}
