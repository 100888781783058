import { useEffect, useState } from "react"
import { assertNonExhaustiveSwitch } from "../../../common/utilityFunctions"
import {
  DiagnosisTaskData,
  Patient,
  PrescriptionTaskData,
  ReferralTaskData,
  SickNoteTaskData,
  SickPayTaskData,
  Task,
  TaskTypes,
  Ticket,
  TicketData,
} from "../../../types"
import { ticketStartData } from "../../UserHomePage/PatientBlock/NewTicketStart"
import { OtherTaskData } from "../OtherTaskCard/OtherTaskCard"

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void
  ? I
  : never

type DoctorDataBase = UnionToIntersection<Task["doctorData"]>

const createDoctorDataBase = (): DoctorDataBase => {
  return {
    comment: "",
    receivable: false,
    receivableOnline: false,
    files: [],
    diagnosis: "",
    therapy: "",
    videoCallID: null,
    date: null,
    documentsReceived: false,
  }
}

const createTaskBaseData = (taskID: number) => {
  return {
    doctorData: createDoctorDataBase(),
    finishedAt: null,
    state: "wip" as const,
    taskID,
  }
}

const createDiagnosisTaskData = (
  base: ReturnType<typeof createTaskBaseData>,
): DiagnosisTaskData => {
  return {
    ...base,
    name: "diagnosis",
    covid: false,
  }
}

const createCovidTaskData = (base: ReturnType<typeof createTaskBaseData>): DiagnosisTaskData => {
  return {
    ...base,
    name: "diagnosis",
    covid: true,
  }
}

const createPrescriptionTaskData = (
  base: ReturnType<typeof createTaskBaseData>,
): PrescriptionTaskData => {
  return {
    ...base,
    name: "prescription",
    amount: "",
    drugIndex: "",
    drugName: "",
    illnessIndex: "",
    prescriptionComment: "",
    prescriptionReason: "",
    additionalInfo: "",
  }
}

const createReferralTaskData = (base: ReturnType<typeof createTaskBaseData>): ReferralTaskData => {
  return {
    ...base,
    name: "referral",
    referralTargetDepartment: "",
    referralTargetID: "",
    referralTargetName: "",
  }
}

const createSickPayTaskData = (base: ReturnType<typeof createTaskBaseData>): SickPayTaskData => {
  return {
    ...base,
    name: "sickPay",
    endDate: null,
    startDate: null,
  }
}

const createSickNoteTaskData = (base: ReturnType<typeof createTaskBaseData>): SickNoteTaskData => {
  return {
    ...base,
    name: "sickNote",
    endDate: null,
    startDate: null,
  }
}

const createOtherTaskData = (base: ReturnType<typeof createTaskBaseData>): OtherTaskData => {
  return {
    ...{
      ...base,
      doctorData: { ...base.doctorData, documentsReceived: false },
    },
    name: "other",
    predefinedTypes: {
      "clinical evidence": false,
      "dr opinion": false,
      "driving license": false,
      newborn: false,
      other: false,
    },
    userDefinedType: "",
  }
}

export const getStartingTaskData = (taskType: keyof ticketStartData, taskId: number): Task => {
  const taskBase = createTaskBaseData(taskId)

  switch (taskType) {
    case "covid":
      return createCovidTaskData(taskBase)
    case "diagnosis":
      return createDiagnosisTaskData(taskBase)
    case "prescription":
      return createPrescriptionTaskData(taskBase)
    case "referral":
      return createReferralTaskData(taskBase)
    case "sickNote":
      return createSickNoteTaskData(taskBase)
    case "sickPay":
      return createSickPayTaskData(taskBase)
    case "other":
      return createOtherTaskData(taskBase)
    default:
      return assertNonExhaustiveSwitch(taskType)
  }
}

const getStartingTaskId = (ticket: Ticket | undefined) => {
  const oldTasks = ticket?.ticketData.tasks || []
  return oldTasks.reduce((acc, curr) => Math.max(acc, curr.taskID), 0) + 1
}

const getInitialTaskList = (ticketStartData: ticketStartData, startingTaskId: number) => {
  let tasks: Task[] = []
  Object.entries(ticketStartData).forEach(([key, value], index) => {
    if (!value) return

    const typedKey = key as keyof ticketStartData
    const taskID = startingTaskId + index

    tasks.push(getStartingTaskData(typedKey, taskID))
  })
  return tasks
}

export const useNewTicketFormValues = (
  patient: Patient,
  ticket: Ticket | undefined,
  ticketStartData: ticketStartData,
) => {
  const startingTaskId = getStartingTaskId(ticket)

  const [formValues, setFormValues] = useState<TicketData>({
    targetDrID: patient.profile.GP?.id || null,
    tasks: [],
    comment: "",
    outpatientData: null,
    files: [],
    symptoms: [],
  })

  return [formValues, setFormValues] as const
}
