import React from "react"
import { limitStringLength } from "src/common/utilityFunctions"
import styles from "./AttachmentLabel.module.css"

interface Props {
  onClick: () => void
  deleteAction?: () => void
  labelText: string
}

const AttachmentLabel: React.FC<Props> = ({ onClick, deleteAction, labelText }) => {
  return (
    <div className={styles.link}>
      <i onClick={onClick} className={["material-icons", "notranslate"].join(" ")}>
        attachment
      </i>
      <p onClick={onClick} id={styles.label}>
        {limitStringLength(labelText, 20)}
      </p>
      {deleteAction ? (
        <i onClick={() => deleteAction()} className={["material-icons", "notranslate"].join(" ")}>
          clear
        </i>
      ) : null}
    </div>
  )
}

export default AttachmentLabel
