import React from "react"
import GenericButton from "../../common-components/GenericButton/GenericButton"
import { ConsultationTaskData, PersonalConsultationTaskData, Task } from "../../types"
import styles from "./DoctorTasksComponents.module.scss"

export const didPersonalConsultationHappen = (t: PersonalConsultationTaskData) => {
  return t.doctorData.date && t.doctorData.date! < Date.now() / 1000
}

const isDateGiven = (t: ConsultationTaskData) => {
  return (
    (t.type === "personal" && t.doctorData.date) || (t.type === "video" && t.doctorData.videoCallID)
  )
}

interface RejectButtonProps {
  task: Task
  openRejectWindow: () => void
  isTicketReady: boolean
}

interface MainActionButtonProps {
  task: Task
  mainAction: () => void
  doesTicketContainConsultationTask: boolean
}

interface TaskStateProps {
  taskState: Task["state"]
}

export class DoctorTasksComponents {
  static OpenRejectWindowButton: React.FC<RejectButtonProps> = ({
    task,
    openRejectWindow,
    isTicketReady,
  }) => {
    const shouldShowRejectButton = (() => {
      return (
        task.state !== "ready"
        // &&
        // !(
        //   task.name === "consultation" &&
        //   task.type === "personal" &&
        //   didPersonalConsultationHappen(task)
        // )
      )
    })()

    if (!shouldShowRejectButton) return null

    let rejectText =
      task.state === "rejected" ? (isTicketReady ? "Megtekintés" : "Szerkesztés") : "Elutasítás"
    return (
      <GenericButton
        action={openRejectWindow}
        text={rejectText}
        style={task.state === "rejected" ? "blue" : "red"}
      />
    )
  }

  static MainActionButton: React.FC<MainActionButtonProps> = ({
    task,
    mainAction,
    doesTicketContainConsultationTask,
  }) => {
    const shouldShowButton = (() => {
      if (task.name === "consultation" && (task.state === "ready" || task.state === "rejected"))
        return false
      return true
    })()

    if (!shouldShowButton) return null

    const acceptButtonText = (() => {
      if (task.name === "consultation") {
        if (task.type === "personal" && didPersonalConsultationHappen(task))
          return "Sikeres konzultáció"
        return isDateGiven(task) ? "Időpont szerkesztése" : "Időpont megadása"
      }
      return task.state === "ready" ? "Megtekintés" : "Befejezés"
    })()

    return (
      <GenericButton
        action={mainAction}
        text={acceptButtonText}
        style="blue"
        className={doesTicketContainConsultationTask ? styles.acceptButton : undefined} //videocall task button text is longer --> make the buttons have same length
      />
    )
  }

  static TaskState: React.FC<TaskStateProps> = ({ taskState }) => {
    if (taskState === "ready")
      return (
        <div className={styles.icon}>
          <i className={["material-icons", "notranslate"].join(" ")}>checked</i>
        </div>
      )

    if (taskState === "rejected")
      return (
        <div className={`${styles.icon} ${styles.rejected}`}>
          <i className={["material-icons", "notranslate"].join(" ")}>close</i>
        </div>
      )

    return null
  }
}
