import React, { useContext } from "react"
import MyTextInput from "src/common-components/TextInput/TextInput"
import { necessary } from "src/common/validationFunctions"
import { ReferralFormData } from "src/types"
import { createFormikPathsObj } from "../../../common/utilityFunctions"
import { newTicketFormContext } from "../NewTicketPage"
import TaskCardBase from "../TaskCardBase"

const initialValues: ReferralFormData = {
  referralTargetDepartment: "",
  referralTargetID: "",
  referralTargetName: "",
}

const formikPaths = createFormikPathsObj<ReferralFormData>(initialValues)

const initialErrors = initialValues

const validate = (values: ReferralFormData) => {
  let errors = {} as ReferralFormData
  necessary(values, formikPaths.referralTargetName, errors)
  necessary(values, formikPaths.referralTargetDepartment, errors)
  if (values.referralTargetID && values.referralTargetID.length !== 9) {
    errors.referralTargetID = "Helytelen azonosító (9 jegyű)"
  }
  return errors
}
const ReferralTaskCard: React.FC<{ taskID: number }> = ({ taskID }) => {
  const formContext = useContext(newTicketFormContext)
  const taskIndex = formContext.formValues.tasks.findIndex((task) => task.taskID === taskID)
  return (
    <TaskCardBase
      initialValues={initialValues}
      initialErrors={initialErrors}
      title="Beutaló igényléséhez szükséges információk"
      formikValidate={validate}
      formContext={formContext}
      path={`tasks[${taskIndex}]`}
      gap
    >
      <MyTextInput
        label="Melyik intézménybe szóljon a beutaló?"
        name={formikPaths.referralTargetName}
        maxLength={50}
      />
      <MyTextInput
        label="Milyen szakrendelésre kéri a beutalót?"
        name={formikPaths.referralTargetDepartment}
      />
      <MyTextInput
        label="Intézményazonosító"
        name={formikPaths.referralTargetID}
        optional
        tooltip="A szakrendelésre való időpont kérésekor megkérdezhetik, ezáltal gördülékenyebben működik a beutalás az adott intézménybe."
      />
    </TaskCardBase>
  )
}

export default ReferralTaskCard
