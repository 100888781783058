const built = process.env.NODE_ENV === "production"
export const dummyDrToken = process.env.REACT_APP_DUMMY_DOCTOR_TOKEN
export const dummyUserToken = process.env.REACT_APP_DUMMY_USER_TOKEN

export const config = {
  built,
  API_URL: process.env.REACT_APP_API_URL,
  API_KEY: process.env.REACT_APP_API_KEY,
  LOGIN_PAGE_URL: process.env.REACT_APP_LOGIN_PAGE_URL,
  SUPPORT_EMAIL: process.env.REACT_APP_SUPPORT_EMAIL,
  INFO_UPDATE_INTERVAL: parseInt(process.env.REACT_APP_INFO_UPDATE_INTERVAL),
  ACCESSIBLE_FROM_MOBILE: process.env.REACT_APP_ACCESSIBLE_FROM_MOBILE === "true",
  REDIRECT_ON_INVALID_USERTOKEN: process.env.REACT_APP_REDIRECT_ON_INVALID_USERTOKEN === "true",
}
