import React from "react"
import { createImage } from "../../../apicalls/createImage"
import {
  getFormatFromFilename,
  getTaskDisplayName,
  toBase64,
} from "../../../common/utilityFunctions"
import { AssignedMedia, MediaAtCreation, Task, Ticket } from "../../../types"
import SetPersonalConsultationAppointment from "./FinishConsultationTask/SetPersonalConsultationAppointment"
import SetVideoConsultationAppointment from "./FinishConsultationTask/SetVideoConsultationAppointment"
import FinishDiagnosisTask from "./FinishDiagnosisTask"
import FinishFileUploadTask from "./FinishFileUploadTask"
import FinishOtherTasks from "./FinishOtherTask"
import styles from "./styles.module.scss"

interface Props {
  task: Task
  ticket: Ticket
  setShowFinishTaskWindow: React.Dispatch<React.SetStateAction<"reject" | "finish" | null>>
  taskIndex: number
}

export const FinisherContainer: React.FC<{ task: Task; closeWindow: () => void }> = ({
  closeWindow,
  task,
  children,
}) => {
  return (
    <div className={styles.finisherContainer}>
      <div className={styles.header}>
        {getTaskDisplayName(task)}
        <i onClick={closeWindow} className="material-icons notranslate">
          close
        </i>{" "}
      </div>
      {children}
    </div>
  )
}

const FinishTaskDialogElements: React.FC<Props> = ({
  task,
  ticket,
  setShowFinishTaskWindow,
  taskIndex,
}) => {
  switch (task.name) {
    case "diagnosis": {
      return (
        <FinishDiagnosisTask
          setOpenFinishTaskWindow={setShowFinishTaskWindow}
          taskIndex={taskIndex}
          ticket={ticket}
        />
      )
    }
    case "consultation": {
      switch (task.type) {
        case "video": {
          return (
            <SetVideoConsultationAppointment
              closeWindow={() => setShowFinishTaskWindow(null)}
              taskIndex={taskIndex}
              ticket={ticket}
            />
          )
        }
        case "personal": {
          return (
            <SetPersonalConsultationAppointment
              closeWindow={() => setShowFinishTaskWindow(null)}
              taskIndex={taskIndex}
              ticket={ticket}
            />
          )
        }
      }
    }
    case "other": {
      return (
        <FinishOtherTasks
          ticket={ticket}
          setOpenFinishTaskWindow={setShowFinishTaskWindow}
          taskIndex={taskIndex}
        />
      )
    }
    default: {
      return (
        <FinishFileUploadTask
          setOpenFinishTaskWindow={setShowFinishTaskWindow}
          taskIndex={taskIndex}
          ticket={ticket}
        />
      )
    }
  }
}

/***************** MAIN ELEMENT ******************/

export const FinishTaskDialog: React.FC<Props> = ({
  task,
  ticket,
  taskIndex,
  setShowFinishTaskWindow,
}) => {
  return (
    <FinisherContainer closeWindow={() => setShowFinishTaskWindow(null)} task={task}>
      <FinishTaskDialogElements
        setShowFinishTaskWindow={setShowFinishTaskWindow}
        task={task}
        taskIndex={taskIndex}
        ticket={ticket}
      />
    </FinisherContainer>
  )
}

/*************** FUNCTIONS *******************/

export const saveImage = async (
  file: File | MediaAtCreation,
  ticket: Ticket,
  userToken: string,
): Promise<AssignedMedia> => {
  const fileData = (file as any).file ? (file as MediaAtCreation).file : (file as File)
  const image = await toBase64(fileData)
  const imageUploadResponse = await createImage(userToken, {
    meta: (file as MediaAtCreation).meta || {
      format: getFormatFromFilename(fileData.name),
      name: fileData.name,
      createdAt: null,
      ticketID: ticket.ticketID,
    },
    data: image as string,
  })
  const imageID = imageUploadResponse.status!.message.imageID //TODO: bad resp check
  return { id: imageID, name: fileData.name }
}

export const setTaskDataToFinished = (task: Task) => {
  task.finishedAt = Math.round(Date.now() / 1000)
  task.state = "ready"
}

export const setTaskDataToRejected = (task: Task) => {
  task.finishedAt = Math.round(Date.now() / 1000)
  task.state = "rejected"
}
