import React, { useContext } from "react"
import { Location, useLocation, useNavigate } from "react-router-dom"
import { GlobalContext } from "src/App"
import { Patient, Ticket } from "src/types"
import ClosedTicketView from "./ClosedTicketView/ClosedTicketView"
import styles from "./UserClosedTicketsPage.module.css"

const UserClosedTicketsPage: React.FC = () => {
  const { user } = useContext(GlobalContext)

  const navigate = useNavigate()
  const location = useLocation() as Location & { state: { patient: Patient } }
  let tickets: Ticket[] = []

  if (location.state.patient.id) {
    const patientID = location.state.patient.id
    tickets = user.tickets.filter((t) => t.patientID === patientID && t.ready)
  }

  if (tickets.length > 0) {
    // generate ticket list
    const ticketList = tickets.map((t) => (
      <ClosedTicketView key={"closed-ticket-view-" + t.ticketID.toString()} ticket={t} />
    ))

    return (
      <>
        <div className={styles.container}>
          <h2>Lezárt ügyek</h2>
        </div>
        <>{ticketList}</>
      </>
    )
  }
  return (
    <div className={styles.container}>
      <h2>Lezárt ügyek</h2>
      <p>Ennek a páciensnek még nincsenek lezárt ügyei.</p>
    </div>
  )
}

export default UserClosedTicketsPage
