import { useFormikContext } from "formik"
import React from "react"
import Select from "src/common-components/Select/Select"
import MyTextInput from "src/common-components/TextInput/TextInput"
import { Patient } from "src/types"
import ExtraInfoInput from "./ExtraInfoInput"
import WithoutSicknessInputs, { PrescriptionTypeInput } from "./WithoutSicknessInputs"

interface Props {
  illnesses: Patient["profile"]["illnesses"]
}

const YesPatientIllness: React.FC<Props> = ({ illnesses }) => {
  const { values } = useFormikContext<{
    illnessIndex: string
    drugIndex: string
  }>()

  const illnessNames = illnesses!.map((illness, index) => {
    return { value: index, text: illness.illnessName }
  })

  const selectedIllnessIndex = parseInt(values.illnessIndex)
  let drugNames: { value: number; text: string }[] = []
  if (illnesses[selectedIllnessIndex] && illnesses[selectedIllnessIndex].drugs) {
    illnesses[selectedIllnessIndex].drugs.forEach((drug, index) => {
      drugNames.push({ value: index, text: drug.name })
    })
  }

  const getTextArea = () => {
    if (values.illnessIndex === "Egyéb") {
      return <WithoutSicknessInputs />
    } else if (values.drugIndex === "Egyéb") {
      return (
        <>
          <PrescriptionTypeInput />
          <ExtraInfoInput />
        </>
      )
    }
    return <ExtraInfoInput />
  }

  const textArea = getTextArea()

  return (
    <>
      <Select
        label="Receptkérés oka"
        name="illnessIndex"
        selectOptions={[...illnessNames, { value: "Egyéb" }]}
      />
      {values.illnessIndex === "Egyéb" ? null : (
        <Select
          label="Gyógyszer"
          name="drugIndex"
          selectOptions={[...drugNames, { value: "Egyéb" }]}
        />
      )}
      {textArea}
    </>
  )
}

export default YesPatientIllness
