import { ApiResponse, VideoCall } from "src/types"
import { APIBASEURL, headersBase } from "."

export const patchVideoCall = async (
  userToken: string,
  body: { id: number } & Partial<VideoCall>,
) => {
  const res = await fetch(APIBASEURL + "/videocall", {
    method: "PATCH",
    headers: {
      ...headersBase,
      "User-Token": userToken,
      "content-type": "application/json",
    },
    body: JSON.stringify(body),
  })
  const result: ApiResponse<VideoCall> = await res.json()
  return result
}
