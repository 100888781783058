import { useFormikContext } from "formik"
import React, { useContext, useEffect } from "react"
import CheckboxFormik from "../../../common-components/Checkbox/CheckboxFormik"
import { Ticket } from "../../../types"
import { ticketStartData } from "../../UserHomePage/PatientBlock/NewTicketStart"
import { newTicketFormContext } from "../NewTicketPage"
import TaskCardBase from "../TaskCardBase"
import {
  addNewTaskToTaskList,
  checkIfTaskExistsInFormValues,
  doesTaskExistInFormValues,
  getActiveTasks,
  removeTasksFromTaskList,
} from "./utils"

interface Props {
  ticket?: Ticket
}

const initialValues: ticketStartData = {
  covid: false,
  diagnosis: false,
  other: false,
  prescription: false,
  referral: false,
  sickNote: false,
  sickPay: false,
}

const validate = (values: ticketStartData) => {
  if (Object.values(values).every((v) => !v)) return { dummy: "error" } as any
  // this works as we dont want to display the error under a given checkbox
  // so it does not matter that formik doesn't know what to do with this value,
  // we will handle error display ourselves, we will only need formik to invalidate the form
  return
}

const TaskChooseCard: React.FC<Props> = ({ ticket }) => {
  const formContext = useContext(newTicketFormContext)

  return (
    <TaskCardBase
      initialValues={initialValues}
      initialErrors={initialValues}
      title="Válassza ki, milyen ügyet szeretne indítani"
      formikValidate={validate}
      formContext={formContext}
      dontSaveToFormContext
      gap
    >
      <Checkboxes ticket={ticket} />
    </TaskCardBase>
  )
}

const useSyncTasksWithFormValues = () => {
  const { formValues, setFormValues } = useContext(newTicketFormContext)
  const { values } = useFormikContext<typeof initialValues>()

  useEffect(() => {
    Object.entries(values).forEach(([key, value]) => {
      const typedKey = key as keyof ticketStartData
      if (value && !doesTaskExistInFormValues(typedKey, formValues.tasks)) {
        const newTaskList = addNewTaskToTaskList(typedKey, formValues.tasks)
        setFormValues((s) => {
          return { ...s, tasks: newTaskList }
        })
      } else if (!value && doesTaskExistInFormValues(typedKey, formValues.tasks)) {
        const newTaskList = removeTasksFromTaskList(typedKey, formValues.tasks)
        setFormValues((s) => {
          return { ...s, tasks: newTaskList }
        })
      }
    })
  }, [values])

  return formValues
}

const Checkboxes: React.FC<{ ticket?: Ticket }> = ({ ticket }) => {
  const formValues = useSyncTasksWithFormValues()

  const ongoingTasks = getActiveTasks(ticket?.ticketData.tasks)

  const canStartDiagnosisTask = !ongoingTasks?.diagnosis
  const canStartCovidTask = !ongoingTasks?.covid

  return (
    <>
      {canStartDiagnosisTask && <CheckboxFormik name="diagnosis" label="Diagnózis" />}
      <CheckboxFormik name="sickNote" label="Igazolás" />
      <CheckboxFormik name="sickPay" label="Táppénz" />
      <CheckboxFormik name="prescription" label="Recept" />
      <CheckboxFormik name="referral" label="Beutaló" />
      {canStartCovidTask && <CheckboxFormik name="covid" label="Covid" />}
      <CheckboxFormik name="other" label="Egyéb" />
    </>
  )
}

export default TaskChooseCard
