import { Formik } from "formik"
import React from "react"
import MyTextInput from "../../common-components/TextInput/TextInput"
import { mergeDeep } from "../../common/utilityFunctions"
import { necessary } from "../../common/validationFunctions"
import { DoctorProfile, DoctorProfileSecondCard } from "../../types"
import AddressTextInputs from "../../user-components/NewPatientPage/NewPatientForm/AddressFormSlide/AddressTextInputs"
import styles from "./CreateDrExtProfSecondCard.module.css"
import Header from "./Header"

export const validateSecondPage = (values: DoctorProfile) => {
  let officeAddressErrors = {}
  necessary(values.officeAddress, "country", officeAddressErrors)
  necessary(values.officeAddress, "region", officeAddressErrors)
  necessary(values.officeAddress, "city", officeAddressErrors)
  necessary(values.officeAddress, "zipCode", officeAddressErrors)
  necessary(values.officeAddress, "street", officeAddressErrors)
  necessary(values.officeAddress, "streetNum", officeAddressErrors)

  return Object.keys(officeAddressErrors).length > 0 ? { officeAddress: officeAddressErrors } : {}
}

interface Props {
  setFormState?: React.Dispatch<React.SetStateAction<number>>
}

const CreateDrExtProfSecondCard: React.FC<Props> = ({ setFormState }) => {
  return (
    <>
      {setFormState ? <Header title="Kórház / rendelő címe" setFormState={setFormState} /> : null}
      <AddressTextInputs baseName="officeAddress" />
      <MyTextInput label="Körzet (opcionális)" type="text" name="zone" tooltip="Körzet" />
    </>
  )
}

export default CreateDrExtProfSecondCard
