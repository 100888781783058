import React, { useContext } from "react"
import { logout } from "src/apicalls/logout"
import { GlobalContext } from "src/App"
import styles from "./LogOutButton.module.scss"

const LogOutButton = () => {
  const { userToken } = useContext(GlobalContext)
  return (
    <button onClick={() => logout(userToken)} className={styles.button}>
      Kijelentkezés
    </button>
  )
}

export default LogOutButton
