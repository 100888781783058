import { useEffect, useRef, useState } from "react"
import SimplePeer from "simple-peer"
import { getVideoCall } from "src/apicalls/getVideoCall"
import { isCallInitiator } from "src/common/utilityFunctions"
import { VideoCall } from "src/types"

export const useTryToGetAnswer = (args: {
  videoCall: VideoCall | null
  userToken: string
  p: SimplePeer.Instance
  isDoctor: boolean
  createPeer: (initiator: boolean, videoCall: VideoCall | null) => void
  setVideoCall: (vc: VideoCall) => void
}): [boolean, React.Dispatch<React.SetStateAction<boolean>>] => {
  const { p, videoCall, userToken, isDoctor, createPeer, setVideoCall } = args
  const [isWaitingForAnswer, setIsWaitingForAnswer] = useState<boolean>(false)
  const intervalRef = useRef<NodeJS.Timeout>()
  useEffect(() => {
    if (videoCall && isCallInitiator(isDoctor, videoCall)) {
      const tryToGetAnswer = async () => {
        if (videoCall && isWaitingForAnswer) {
          const res = await getVideoCall(userToken, videoCall.id)
          const vc = res.status.message
          setVideoCall(vc)
          if (vc.reverseRoles && isDoctor) {
            createPeer(isCallInitiator(isDoctor, vc), vc)
            setIsWaitingForAnswer(false)
          } else if (res.status.message.answer) {
            p.signal(res.status.message.answer)
            setIsWaitingForAnswer(false)
          }
        }
      }
      intervalRef.current = setInterval(() => tryToGetAnswer(), 1000)
    }
    return () => (intervalRef.current ? clearInterval(intervalRef.current) : undefined)
  }, [isWaitingForAnswer, videoCall])
  return [isWaitingForAnswer, setIsWaitingForAnswer]
}
