const userHomepageUrl = "/"
const userNewPatientPageUrl = `/new_patient`
const userNewTicketPageUrl = `/new_ticket`
const userTicketPageUrl = `/ticket`
const userClosedTicketsPageUrl = "/closed"
const profilePageUrl = `/profile`
const editProfilePageUrl = "/edit_profile"
const logBookUrl = "/log_book"
const articlePageUrl = "/articles"

export default {
  userClosedTicketsPageUrl,
  userHomepageUrl,
  userNewPatientPageUrl,
  userNewTicketPageUrl,
  userTicketPageUrl,
  profilePageUrl,
  editProfilePageUrl,
  logBookUrl,
  articlePageUrl,
}
