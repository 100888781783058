import { FieldAttributes, useField, useFormikContext } from "formik"
import React from "react"
import styles from "./RadioButton.module.css"

type RadioButtonProps = {
  options: { label: string; value: string | boolean }[]
  customOnClick?: Function
  customValue?: any
  callback?: (value?: any) => void
  vertical?: boolean
} & FieldAttributes<{}>

const RadioButton: React.FC<RadioButtonProps> = ({
  options,
  customOnClick,
  customValue,
  callback,
  vertical,
  ...props
}) => {
  const [field] = useField<{}>(props)
  const { setFieldValue } = useFormikContext()

  const content = options.map(({ label, value }, index) => {
    const className =
      value === (customValue !== undefined ? customValue : field.value)
        ? styles.checked
        : styles.unchecked
    return (
      <div
        className={vertical ? styles.wideOptionCont : styles.optionCont}
        key={index}
        onClick={
          customOnClick
            ? () => customOnClick(value)
            : () => {
                setFieldValue(props.name, value)
                callback?.(value)
              }
        }
      >
        <label>{label}</label>
        <div className={className}>
          <i className={["material-icons", "notranslate"].join(" ")}>done</i>
        </div>
      </div>
    )
  })

  return (
    <div className={vertical ? styles.verticalRadioOptions : styles.radioOptions}>{content}</div>
  )
}

export default RadioButton
