import React, { useContext } from "react"
import { patchTicket } from "src/apicalls"
import { GlobalContext } from "src/App"
import GenericButton from "src/common-components/GenericButton/GenericButton"
import TicketStatus from "src/common-components/TicketStatus/TicketStatus"
import { symptomsMap } from "src/common/symptoms"
import {
  getDateFromInt,
  getTaskDisplayName,
  getTicketStatusFromTicket,
  givenNumberOfDigits,
  limitStringLength,
} from "src/common/utilityFunctions"
import { Ticket } from "src/types"
import OVIcon from "../../../common-components/Icon/OVIcon"
import { useIsMobileSize } from "../../../common/hooks/useIsMobileSize"
import { useOVNavigator } from "../../../common/hooks/useOVNavigator"
import styles from "./InProgressTicket.module.scss"

interface Props {
  ticket: Ticket
}

const InProgressTicket: React.FC<Props> = ({ ticket }) => {
  const { userToken, setShouldUpdateUserDataFromServer } = useContext(GlobalContext)
  const tasks = ticket.ticketData.tasks
  const ticketData = ticket.ticketData

  const OVNavigator = useOVNavigator()

  const isMobile = useIsMobileSize()

  const toModifyTicketPage = () => {
    OVNavigator.toModifyTicketPage(ticket.ticketID)
  }

  const labels = tasks.map((task, index) => {
    return (
      <div key={index} className={styles.label}>
        {getTaskDisplayName(task)}
      </div>
    )
  })

  const getSymptomsString = () => {
    if (ticketData.symptoms && ticketData.symptoms.length > 0) {
      const symptomsString = ticketData.symptoms.map((symptom, index) => {
        const id = symptom.symptomID
        if (index === 0) {
          return symptomsMap.get(id)
        } else return " " + symptomsMap.get(id)
      })
      return symptomsString
    }
  }

  const archiveTicket = async (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e?.stopPropagation()
    const res = await patchTicket(userToken, {
      ticketID: ticket.ticketID,
      archived: true,
    })
    if (res.status?.result === "ok") {
      setShouldUpdateUserDataFromServer(true)
    } else {
      console.log(res)
    }
  }

  const symptomsString = getSymptomsString()

  return (
    <div
      className={styles.container}
      onClick={isMobile ? () => OVNavigator.toTicket(ticket.ticketID) : undefined}
    >
      {isMobile && ticket.ready ? (
        <OVIcon name="close" className={styles.mobileClose} onClick={archiveTicket} />
      ) : null}
      <div className={styles.leftColumnContainer}>
        <div className={styles.topLine}>
          <h5>
            {limitStringLength(
              `#${givenNumberOfDigits(3, ticket.ticketID)}` +
                (symptomsString ? ` - ${symptomsString}` : " - Adminisztráció"),
              isMobile ? 40 : 54,
            )}
          </h5>
          <TicketStatus status={getTicketStatusFromTicket(ticket)} />
        </div>
        <div className={`${styles.dateCont} midsizedGreyText`}>
          <i className={["material-icons", "notranslate"].join(" ")}>access_time</i>
          <p>{getDateFromInt(ticketData.createdAt!)}</p>
        </div>
        <div className={styles.labelCont}>{labels}</div>
      </div>
      {isMobile ? null : (
        <div className={styles.rightColumnContainer}>
          {ticket.ready ? (
            <GenericButton
              style="grey"
              action={archiveTicket}
              text="Elrejtés"
              className={styles.button}
            />
          ) : (
            <GenericButton
              style="whiteBlue"
              action={toModifyTicketPage}
              text="Új igény"
              className={styles.button}
            />
          )}
          <GenericButton
            style="blue"
            action={() => OVNavigator.toTicket(ticket.ticketID)}
            text="Megnyitás"
            className={styles.button}
          />
        </div>
      )}
    </div>
  )
}

export default InProgressTicket
