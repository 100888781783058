import React from "react"
import { useContext } from "react"
import { GlobalContext } from "../../../App"
import NewNotiCirc from "./NewNotiCirc"
import styles from "./PageSelectButton.module.scss"

interface Props {
  setActivePage: () => void
  text: string
  newNoti: number
}

const PageSelectButton: React.FC<Props> = ({ setActivePage, text, newNoti }) => {
  const { doctor } = useContext(GlobalContext)

  if (Object.keys(doctor).length === 0) return null

  return (
    <div onClick={setActivePage} className={styles.button}>
      <div>
        <NewNotiCirc numOfNotifications={newNoti} />
      </div>
      {text}
    </div>
  )
}

export default PageSelectButton
