import { ApiResponse } from "../types"

export class InvalidApiDataError extends Error {
  name: string = "InvalidApiDataError"
  staticDescription: string = "Data received from the server is not in the expected format"

  constructor(description: string) {
    super(description)
  }
}

export class InvalidRequestError extends Error {
  name = "InvalidRequest"
  staticDescription = "Data sent to the server is not valid"
  serverResponse: ApiResponse<any>

  constructor(response: any) {
    super()
    this.serverResponse = response
  }
}

export class ServerError extends Error {
  name = "ServerError"
  staticDescription = "Server is down or responded with 500"
  restError: any

  constructor(restError: any, description?: string) {
    super(description)
    this.restError = restError
  }
}
