import React from "react"
import { drHomePages } from "src/types"
import PageSelectButton from "./PageSelectButton"
import styles from "./PageSelector.module.scss"

interface Props {
  activePage: drHomePages
  cases: {
    inProgress: number
    incoming: number
    open: number
  }
  setActivePage: React.Dispatch<React.SetStateAction<drHomePages>>
}

const PageSelector: React.FC<Props> = ({ activePage, setActivePage, cases }) => {
  const wantsOutsideClients = true

  const getLinePositionClassName = () => {
    if (activePage === drHomePages.inProgress) return "first"
    if (activePage === drHomePages.incoming) return "second"
    if (activePage === drHomePages.open || !wantsOutsideClients) return "third"
    return "fourth"
  }

  const linePositionClassName = getLinePositionClassName()

  return (
    <div className={styles.container}>
      <div className={styles.firstButtonCont}>
        <PageSelectButton
          text="Folyamatban"
          setActivePage={() => setActivePage(drHomePages.inProgress)}
          newNoti={cases.inProgress}
        />
        <div className={`${styles.line} ${styles[linePositionClassName]}`}></div>
      </div>
      <PageSelectButton
        text="Új saját ügyek"
        setActivePage={() => setActivePage(drHomePages.incoming)}
        newNoti={cases.incoming}
      />
      {wantsOutsideClients ? (
        <PageSelectButton
          text="Összes új ügy"
          setActivePage={() => setActivePage(drHomePages.open)}
          newNoti={cases.open}
        />
      ) : null}
      <PageSelectButton
        text="Lezárt ügyek"
        setActivePage={() => setActivePage(drHomePages.closed)}
        newNoti={0}
      />
    </div>
  )
}

export default PageSelector
