import React from "react"
import { useState } from "react"
import { useContext } from "react"
import { patchTicket } from "../../../../apicalls"
import { createVideoCall } from "../../../../apicalls/createVideoCall"
import { patchVideoCall } from "../../../../apicalls/patchVideoCall"
import { GlobalContext } from "../../../../App"
import { canEditConsultationDate, getNameSubset } from "../../../../common/utilityFunctions"
import { Ticket, VideoConsultationTaskData } from "../../../../types"
import SetAppointmentDate from "./SetAppointmentDate"
import { getInitialConsultationDate } from "./SetPersonalConsultationAppointment"
import styles from "./SetVideoConsultationAppointment.module.css"

interface Props {
  ticket: Ticket
  closeWindow: () => void
  taskIndex: number
}

const SetVideoConsultationAppointment: React.FC<Props> = ({ ticket, closeWindow, taskIndex }) => {
  let task = ticket.ticketData.tasks[taskIndex] as VideoConsultationTaskData
  if (task.name !== "consultation" || task.type !== "video")
    throw "SetVideoConsultationAppointment component can't handle task with type: " + task.name

  const { setShouldUpdateUserDataFromServer, userToken, doctor } = useContext(GlobalContext)

  const videoCall = doctor.videoCalls.find(
    (v) => task.name === "consultation" && v.id === task.doctorData.videoCallID,
  )

  const [date, setDate] = useState(() => getInitialConsultationDate(videoCall?.date))

  const isDateChangable = !videoCall || canEditConsultationDate(videoCall.date)

  const submit = async () => {
    if (!ticket.userProfile) {
      throw "Missing userProfile in ticket"
    }
    if (!videoCall) {
      const userName = getNameSubset(ticket.userProfile)
      const doctorName = getNameSubset(doctor.userProfile)
      const {
        status: { message },
      } = await createVideoCall(userToken, {
        date: date.getTime() / 1000,
        doctorName,
        userName,
        ticketID: ticket.ticketID,
        userID: ticket.userID,
      })
      task.doctorData.videoCallID = message.id
      const res = await patchTicket(userToken, {
        ticketID: ticket.ticketID,
        ticketData: ticket.ticketData,
      })
    } else {
      videoCall.date = Math.round(date.getTime() / 1000)
      const res = await patchVideoCall(userToken, videoCall)
    }
    setShouldUpdateUserDataFromServer(true)
    closeWindow()
  }

  return (
    <SetAppointmentDate
      date={date}
      submit={submit}
      setDate={setDate}
      isEditing={!!videoCall}
      isDateChangable={isDateChangable}
    />
  )
}

export default SetVideoConsultationAppointment
