import React from "react"
import { PatientDataOverviewLabelValuePairs } from "src/types"
import styles from "./FormDataReviewRow.module.css"

interface Props {
  labelValuePair: PatientDataOverviewLabelValuePairs
  color?: "white" | "blue"
}

const FormDataReviewRow: React.FC<Props> = ({ labelValuePair, color = "white" }) => {
  if (!labelValuePair.value) return null
  return (
    <div className={styles.row + " " + styles[color]}>
      <p>{labelValuePair.label}</p>
      <div>{labelValuePair.value}</div>
    </div>
  )
}

export default FormDataReviewRow
