import React, { useContext } from "react"
import { GlobalContext } from "../../App"
import { getDisplayNameFromNames } from "../../common/utilityFunctions"
import styles from "./DrVerificationPendingPage.module.css"
const DrVerificationPendingPage: React.FC = () => {
  const { doctor } = useContext(GlobalContext)

  const displayName = getDisplayNameFromNames(doctor.userProfile)

  return (
    <div className={styles.container}>
      <h2>Tisztelt {displayName}!</h2>
      <h3 className={styles.thankMsg}>Köszönjük a regisztrációt</h3>
      <h4 className={styles.checkingMsg}>A megadott adatok ellenőrzése folyamatban van.</h4>
      <p>
        Az adatok ellenőrzése maximum <strong>5</strong> munkanapot vesze igénybe.
      </p>
      <p>
        Az ellenőrzés végeztével email-ben értesítjük Önt, és sikeresség esetén innentől tudja
        használni a platform összes funkcióját.
      </p>
    </div>
  )
}
export default DrVerificationPendingPage
