import React from "react"
import { mergeDeep } from "../../common/utilityFunctions"
import { DoctorProfile, DoctorProfileSecondCard, DoctorProfileThirdCard } from "../../types"
import styles from "./Header.module.css"

interface Props {
  setFormState?: (value: React.SetStateAction<number>) => void
  title: string
}

const Header: React.FC<Props> = ({ setFormState, title }) => {
  return (
    <span className={styles.title}>
      {setFormState ? (
        <i
          onClick={() => setFormState((s) => s - 1)}
          className={["material-icons", "notranslate"].join(" ")}
        >
          arrow_back
        </i>
      ) : null}
      <h1>{title}</h1>
    </span>
  )
}

export default Header
