import React from "react"
import ReactDOM from "react-dom"
import { useIsMobileSize } from "../../common/hooks/useIsMobileSize"
import styles from "./NotificationTabWrapper.module.scss"

interface Props {
  open: boolean
}

const NotificationTabWrapper: React.FC<Props> = ({ children, open }) => {
  const isMobile = useIsMobileSize()

  const className = open || !isMobile ? styles.openContainer : styles.closedContainer

  if (isMobile)
    return ReactDOM.createPortal(<div className={className}>{children}</div>, document.body)

  if (!open) return null

  return <div className={className}>{children}</div>
}

export default NotificationTabWrapper
