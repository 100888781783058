import React, { useState } from "react"
import Loader from "../Loader/Loader"
import styles from "./GenericButton.module.scss"

interface Props {
  text: string
  style: "blue" | "white" | "grey" | "whiteBlue" | "red"
  action: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  className?: string
  disabled?: boolean
  loading?: boolean
  title?: string
}

const GenericButton: React.FC<Props> = ({
  text,
  style,
  action,
  className,
  disabled,
  loading,
  title,
}) => {
  const [innerLoading, setInnerLoading] = useState<boolean>(false)

  const onClickWithLoading = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const result = action(e) as any
    if (result instanceof Promise) {
      setInnerLoading(true)
      await result
      setInnerLoading(false)
    }
  }

  return (
    <button
      type="button"
      onClick={(e) => onClickWithLoading(e)}
      className={`${styles.button} ${styles[style]} ${className} ${disabled ? "disabled" : ""}`}
      disabled={disabled}
      title={title}
    >
      {loading || innerLoading ? (
        <div className={styles.loaderContainer}>
          <Loader scale={0.25} color={style === "blue" ? "white" : "blue"} />
        </div>
      ) : null}
      <p className={loading || innerLoading ? styles.hidden : ""}>{text}</p>
    </button>
  )
}

export default GenericButton
