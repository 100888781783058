import React from "react"
import { useState } from "react"
import styles from "./Checkbox.module.css"

type Props = {
  label: string
  value: boolean
  onClick: (v: boolean) => void | Promise<void>
  className?: string
  disabled?: boolean
}
const Checkbox: React.FC<Props> = ({ label, value, onClick, className = "", disabled }) => {
  const [loading, setLoading] = useState(false)

  const boxStyle = value ? styles.checked : styles.unchecked

  const onClickWithLoading = async (v: boolean) => {
    const result = onClick(v)
    if (result instanceof Promise) {
      setLoading(true)
      await result
      setLoading(false)
    }
  }

  return (
    <div
      className={`${styles.checkboxCont} ${className} ${disabled || loading ? "disabled" : ""}`}
      onClick={disabled || loading ? undefined : () => onClickWithLoading(!value)}
    >
      <div className={styles.topContainer}>
        <div className={boxStyle}>
          <i className={["material-icons", "notranslate"].join(" ")}>done</i>
        </div>
        <label>{label}</label>
      </div>
    </div>
  )
}

export default Checkbox
