import { FieldAttributes, useField } from "formik"
import React from "react"
import styles from "./Select.module.css"
import {
  createStyles,
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
  MenuItem,
  Select as MuiSelect,
  Theme,
} from "@material-ui/core"

type SelectProps = {
  label: string
  className?: string
  customOnChange?: Function
  customValue?: string
  selectOptions: Array<{
    value: string | number
    text?: string
  }>
  callback?: (value: string) => void
} & FieldAttributes<{}>

const Select: React.FC<SelectProps> = ({
  label,
  selectOptions,
  customOnChange,
  customValue,
  className,
  callback,
  ...props
}) => {
  const [field, meta] = useField<{}>(props)

  const options = selectOptions.map((el, index) => {
    return (
      <MenuItem value={el.value} key={index}>
        {el.text ?? el.value}
      </MenuItem>
    )
  })

  return (
    <div className={styles.root}>
      <FormControl className={styles.root}>
        <InputLabel>{label}</InputLabel>
        <MuiSelect
          variant="standard"
          {...(field as any)}
          {...props}
          error={meta.touched && meta.error}
          value={String(customValue ?? field.value ?? "")}
          onChange={
            customOnChange
              ? (event) => customOnChange(event.target.value)
              : (event) => {
                  field.onChange(event)
                  callback?.(event.target.value as string)
                }
          }
          fullWidth
        >
          {options}
        </MuiSelect>
        <FormHelperText className={styles.selectError}>
          {(meta.touched && meta.error) || " "}
        </FormHelperText>
      </FormControl>
    </div>
  )
}

export default Select
