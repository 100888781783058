import React, { useContext, useEffect, useState } from "react"
import styles from "./DisclaimerFormSlide.module.css"
import { newPatientFormContext } from "../NewPatientForm"
import TaskCardBase from "src/user-components/NewTicketPage/TaskCardBase"
import Tooltip from "src/common-components/Tooltip/Tooltip"

const DisclaimerFormSlide: React.FC = () => {
  const formContext = useContext(newPatientFormContext)
  return (
    <TaskCardBase
      formContext={formContext}
      dontSaveToFormContext
      initialValues={{}}
      title="Új páciens felvétele"
      justifyContent="top"
    >
      <div className={styles.formSlide}>
        <p>
          Új páciens felvételéhez szükségünk van a
          <strong title="Például gyermeke, családtagja."> páciens </strong>
          néhány személyes és egészségügyi adatára.
        </p>
        <p>
          Töltse ki a következő űrlapokat, hogy a páciens (gyermek) egészségével kapcsolatos ügyeit
          rendszerünkben kezelhesse!
        </p>
        <p className={styles.note}>A kezdéshez kattintson a "Tovább" gombra!</p>
      </div>
    </TaskCardBase>
  )
}

export default DisclaimerFormSlide
