import React, { useContext } from "react"
import { Route, BrowserRouter as Router, Routes } from "react-router-dom"
import { GlobalContext } from "./App"
import PageBaseView from "./common-components/PageBaseView/PageBaseView"
import CreateDoctorExtendedProfilePage from "./dr-components/CreateDoctorExtendedProfilePage/CreateDoctorExtendedProfilePage"
import DrHomePage from "./dr-components/DrHomePage/DrHomePage"
import DrTicketPage from "./dr-components/DrTicketPage/DrTicketPage"
import PatientProfilePage from "./dr-components/PatientProfilePage/PatientProfilePage"
import ProfilePage from "./dr-components/ProfilePage/ProfilePage"
import EditProfilePage from "src/dr-components/EditProfilePage/DoctorEditProfilePage"
import LogBookPage from "./user-components/LogBookPage/LogBookPage"
import VideoPage from "./common-components/VideoPage/VideoPage"
import DrVerificationPendingPage from "./dr-components/DrVerificationPendingPage/DrVerificationPendingPage"
import urls from "./common/urls"
import ArticlePage from "./user-components/ArticlePage/ArticlePage"
import DrSidebar from "./common-components/Sidebar/DrSidebar"

const DrApp: React.FC = () => {
  const { doctor } = useContext(GlobalContext)

  if (!doctor.userProfile) return <CreateDoctorExtendedProfilePage />

  if (doctor.verified) return <DrVerificationPendingPage />

  return (
    <Router>
      <PageBaseView Sidebar={DrSidebar}>
        <Routes>
          <Route path={"/ticket/:ticketID/patient_data/*"} element={<PatientProfilePage />} />
          <Route path={"/ticket/:ticketID/*"} element={<DrTicketPage />} />
          <Route path={urls.profilePageUrl + "/*"} element={<ProfilePage />} />
          <Route path={urls.editProfilePageUrl + "/*"} element={<EditProfilePage />} />
          <Route path={urls.logBookUrl + "/*"} element={<LogBookPage />} />
          <Route path={"/videocall/*"} element={<VideoPage />} />
          <Route path={urls.articlePageUrl + "/:ticketID/*"} element={<ArticlePage />} />
          <Route path={"/*"} element={<DrHomePage />} />
        </Routes>
      </PageBaseView>
    </Router>
  )
}

export default DrApp
