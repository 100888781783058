import { get, set } from "lodash"

export const necessary = <T>(
  values: T,
  valuePath: string,
  errors: { [key in keyof Partial<T>]: any },
) => {
  const value = get(values, valuePath)
  if (value !== false && value !== 0 && (!value || value === "default")) {
    set(errors, valuePath, "Mező kitöltése kötelező")
  }
}

export const isNumeric = (str: string) => {
  return /^\d+$/.test(str)
}

export const isValidBirthYear = (str: string) => {
  return (
    isNumeric(str) &&
    str.length === 4 &&
    parseInt(str) >= 1900 &&
    parseInt(str) <= new Date().getFullYear()
  )
}

export const isValidHungarianZipCode = (str: string) => {
  return isNumeric(str) && str.length === 4
}

export const isValidTajNum = (str: string) => {
  return isNumeric(str) && str.length === 9
}

export const isValidPhoneNumber = (str: string) => {
  const isValid36 = str[0] === "+" && str.length === 12 && isNumeric(str.substring(1))
  const isValid06 = str[0] === "0" && str[1] === "6" && isNumeric(str) && str.length === 11
  return isValid06 || isValid36
}
