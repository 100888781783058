import { Formik } from "formik"
import React, { useContext, useEffect, useRef, useState } from "react"
import { createExtendedUserProfile } from "src/apicalls"
import { GlobalContext } from "src/App"
import CreateExtendedProfilePage from "src/common-components/CreateExtendedProfile/CreateExtendedProfile"
import { DoctorProfile } from "src/types"
import CreateDrExtProfFirstCard, { validateFirstPage } from "./CreateDrExtProfFirstCard"
import CreateDrExtProfSecondCard, { validateSecondPage } from "./CreateDrExtProfSecondCard"
import CreateDrExtProfThirdCard, { validateCompany } from "./CreateDrExtProfThirdCard"
import {
  initialValueDrExtProfSecondPage,
  initialValuesDrExtProfFirstPage,
  initialValueDrExtProfThirdPage,
  initialValuesDrExtProfFourthPage,
} from "./initialValues"
import CreateDrExtProfFourthCard, {
  DrExtProfCreationLastCard,
  validateFourthPage,
} from "./CreateDrExtProfFourthCard"

const validationFunctions = [
  validateFirstPage,
  validateSecondPage,
  validateCompany,
  validateFourthPage,
]

export type DoctorExtProfCreation = DoctorProfile & DrExtProfCreationLastCard

const CreateDoctorExtendedProfilePage: React.FC = () => {
  const { userToken, setShouldUpdateUserDataFromServer } = useContext(GlobalContext)
  const [formState, setFormState] = useState(0)
  const [confirmPopupVisible, setConfirmPopupVisible] = useState<boolean>(false)
  const formikRef = useRef<any>(null)

  const initialValues = {
    ...initialValueDrExtProfSecondPage,
    ...initialValuesDrExtProfFirstPage,
    ...initialValueDrExtProfThirdPage,
    ...initialValuesDrExtProfFourthPage,
  }

  const submit = async () => {
    if (formikRef.current?.values) delete formikRef.current.values.email
    const res = await createExtendedUserProfile(userToken, formikRef.current.values)
    if (res.status?.result === "ok") {
      setShouldUpdateUserDataFromServer(true)
    } else console.log("Error at communication with server") //TODO: show user the error
    return res
  }

  const page1 = <CreateDrExtProfFirstCard />

  const page2 = <CreateDrExtProfSecondCard setFormState={setFormState} />

  const page3 = (
    <CreateDrExtProfThirdCard
      initialValues={initialValueDrExtProfThirdPage}
      setFormState={setFormState}
    />
  )

  const page4 = <CreateDrExtProfFourthCard setFormState={setFormState} />

  const pages = [page1, page2, page3, page4]
  const isFinalPage = formState === pages.length - 1

  const onNext = async (
    values: any,
    actions: {
      setSubmitting: (val: boolean) => void
      setTouched: (val: any) => void
    },
  ) => {
    actions.setSubmitting(false)
    actions.setTouched({})
    if (!isFinalPage) setFormState(formState + 1)
    else setConfirmPopupVisible(true)
  }

  return (
    <Formik
      onSubmit={onNext}
      initialValues={initialValues}
      validate={(values) => validationFunctions[formState]?.(values, userToken)}
      innerRef={formikRef}
    >
      {({ submitForm }) => (
        <CreateExtendedProfilePage
          next={submitForm}
          confirmPopupVisible={confirmPopupVisible}
          setConfirmPopupVisible={setConfirmPopupVisible}
          submit={submit}
        >
          {pages[formState]}
        </CreateExtendedProfilePage>
      )}
    </Formik>
  )
}

export default CreateDoctorExtendedProfilePage
