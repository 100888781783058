import React, { useRef, useState } from "react"
import { useEffect } from "react"
import Tooltip from "../../common-components/Tooltip/Tooltip"
import { Description } from "./ArticlePage"
import PDFDisplay from "./PDFDisplay"
import styles from "./PdfSlider.module.css"

interface PositionMarkerProps {
  onClick: () => void
  pdfTitle: string
  isSelected: boolean
}

const PositionMarker: React.FC<PositionMarkerProps> = ({ isSelected, pdfTitle, onClick }) => {
  return (
    <Tooltip tooltipText={pdfTitle}>
      <div onClick={onClick} className={isSelected ? styles.selectedMarker : styles.marker} />
    </Tooltip>
  )
}

interface NavigateButtonProps {
  pdfTitles: string[]
  selectedPdf: number
  setSelectedPdf: (n: number) => void
  type: "prev" | "next"
  scrollToTop?: () => void
}

const NavigateButton: React.FC<NavigateButtonProps> = ({
  pdfTitles,
  selectedPdf,
  setSelectedPdf,
  type,
  scrollToTop,
}) => {
  const dontShowChevronIndex = type === "next" ? pdfTitles.length - 1 : 0

  const onClick = () => {
    const change = type === "next" ? 1 : -1
    const nextState = selectedPdf + change
    if (pdfTitles[nextState]) {
      scrollToTop?.()
      setSelectedPdf(nextState)
    }
  }

  return (
    <div className={styles.navigateButtonContainer} onClick={onClick}>
      {type === "prev" ? <p>{pdfTitles[selectedPdf - 1]}</p> : null}
      <i
        className={[
          "material-icons",
          "notranslate",
          selectedPdf === dontShowChevronIndex ? styles.hidden : "",
        ].join(" ")}
      >
        {`chevron_${type === "prev" ? "left" : "right"}`}
      </i>
      {type === "next" ? <p>{pdfTitles[selectedPdf + 1]}</p> : null}
    </div>
  )
}

interface Props {
  pdfs: Description[]
}

const PdfSlider: React.FC<Props> = ({ pdfs }) => {
  const [selectedPdf, setSelectedPdf] = useState(0)
  const [height, setHeight] = useState(1500)

  const contRef = useRef<HTMLDivElement>(null)
  const selectedPdfRef = useRef<HTMLDivElement>(null)

  const isMoreThanOne = pdfs.length > 1

  const setContainerHeight = () => {
    if (selectedPdfRef.current && selectedPdfRef.current.offsetHeight > 200 && contRef.current) {
      const markerContHeight = getComputedStyle(contRef.current).getPropertyValue(
        "--markerContainerHeight",
      )
      setHeight(selectedPdfRef.current.offsetHeight + 2 * parseInt(markerContHeight))
    }
  }

  useEffect(() => {
    setContainerHeight()
  }, [selectedPdf])

  const pdfDisplayElements = pdfs.map(({ name }, index) => {
    const isSelected = index === selectedPdf
    return (
      <div
        style={{ width: `${100 / pdfs.length}%` }}
        ref={isSelected ? selectedPdfRef : undefined}
        className={styles.child}
        key={index}
      >
        {
          <PDFDisplay
            pdfUrl={"https://static.orvosvalasz.hu/pdf/" + name}
            onLoaded={isSelected ? () => setTimeout(setContainerHeight, 300) : undefined} //need to go to the end of call stack or it will be called with a stale value
          />
        }
      </div>
    )
  })

  const bottomMarkerElements = pdfs.map(({ title }, i) => {
    const onClick = () => {
      contRef.current?.scrollIntoView()
      setSelectedPdf(i)
    }
    return (
      <PositionMarker isSelected={i === selectedPdf} pdfTitle={title} onClick={onClick} key={i} />
    )
  })
  const topMarkerElements = pdfs.map(({ title }, i) => {
    const onClick = () => {
      setSelectedPdf(i)
    }
    return (
      <PositionMarker isSelected={i === selectedPdf} pdfTitle={title} onClick={onClick} key={i} />
    )
  })

  const pdfTitles = pdfs.map(({ title }) => title)

  return (
    <div style={{ height }} className={styles.container} ref={contRef}>
      {isMoreThanOne ? (
        <div className={styles.markerContainer}>
          <NavigateButton
            type="prev"
            setSelectedPdf={setSelectedPdf}
            selectedPdf={selectedPdf}
            pdfTitles={pdfTitles}
          />
          {topMarkerElements}
          <NavigateButton
            type="next"
            setSelectedPdf={setSelectedPdf}
            selectedPdf={selectedPdf}
            pdfTitles={pdfTitles}
          />
        </div>
      ) : null}
      <div
        style={{
          transform: `translateX(-${(selectedPdf * 100) / pdfs.length}%)`,
          width: `${pdfs.length * 100}%`,
        }}
        className={styles.sliderContainer}
      >
        {pdfDisplayElements}
      </div>
      {isMoreThanOne ? (
        <div className={styles.bottomMarkerContainer}>
          <NavigateButton
            type="prev"
            setSelectedPdf={setSelectedPdf}
            selectedPdf={selectedPdf}
            pdfTitles={pdfTitles}
            scrollToTop={() => contRef.current?.scrollIntoView()}
          />
          {bottomMarkerElements}
          <NavigateButton
            type="next"
            setSelectedPdf={setSelectedPdf}
            selectedPdf={selectedPdf}
            pdfTitles={pdfTitles}
            scrollToTop={() => contRef.current?.scrollIntoView()}
          />
        </div>
      ) : null}
    </div>
  )
}

export default PdfSlider
