import React from "react"
import styles from "./DrTicketLabel.module.scss"

interface Props {
  taskName: string
  state?: "wip" | "ready" | "rejected" | null
}

const DrTicketLabel: React.FC<Props> = ({ taskName, state }) => {
  return (
    <div
      className={`
        ${styles.label}
        ${state === "rejected" ? styles.rejected : ""}
        ${state === "ready" ? styles.done : ""}
      `}
    >
      <p>{taskName}</p>
    </div>
  )
}

export default DrTicketLabel
