import { URL } from "url"
import { APIBASEURL, Getter, headersBase, Poster } from "."
import { ApiResponse, NotificationPreferences } from "../types"

export class NotificationPreferencesSetter {
  private baseURL = "email-notifications"

  private getter: Getter<null, NotificationPreferences>
  private poster: Poster<NotificationPreferences>

  private prefs: NotificationPreferences | null = null

  constructor(userToken: string) {
    this.getter = new Getter(this.baseURL, userToken)
    this.poster = new Poster(this.baseURL, userToken)
  }

  async get() {
    return await this.getter.get(null)
  }

  async post(body: Partial<NotificationPreferences>) {
    if (!this.prefs) {
      this.prefs = (await this.get()).status.message
    }
    const result = await this.poster.post({ ...this.prefs, ...body })
    this.prefs = result.status.message
    return result
  }
}
