import React, { ReactElement, useRef } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import NewNotiCirc from "src/dr-components/DrHomePage/PageSelector/NewNotiCirc"
import { OverlayType } from "src/types"
import { useIsMobileSize } from "../../common/hooks/useIsMobileSize"
import { useOnClickOutside } from "../../common/hooks/useOnClickOutside"
import Notifications from "../Notifications/Notifications"
import SidebarButton from "../SidebarButton/SidebarButton"
import ConsultationsTab from "../VideoCallsLayout/ConsultationsTab"
import {
  addOverlayToPath,
  removeOverlayFromPath,
  useCloseOverlays,
  useIsVideoCallHappeningSoon,
  useNumOfRelevantNotifications,
  useOpenOverlay,
} from "./sidebarButtonOverlayOpening_utils"
import styles from "./SidebarButtonOverlayOpening.module.scss"

const icons: { [key in OverlayType]: string } = {
  "consultations-overlay": "video_camera_front",
  "msg-overlay": "messages",
  "noti-overlay": "notifications",
}

const buttonTexts: { [key in OverlayType]: string } = {
  "consultations-overlay": "Konzultációk",
  "msg-overlay": "Üzenetek",
  "noti-overlay": "Értesítések",
}

const overlayElements: { [key in OverlayType]: ReactElement } = {
  "consultations-overlay": <ConsultationsTab />,
  "msg-overlay": <Notifications type="msg" />,
  "noti-overlay": <Notifications type="basic" />,
}

interface Props {
  overlay: OverlayType
}

const OverlayOpeningSingleButton: React.FC<Props> = ({ overlay }) => {
  const isMobile = useIsMobileSize()
  const openOverlay = useOpenOverlay()
  const numOfNotifications = useNumOfRelevantNotifications(overlay)
  const navigate = useNavigate()
  const location = useLocation()

  const notiColor =
    useIsVideoCallHappeningSoon() && overlay === "consultations-overlay" ? "blue" : "orange"

  const closeOverlays = useCloseOverlays()
  const openOverlayButtonRef = useRef(null)
  const overlayRef = useOnClickOutside(
    closeOverlays,
    openOverlayButtonRef,
    isMobile || overlay !== openOverlay,
  )

  const handleOpenOverlayClick = (clicked: OverlayType) => {
    let newPath = removeOverlayFromPath(location.pathname)
    if (!location.pathname.includes(clicked)) newPath = addOverlayToPath(newPath, clicked)
    navigate({ pathname: newPath }, { state: location.state, replace: true })
  }

  return (
    <div className={`${styles.notificationContainer}`}>
      <div ref={openOverlayButtonRef} className={styles.buttonContainer}>
        <SidebarButton
          action={() => handleOpenOverlayClick(overlay)}
          icon={icons[overlay]}
          text={buttonTexts[overlay]}
        />
        <NewNotiCirc
          numOfNotifications={numOfNotifications}
          className={styles.notificationCircle}
          color={notiColor}
        />
      </div>
      <div ref={overlayRef}>{overlayElements[overlay]}</div>
    </div>
  )
}

export default OverlayOpeningSingleButton
