import React, { useRef, useState } from "react"
import { Calendar } from "react-date-range"
import { useOnClickOutside } from "src/common/hooks/useOnClickOutside"
import { getDateFromInt } from "src/common/utilityFunctions"
import styles from "./DayChooser.module.css"

interface Props {
  date: Date
  setDate: React.Dispatch<React.SetStateAction<Date>>
  disabled?: boolean
  minDate?: Date
}

const DayChooser: React.FC<Props> = ({ date, setDate, disabled, minDate }) => {
  const [shouldShowDatePicker, setShouldShowDatePicker] = useState<boolean>(false)

  const datePickerButtonRef = useRef(null)
  const datePickerRef = useOnClickOutside<HTMLDivElement>(
    () => setShouldShowDatePicker(false),
    datePickerButtonRef,
  )

  return (
    <>
      <p
        ref={datePickerButtonRef}
        onClick={disabled ? () => {} : () => setShouldShowDatePicker((s) => !s)}
        className={styles.text}
      >
        {getDateFromInt(date.getTime())}
      </p>
      {shouldShowDatePicker ? (
        <div className={styles.datePickerContainer} ref={datePickerRef}>
          <Calendar
            date={date}
            onChange={(date) => {
              setDate(date as Date)
              setTimeout(() => setShouldShowDatePicker(false), 100)
            }}
            minDate={minDate}
          />
        </div>
      ) : null}
    </>
  )
}

export default DayChooser
