import { ApiResponse } from "src/types"
import { APIBASEURL, headersBase } from "."

const defaultTimeout = 10000

const sendRequest = async <T = ApiResponse>(
  userToken: string,
  endpoint: string,
  options?: RequestInit,
  timeout?: number,
) => {
  const controller = new AbortController()
  let opt: RequestInit = {
    headers: {
      "User-Token": userToken,
      ...headersBase,
    },
  }
  const id = setTimeout(
    () => {
      controller.abort()
    },
    timeout ? timeout : defaultTimeout,
  )
  if (options) {
    opt = { ...options }
    opt.headers = {
      "User-Token": userToken,
      ...headersBase,
      ...options.headers,
    }
  }
  opt.signal = controller.signal
  const res = await fetch(APIBASEURL + endpoint, opt).catch((e) => console.log(e))
  clearTimeout(id)
  if (res) {
    return (await res.json()) as T
  } else {
    return {
      status: {
        result: "error",
      },
    } as ApiResponse
  }
}

export default sendRequest
