import { useFormikContext } from "formik"
import React, { useContext } from "react"
import SearchableSelect from "src/common-components/SearchableSelect/SearchableSelect"
import { bodyLocationValueLabelPairs } from "src/common/bodyLocations"
import { boyLocationIDSymptomIDsMap } from "src/common/boySymptoms"
import { girlLocationIDSymptomIDsMap } from "src/common/girlSymptoms"
import { symptomsMap } from "src/common/symptoms"
import { addSymptomToNewTicketFormContext } from "src/common/utilityFunctions"
import { Sex } from "src/types"
import { newTicketFormContext } from "../NewTicketPage"

interface Props {
  hideAddSymptomForm: Function
  patientSex: Sex
}

const SymptomChooserByLocation: React.FC<Props> = ({ hideAddSymptomForm, patientSex }) => {
  const { values, resetForm } = useFormikContext<any>()
  const formContext = useContext(newTicketFormContext)

  const locationIDSymptomIDMap =
    patientSex === "male" ? boyLocationIDSymptomIDsMap : girlLocationIDSymptomIDsMap

  const onSymptomSelect = (symptomID: number) => {
    const result = addSymptomToNewTicketFormContext(formContext, symptomID)
    if (result) {
      hideAddSymptomForm()
      resetForm()
    }
  }

  const symptomOptionValues = locationIDSymptomIDMap.get(values.location)
  const symptomOptions = symptomOptionValues
    ? symptomOptionValues.map((symptomID) => {
        return { label: symptomsMap.get(symptomID), value: symptomID }
      })
    : undefined

  return (
    <>
      <SearchableSelect
        options={bodyLocationValueLabelPairs}
        name="location"
        placeholder="Érintett testtáj"
      />
      <SearchableSelect
        options={symptomOptions || []}
        name="symptomID"
        customOnChange={onSymptomSelect}
        disabled={values.location ? false : true}
        placeholder="Tünet / probléma"
      />
    </>
  )
}

export default SymptomChooserByLocation
