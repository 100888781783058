import React, { useContext } from "react"
import { GlobalContext } from "src/App"
import OutpatientSheetDataTable from "src/common-components/OutpatientSheetDataTable/OutpatientSheetDataTable"
import { Ticket } from "src/types"
import { getDateFromInt, getSymptomName } from "src/common/utilityFunctions"
import styles from "./OutpatientSheetReadonly.module.scss"

type SymptomListProps = {
  name: string
  date: { start: number; end: number | null }
}

const SymptomListItem: React.FC<SymptomListProps> = ({ name, date }) => {
  const endDateDisplayFormat = date.end ? getDateFromInt(date.end) : null
  const startDateDisplayFormat = getDateFromInt(date.start)
  return (
    <div title="Kattintson a másoláshoz" className={styles.tableDataRow}>
      <p onClick={() => navigator.clipboard.writeText(name)}>{name}</p>
      <p
        onClick={() => navigator.clipboard.writeText(startDateDisplayFormat)}
        className={styles.tableData}
      >
        {startDateDisplayFormat + "- "}
      </p>
      {endDateDisplayFormat ? (
        <p
          onClick={() => navigator.clipboard.writeText(endDateDisplayFormat)}
          className={styles.tableData}
        >
          {endDateDisplayFormat}
        </p>
      ) : null}
    </div>
  )
}

interface Props {
  ticket: Ticket
}

const OutpatientSheetReadonly: React.FC<Props> = ({ ticket }) => {
  const { user } = useContext(GlobalContext)

  let patientProfile = undefined
  let userProfile = undefined

  if (Object.keys(user).length > 0) {
    patientProfile = user.patients.find((patient) => patient.id === ticket.patientID)?.profile
    userProfile = user.userProfile
  } else {
    patientProfile = ticket.patientProfile
    userProfile = ticket.userProfile
  }

  const symptomList: JSX.Element[] = ticket.ticketData.symptoms.map((s) => {
    return (
      <SymptomListItem
        key={s.symptomID}
        name={getSymptomName(s.symptomID)}
        date={s.symptomInterval}
      />
    )
  })

  const outpatientData = ticket.ticketData.outpatientData

  if (!outpatientData) return null

  return (
    <div className={styles.container}>
      <div>
        <h2>Beteg adatai</h2>
        <OutpatientSheetDataTable
          ticket={ticket}
          patientProfile={patientProfile}
          userProfile={userProfile}
        />
        <h2>Tünetek</h2>
        <div className={styles.tableDataContainer}>
          <div className={styles.tableDataRow}>
            <p>Tünet neve</p>
            <p className={styles.tableData}>
              <b>Tünet fennállásának időtartam</b>
            </p>
          </div>
          {symptomList}
        </div>
      </div>
      <div>
        <h2>Diagnózis</h2>
        <p>{outpatientData?.diagnosis || "Nincs megadva"}</p>
      </div>
      <div>
        <h2>Jelen panasz</h2>
        <p>{outpatientData?.status || "Nincs megadva"}</p>
      </div>
      <div>
        <h2>Terápia</h2>
        <p>{outpatientData?.therapy || "Nincs megadva"}</p>
      </div>
      <div>
        <h2>Vélemény</h2>
        <p>{outpatientData?.opinion || "Nincs megadva"}</p>
      </div>
    </div>
  )
}

export default OutpatientSheetReadonly
