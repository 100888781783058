import React, { useContext, useEffect, useRef, useState } from "react"
import { overlayChildrenContext } from "src/App"
import { useOnClickOutside } from "src/common/hooks/useOnClickOutside"
import { toBase64 } from "src/common/utilityFunctions"
import AttachmentLabel from "../AttachmentLabel/AttachmentLabel"
import stylesOther from "../ShowImageFromServer/ShowImageFromServer.module.scss"

interface Props {
  file: File
}

const ShowUploadedMedia: React.FC<Props> = ({ file }) => {
  const [base64, setBase64] = useState<string | null>(null)

  const { children, setChildren } = useContext(overlayChildrenContext)

  const imageRef = useOnClickOutside<HTMLImageElement>(() => setChildren(null))

  useEffect(() => {
    toBase64(file).then((base64) => setBase64(base64))
  }, [])

  const intoOverlay = (
    <>
      <div className={stylesOther.imageContainer}>
        <img ref={imageRef} className={stylesOther.img} src={base64 || ""} />
      </div>
    </>
  )

  return <AttachmentLabel labelText={file.name} onClick={() => setChildren(intoOverlay)} />
}

export default ShowUploadedMedia
