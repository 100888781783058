import React from "react"
import { useNavigate } from "react-router-dom"
import Popup from "src/common-components/Popup/Popup"
import styles from "./SickPayErrorPopup.module.css"
const SickPayErrorPopup: React.FC = () => {
  const navigate = useNavigate()
  const handleClose = () => navigate({ pathname: "/" })
  return (
    <Popup
      confirmButton={{ title: "Rendben" }}
      onClose={handleClose}
      panelStyle={{ width: "30rem" }}
      title="Hiányzó háziorvos"
    >
      Táppénzes papírt csak a háziorvos tud kiadni. A kiválasztott páciens profiljában viszont nincs
      megadva háziorvos, ezért ezt az ügyet nem lehet elindítani. Háziorvost a profil menüpontban a
      páciens szerkesztésénél tud megadni.
    </Popup>
  )
}
export default SickPayErrorPopup
