import { TaskTypesArray, TaskTypes, Ticket } from "src/types"
import { useNavigate, useLocation } from "react-router-dom"
import { getDisplayNameFromNames } from "../../common/utilityFunctions"

const availableFilters = TaskTypesArray

export const useQueryParams = () => new URLSearchParams(useLocation().search)

export const useIsFilterActive = () => {
  const query = useQueryParams()
  return (filterName: string) => query.has(filterName)
}

const getWhichTasksToShow = (query: URLSearchParams) => {
  const tasksToShow: TaskTypes[] = []
  availableFilters.forEach((filter) => {
    if (query.has(filter)) {
      tasksToShow.push(filter)
    }
  })

  return tasksToShow.length > 0 ? tasksToShow : (TaskTypesArray as any as TaskTypes[]) // show all tasks when no filter is enabled
}

export const useTaskFiltering = () => {
  const navigate = useNavigate()
  const query = useQueryParams()

  const toggle = (filterName: string) => {
    if (query.has(filterName)) {
      query.delete(filterName)
    } else {
      query.append(filterName, "")
    }
    navigate({ search: query.toString() }, { replace: true })
  }

  return toggle
}

export const useSearch = () => {
  const query = useQueryParams()
  const navigate = useNavigate()

  const update = (searchTerm: string) => {
    query.set("search", searchTerm)
    navigate({ search: query.toString() }, { replace: true })
  }

  return [query.get("search"), update] as const
}

export const useFilterTickets = () => {
  const query = useQueryParams()
  const filter = (tickets: Ticket[]) => filterTickets(tickets, query)
  return filter
}

const getTaskNamesInTicket = (ticket: Ticket) => {
  return ticket.ticketData.tasks.reduce((acc, curr) => [...acc, curr.name], [] as TaskTypes[])
}

const filterTicketsBasedOnTasks = (ticketsToFilter: Ticket[], tasksToShow: TaskTypes[]) => {
  return ticketsToFilter.filter((ticket) => {
    return tasksToShow.some((taskName) => getTaskNamesInTicket(ticket).includes(taskName))
  })
}

const userNameContainsTerm = (ticket: Ticket, searchTerm: string) => {
  const username = ticket.userProfile
    ? getDisplayNameFromNames(ticket.userProfile).toLowerCase()
    : undefined

  return username && username.includes(searchTerm)
}

const patientNameContainsTerm = (ticket: Ticket, searchTerm: string) => {
  const patientName = ticket.patientProfile
    ? getDisplayNameFromNames(ticket.patientProfile).toLowerCase()
    : undefined

  return patientName && patientName.includes(searchTerm)
}

const tajNumContainsTerm = (ticket: Ticket, searchTerm: string) => {
  const tajNum = ticket.patientProfile?.tajNum.toLowerCase()

  return tajNum && tajNum.includes(searchTerm)
}

const filterTicketsBasedOnSearchTerm = (ticketsToFilter: Ticket[], searchTerm: string) => {
  let lowerCaseSearchTerm = searchTerm.toLowerCase()
  return ticketsToFilter.filter((ticket) => {
    return (
      userNameContainsTerm(ticket, lowerCaseSearchTerm) ||
      patientNameContainsTerm(ticket, lowerCaseSearchTerm) ||
      tajNumContainsTerm(ticket, lowerCaseSearchTerm)
    )
  })
}

export const filterTickets = (tickets: Ticket[], query: URLSearchParams) => {
  const tasksToShow = getWhichTasksToShow(query)
  let filteredTickets = filterTicketsBasedOnTasks(tickets, tasksToShow)

  const searchTerm = query.get("search")
  if (searchTerm) {
    filteredTickets = filterTicketsBasedOnSearchTerm(filteredTickets, searchTerm)
  }

  return filteredTickets
}
