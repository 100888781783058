import { useFormikContext } from "formik"
import React from "react"
import Select from "src/common-components/Select/Select"
import MyTextInput from "src/common-components/TextInput/TextInput"
import { monthOptions, titleOptions } from "src/common/consts"
import { getDayOptions } from "src/common/utilityFunctions"
import { DoctorProfile, DoctorProfileFirstCard } from "src/types"
import { necessary } from "../../common/validationFunctions"
import styles from "./CreateDrExtProfFirstCard.module.css"
import Header from "./Header"

export const validateFirstPage = (values: DoctorProfile) => {
  let errors = {}
  necessary(values, "firstName", errors)
  necessary(values, "lastName", errors)
  necessary(values, "birthName", errors)
  necessary(values, "birthYear", errors)
  necessary(values, "birthMonth", errors)
  necessary(values, "birthDay", errors)
  return errors
}

interface Props {
  editing?: boolean
}

const CreateDrExtProfFirstCard: React.FC<Props> = ({ editing = false }) => {
  const { values } = useFormikContext<DoctorProfileFirstCard>()
  return (
    <>
      {editing ? null : <Header title="Személyes profil" />}
      <div className={styles.horizontalContainer}>
        <Select
          label="Titulus"
          name="title"
          selectOptions={titleOptions}
          className={styles.nameTitle}
        />
        <MyTextInput label="Vezetéknév" type="text" name="lastName" />
      </div>
      <div className={`${styles.nameContainer} `}>
        <MyTextInput label="Keresztnév" type="text" name="firstName" />
        <MyTextInput label="Utónév (opcionális)" type="text" name="foreName" />
      </div>
      <MyTextInput label="Születési név" type="text" name="birthName" />
      <div className={styles.dateContainer}>
        <MyTextInput label="Születési év" type="text" name="birthYear" className={styles.year} />
        <Select
          label="Hónap"
          name="birthMonth"
          selectOptions={monthOptions}
          className={styles.month}
        />
        <Select
          label="Nap"
          name="birthDay"
          selectOptions={getDayOptions(values.birthYear, values.birthMonth)}
          className={styles.day}
        />
      </div>
      <MyTextInput
        label="ÁNTSZ engedélyszám (opcionális)"
        type="text"
        name="antszLicenseNumber"
        tooltip="ÁNTSZ engedélyszám"
      />
    </>
  )
}

export default CreateDrExtProfFirstCard
