import { ApiResponse } from "src/types"
import { APIBASEURL, headersBase } from "."
import { config } from "../config"

export const logout = async (userToken: string) => {
  const res = await fetch(APIBASEURL + "/logout", {
    method: "POST",
    headers: {
      ...headersBase,
      "User-Token": userToken,
    },
  })
  const result: ApiResponse = await res.json()
  document.cookie = "session_key=0; path=/; domain=orvosvalasz.hu"
  window.location.replace(config.LOGIN_PAGE_URL)
  return result
}
