import { APIBASEURL, headersBase } from "."

export const downloadSickNoteDocumentPreview = async (
  userToken: string,
  ticketID: number,
  taskID: number,
  drComment: string | null,
  peSuspensionEndDate: number | null,
) => {
  const res = await fetch(APIBASEURL + `/sick-note-document-preview/${ticketID}/${taskID}`, {
    method: "POST",
    headers: {
      ...headersBase,
      "User-Token": userToken,
      "content-type": "application/json",
    },
    body: JSON.stringify({
      drComment,
      peSuspensionEndDate,
    }),
  })
  return res
}
