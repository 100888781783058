import React, { useContext, useState } from "react"
import { patchTicket } from "src/apicalls"
import { GlobalContext } from "src/App"
import OutpatientSheetReadonly from "src/common-components/OutpatientSheetReadonly/OutpatientSheetReadonly"
import Popup from "src/common-components/Popup/Popup"
import TicketHead from "src/common-components/TicketHead/TicketHead"
import { symptomsMap } from "src/common/symptoms"
import { ConsultationTaskData, Ticket } from "src/types"
import GenericButton from "../../../common-components/GenericButton/GenericButton"
import MobileTicketMenu from "../../../common-components/MobileTicketMenu/MobileTicketMenu"
import { useIsMobileSize } from "../../../common/hooks/useIsMobileSize"
import { createConsultationObject, getNewTaskId } from "../../../common/ticket/task"
import ConsultationPopup from "./ConsultationPopup"
import DiagnosisDisplay from "./DiagnosisDisplay"
import styles from "./TicketInfo.module.scss"
import TicketInfoFileUpload from "./TicketInfoFileUpload"
import UserTicketMenu from "./UserTicketMenu"
import { useUploadSingleFile } from "./useUploadUserFileToTicket"

const getSymptomLabels = (ticket: Ticket) =>
  ticket.ticketData.symptoms?.map((symptom) => {
    const symptomName = symptomsMap.get(symptom.symptomID)
    return (
      <div key={symptom.symptomID} className={styles.symptomLabel}>
        <p>{symptomName}</p>
      </div>
    )
  })

interface Props {
  ticket: Ticket | undefined
  patientFullName: string
}

const TicketInfo: React.FC<Props> = ({ ticket, patientFullName }) => {
  const [visibleOverlay, setVisibleOverlay] = useState<"outpatient" | "consultation" | null>(null)
  const { userToken, setShouldUpdateUserDataFromServer } = useContext(GlobalContext)

  const [files, setFiles] = useUploadSingleFile(userToken, ticket?.ticketID, ticket?.ticketData)

  const isMobile = useIsMobileSize()

  if (!ticket) return null

  const addConsultationTask = async (type: ConsultationTaskData["type"]) => {
    const consultationTask = createConsultationObject(getNewTaskId(ticket), type)
    ticket.ticketData.tasks.push(consultationTask)
    const res = await patchTicket(userToken, {
      ticketID: ticket.ticketID,
      ticketData: { ...ticket.ticketData },
    })
    setShouldUpdateUserDataFromServer(true)
  }

  return (
    <div className={styles.container}>
      <div className={styles.containerLeft}>
        <TicketHead patientFullName={patientFullName} ticket={ticket} />
        <div className={styles.contentContainer}>
          <div className={styles.symptomLabelContainer}>{getSymptomLabels(ticket)}</div>

          {/* FIXME: Temporarily removed diagnosis display to suppress ticket page issues */}
          {/* <DiagnosisDisplay diagnosis={ticket.ticketData.diagnosis} /> */}

          <TicketInfoFileUpload ticket={ticket} setFiles={setFiles} files={files} />
        </div>
        <MobileTicketMenu>
          <UserTicketMenu ticket={ticket} setVisibleOverlay={setVisibleOverlay} />
        </MobileTicketMenu>
      </div>
      <div className={styles.containerRight}>
        {!isMobile && <UserTicketMenu ticket={ticket} setVisibleOverlay={setVisibleOverlay} />}
      </div>
      {visibleOverlay === "consultation" && (
        <ConsultationPopup
          addConsultationTask={addConsultationTask}
          hideOverlay={() => setVisibleOverlay(null)}
        />
      )}
      {visibleOverlay === "outpatient" && (
        <Popup
          title="Összefoglaló lap"
          confirmButton={{ title: "Bezárás" }}
          onClose={() => setVisibleOverlay(null)}
          mobileFullScreen
        >
          <OutpatientSheetReadonly ticket={ticket} />
        </Popup>
      )}
    </div>
  )
}

export default TicketInfo
