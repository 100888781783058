import React, { useContext } from "react"
import { GlobalContext } from "src/App"
import { ConsultationTaskData } from "src/types"
import styles from "./ConsultationTask.module.scss"

interface Props {
  task: ConsultationTaskData
  isTicketTaken: boolean
}

const isDateGiven = (t: ConsultationTaskData) => {
  return (
    (t.type === "personal" && t.doctorData.date) || (t.type === "video" && t.doctorData.videoCallID)
  )
}

const ConsultationTask: React.FC<Props> = ({ task, isTicketTaken }) => {
  const { user } = useContext(GlobalContext)

  const getText = () => {
    if (!isTicketTaken) return "Köszönjük, kérését orvosához továbbítottuk"

    if (!isDateGiven(task)) return "Az orvosa még nem adott időpontot"

    if (task.type === "video") {
      const videoCall = user.videoCalls.find((v) => v.id === task.doctorData.videoCallID)
      if (!videoCall) {
        if (task.finishedAt) {
          const date = new Date(task.finishedAt * 1000).toLocaleString()
          return `A konzultáció befejeződött ${date} - kor`
        }
        return null
      }
      return new Date(videoCall.date * 1000).toLocaleString()
    }

    if (!task.doctorData.date) return //this case is already handled but typescript can't infer it that way
    if (task.state === "ready") return "A konzultáció sikeresen lezajlott"
    if (task.state === "rejected")
      return `A konzultáció elmaradt: ${task.doctorData.comment || "-"}`
    //type is personal and it should have already happened
    if (task.doctorData.date < Date.now() / 1000) {
      return "Amennyiben a konzultáció sikeresen lezajlott, orvosa hamarosan lezárja az ügyet."
    }
    //date is still in the future
    return new Date(task.doctorData.date * 1000).toLocaleString()
  }

  const text = getText()

  return <div className={styles.container}>{text}</div>
}

export default ConsultationTask
