import { ApiResponse, VideoCall, VideoCallRequest } from "src/types"
import { APIBASEURL, headersBase } from "."

export const createVideoCall = async (userToken: string, body: VideoCallRequest) => {
  const res = await fetch(APIBASEURL + "/videocall", {
    method: "POST",
    headers: {
      ...headersBase,
      "User-Token": userToken,
      "content-type": "application/json",
    },
    body: JSON.stringify(body),
  })
  const result: ApiResponse<VideoCall> = await res.json()
  return result
}
