import React, { useContext, useDebugValue, useEffect, useState } from "react"
import { Address, UserProfile } from "src/types"
import AddressTextInputs from "src/user-components/NewPatientPage/NewPatientForm/AddressFormSlide/AddressTextInputs"
import { newTicketFormContext } from "../NewTicketPage"
import TaskCardBase from "../TaskCardBase"
import {
  validateAddress,
  addressInitialValuesBase,
} from "src/user-components/NewPatientPage/NewPatientForm/AddressFormSlide/AddressFormSlide"
import styles from "./SickPayTaskCard.module.css"
import MyTextInput from "src/common-components/TextInput/TextInput"
import { necessary } from "src/common/validationFunctions"
import { GlobalContext } from "src/App"
import { prefillInitialValues } from "../../../common/utilityFunctions"

interface Props {
  setNewUserDataForSickPayTask: React.Dispatch<React.SetStateAction<Partial<UserProfile>>>
}

const validate = (values: any) => {
  let errors = validateAddress(values)
  necessary(values, "mothersName", errors)
  necessary(values, "tajNum", errors)
  necessary(values, "job", errors)
  return errors
}
let initialValues = { ...addressInitialValuesBase, job: "", tajNum: "", mothersName: "" }

const SickPayTaskCard: React.FC<Props> = ({ setNewUserDataForSickPayTask }) => {
  const formContext = useContext(newTicketFormContext)
  const {
    user: { userProfile },
  } = useContext(GlobalContext)

  initialValues = prefillInitialValues(initialValues, userProfile)

  return (
    <TaskCardBase
      initialErrors={initialValues}
      initialValues={initialValues}
      title="Táppénzes papír igényléséhez szükséges információk"
      formikValidate={(values: any) => validate(values)}
      formContext={formContext}
      dontSaveToFormContext
      customSubmit={(values: any) => {
        setNewUserDataForSickPayTask(values)
      }}
    >
      <AddressTextInputs baseName="address" />
      <MyTextInput name="mothersName" label="Anyja neve" />
      <MyTextInput name="tajNum" label="TAJ-szám" />
      <MyTextInput name="job" label="Foglalkozás" />
    </TaskCardBase>
  )
}

export default SickPayTaskCard
