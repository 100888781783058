import { doctorListApiResponse } from "src/types"
import { APIBASEURL, headersBase } from "."

export const getDoctorList = async (userToken: string) => {
  const res = await fetch(APIBASEURL + "/doctor", {
    headers: {
      ...headersBase,
      "User-Token": userToken,
    },
  })
  const result: doctorListApiResponse = await res.json()
  return result
}
