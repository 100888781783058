import { Formik } from "formik"
import React, { useContext, useState } from "react"
import { patchTicket } from "src/apicalls/patchTicket"
import { GlobalContext } from "src/App"
import GenericButton from "src/common-components/GenericButton/GenericButton"
import MyTextInput from "src/common-components/TextInput/TextInput"
import { necessary } from "src/common/validationFunctions"
import { Ticket } from "src/types"
import { FinisherContainer } from "."
import styles from "./RejectTask.module.css"

interface Props {
  ticket: Ticket
  taskIndex: number
  setOpenTaskFinishWindow: React.Dispatch<React.SetStateAction<"finish" | "reject" | null>>
}

const validate = (data: { comment: string }) => {
  let errors = {}
  necessary(data, "comment", errors)
  return errors
}

const RejectTask: React.FC<Props> = ({ taskIndex, ticket, setOpenTaskFinishWindow }) => {
  const { userToken, setShouldUpdateUserDataFromServer } = useContext(GlobalContext)
  const [loading, setLoading] = useState<boolean>(false)

  const task = ticket.ticketData.tasks[taskIndex]

  const onSubmit = async (data: { comment: string }) => {
    setLoading(true)
    let newTasks = ticket.ticketData.tasks
    const newTask = newTasks[taskIndex]
    newTask.state = "rejected"
    newTask.doctorData.comment = data.comment
    const newTicketData = { ...ticket.ticketData, tasks: newTasks }
    const res = await patchTicket(userToken, {
      ticketID: ticket.ticketID,
      ticketData: newTicketData,
    })
    setOpenTaskFinishWindow(null)
    setShouldUpdateUserDataFromServer(true)
    setLoading(false)
  }

  const cancelRejection = async () => {
    setLoading(true)
    let newTasks = ticket.ticketData.tasks.slice()
    const newTask = newTasks[taskIndex]
    newTask.state = "wip"
    if ("comment" in newTask.doctorData) newTask.doctorData.comment = ""
    const newTicketData = { ...ticket.ticketData, tasks: newTasks }
    await patchTicket(userToken, {
      ticketID: ticket.ticketID,
      ticketData: newTicketData,
    })
    setOpenTaskFinishWindow(null)
    setShouldUpdateUserDataFromServer(true)
    setLoading(false)
  }

  const textFieldPlaceholder = "Elutasítás oka"
  const cancelButtonText = "Elutasítás visszavonása"

  return (
    <Formik
      initialValues={{
        comment: task.doctorData.comment || "",
      }}
      onSubmit={(data) => onSubmit(data)}
      validate={(data) => validate(data)}
    >
      {({ submitForm }) => (
        <div className={styles.container}>
          <MyTextInput
            maxLength={500}
            name="comment"
            label={textFieldPlaceholder}
            rows={6}
            disabled={ticket.ready}
            className={styles.textarea}
          />
          {ticket.ticketData.tasks[taskIndex].state === "rejected" && !ticket.ready ? (
            <GenericButton
              style="whiteBlue"
              text={cancelButtonText}
              action={() => cancelRejection()}
              loading={loading}
            />
          ) : null}
          {ticket.ready ? (
            <GenericButton
              action={() => setOpenTaskFinishWindow(null)}
              text="Bezárás"
              style="blue"
              loading={loading}
            />
          ) : (
            <GenericButton
              action={submitForm}
              text={ticket.ticketData.tasks[taskIndex].state === "wip" ? "Befejezés" : "Módosítás"}
              style="blue"
              loading={loading}
            />
          )}
        </div>
      )}
    </Formik>
  )
}

const WrappedRejectTask: React.FC<Props> = (props) => {
  const task = props.ticket.ticketData.tasks[props.taskIndex]

  return (
    <FinisherContainer closeWindow={() => props.setOpenTaskFinishWindow(null)} task={task}>
      <RejectTask {...props} />
    </FinisherContainer>
  )
}

export default WrappedRejectTask
