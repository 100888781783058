import React, { useContext } from "react"
import Scrollbars from "react-custom-scrollbars"
import { GlobalContext } from "src/App"
import styles from "src/common-components/Notifications/Notifications.module.scss"
import DrVideoCall from "src/dr-components/DrVideoCall/DrVideoCall"
import { PersonalConsultationNotificationData, VideoCall } from "src/types"
import BasicConsultation from "src/user-components/UserVideoCall/BasicConsultation"
import { toServerTime } from "../../common/utilityFunctions"
import Info from "../Info/Info"
import NotificationTabWrapper from "../NotificationTabWrapper/NotificationTabWrapper"
import { useOpenOverlay } from "../SidebarButtonsOverlayOpening/sidebarButtonOverlayOpening_utils"

export const isOldConsultation = (c: VideoCall | PersonalConsultationNotificationData) => {
  return (
    toServerTime(new Date()) - c.date > 7200 || (c as PersonalConsultationNotificationData).finished
  )
}

const ConsultationsTab: React.FC = () => {
  const { user, doctor } = useContext(GlobalContext)

  const isOpen = useOpenOverlay() === "consultations-overlay"

  const isDoctor = Object.keys(doctor).length > 0
  let videoCalls: VideoCall[] = isDoctor ? doctor.videoCalls : user.videoCalls
  let filteredVideoCalls: (VideoCall | PersonalConsultationNotificationData)[] = videoCalls.filter(
    (v) => !v.answer,
  )
  const personalConsultations = isDoctor ? doctor.personalConsultations : user.personalConsultations
  let consultations = filteredVideoCalls.concat(personalConsultations)
  consultations = consultations.filter((c) => !isOldConsultation(c))

  const content = consultations
    .sort((a, b) => a.date - b.date)
    .map((v, i) => {
      if ((v as VideoCall).id) {
        v = v as VideoCall
        if (isDoctor) return <DrVideoCall videoCall={v} key={i} />
        return (
          <BasicConsultation
            ticketID={v.id}
            partnerName={v.doctorName}
            dateInSeconds={v.date}
            isVideo
          />
        )
      }
      v = v as PersonalConsultationNotificationData
      return (
        <BasicConsultation
          dateInSeconds={v.date}
          partnerName={v.partnerName}
          ticketID={v.ticketID}
          isVideo={false}
        />
      )
    })

  const placeholder = !isDoctor
    ? "Konzultációs időpontot az adott ügy megnyitásánál tud kérvényezni,  mivel ez bizonyos esetekben kényelmi szolgáltatás, orvosa díjat számíthat fel, amelyet előre utalással tud kifizetni."
    : "Nincs egyeztetett konzultációja"

  return (
    <NotificationTabWrapper open={isOpen}>
      <div className={styles.topRow}>
        <h1>Konzultációk</h1>
        {!isDoctor ? (
          <Info text="Az egyeztetett videókonzultációt az orvosa fogja elindítani a megadott időpontban" />
        ) : null}
      </div>
      <Scrollbars>
        <div className={`${styles.innerContainer} `}>
          {content.length > 0 ? content : <p className={styles.placeholder}>{placeholder}</p>}
        </div>
      </Scrollbars>
    </NotificationTabWrapper>
  )
}

export default ConsultationsTab
