import cloneDeep from "lodash/cloneDeep"
import React, { useContext, useEffect, useRef, useState } from "react"
import { Location, useLocation, useNavigate } from "react-router-dom"
import { GlobalContext } from "src/App"
import Deck from "src/common-components/Deck/Deck"
import { NewPatientHistoryType, PatientFormData } from "src/types"
import { createFormContext } from "src/user-components/createFormContext"
import AddressFormSlide from "./AddressFormSlide/AddressFormSlide"
import DisclaimerFormSlide from "./DisclaimerFormSlide/DisclaimerFormSlide"
import FifthFormSlideWrapper from "./FifthFormSlide/FifthFormSlideWrapper"
import FirstFormSlide from "./FirstFormSlide/FirstFormSlide"
import FormDataReview from "./FormDataReview/FormDataReview"
import FourthFormSlide from "./FourthFormSlide/FourthFormWrapper"
import styles from "./NewPatientForm.module.scss"
import SixthFormSlideWrapper from "./SixthFormSlide/SixthFormSlideWrapper"
import ThirdFormSlide from "./ThirdFormSlide/ThirdFormSlide"

const initialValues: PatientFormData = {
  firstName: "",
  lastName: "",
  foreName: "",
  birthName: "",
  mothersName: "",
  height: "",
  weight: "",
  tajNum: "",
  address: {
    city: "",
    country: "",
    region: "",
    street: "",
    streetNum: "",
    zipCode: "",
  },
  birthYear: "",
  beenToHospital: "",
  birthMonth: "default",
  birthDay: "default",
  sex: "",
  hasDrugAllergy: "",
  hasPersistentDisease: "",
  drugAllergy: [],
  hospitalVisits: [],
  illnesses: [],
  GP: { id: null, name: "" },
}

export const newPatientFormContext = createFormContext<PatientFormData>()

const NewPatientForm: React.FC<{
  setKey: React.Dispatch<React.SetStateAction<number>>
}> = ({ setKey }) => {
  const { user } = useContext(GlobalContext)
  const [formState, setFormState] = useState(0) //keeping track of which card to show
  const [formValues, setFormValues] = useState<PatientFormData>(cloneDeep(initialValues))
  const isFirstLoadRef = useRef<boolean>(true)

  const navigate = useNavigate()
  const location = useLocation() as Location & { state: NewPatientHistoryType }
  const isEditing = location.state?.patientID

  const getDefaultData = () => {
    if (isEditing) {
      const patient = user.patients.find(({ id }) => id === location.state?.patientID)
      patient && setFormValues({ ...patient.profile })
    } else if (user.userProfile.address) {
      const newFormValues = { ...formValues }
      newFormValues.address = user.userProfile.address
      setFormValues({ ...newFormValues })
    }
  }

  if (isFirstLoadRef.current) {
    getDefaultData()
    isFirstLoadRef.current = false
  }

  useEffect(() => {
    if (!isFirstLoadRef.current) {
      getDefaultData()
    }
  }, [user.patients])

  const cards = [
    <FirstFormSlide key={1} />,
    <AddressFormSlide key={2} />,
    <ThirdFormSlide key={3} />,
    <FourthFormSlide key={4} />,
    <FifthFormSlideWrapper key={5} />,
    <SixthFormSlideWrapper key={6} />,
  ]

  if (!isEditing) cards.unshift(<DisclaimerFormSlide key={7} />)

  return (
    <div className={styles.container}>
      <newPatientFormContext.Provider
        value={{
          setFormState,
          formState,
          formValues,
          maxFormState: cards.length + 1,
          setFormValues,
        }}
      >
        <div className={styles.cardContainer}>
          <Deck formState={formState}>{cards}</Deck>
        </div>
      </newPatientFormContext.Provider>
      {formState === cards.length ? (
        <FormDataReview
          patientData={formValues}
          backAction={() => setFormState(formState - 1)}
          setKey={setKey}
        />
      ) : null}
    </div>
  )
}

export default NewPatientForm
