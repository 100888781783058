import React from "react"
import Popup from "../../../common-components/Popup/Popup"
import { ConsultationTaskData } from "../../../types"
import styles from "./ConsultationPopup.module.scss"

interface ConsultationPopupProps {
  addConsultationTask: (type: ConsultationTaskData["type"]) => void
  hideOverlay: () => void
}

const ConsultationPopup: React.FC<ConsultationPopupProps> = ({
  addConsultationTask,
  hideOverlay,
}) => {
  return (
    <Popup
      confirmButton={{
        title: "Videochaten",
        action: () => {
          addConsultationTask("video")
          hideOverlay()
        },
        className: styles.popupButton,
      }}
      onClose={hideOverlay}
      cancelButton={{
        title: "Személyesen",
        buttonStyle: "blue",
        action: () => {
          addConsultationTask("personal")
          hideOverlay()
        },
        className: styles.popupButton,
      }}
      title="Kérelem konzultációra"
    >
      <div className={styles.videoConsultationPopupTextContainer}>
        Konzultáicós időpontot kérhet az ügyet intéző orvostól. Az időpont egyeztetéséhez üzenetben
        fogja felvenni Önnel a kapcsolatot. Válassza ki, hogy személyesen vagy videochaten keresztül
        szeretne konzultálni.
      </div>
    </Popup>
  )
}

export default ConsultationPopup
