import React from "react"
import FileUpload from "../../../common-components/FileUpload/FileUpload"
import ShowImageFromServer from "../../../common-components/ShowImageFromServer/ShowImageFromServer"
import { useIsMobileSize } from "../../../common/hooks/useIsMobileSize"
import { MediaAtCreation, Ticket } from "../../../types"
import styles from "./TicketInfoFileUpload.module.scss"

interface Props {
  ticket: Ticket
  files: MediaAtCreation[]
  setFiles: (v: MediaAtCreation[]) => void
}

const TicketInfoFileUpload: React.FC<Props> = ({ ticket, setFiles, files }) => {
  const isMobile = useIsMobileSize()

  const fileUpload = (
    <div className={styles.fileUploadButtonContainer}>
      <FileUpload
        files={files}
        setFiles={setFiles}
        shouldGiveDescription
        buttonText="Dokumentum feltöltése"
        relatedTicketID={ticket.ticketID}
      />
    </div>
  )

  const userFileLabels: JSX.Element[] = ticket.ticketData.files.map((file) => (
    <ShowImageFromServer key={file.id} file={file} />
  ))

  return (
    <div>
      <div className={styles.flexRow}>
        {userFileLabels.length > 0 ? <h6>Az Ön által csatolt dokumentumok</h6> : null}
        {!isMobile && fileUpload}
      </div>
      {userFileLabels}
      {isMobile && fileUpload}
    </div>
  )
}

export default TicketInfoFileUpload
