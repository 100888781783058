import { cloneDeep } from "lodash"
import {
  Address,
  ApiResponse,
  Doctor,
  Image,
  MediaAtCreation,
  Name,
  Task,
  TaskLabels,
  Ticket,
  TicketData,
  VideoCall,
} from "src/types"
import { symptomsMap } from "./symptoms"

export const isLeapYear = (year: number) => year % 4 === 0

export const howManyDaysInMonth = (monthNumber: string, year: string) => {
  const month = monthNumber
  if (month === "4" || month === "6" || month === "9" || month === "11") {
    return 30
  }
  if (month === "2") {
    if (isLeapYear(parseInt(year))) {
      return 29
    }
    return 28
  }
  return 31
}

export const givenNumberOfDigits = (numOfDigits: number, number: string | number) => {
  return ("0".repeat(numOfDigits) + number).slice(numOfDigits * -1)
}

export const removeElementFromArray = (array: any[], elementToRemove: any) => {
  const index = array.indexOf(elementToRemove)
  if (index > -1) {
    array.splice(index, 1)
  }
  return array
}

export const removeObjectWithGivenEntryFromArray = (
  array: any[],
  objectAttributeName: string,
  objectAttributeValue: any,
) => {
  for (let index = 0; index < array.length; index++) {
    if (array[index][objectAttributeName] === objectAttributeValue) {
      return removeElementFromArray(array, index)
    }
  }
  return array
}

export const arrayIncludesObjectWithGivenAttribute = (
  array: any[],
  searchedAttributeName: string,
  searchedAttributeValue: any,
) => {
  for (let i = 0; i < array.length; i++) {
    if (array[i][searchedAttributeName] === searchedAttributeValue) {
      return true
    }
  }
  return false
}

export const getSymptomName = (symptomID: number): string => {
  const name = symptomsMap.get(symptomID)
  return name ? name : symptomID.toString()
}

export const getSymptomNames = (symptoms: TicketData["symptoms"] | null) => {
  if (!symptoms) return null
  const symptomNames = symptoms.map((symptom) => symptomsMap.get(symptom.symptomID))
  return symptomNames
}

const dayNames = ["Vasárnap", "Hétfő", "Kedd", "Szerda", "Csütörtök", "Péntek", "Szombat"]

export const getDateFromInt = (dateInt: number, time: boolean = false) => {
  const date = new Date(dateInt > 2552046556 ? dateInt : dateInt * 1000)
  const timeToShow = time ? `${date.getHours()}:${date.getMinutes()}` : ""
  const dateToShow = `${date.getFullYear()}. ${givenNumberOfDigits(
    2,
    date.getMonth() + 1,
  )}. ${givenNumberOfDigits(2, date.getDate())}. ${timeToShow}`
  return dateToShow
}

export const whatDayIsIt = (date: Date) => {
  const today = new Date()
  if (date.getMonth() == today.getMonth() && date.getFullYear() == today.getFullYear()) {
    if (date.getDate() === today.getDate()) return "today"
    if (date.getDate() === today.getDate() - 1) return "yesterday"
    else return null
  } else return null
}

export const getNotiDateFromInt = (dateInt: number) => {
  const date = new Date(dateInt > 2552046556 ? dateInt : dateInt * 1000)
  const timeToShow = `${date.getHours()}:${givenNumberOfDigits(2, date.getMinutes())}`
  let dayToShow = ""
  if (whatDayIsIt(date) === "today") dayToShow = ""
  else if (whatDayIsIt(date) === "yesterday") dayToShow = "Tegnap"
  else dayToShow = dayNames[date.getDay()]
  if (dayToShow === "") {
    return timeToShow
  } else if (dayToShow === "Tegnap") {
    return dayToShow
  } else if (Date.now() - dateInt * 1000 < 518400000) {
    return dayToShow
  } else {
    return getDateFromInt(dateInt)
  }
}

export function isObject(item: any) {
  return item && typeof item === "object" && !Array.isArray(item)
}

export function mergeDeep(target: any, source: any) {
  let output = Object.assign({}, target)
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!(key in target)) Object.assign(output, { [key]: source[key] })
        else output[key] = mergeDeep(target[key], source[key])
      } else {
        Object.assign(output, { [key]: source[key] })
      }
    })
  }
  return output
}

export const getDisplayNameFromNames = (name: Name | null | undefined, fallBack?: string) => {
  if (!name) return fallBack ?? "Törölt / ismeretlen felhasználó"
  return `${name.title ? name.title + " " : ""}${name.lastName} ${name.firstName} ${
    name.foreName ? name.foreName : ""
  }`
}

export const getTicketStatusFromTicket = (ticket: Ticket) => {
  if (!ticket.doctor.id) return "notTaken"
  else if (!ticket.ready) return "wip"
  else return "ready"
}

export const checkIfApiError = (ApiResponse: ApiResponse) => {
  if (!ApiResponse.status || ApiResponse.status.result !== "ok") {
    console.log("Error at communicating with server")
    console.log(ApiResponse)
    return true
  }
  return false
}

export const getDisplayDateFromDate = (date: { year: string; month: string; day: string }) => {
  return `${date.year}. ${givenNumberOfDigits(2, date.month)}. ${givenNumberOfDigits(2, date.day)}.`
}

export const toBase64 = (file: File): Promise<string> =>
  new Promise<any>((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

export const mutateTicketTasks = (
  ticketData: TicketData,
  taskIndex: number,
  task: Task,
): TicketData => {
  const data = task.doctorData
  let newTasks = ticketData.tasks
  newTasks[taskIndex].doctorData = {
    ...newTasks[taskIndex].doctorData,
    ...data,
  }
  return { ...ticketData, tasks: newTasks }
}

export const getDayOptions = (year: string, month: string) => {
  let dayOptions: Array<{ value: number }> = []
  const daysInMonth = howManyDaysInMonth(month, year)
  for (let i = 1; i <= daysInMonth; i++) {
    dayOptions.push({ value: i })
  }
  return dayOptions
}

export const formatAddressString = (address: Address) => {
  return [address.zipCode, address.city + ",", address.street, address.streetNum].join(" ")
}

export const addSymptomToNewTicketFormContext = (
  context: {
    setFormState: React.Dispatch<React.SetStateAction<number>>
    setFormValues: React.Dispatch<React.SetStateAction<TicketData>>
    formState: number
    maxFormState: number
    formValues: TicketData
    setShouldSubmit?: React.Dispatch<boolean> | undefined
  },
  symptomID: number,
) => {
  if (
    context.formValues.symptoms &&
    !arrayIncludesObjectWithGivenAttribute(context.formValues.symptoms, "symptomID", symptomID)
  ) {
    context.setFormValues({
      ...context.formValues,
      symptoms: [
        ...context.formValues.symptoms,
        {
          symptomID,
          symptomInterval: { start: new Date().getTime(), end: null },
        },
      ],
    })
    return true
  }
  return false
}

export const limitStringLength = (text: string, maxLength: number): string => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength > 4 ? maxLength - 4 : maxLength) + " ..."
  }
  return text
}

export const toImageData = async (file: MediaAtCreation): Promise<Image> => {
  const base64data = await toBase64(file.file)
  return { data: base64data, meta: file.meta }
}

export const getMonthBoundary = (date: Date) => {
  const beforeBoundary = new Date(date.getTime())
  beforeBoundary.setMonth(beforeBoundary.getMonth() - 1)
  beforeBoundary.setDate(20)
  const afterBoundary = new Date(date.getTime())
  afterBoundary.setMonth(afterBoundary.getMonth() + 1)
  afterBoundary.setDate(8)
  return {
    startTime: Math.round(beforeBoundary.getTime() / 1000),
    endTime: Math.round(afterBoundary.getTime() / 1000),
  }
}

export const addHourToDate = (date: Date, hour: number) => {
  const dateCopy = new Date(date.getTime())
  dateCopy.setHours(date.getHours() + hour)
  return dateCopy
}

export const convertSecondsToJSDate = (int: number | null) => {
  if (!int) return null
  return new Date(int * 1000)
}

export const getNameSubset = (name: Name): Name => {
  return {
    firstName: name.firstName,
    foreName: name.foreName,
    lastName: name.lastName,
    title: name.title,
  }
}

export const getVideoCallTask = (videoCallID: number, tickets: Ticket[]) => {
  let ticketData, taskIndex, ticketID
  outer: for (let ticket of tickets) {
    for (let [index, task] of ticket.ticketData.tasks.entries()) {
      if (
        task.name === "consultation" &&
        task.type === "video" &&
        task.doctorData.videoCallID === videoCallID
      ) {
        ticketData = ticket.ticketData
        taskIndex = index
        ticketID = ticket.ticketID
        break outer
      }
    }
  }
  return { ticketData, taskIndex, ticketID }
}

export const filterVideoCalls = (videoCalls: VideoCall[], tickets: Ticket[]) => {
  if (!videoCalls) return []
  let filteredVideoCalls: VideoCall[] = []
  videoCalls.forEach((v) => {
    const { taskIndex, ticketData } = getVideoCallTask(v.id, tickets)
    if (taskIndex && ticketData && ticketData.tasks[taskIndex].state === null) {
      filteredVideoCalls.push(v)
    }
  })
  return filteredVideoCalls
}

export const isCallInitiator = (isDoctor: boolean, videoCall: VideoCall) => {
  return (videoCall.reverseRoles && !isDoctor) || (!videoCall.reverseRoles && isDoctor)
}

export const prefillInitialValues = (initialValues: any, valuesToPrefillFrom: any) => {
  const i = cloneDeep(initialValues)
  for (let key in i) {
    if (valuesToPrefillFrom[key]) {
      if (typeof valuesToPrefillFrom[key] !== "object") i[key] = valuesToPrefillFrom[key]
      else i[key] = prefillInitialValues(i[key], valuesToPrefillFrom[key])
    }
  }
  return i
}

export const createFormikPathsObj = <T>(initialValues: any, pretag?: string) => {
  let fieldNames = {} as { [key in keyof T]: string }
  for (let key in initialValues) (fieldNames as any)[key] = pretag ? pretag + "." + key : key
  return fieldNames
}

export const getTaskDisplayName = (task: Task) => {
  if (task.name === "diagnosis" && task.covid) return "Covid"
  if (task.name === "consultation")
    return task.type === "personal" ? "Személyes konzultáció" : "Videókonzultáció"
  return TaskLabels[task.name]
}

export const isFunction = (f: any) => {
  return typeof f === "function"
}

export const getFormatFromFilename = (name: string) => name.split(".").reverse()[0]

export const displayAddress = (a: Address) => {
  return `${a.city} ${a.zipCode}, ${a.street} ${a.streetNum}`
}

export const getLoadedDrTicketByID = (tickets: Doctor["tickets"], ticketID: number) => {
  const allTickets = [...tickets.inProgress, ...tickets.incoming, ...tickets.open]
  return allTickets.find((ticket) => ticketID === ticket.ticketID)
}

export const canEditConsultationDate = (dateInSeconds: number) => {
  return dateInSeconds - 3 * 3600 > Date.now() / 1000
}

export const toServerTime = (date: Date) => {
  return Math.round(date.getTime() / 1000)
}

export const getCookie = () => {
  const sessionKeyPosition = document.cookie.indexOf("session_key")
  if (sessionKeyPosition != -1) {
    return document.cookie.substr(sessionKeyPosition + "session_key".length + 1, 64)
  }
  return ""
}

export const getPageBaseElement = () => document.getElementById("page-base")

export const disablePageBaseScrolling = () => {
  const el = getPageBaseElement()
  if (!el) return

  el.style.overflowY = "hidden"
}

export const enablePageBaseScrolling = () => {
  const el = getPageBaseElement()
  if (!el) return

  el.style.overflowY = "auto"
}

export const assertNonExhaustiveSwitch = (val: never): never => {
  throw new Error("Non-exhaustive switch or if logic")
}
