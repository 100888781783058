import React from "react"
import { deleteUser } from "../apicalls/deleteUser"
import styles from "./DeleteUserProfilePopup.module.css"
import Popup from "./Popup/Popup"
import layoutStyles from "src/global-styles/layouts.module.css"

interface Props {
  setUserDeletePopupOpen: (val: boolean) => void
  userToken: string
}

const DeleteUserProfilePopup: React.FC<Props> = ({ setUserDeletePopupOpen, userToken }) => {
  return (
    <Popup
      fullscreenContainer
      cancelButton={{ title: "Mégsem" }}
      onClose={() => setUserDeletePopupOpen(false)}
      confirmButton={{
        title: "Törlés",
        buttonStyle: "red",
        action: () => deleteUser(userToken),
      }}
    >
      <div className={layoutStyles.flexColCent}>
        A törlés gombra kattintva a profilja törlődik.
        <br /> <strong>A folyamat nem visszafordítható.</strong>
      </div>
    </Popup>
  )
}
export default DeleteUserProfilePopup
