import React, { useContext, useEffect, useState } from "react"
import { isValidHungarianZipCode, necessary } from "src/common/validationFunctions"
import { Address } from "src/types"
import TaskCardBase from "src/user-components/NewTicketPage/TaskCardBase"
import { newPatientFormContext } from "../NewPatientForm"
import AddressTextInputs from "./AddressTextInputs"

export let addressInitialValuesBase: { address: Address } = {
  address: {
    city: "",
    country: "",
    region: "",
    street: "",
    streetNum: "",
    zipCode: "",
  },
}

export const validateAddress = (values: { address: Address }) => {
  let errors = { address: {} } as { address: Address }
  necessary(values.address, "country", errors.address)
  necessary(values.address, "region", errors.address)
  necessary(values.address, "zipCode", errors.address)
  necessary(values.address, "city", errors.address)
  necessary(values.address, "street", errors.address)
  necessary(values.address, "streetNum", errors.address)
  if (!isValidHungarianZipCode(values.address.zipCode))
    errors.address["zipCode"] = "Helytelen irányítószám"
  if (Object.keys(errors.address).length === 0) return {}
  return errors
}

const AddressFormSlide: React.FC = () => {
  const [loaded, setLoaded] = useState<boolean>(false)
  const [addressInitialValues, setAddressInitialValues] = useState(addressInitialValuesBase)
  const formContext = useContext(newPatientFormContext)

  useEffect(() => {
    const {
      formValues: { address },
    } = formContext
    if (address.city !== "" && !loaded) {
      const newAddressInitialValues = {
        address: {
          city: address.city,
          country: address.country,
          region: address.region,
          street: address.street,
          streetNum: address.streetNum,
          zipCode: address.zipCode,
        },
      }
      setLoaded(true)
      setAddressInitialValues(newAddressInitialValues)
    }
  }, [formContext.formValues])

  return (
    <TaskCardBase
      formContext={formContext}
      initialValues={addressInitialValues}
      initialErrors={addressInitialValues}
      formikValidate={validateAddress}
      title="Lakcím"
      justifyContent="space-between"
      subtitle="A pácienshez tartozó állandó lakcím"
    >
      <AddressTextInputs />
    </TaskCardBase>
  )
}

export default AddressFormSlide
