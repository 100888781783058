import { ClockView } from "@material-ui/pickers"
import React from "react"

interface Props {
  date: Date
  setDate: React.Dispatch<React.SetStateAction<Date>>
  activeClock: "hours" | "minutes"
  setActiveClock: React.Dispatch<React.SetStateAction<"hours" | "minutes">>
  setShouldShowTimePicker: React.Dispatch<React.SetStateAction<boolean>>
  maxDate?: Date
  minDate?: Date
}

const TimeChooserClock: React.FC<Props> = ({
  date,
  setDate,
  activeClock,
  setActiveClock,
  setShouldShowTimePicker,
  maxDate,
  minDate,
}) => {
  const setDateWhenHourChanges = (date: Date, isFinished?: boolean) => {
    if (maxDate && date.getDate() === maxDate.getDate() && date.getHours() > maxDate.getHours())
      return
    if (minDate && date.getDate() === minDate.getDate() && date.getHours() < minDate.getHours())
      return
    setDate(date as Date)
    if (isFinished) {
      setTimeout(() => setActiveClock("minutes"), 100)
    }
  }

  const setDateWhenMinChanges = (date: Date, isFinished?: boolean) => {
    if (maxDate && date > maxDate) return
    if (minDate && date < minDate) return
    setDateWhenHourChanges(date, isFinished)
    if (isFinished) setTimeout(() => setShouldShowTimePicker(false), 100)
  }

  return (
    <ClockView
      date={date}
      onHourChange={(date, isFinished) => setDateWhenHourChanges(date as Date, isFinished)}
      onMinutesChange={(date, isFinished) => setDateWhenMinChanges(date as Date, isFinished)}
      onSecondsChange={() => {}}
      type={activeClock}
      ampm={false}
    />
  )
}

export default TimeChooserClock
