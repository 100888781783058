import React, { useContext, useEffect, useState } from "react"
import { getDoctorList } from "src/apicalls/getDoctorList"
import { GlobalContext } from "src/App"
import FileUpload from "src/common-components/FileUpload/FileUpload"
import RadioButton from "src/common-components/RadioButton/RadioButton"
import SearchableSelect from "src/common-components/SearchableSelect/SearchableSelect"
import { ImageMeta } from "src/types"
import { newTicketFormContext } from "../NewTicketPage"
import TaskCardBase from "../TaskCardBase"
import styles from "./DrChooseCard.module.css"

interface Props {}

const DrChooseCard: React.FC<Props> = () => {
  const formContext = useContext(newTicketFormContext)
  const { userToken } = useContext(GlobalContext)
  const [drOptions, setDrOptions] = useState<{ label: string; value: number }[]>([])
  const [wantToPickDoctor, setWantToPickDoctor] = useState(
    formContext.formValues.targetDrID ? true : false,
  )

  useEffect(() => {
    const getList = async () => {
      const res = await getDoctorList(userToken)
      const drOptions = res.status.message.map((doctor) => {
        const name = `${doctor.lastName} ${doctor.firstName} ${
          doctor.foreName ? doctor.foreName : ""
        }`
        return { label: name, value: doctor.userID }
      })
      setDrOptions(drOptions)
    }
    getList()
  }, [])
  useEffect(() => {
    if (!wantToPickDoctor) {
      formContext.setFormValues({
        ...formContext.formValues,
        targetDrID: null,
      })
    }
  }, [wantToPickDoctor])

  return (
    <TaskCardBase
      formContext={formContext}
      initialValues={{}}
      title="Doktor kiválasztása, ügy elindítása"
      gap
    >
      <RadioButton
        customOnClick={(value: boolean) => setWantToPickDoctor(value)}
        customValue={wantToPickDoctor}
        options={[
          { label: "Igen", value: true },
          { label: "Nem", value: false },
        ]}
        name="wantToPickDoctor"
      />
      <p className={styles.note}>
        {wantToPickDoctor
          ? "Orvosaink leterheltségétől függően tudják a kéréseiket feldolgozni, jelen helyzetben a terhelés jelentős, ezért szíves türelmüket kérjük a nevükben is."
          : "Figyelem! Bizonyos dolgokat (pl. táppénzes papírt, beutaló) csak a saját háziorvosánál tud igényelni, így kérjük válassza ki a listából. Amennyiben rendszerünket még nem használja orvosa, javasolja neki. "}
      </p>
      <SearchableSelect
        name="targetDrID"
        options={drOptions}
        disabled={!wantToPickDoctor}
        placeholder="Válasszon orvost"
        customOnChange={(value: number) =>
          formContext.setFormValues({
            ...formContext.formValues,
            targetDrID: value,
          })
        }
        customValue={formContext.formValues.targetDrID}
      />
      <div className={styles.finalNotes}>
        <p>
          További, az ügyhöz kapcsolódó igényeket (pl.: igazolás, recept, táppénzes papír) az ügyön
          belül az "Új igény" menüpont alatt tud majd indítani.
        </p>
        <p>
          A "Befejezés" gombra való kattintással az ügy bekerül a rendszerbe, ezért előtte
          ellenőrizze a megadott adatokat!
        </p>
      </div>
    </TaskCardBase>
  )
}

export default DrChooseCard
