import { getIn } from "formik"
import React from "react"
import { useContext } from "react"
import { useState } from "react"
import { patchTicket } from "../../../../apicalls"
import { GlobalContext } from "../../../../App"
import { canEditConsultationDate, toServerTime } from "../../../../common/utilityFunctions"
import { PersonalConsultationTaskData, Ticket } from "../../../../types"
import SetAppointmentDate from "./SetAppointmentDate"
import styles from "./SetPersonalConsultationAppointment.module.css"

export const getInitialConsultationDate = (date: number | undefined | null) => {
  if (date) return new Date(date * 1000)
  const newDate = new Date()
  newDate.setSeconds(0, 0)
  return newDate
}

interface Props {
  ticket: Ticket
  closeWindow: () => void
  taskIndex: number
}

const SetPersonalConsultationAppointment: React.FC<Props> = ({
  closeWindow,
  ticket,
  taskIndex,
}) => {
  const task = ticket.ticketData.tasks[taskIndex] as PersonalConsultationTaskData
  if (task.name !== "consultation" || task.type !== "personal") {
    throw "Unexpected task name or type. Expected: consultion, personal"
  }

  const { userToken, setShouldUpdateUserDataFromServer } = useContext(GlobalContext)

  const [date, setDate] = useState(() => getInitialConsultationDate(task.doctorData.date))

  const isEditing = !!task.doctorData.date

  const isDateChangable = !task.doctorData.date || canEditConsultationDate(task.doctorData.date)

  const submit = async () => {
    task.doctorData.date = toServerTime(date)
    const res = await patchTicket(userToken, {
      ticketID: ticket.ticketID,
      ticketData: ticket.ticketData,
    })
    setShouldUpdateUserDataFromServer(true)
    closeWindow()
  }

  return (
    <SetAppointmentDate
      date={date}
      setDate={setDate}
      isEditing={isEditing}
      isDateChangable={isDateChangable}
      submit={submit}
    />
  )
}

export default SetPersonalConsultationAppointment
