import { DrugForm, LogBookBaseEntryTitles } from "src/types"

export const monthOptions = [
  { text: "Január", value: "1" },
  { text: "Február", value: "2" },
  { text: "Március", value: "3" },
  { text: "Április", value: "4" },
  { text: "Május", value: "5" },
  { text: "Június", value: "6" },
  { text: "Július", value: "7" },
  { text: "Augusztus", value: "8" },
  { text: "Szeptember", value: "9" },
  { text: "Október", value: "10" },
  { text: "November", value: "11" },
  { text: "December", value: "12" },
]

export const titleOptions = [
  { text: "Nincs", value: "" },
  { text: "Dr.", value: "Dr." },
  { text: "Dr. Prof.", value: "Dr. Prof." },
]

export const baseHospitalDiagnosises = [
  "Infectio virosa/Vírusfertőzés",
  "Vomitus/Hányás",
  "Enteritis acuta/Hasmenés",
  "Gastroenteritis acuta/Hányás, hasmenés",
  "Otitis media acuta/Középfül gyulladás",
  "Felső légúti fertőzés",
  "Rhinitis acuta/Nátha",
  "Exsiccatio/Kiszáradás",
  "Obstruktív Bronchitis",
  "Bronchiolitis",
  "Laryngitis subglottica/Croup",
  "Bronchopneumonia/Tüdőgyulladás",
  "Tonsillitis acuta/follicularis/Tüszős mandula gyulladás",
  "Lázgörcs",
]

export const baseSymptomIDs = [14, 13, 11, 87, 153, 15, 133, 10, 101, 50, 33, 107, 124, 187]
export const covidSymptomsIDs = [
  11, 12, 228, 26, 27, 29, 33, 44, 47, 50, 124, 133, 153, 177, 15, 987,
]

export const logTitlePlaceholderDict: {
  [key in LogBookBaseEntryTitles]: string
} = {
  Fejfájás: "",
  Gyógyszerezés: "",
  Hasfájás: "",
  Hányás: "",
  Kiütés: "",
  Láz: "Hőmérséklet",
  Orrszívás: "",
  Széklet: "",
  Vizelet: "",
}

export const unitSelectOptions: { value: DrugForm["agentUnit"] }[] = [
  { value: "mg" },
  { value: "mg/5ml" },
  { value: "mg/ml" },
  { value: "μg" },
]
