import React, { useContext, useEffect, useRef, useState } from "react"
import SixthFormSlide from "./SixthFormSlide"
import TaskCardBase from "src/user-components/NewTicketPage/TaskCardBase"
import { newPatientFormContext } from "../NewPatientForm"
import { CreatePatientDataSixthCard, illnessForm } from "src/types"
import cloneDeep from "lodash/cloneDeep"

let initialValuesBase: CreatePatientDataSixthCard = {
  hasPersistentDisease: "",
  illnesses: [{ takesDrugs: undefined, illnessName: "", drugs: [] }],
}

const SixthFormSlideWrapper: React.FC = ({}) => {
  const formContext = useContext(newPatientFormContext)
  const [initialValues, setInitialValues] = useState(cloneDeep(initialValuesBase))
  const [numOfFields, setNumOfFields] = useState<number[]>([])
  const initialNumOfFieldsRef = useRef<number | undefined>(undefined)

  if (initialNumOfFieldsRef.current === undefined) {
    const { formValues } = formContext
    const initialValuesCopy = { ...initialValues }
    initialValuesCopy.hasPersistentDisease = formValues.hasPersistentDisease
    formValues.illnesses.forEach((illness, index) => {
      initialValues.illnesses[index] = illness
    })
    setInitialValues({ ...initialValuesCopy })
    const numOfFields = formValues.illnesses.map((illness) => {
      return illness.drugs?.length || 0
    })
    setNumOfFields(numOfFields)
    initialNumOfFieldsRef.current = formValues.illnesses.length || 0
  }

  return (
    <TaskCardBase
      formContext={formContext}
      initialValues={initialValues}
      title="Állandó betegségek"
    >
      <SixthFormSlide
        wrapperSetNumOfFields={setNumOfFields}
        wrapperNumOfFields={numOfFields}
        initialNumOfFields={initialNumOfFieldsRef.current}
      />
    </TaskCardBase>
  )
}

export default SixthFormSlideWrapper
