import { ApiResponse, DoctorProfile, UserProfile } from "src/types"
import { APIBASEURL, headersBase } from "."

export const createExtendedUserProfile = async (
  userToken: string,
  body: UserProfile | DoctorProfile,
) => {
  const res = await fetch(APIBASEURL + "/profile", {
    method: "POST",
    headers: {
      ...headersBase,
      "User-Token": userToken,
      "content-type": "application/json",
    },
    body: JSON.stringify(body),
  })
  const result: ApiResponse = await res.json()
  return result
}
