import React, { useContext, useEffect, useRef, useState } from "react"
import Tooltip from "src/common-components/Tooltip/Tooltip"
import { patchTicketAssignee } from "src/apicalls/patchTicketAssignee"
import { useOnClickOutside } from "src/common/hooks/useOnClickOutside"
import { TicketAssignee } from "src/types"
import styles from "./TicketAssigneeDisplay.module.scss"
import { GlobalContext } from "src/App"
import { useIsMobileSize } from "src/common/hooks/useIsMobileSize"

interface Props {
  ticketID: number
  initialState: {
    assignee: TicketAssignee
    blocked: boolean
  }
  disabled?: boolean
  menuPosition?: "top" | "bottom"
}

const classIf = (condition: boolean, className: string) => (condition ? className : "")

const Icon: React.FC<{ icon: string; classNames?: string[] }> = ({ icon, classNames }) => {
  return (
    <span className={["material-symbols-outlined", "notranslate", ...(classNames || [])].join(" ")}>
      {icon}
    </span>
  )
}

const TicketAssigneeDisplay: React.FC<Props> = ({
  ticketID,
  initialState,
  disabled,
  menuPosition = "top",
}) => {
  const { userToken, setShouldUpdateUserDataFromServer, doctor } = useContext(GlobalContext)
  const [assignee, setAssignee] = useState<TicketAssignee>(initialState.assignee)
  const [blocked, setBlocked] = useState<boolean>(initialState.blocked)
  const [shouldUpdate, setShouldUpdate] = useState<boolean>(false)
  const [selectorOpen, setSelectorOpen] = useState(false)
  const isMobile = useIsMobileSize()
  const buttonRef = useRef<HTMLDivElement>(null)
  const clickRef = useOnClickOutside(() => setSelectorOpen(false), buttonRef)

  const onChange = (target: TicketAssignee) => {
    if (assignee !== target) {
      setBlocked(false)
      setAssignee(target)
    } else {
      setBlocked(!blocked)
    }
    setShouldUpdate(true)
  }

  useEffect(() => {
    const updateTicketData = async () => {
      await patchTicketAssignee(userToken, {
        ticketID,
        assignee,
        blocked,
      })
      setShouldUpdateUserDataFromServer(true)
    }
    if (shouldUpdate) {
      setShouldUpdate(false)
      updateTicketData()
    }
  }, [shouldUpdate])

  const icons = {
    doctor: "stethoscope",
    assistant: "medical_information",
    parent: "person",
  }

  const tooltip = {
    doctor: "Orvosra vár",
    assistant: "Asszisztensre vár",
    parent: "Szülőre vár",
  }

  if (!doctor.doctor) {
    return null
  }

  return (
    <div className={[styles.container, classIf(!!disabled, styles.disabled)].join(" ")}>
      <Tooltip tooltipText={tooltip[assignee]} disabled={disabled || isMobile}>
        <div
          ref={buttonRef}
          onClick={() => setSelectorOpen(!selectorOpen)}
          className={`${styles.assignee} ${classIf(blocked, styles.blocked)}`}
        >
          <Icon icon={icons[assignee]} classNames={[styles.icon]} />
        </div>
      </Tooltip>
      {selectorOpen ? (
        <div
          className={[
            styles.selector,
            classIf(menuPosition === "bottom", styles.bottomPlacement),
          ].join(" ")}
          ref={clickRef}
        >
          <div
            className={[
              styles.button,
              classIf(assignee === "parent", styles.active),
              classIf(assignee === "parent" && blocked, styles.blocked),
            ].join(" ")}
            onClick={() => onChange("parent")}
          >
            <Icon icon={icons.parent} classNames={[styles.icon]} />
          </div>
          <div
            className={[
              styles.button,
              classIf(assignee === "assistant", styles.active),
              classIf(assignee === "assistant" && blocked, styles.blocked),
            ].join(" ")}
            onClick={() => onChange("assistant")}
          >
            <Icon icon={icons.assistant} classNames={[styles.icon]} />
          </div>
          <div
            className={[
              styles.button,
              classIf(assignee === "doctor", styles.active),
              classIf(assignee === "doctor" && blocked, styles.blocked),
            ].join(" ")}
            onClick={() => onChange("doctor")}
          >
            <Icon icon={icons.doctor} classNames={[styles.icon]} />
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default TicketAssigneeDisplay
