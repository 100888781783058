import React, { useContext, useEffect } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { GlobalContext } from "../../../App"
import OVIcon from "../../../common-components/Icon/OVIcon"
import { useIsMobileSize } from "../../../common/hooks/useIsMobileSize"
import { disablePageBaseScrolling, enablePageBaseScrolling } from "../../../common/utilityFunctions"
import NewNotiCirc from "../../../dr-components/DrHomePage/PageSelector/NewNotiCirc"
import { Ticket } from "../../../types"
import Messages from "./Messages"
import styles from "./MessagesWrapper.module.scss"

interface Props {
  ticket: Ticket
}

const MessagesWrapper: React.FC<Props> = ({ ticket }) => {
  const shouldMessagingBeOpen = useLocation().pathname.includes("messaging")
  const navigate = useNavigate()
  const location = useLocation()
  const isMobile = useIsMobileSize()
  const { notifications, user, doctor } = useContext(GlobalContext)

  useEffect(() => {
    if (!shouldMessagingBeOpen) enablePageBaseScrolling()
    else disablePageBaseScrolling()
  }, [shouldMessagingBeOpen])

  const numOfUnseenMessages = notifications.unseen.filter(
    (n) => n.subject === "new-message" && n.ticketID === ticket.ticketID,
  ).length

  const openMessaging = () => {
    disablePageBaseScrolling()
    navigate({ pathname: location.pathname + "/messaging" })
  }

  const isDoctor = Object.keys(doctor).length > 0

  return (
    <>
      <div
        className={
          !isMobile || shouldMessagingBeOpen
            ? styles.messagesContainerOpen
            : styles.messagesContainerClosed
        }
      >
        <Messages
          markSeen={!isMobile || shouldMessagingBeOpen}
          ticketID={ticket.ticketID}
          subscriber={ticket.doctor}
          ticketReady={ticket.ready}
          partnerName={isDoctor ? ticket.userProfile! : ticket.doctor.name}
        />
      </div>

      {isMobile && !shouldMessagingBeOpen && (
        <div className={styles.openMessagingButton} onClick={openMessaging}>
          <OVIcon name="message" />
          <div className={styles.newMsgMarkerContainer}>
            <NewNotiCirc numOfNotifications={numOfUnseenMessages} />
          </div>
        </div>
      )}
    </>
  )
}

export default MessagesWrapper
