import { FieldAttributes, useField, useFormikContext } from "formik"
import React from "react"
import styles from "./Checkbox.module.css"

type Props = {
  label: string | JSX.Element
  disabled?: boolean
} & FieldAttributes<{}>

const CheckboxFormik: React.FC<Props> = ({ label, disabled, ...props }) => {
  const [field, meta] = useField<{}>(props)
  const { setFieldValue } = useFormikContext<any>()
  const className = field.value ? styles.checked : styles.unchecked

  const shouldShowError = meta.error && meta.touched

  return (
    <div
      className={styles.checkboxCont}
      onClick={() => {
        if (!disabled) setFieldValue(props.name, !field.value)
      }}
    >
      <div className={[styles.topContainer, disabled ? styles.disabled : ""].join(" ")}>
        <label>{label}</label>
        <div className={`${className} ${shouldShowError ? styles.errorBox : ""}`}>
          {!shouldShowError ? (
            <i className={["material-icons", "notranslate"].join(" ")}>done</i>
          ) : null}
        </div>
      </div>
      {shouldShowError ? <p className={styles.error}>{meta.error}</p> : null}
    </div>
  )
}

export default CheckboxFormik
