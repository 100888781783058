import React, { useContext, useEffect, useReducer, useRef, useState } from "react"
import FifthFormSlide from "./FifthFormSlide"
import TaskCardBase from "src/user-components/NewTicketPage/TaskCardBase"
import { newPatientFormContext } from "../NewPatientForm"
import { CreatePatientDataFifthCard } from "src/types"
import { necessary } from "src/common/validationFunctions"
import cloneDeep from "lodash/cloneDeep"

const validate = (values: CreatePatientDataFifthCard, numOfFields: number) => {
  let errors = {} as CreatePatientDataFifthCard
  necessary(values, "beenToHospital", errors)
  for (let i = 0; i < numOfFields; i++) {
    if (!values["hospitalVisits"] || !values["hospitalVisits"]![i].diagnose) {
      if (!errors["hospitalVisits"]) errors["hospitalVisits"] = []
      if (!errors["hospitalVisits"][i]) errors["hospitalVisits"][i] = {} as any
      errors["hospitalVisits"][i].diagnose = "Mező kitöltése kötelező"
    }
    if (!values["hospitalVisits"] || !values["hospitalVisits"]![i].month) {
      if (!errors["hospitalVisits"]) errors["hospitalVisits"] = []
      errors["hospitalVisits"][i].month = "Mező kitöltése kötelező"
    }
    if (!values["hospitalVisits"] || !values["hospitalVisits"]![i].year) {
      if (!errors["hospitalVisits"]) errors["hospitalVisits"] = []
      errors["hospitalVisits"][i].year = "Mező kitöltése kötelező"
    }
  }
  return errors
}

export let hospitalVisitsInitialValuesBase: CreatePatientDataFifthCard = {
  hospitalVisits: [],
  beenToHospital: "",
}
for (let i = 0; i < 50; i++) {
  hospitalVisitsInitialValuesBase.hospitalVisits[i] = {
    diagnose: "",
    month: "",
    year: "",
    media: null,
    mediaAtCreation: [],
  }
}

const FifthFormSlideWrapper: React.FC = ({}) => {
  const formContext = useContext(newPatientFormContext)
  const [initialValues, setInitialValues] = useState(cloneDeep(hospitalVisitsInitialValuesBase))
  const [numOfFields, setNumOfFields] = useState(0)
  const initialNumOfFieldsRef = useRef<number | undefined>(undefined)

  if (initialNumOfFieldsRef.current === undefined) {
    const { formValues } = formContext
    const initialValuesCopy = { ...initialValues }
    initialValuesCopy.beenToHospital = formValues.beenToHospital
    formValues.hospitalVisits?.forEach((hospitalVisit, index) => {
      initialValuesCopy.hospitalVisits![index] = hospitalVisit
    })
    setInitialValues({ ...initialValuesCopy })
    initialNumOfFieldsRef.current = formValues.hospitalVisits?.length || 0
  }

  return (
    <TaskCardBase
      formContext={formContext}
      initialValues={initialValues}
      title="Kórházi kezelések"
      formikValidate={(values: CreatePatientDataFifthCard) => validate(values, numOfFields)}
    >
      <FifthFormSlide
        initialNumOfFields={initialNumOfFieldsRef.current}
        setNumOfFieldsInParent={setNumOfFields}
      />
    </TaskCardBase>
  )
}

export default FifthFormSlideWrapper
