import React from "react"
import styles from "./Loader.module.css"

interface Props {
  text?: string
  color?: "white" | "blue"
  scale?: number
}

const Loader: React.FC<Props> = ({ text, color = "blue", scale = 1 }) => {
  return (
    <div className={styles.container}>
      <div className={`${styles.loader} ${styles[color]}`} style={{ transform: `scale(${scale})` }}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      {text ? <p className={styles.text}>{text}</p> : null}
    </div>
  )
}

export default Loader
