export const openFileFromResponse = async (res?: Response, blob?: Blob) => {
  if (res) {
    blob = await res.blob()
  }
  const url = URL.createObjectURL(blob)
  if (window.navigator.userAgent.match(/iPad/i) || window.navigator.userAgent.match(/iPhone/i)) {
    window.location.href = url
  } else {
    const link = document.createElement("a")
    link.href = url
    link.target = "_blank"
    link.style.display = "none"
    document.body.appendChild(link)
    link.click()
    link.remove()
    URL.revokeObjectURL(url)
  }
}
