import React, { useState, useEffect, useContext } from "react"
import { TableRow } from "../../common-components/OutpatientSheetDataTable/OutpatientSheetDataTable"
import { getDateFromInt } from "../../common/utilityFunctions"
import {
  PrescriptionTaskData,
  ReferralTaskData,
  SickNoteTaskData,
  SickPayTaskData,
  Task,
  Ticket,
  ConsultationTaskData,
} from "../../types"
import {
  OtherTaskData,
  predefinedOtherTaskTypes,
  predefinedTypesLabels,
} from "../../user-components/NewTicketPage/OtherTaskCard/OtherTaskCard"
import IntervalChooser from "src/common-components/IntervalChooser/IntervalChooser"
import { patchTicketTaskDates } from "src/apicalls/patchTicketTaskDates"
import styles from "./DoctorTasks.module.scss"
import { GlobalContext } from "src/App"

const PrescriptionSpecificData: React.FC<{ task: PrescriptionTaskData; ticket: Ticket }> = ({
  task,
  ticket,
}) => {
  let reason = ""
  let drugName = ""
  let agentAmount = ""
  let agentUnit = ""

  if (task.illnessIndex !== "Egyéb") {
    reason = ticket.patientProfile!.illnesses[parseInt(task.illnessIndex)].illnessName
  } else reason = task.prescriptionReason
  if (task.drugIndex !== "" && task.drugIndex !== "Egyéb") {
    const drug =
      ticket.patientProfile!.illnesses[parseInt(task.illnessIndex)].drugs[parseInt(task.drugIndex)]
    drugName = drug.name
    agentAmount = drug.agentAmount
    agentUnit = drug.agentUnit
  } else drugName = task.drugName
  return (
    <div className={styles.bottomContainer}>
      <TableRow title="Receptkérés oka" value={reason} />
      <TableRow title="Gyógyszer neve" value={drugName} />
      {agentAmount ? (
        <TableRow title="Hatóanyag mennyisége" value={agentAmount + " " + agentUnit} />
      ) : null}
      {task.amount ? <TableRow title="Kért adag" value={task.amount + " havi"} /> : null}
      <TableRow title="Megjegyzés" value={task.additionalInfo} />
    </div>
  )
}

const ReferralSpecificData: React.FC<{ task: ReferralTaskData }> = ({ task }) => {
  return (
    <div className={styles.bottomContainer}>
      <TableRow title="Intézmény" value={task.referralTargetName} />
      <TableRow title="Szakrendelés" value={task.referralTargetDepartment} />
      <TableRow title="Azonosító" value={task.referralTargetID || "Nincs megadva"} />
    </div>
  )
}

const getStartDate = (task: SickNoteTaskData) => new Date(task.startDate! * 1000)
const getEndDate = (task: SickNoteTaskData) =>
  task.endDate ? new Date(task.endDate! * 1000) : null
const getUTCEpoch = (date: Date | null) => date && Math.round(date.getTime() / 1000)

const SickNoteSpecificData: React.FC<{ task: SickNoteTaskData; ticketID: number }> = ({
  task,
  ticketID,
}) => {
  const [startDate, setStartDate] = useState<Date>(getStartDate(task))
  const [endDate, setEndDate] = useState<Date | null>(getEndDate(task))
  const { userToken } = useContext(GlobalContext)

  // Update task dates when changed
  useEffect(() => {
    const updateTaskDates = async () => {
      const res = await patchTicketTaskDates(userToken, ticketID, task.taskID, {
        startDate: Math.round(startDate.getTime() / 1000),
        endDate: endDate && Math.round(endDate.getTime() / 1000),
      })
      if (res.status.result === "ok") {
        task.startDate = Math.round(startDate.getTime() / 1000)
        task.endDate = endDate && Math.round(endDate.getTime() / 1000)
      }
    }
    if (getUTCEpoch(startDate) !== task.startDate || getUTCEpoch(endDate) !== task.endDate) {
      updateTaskDates()
    }
  }, [startDate, endDate])

  return (
    <p className={styles.bottomContainer}>
      <p>Az igazolás időintervalluma</p>
      <p className={styles.note}>A szerkesztéshez kattintson a dátumokra</p>
      <div className={styles.intervalChooser}>
        <IntervalChooser
          unknownText="Nincs megadva"
          startDate={startDate}
          endDate={endDate}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
        />
      </div>
    </p>
  )
}

const VideoCallSpecificData: React.FC<{ task: ConsultationTaskData }> = ({ task }) => {
  if (task.state !== "ready" || !task.finishedAt) return null
  const date = new Date(task.finishedAt * 1000).toLocaleString()
  return <p className={styles.bottomContainer}>A konzultáció befejeződött {date} - kor</p>
}

const SickPaySpecificData: React.FC<{ task: SickPayTaskData }> = ({ task }) => {
  return (
    <p className={styles.bottomContainer}>{`Időintervallum: 
			${task.startDate ? getDateFromInt(task.startDate) : "Nincs megadva"}
			 - 
			${task.endDate ? getDateFromInt(task.endDate) : "Nincs megadva"}
			`}</p>
  )
}

const OtherSpecificData: React.FC<{ task: OtherTaskData }> = ({ task }) => {
  let filtered: string[] = (Object.keys(task.predefinedTypes) as predefinedOtherTaskTypes[]).filter(
    (x) => {
      return (task.predefinedTypes as any)[x]
    },
  )
  if (task.userDefinedType) {
    filtered = filtered.filter((x) => x !== "other") as string[]
    filtered.push(task.userDefinedType)
  }
  return (
    <ul className={styles.bottomContainer}>
      {filtered.map((x, i) => (
        <li key={i}>{(predefinedTypesLabels as any)[x] || x}</li>
      ))}
    </ul>
  )
}

export const getTaskSpecificData = (task: Task, ticket: Ticket) => {
  switch (task.name) {
    case "diagnosis":
      return null
    case "other":
      return <OtherSpecificData task={task} />
    case "prescription":
      return <PrescriptionSpecificData ticket={ticket} task={task} />
    case "referral":
      return <ReferralSpecificData task={task} />
    case "sickNote":
      return <SickNoteSpecificData task={task} ticketID={ticket.ticketID} />
    case "sickPay":
      return <SickPaySpecificData task={task} />
    case "consultation":
      return <VideoCallSpecificData task={task} />
    default:
      return null
  }
}
