import { checkIfApiError } from "src/common/utilityFunctions"
import { ApiResponse, Notification } from "src/types"
import { APIBASEURL, headersBase } from "."
import { convertToUsedNotificationFormat } from "./getNotifications"

export const patchNotification = async (userToken: string, notificationID: number) => {
  const res = await fetch(APIBASEURL + "/notification", {
    method: "PATCH",
    headers: {
      ...headersBase,
      "User-Token": userToken,
      "content-type": "application/json",
    },
    body: JSON.stringify({ notificationID }),
  })
  const result: ApiResponse<Notification[]> = await res.json()
  if (checkIfApiError(result)) return "error"

  return convertToUsedNotificationFormat(result.status!.message)
}
