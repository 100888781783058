type DocTypes = "aszf" | "data" | "wage"

const aszfUrl = "https://static.orvosvalasz.hu/legal/orvosvalasz_aszf_v1.0.pdf"
const dataUrl = "https://static.orvosvalasz.hu/legal/orvosvalasz_adatkezelesi_v1.0.pdf"
const wageUrl = ""

export const openDocument = (doc: DocTypes) => {
  let link = ""
  switch (doc) {
    case "aszf":
      link = aszfUrl
      break
    case "data":
      link = dataUrl
      break
    case "wage":
      link = wageUrl
      break
    default:
      break
  }
  window.open(link, "_blank")
  return
}
