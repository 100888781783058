import React, { useContext } from "react"
import { patchTicket } from "src/apicalls/patchTicket"
import { GlobalContext } from "src/App"
import GenericButton from "src/common-components/GenericButton/GenericButton"
import {
  getDateFromInt,
  getDisplayNameFromNames,
  getSymptomNames,
  getTaskDisplayName,
} from "src/common/utilityFunctions"
import TicketAssigneeDisplay from "src/dr-components/TicketAssigneeDisplay/TicketAssigneeDisplay"
import { Ticket } from "src/types"
import { useIsMobileSize } from "../../../../common/hooks/useIsMobileSize"
import { useOVNavigator } from "../../../../common/hooks/useOVNavigator"
import DrTicketLabel from "./DrTicketLabel"
import styles from "./DrTicketMinified.module.scss"

const DrTicketMinified: React.FC<{ ticket: Ticket }> = ({ ticket }) => {
  const ticketData = ticket.ticketData
  const tasks = ticketData.tasks
  const { userToken, setShouldUpdateUserDataFromServer, doctor } = useContext(GlobalContext)
  const isMobile = useIsMobileSize()

  const OVNavigator = useOVNavigator()

  const labels = [
    ...tasks.filter((task) => task.state === "ready"),
    ...tasks.filter((task) => task.state === "rejected"),
    ...tasks.filter((task) => task.state !== "ready" && task.state !== "rejected"),
  ].map((task, index) => {
    return <DrTicketLabel taskName={getTaskDisplayName(task)} state={task.state} key={index} />
  })

  const labelsElement = (
    <div className={styles.labelsCont}>{labels.slice(0, Math.min(labels.length, 7))}</div>
  )

  const shouldRenderLastRow = ticketData.symptoms?.length > 0 || !ticket.doctor.id || !isMobile

  return (
    <div
      className={styles.container}
      onClick={isMobile ? () => OVNavigator.toTicket(ticket.ticketID) : undefined}
    >
      <div className={styles.ticketContainer}>
        {ticket.priority ? <i className={`material-icons ${styles.priorIcon}`}>report</i> : null}
        <div className={styles.topRow}>
          <h3>
            {`#${ticket.ticketID.toString().padStart(3, "0")} - ${getDisplayNameFromNames(
              ticket.patientProfile!,
            )}`}
          </h3>
          <div className={styles.spacer} />
          {!isMobile && labelsElement}
          <TicketAssigneeDisplay
            ticketID={ticket.ticketID}
            initialState={{
              assignee: ticket.ticketData.assignee?.assignee || "doctor",
              blocked: !!ticket.ticketData.assignee?.blocked,
            }}
            disabled={!!ticket.ticketData.closedAt}
          />
        </div>
        <div className={styles.secondRow}>
          <i className={["material-icons", "notranslate"].join(" ")}>person</i>
          <p>{getDisplayNameFromNames(ticket.userProfile!)} </p>
          <i className={["material-icons", "notranslate"].join(" ")}>access_time</i>
          <p> {getDateFromInt(ticket.ticketData.createdAt!)} </p>
        </div>
        {isMobile && labelsElement}
        {shouldRenderLastRow && (
          <div id={styles.lastRow}>
            {ticket.ticketData.symptoms?.length > 0 && (
              <p className={styles.symptomList}>
                <strong>Tünetek: </strong>
                {getSymptomNames(ticket.ticketData.symptoms)?.join(", ").toLowerCase()}
              </p>
            )}
            <div className={styles.buttonCont}>
              {!isMobile && (
                <GenericButton
                  style={!ticket.doctor.id ? "whiteBlue" : "blue"}
                  text="Megnyitás"
                  action={() => OVNavigator.toTicket(ticket.ticketID)}
                />
              )}
              {!ticket.doctor.id ? (
                <GenericButton
                  style="blue"
                  text="Elvállalás"
                  action={async () => {
                    const res = await patchTicket(userToken, {
                      ticketID: ticket.ticketID,
                      doctor: {
                        id: doctor.userID,
                        name: {
                          firstName: doctor.userProfile.firstName,
                          lastName: doctor.userProfile.lastName,
                          foreName: doctor.userProfile.foreName,
                        },
                      },
                    })
                    if (res.status?.result !== "ok") {
                      console.log("Error at communication with server")
                    } else {
                      setShouldUpdateUserDataFromServer(true)
                      OVNavigator.toTicket(ticket.ticketID)
                    }
                  }}
                />
              ) : null}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default DrTicketMinified
