import { useContext, useEffect } from "react"
import { getNotifications } from "src/apicalls/getNotifications"
import { patchNotification } from "src/apicalls/patchNotification"
import { GlobalContext } from "src/App"

export const useSetTicketRelatedNotisSeen = (ticketID: number | undefined) => {
  const { notifications, userToken, setNotifications } = useContext(GlobalContext)
  useEffect(() => {
    if (ticketID) {
      let promises: Promise<any>[] = []
      const updateNotifications = async () => {
        const relatedNotifications = notifications.unseen.filter(
          (noti) => noti.ticketID === ticketID && noti.subject !== "new-message",
        )
        if (relatedNotifications.length > 0) {
          promises = relatedNotifications.map((noti) => {
            return patchNotification(userToken, noti.id)
          })
          await Promise.all(promises)
          const newNotifications = await getNotifications(userToken)
          setNotifications(
            newNotifications !== "error" ? { ...newNotifications } : { seen: [], unseen: [] },
          )
        }
      }
      updateNotifications()
    }
  }, [notifications.unseen, ticketID])
}
