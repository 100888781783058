import React from "react"
import ShowImageFromServer from "src/common-components/ShowImageFromServer/ShowImageFromServer"
import { Task } from "src/types"
import styles from "./BasicTask.module.scss"

interface Props {
  task: Task
  ready: boolean
}

const BasicTask: React.FC<Props> = ({ task, ready }) => {
  let receivableText = ""
  let fileLabels: JSX.Element[] = []
  let comment = null
  let therapy = null
  if (task.name !== "consultation" && task.name !== "other") {
    const {
      doctorData: { receivable, receivableOnline, files },
    } = task

    if (receivable && task.name === "prescription") {
      receivableText = "Átvehető a patikában"
    } else if (receivable) {
      receivableText = "Átvehető a rendelőben"
    }
    if (receivableOnline) {
      const receivableOnlineText = "E-térben elérhető"
      if (receivableText) receivableText = [receivableText, receivableOnlineText].join(" és ")
      else receivableText = receivableOnlineText
    }
    if (receivableText) receivableText += "."

    if (files.length > 0) {
      files.forEach((file, index) => {
        fileLabels.push(<ShowImageFromServer file={file} key={index} />)
      })
    }

    comment =
      task.name === "diagnosis"
        ? task.doctorData.diagnosis || task.doctorData.comment || "—"
        : task.doctorData.comment
        ? "Megjegyzés: " + task.doctorData.comment
        : null

    therapy = task.name === "diagnosis" ? task.doctorData.therapy || "—" : null
  }

  return (
    <div className={styles.container}>
      {task.state !== "ready" && task.name !== "diagnosis" && task.name !== "consultation" ? (
        <p>Az igényhez kapcsolódó információk itt fognak megjelenni.</p>
      ) : (
        <div className={styles.dataContainer}>
          {receivableText ? <div>{receivableText}</div> : null}
          {fileLabels.length > 0 ? (
            <div className={styles.fileContainer}>
              <h6>Orvosa által feltöltött fájlok: </h6>
              {fileLabels}
            </div>
          ) : null}
        </div>
      )}
      {comment && (ready || task.name === "diagnosis") ? (
        <div className={styles.block}>
          <h6>Megjegyzés:</h6>
          <p>{comment}</p>
        </div>
      ) : null}
      {therapy ? (
        <div className={styles.block}>
          <h6>Terápia</h6>
          <p>{therapy}</p>
        </div>
      ) : null}
    </div>
  )
}

export default BasicTask
