import React, { useContext, useEffect, useState } from "react"
import ShowImageFromServer from "src/common-components/ShowImageFromServer/ShowImageFromServer"
import IntervalChooser from "src/common-components/IntervalChooser/IntervalChooser"
import { patchTicketTaskDates } from "src/apicalls/patchTicketTaskDates"
import { GlobalContext } from "src/App"
import { Task, SickNoteTaskData } from "src/types"
import styles from "./UserSickNoteTask.module.scss"
import { documentURL } from "src/apicalls/documentURL"
import OVIcon from "src/common-components/Icon/OVIcon"

interface Props {
  task: Task
  ticketID: number
  ready: boolean
}

const getStartDate = (task: SickNoteTaskData) => new Date(task.startDate! * 1000)
const getEndDate = (task: SickNoteTaskData) =>
  task.endDate ? new Date(task.endDate! * 1000) : null
const getUTCEpoch = (date: Date | null) => date && Math.round(date.getTime() / 1000)

const UserSickNoteTask: React.FC<Props> = ({ task, ticketID, ready }) => {
  const sickNoteTask: SickNoteTaskData = task as SickNoteTaskData
  const { userToken } = useContext(GlobalContext)
  const [startDate, setStartDate] = useState<Date>(getStartDate(sickNoteTask))
  const [endDate, setEndDate] = useState<Date | null>(getEndDate(sickNoteTask))

  // Update task dates when changed
  useEffect(() => {
    const updateTaskDates = async () => {
      const res = await patchTicketTaskDates(userToken, ticketID, task.taskID, {
        startDate: Math.round(startDate.getTime() / 1000),
        endDate: endDate && Math.round(endDate.getTime() / 1000),
      })
    }
    if (
      getUTCEpoch(startDate) !== sickNoteTask.startDate ||
      getUTCEpoch(endDate) !== sickNoteTask.endDate
    ) {
      updateTaskDates()
    }
  }, [startDate, endDate])

  let receivableText = ""
  let drComment = ""
  let fileLabels: JSX.Element[] = []
  let document: JSX.Element | null = null

  if (task.name === "sickNote") {
    const {
      doctorData: { receivable, receivableOnline, files, comment, documentID },
    } = task

    if (receivable) receivableText = "Átvehető a rendelőben"
    if (comment) drComment = comment
    if (documentID)
      document = (
        <a className={styles.documentDownloadLink} target="_blank" href={documentURL(documentID)}>
          <OVIcon name="description" />
          Dokumentum megnyitása
        </a>
      )

    if (receivableOnline) {
      const receivableOnlineText = "E-térben elérhető"
      if (receivableText) receivableText = [receivableText, receivableOnlineText].join(" és ")
      else receivableText = receivableOnlineText
    }
    if (receivableText) receivableText += "."

    if (files.length > 0) {
      files.forEach((file, index) => {
        fileLabels.push(<ShowImageFromServer file={file} key={index} />)
      })
    }
  }

  if (task.state === "rejected")
    return (
      <div className={styles.container}>
        <div className={styles.preview}>
          <p>Kérelmét orvosa elutasította, a következő indoklással: "{drComment}"</p>
        </div>
      </div>
    )

  return (
    <div className={styles.container}>
      {task.state !== "ready" ? (
        <div className={styles.preview}>
          <p>
            Az igazoláshoz kapcsolódó információk vagy letölthető dokumentumok itt fognak
            megjelenni, amint orvosa feltölti azokat.
          </p>
          <div className={styles.intervalChooser}>
            <IntervalChooser
              unknownText="Nincs megadva"
              startDate={startDate}
              endDate={endDate}
              setEndDate={setEndDate}
              setStartDate={setStartDate}
            />
          </div>
        </div>
      ) : (
        <div className={styles.dataContainer}>
          {receivableText ? <div>{receivableText}</div> : null}
          {document || null}
          {fileLabels.length > 0 ? (
            <div className={styles.fileContainer}>
              <h6>Orvosa által feltöltött fájlok: </h6>
              {fileLabels}
            </div>
          ) : null}
        </div>
      )}
    </div>
  )
}

export default UserSickNoteTask
