import { Formik, FormikProps } from "formik"
import React, { useContext, useRef, useState } from "react"
import { saveImage, setTaskDataToFinished } from "."
import { patchTicket } from "../../../apicalls"
import { GlobalContext } from "../../../App"
import CheckboxFormik from "../../../common-components/Checkbox/CheckboxFormik"
import FileUpload from "../../../common-components/FileUpload/FileUpload"
import GenericButton from "../../../common-components/GenericButton/GenericButton"
import MyTextInput from "../../../common-components/TextInput/TextInput"
import { MediaAtCreation, Ticket } from "../../../types"
import { OtherTaskData } from "../../../user-components/NewTicketPage/OtherTaskCard/OtherTaskCard"
import styles from "./FinishOtherTask.module.css"

type FormData = Omit<OtherTaskData["doctorData"], "files">

interface Props {
  ticket: Ticket
  taskIndex: number
  setOpenFinishTaskWindow: React.Dispatch<React.SetStateAction<"finish" | "reject" | null>>
}

const FinishOtherTask: React.FC<Props> = ({ ticket, taskIndex, setOpenFinishTaskWindow }) => {
  if (ticket.ticketData.tasks[taskIndex].name !== "other") throw "Invalid task (not OtherTaskData)"
  const task = ticket.ticketData.tasks[taskIndex] as OtherTaskData
  const { doctorData } = task

  const { userToken, setShouldUpdateUserDataFromServer } = useContext(GlobalContext)

  const [files, setFiles] = useState<MediaAtCreation[]>([])

  const initialValues = useRef<FormData>({
    comment: doctorData.comment,
    documentsReceived: doctorData.documentsReceived,
  })
  const previouslyUploadedFiles = useRef(task.doctorData.files)

  const submit = async (values: FormData) => {
    if (!values.comment && !values.documentsReceived && !(files.length > 0)) return
    const taskCopy = { ...task }
    const promises = files.map((f) => saveImage(f, ticket, userToken))
    const assignedMedia = await Promise.all(promises)
    taskCopy.doctorData = {
      comment: values.comment,
      files: assignedMedia,
      documentsReceived: values.documentsReceived,
    }
    setTaskDataToFinished(taskCopy)
    ticket.ticketData.tasks[taskIndex] = taskCopy
    const response = await patchTicket(userToken, {
      ticketData: ticket.ticketData,
      ticketID: ticket.ticketID,
    })
    setShouldUpdateUserDataFromServer(true)
    setOpenFinishTaskWindow(null)
  }

  return (
    <div className={styles.container}>
      <Formik initialValues={initialValues.current} onSubmit={(v) => submit(v)}>
        {({ submitForm }) => (
          <>
            <div className={styles.checkbox}>
              <CheckboxFormik name="documentsReceived" label="Dokumentumok átvéve" />
            </div>
            <MyTextInput name="comment" rows={5} label="Megjegyzés" />
            <FileUpload
              maxNumberOfFiles={3}
              files={files}
              setFiles={setFiles}
              relatedTicketID={ticket.ticketID}
              previouslyUploadedFiles={previouslyUploadedFiles.current}
            />
            <GenericButton
              text="Mentés"
              action={submitForm}
              style="blue"
              className={styles.button}
            />
          </>
        )}
      </Formik>
    </div>
  )
}

export default FinishOtherTask
