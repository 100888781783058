const bodyLocations = [
  {
    ID: 16,
    Name: "Has, medence és fenék",
  },
  {
    ID: 7,
    Name: "Kéz és váll",
  },
  {
    ID: 15,
    Name: "Mellkas és hát",
  },
  {
    ID: 6,
    Name: "Fej, torok és nyak",
  },
  {
    ID: 10,
    Name: "Láb",
  },
  {
    ID: 17,
    Name: "Bőr, ízületek, egyéb",
  },
]

export let bodyLocationsMap = new Map()
bodyLocations.forEach((bodyLocation) => {
  bodyLocationsMap.set(bodyLocation.ID, bodyLocation.Name)
})

export let bodyLocationValueLabelPairs: { value: number; label: string }[] = []
bodyLocations.forEach((bodyLocation) => {
  bodyLocationValueLabelPairs.push({
    value: bodyLocation.ID,
    label: bodyLocation.Name,
  })
})
