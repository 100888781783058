import React from "react"
import styles from "./NewNotiCirc.module.scss"

interface Props {
  numOfNotifications: number
  circleOnly?: boolean
  color?: "blue" | "orange"
  className?: string
}

const NewNotiCirc: React.FC<Props> = ({
  numOfNotifications,
  className,
  color = "orange",
  circleOnly,
}) => {
  if (numOfNotifications === 0) return null
  const style: React.CSSProperties = {
    backgroundColor: color === "blue" ? "var(--blue)" : "#ff701f",
  }
  return (
    <div style={style} className={`${styles.cont} ${className}`}>
      {circleOnly || numOfNotifications}
    </div>
  )
}

export default NewNotiCirc
