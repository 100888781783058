import React, { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { GlobalContext } from "src/App"
import GenericButton from "src/common-components/GenericButton/GenericButton"
import { User } from "src/types"
import urls from "../../common/urls"
import PatientBlock from "./PatientBlock/PatientBlock"
import styles from "./UserHomePage.module.scss"

const UserHomePage: React.FC = () => {
  const context = useContext(GlobalContext)
  const user = context.user as User

  const navigate = useNavigate()
  const patientBlocks = user.patients
    ?.filter((patient) => !patient.profile.archived)
    .map((patient, index) => {
      const activeTickets = user.tickets.filter((ticket) => {
        return patient.id === ticket.patientID && !ticket.archived
      })
      return <PatientBlock key={index} patient={patient} activeTickets={activeTickets} />
    })

  if (patientBlocks?.length > 0) {
    return <div className={styles.container}>{patientBlocks}</div>
  }
  return (
    <>
      <GenericButton
        style="blue"
        action={() => navigate({ pathname: urls.userNewPatientPageUrl })}
        text="Új páciens felvétele"
        className={styles.button}
      />
      <p className={styles.text}>
        A fenti gombbal új páciensként fel kell vennie gyermekét vagy gyámsága alá tartozót. Ezt
        követően tud a betegséghez tartozó információkat megosztani orvossal vagy egyéb
        adminisztrációs igényeket intézni. A gyermek tünetei alapján tanácsokat is kap (orvosaink
        által írt, hitelesített), amíg az orvosa visszajelez.
      </p>
    </>
  )
}

export default UserHomePage
