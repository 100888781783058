import React, { useContext, useEffect, useState } from "react"
import { getDoctorList } from "src/apicalls/getDoctorList"
import { GlobalContext } from "src/App"
import Checkbox from "src/common-components/Checkbox/Checkbox"
import SearchableSelect from "src/common-components/SearchableSelect/SearchableSelect"
import Select from "src/common-components/Select/Select"
import MyTextInput from "src/common-components/TextInput/TextInput"
import { isValidTajNum, necessary } from "src/common/validationFunctions"
import { CreatePatientDataThirdCard } from "src/types"
import TaskCardBase from "src/user-components/NewTicketPage/TaskCardBase"
import { newPatientFormContext } from "../NewPatientForm"
import styles from "./ThirdFormSlide.module.scss"

let initialValues: CreatePatientDataThirdCard = {
  sex: "",
  tajNum: "",
  weight: "",
  GP: { id: null, name: "" },
  height: "",
}

const validate = (values: CreatePatientDataThirdCard) => {
  const errors = {} as CreatePatientDataThirdCard
  necessary(values, "tajNum", errors)
  necessary(values, "weight", errors)
  necessary(values, "sex", errors)
  if (!isValidTajNum(values.tajNum)) errors.tajNum = "Helytelen TAJ-szám"
  return errors
}

const ThirdFormSlide: React.FC = ({}) => {
  const [loaded, setLoaded] = useState<boolean>(false)
  const formContext = useContext(newPatientFormContext)
  const { userToken } = useContext(GlobalContext)
  const [isGPNotInList, setIsGPInList] = useState<boolean>(false)
  const [drOptions, setDrOptions] = useState<{ label: string; value: number }[]>([])

  useEffect(() => {
    const getList = async () => {
      const res = await getDoctorList(userToken)
      const drOptions = res.status.message.map((doctor) => {
        const name = `${doctor.lastName} ${doctor.firstName} ${
          doctor.foreName ? doctor.foreName : ""
        }`
        return { label: name, value: doctor.userID }
      })
      setDrOptions(drOptions)
    }
    getList()
  }, [])

  useEffect(() => {
    if (!isGPNotInList && formContext.formValues.GP && formContext.formValues.GP?.id) {
      const formValuesCopy = { ...formContext.formValues }
      formValuesCopy.GP!.name =
        drOptions.find((option) => option.value === formValuesCopy.GP?.id)?.label || ""
      formContext.setFormValues({ ...formValuesCopy })
    } else if (isGPNotInList && formContext.formValues.GP) {
      const formValuesCopy = { ...formContext.formValues }
      formValuesCopy.GP!.id = null
      formContext.setFormValues({ ...formValuesCopy })
    }
  }, [formContext.formState])

  useEffect(() => {
    const { formValues } = formContext
    if (formValues.firstName !== "" && !loaded) {
      initialValues = {
        sex: formValues.sex,
        tajNum: formValues.tajNum,
        weight: formValues.weight,
        GP: formValues.GP,
        height: formValues.height,
      }
      setLoaded(true)
    }
  }, [formContext.formValues])

  return (
    <TaskCardBase
      title="Egészségügyi adatok"
      formContext={formContext}
      initialValues={initialValues}
      formikValidate={validate}
      justifyContent="space-between"
    >
      <MyTextInput
        label="TAJ-szám"
        name="tajNum"
        type="text"
        placeholder="pl.: 123456789"
        tooltip="Ahhoz, hogy egészségével kapcsolatosan bármit (ellátás, recept, táppénz)  intézni tudjon, szükség lesz a TAJ számára."
      />
      <div className={styles.personData}>
        <div className={styles.dataandunit}>
          <MyTextInput label="Magasság" name="height" type="text" optional unit="cm" />
        </div>
        <div className={styles.dataandunit}>
          <MyTextInput label="Tömeg" name="weight" type="text" unit="kg" />
        </div>
      </div>
      <Select
        label="Nem"
        name="sex"
        selectOptions={[
          { text: "Lány", value: "female" },
          { text: "Fiú", value: "male" },
        ]}
      />
      {!isGPNotInList ? (
        <SearchableSelect name="GP.id" options={drOptions} placeholder="Háziorvos neve" />
      ) : null}
      {isGPNotInList ? (
        <MyTextInput name={isGPNotInList ? "GP.name" : ""} label="Háziorvos neve" optional />
      ) : null}
      <Checkbox
        label="A háziorvosom nincs a listában"
        onClick={() => setIsGPInList(!isGPNotInList)}
        value={isGPNotInList}
      />
    </TaskCardBase>
  )
}

export default ThirdFormSlide
