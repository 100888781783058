import React from "react"
import styles from "./TicketLabel.module.css"

interface Props {
  taskName: string
}

const TicketLabel: React.FC<Props> = ({ taskName }) => {
  return (
    <div className={`${styles.label}`}>
      <p>{taskName}</p>
    </div>
  )
}

export default TicketLabel
