import React from "react"
import { useSearch } from "../../dr-components/DrHomePage/useFilters"
import styles from "./Search.module.css"

interface Props {}

const Search: React.FC<Props> = () => {
  const [searchTerm, updateSearchTerm] = useSearch()

  return (
    <div className={styles.container}>
      <i className={["material-icons", "notranslate"].join(" ")}>search</i>
      <input
        onChange={({ target }) => updateSearchTerm(target.value)}
        value={searchTerm ?? ""}
        placeholder="Név vagy TAJ-szám"
        onKeyUp={(event) => {
          if (event.key === "Escape") {
            event.preventDefault()
            updateSearchTerm("")
          }
        }}
      />
      {searchTerm && searchTerm !== "" ? (
        <i
          className={["material-icons", "notranslate", styles.clear].join(" ")}
          onClick={() => updateSearchTerm("")}
        >
          close
        </i>
      ) : null}
    </div>
  )
}

export default Search
