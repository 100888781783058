import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { ImageMeta, Patient, Task, Ticket, UserProfile } from "../../../types"
import DiagnoseSickNoteTaskCard from "../DiagnoseSickNoteTaskCard/ChooseSymptomsCard"
import FinalCommentCard from "../DiagnoseSickNoteTaskCard/FinalCommentCard"
import SymptomStartDateCard from "../DiagnoseSickNoteTaskCard/SymptomStartDateCard"
import DrChooseCard from "../DrChooseCard/DrChooseCard"
import OtherTaskCard from "../OtherTaskCard/OtherTaskCard"
import RecipeTaskCard from "../PrescriptionTaskCard/PrescriptionTaskCard"
import ReferralTaskCard from "../ReferralTaskCard/ReferralTaskCard"
import SickPayTaskCard from "../SickPayTaskCard/SickPayTaskCard"
import TaskChooseCard from "../TaskChooseCard/TaskChooseCard"

const removeSickNoteWhenDiagnosisTaskIsPresent = (tasks: Task[]) => {
  return tasks.find((task) => task.name === "diagnosis")
    ? tasks.filter((task) => task.name !== "sickNote")
    : tasks
}

export const useNewTicketCards = (tasks: Task[], ticket: Ticket | undefined, patient: Patient) => {
  const [files, setFiles] = useState<{ file: File; meta: ImageMeta }[]>([])
  const [newUserDataForSickPayTask, setNewUserDataForSickPayTask] = useState<Partial<UserProfile>>(
    {},
  )
  const isCovid = !!tasks.find((t) => t.name === "diagnosis" && t.covid)

  let filteredTasks = removeSickNoteWhenDiagnosisTaskIsPresent(tasks)

  let cards = filteredTasks
    .flatMap((task, index) => {
      const name = task.name
      switch (name) {
        case "diagnosis":
          return ticket && ticket?.ticketData.tasks.find(({ name }) => name === "sickNote")
            ? undefined
            : [
                <DiagnoseSickNoteTaskCard
                  patientSex={patient.profile.sex}
                  key={index + 50}
                  covid={isCovid}
                />,
                <SymptomStartDateCard key={index + 100} />,
              ]
        case "sickNote":
          return ticket && ticket?.ticketData.tasks.find(({ name }) => name === "diagnosis")
            ? undefined
            : [
                <DiagnoseSickNoteTaskCard
                  patientSex={patient.profile.sex}
                  key={index + 50}
                  covid={isCovid}
                />,
                <SymptomStartDateCard key={index + 100} />,
              ]
        case "prescription":
          return <RecipeTaskCard taskID={task.taskID} patient={patient} key={index + 200} />
        case "referral":
          return <ReferralTaskCard taskID={task.taskID} key={index + 250} />
        case "sickPay":
          return (
            <SickPayTaskCard
              setNewUserDataForSickPayTask={setNewUserDataForSickPayTask}
              key={index + 300}
            />
          )
        case "other":
          return <OtherTaskCard taskID={task.taskID} />
      }
    })
    .filter((el) => el)

  cards.unshift(<TaskChooseCard ticket={ticket} />)

  if (!ticket || filteredTasks.find(({ name }) => name === "sickPay" || name === "sickNote")) {
    cards.push(
      <FinalCommentCard
        setFiles={setFiles}
        files={files}
        tasks={tasks}
        key={15000}
        isOngoingTicket={!!ticket}
      />,
    )
  }

  if (!ticket) {
    cards.push(<DrChooseCard key={5000} />)
  }

  return {
    files,
    cards,
    newUserDataForSickPayTask,
  }
}
