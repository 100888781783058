import { Tooltip as MuiTooltip } from "@material-ui/core"
import React, { useState } from "react"
import styles from "./Tooltip.module.css"

interface Props {
  tooltipText: string | React.ReactChild
  disabled?: boolean
  className?: string
}

const Tooltip: React.FC<Props> = ({ tooltipText, className, children, disabled = false }) => {
  return (
    <MuiTooltip
      title={tooltipText}
      arrow
      classes={{ tooltip: styles.tooltipText }}
      disableHoverListener={disabled}
      enterTouchDelay={0}
    >
      <div className={[styles.innerContainer, className].join(" ")}>{children}</div>
    </MuiTooltip>
  )
}

export default Tooltip
