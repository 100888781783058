import React, { useContext, useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { getSingleTicket } from "src/apicalls/getSingleTicket"
import { GlobalContext } from "src/App"
import Loader from "src/common-components/Loader/Loader"
import OutpatientSheetReadonly from "src/common-components/OutpatientSheetReadonly/OutpatientSheetReadonly"
import Popup from "src/common-components/Popup/Popup"
import { useSetTicketRelatedNotisSeen } from "src/common/hooks/useSetTicketRelatedNotisSeen"
import { getSymptomNames } from "src/common/utilityFunctions"
import { DiagnosisTaskData, Task, Ticket, TicketData, TicketPageHistoryType } from "src/types"
import MessagesWrapper from "../../user-components/UserTicketPage/Messages/MessagesWrapper"
import DoctorTasks from "./DoctorTasks"
import DrTicketData from "./DrTicketData"
import styles from "./DrTicketPage.module.scss"
import OutpatientSheet from "./OutpatientSheet"

const getOutpatientSheetInitialValues = (tasks: Task[], symptoms: TicketData["symptoms"]) => {
  const diagnosisTask = tasks.find((task) => task.name === "diagnosis") as
    | DiagnosisTaskData
    | undefined
  return {
    diagnosis: diagnosisTask?.doctorData.diagnosis || "",
    opinion: "",
    status: getSymptomNames(symptoms)?.join(", ") || "",
    therapy: diagnosisTask?.doctorData.therapy || "",
  }
}

const DrTicketPage: React.FC = () => {
  const { doctor, userToken } = useContext(GlobalContext)
  const [ticket, setTicket] = useState<Ticket | null | undefined>(null)
  const [outpatientSheetVisible, setOutpatientSheetVisible] = useState<boolean>(false)
  const initialLoadRef = useRef<boolean>(true)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const ticketID = parseInt(useParams<{ ticketID: string }>().ticketID!)

  useSetTicketRelatedNotisSeen(ticket?.ticketID)

  useEffect(() => {
    if (!ticketID) {
      navigate({ pathname: "/" })
      return
    }
    const getTicketByID = async () => {
      let newTicket: Ticket | null | undefined = null
      newTicket = doctor.tickets.inProgress.find((ticket) => ticketID === ticket.ticketID)
      if (!newTicket)
        newTicket = doctor.tickets.incoming.find((ticket) => ticket.ticketID === ticketID)
      if (!newTicket) newTicket = doctor.tickets.open.find((ticket) => ticketID === ticket.ticketID)
      if (!newTicket) {
        if (!ticket) setLoading(true)
        const res = await getSingleTicket(userToken, ticketID)
        newTicket = res ? res.status!.message : null
        if (!ticket) setLoading(false)
      }
      setTicket(newTicket)
      initialLoadRef.current = false
    }
    getTicketByID()
  }, [doctor, doctor.tickets, doctor.tickets.inProgress, doctor.tickets.incoming, ticketID])

  if (loading) return <Loader />

  if (!ticket && !initialLoadRef)
    return <div>A megadott ügyet nem találtuk, próbálja meg frissíteni az oldalt.</div>

  if (!ticket) return null

  const isSubscribedToTicket = ticket.doctor.id === doctor.userID

  const taskElements: JSX.Element[] = ticket.ticketData.tasks.map((task, index) => {
    return <DoctorTasks ticket={ticket} taskIndex={index} key={index} />
  })

  return (
    <div className={styles.container}>
      <DrTicketData setOutpatientSheetVisible={setOutpatientSheetVisible} ticket={ticket} />
      <div className={styles.bottomPart}>
        {isSubscribedToTicket ? <div className={styles.tasksContainer}>{taskElements}</div> : null}
        {isSubscribedToTicket && <MessagesWrapper ticket={ticket} />}
      </div>
      {outpatientSheetVisible ? (
        ticket.ready ? (
          <Popup
            title="Összefoglaló lap"
            confirmButton={{ title: "Bezárás" }}
            onClose={() => setOutpatientSheetVisible(false)}
            mobileFullScreen
          >
            <OutpatientSheetReadonly ticket={ticket} />
          </Popup>
        ) : (
          <OutpatientSheet
            ticket={ticket}
            required={!!ticket.ticketData.tasks.find((t) => t.name === "diagnosis")}
            onClose={() => {
              setOutpatientSheetVisible(false)
            }}
            initialValues={getOutpatientSheetInitialValues(
              ticket.ticketData.tasks,
              ticket.ticketData.symptoms,
            )}
          />
        )
      ) : null}
    </div>
  )
}

export default DrTicketPage
