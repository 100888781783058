import React, { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import { GlobalContext } from "src/App"
import GenericButton from "src/common-components/GenericButton/GenericButton"
import {
  getDisplayNameFromNames,
  formatAddressString,
  givenNumberOfDigits,
} from "src/common/utilityFunctions"
import DeleteUserProfilePopup from "../../common-components/DeleteUserProfilePopup"
import NotificationPreferences from "../../common-components/NotificationPreferences/NotificationPreferences"
import PasswordChange from "../../common-components/PasswordChange/PasswordChange"
import urls from "../../common/urls"
import styles from "./ProfilePage.module.css"

const ProfilePage: React.FC = () => {
  const navigate = useNavigate()
  const [userDeletePopupOpen, setUserDeletePopupOpen] = useState<boolean>(false)
  const { doctor, userToken } = useContext(GlobalContext)
  const profile = doctor.userProfile
  return (
    <>
      <div className={styles.profileContainer}>
        <h1>Név</h1>
        <h2>{getDisplayNameFromNames(profile)}</h2>
        <h1>Születési név</h1>
        <h2>{profile.birthName}</h2>
        <h1>Születési dátum</h1>
        <h2>
          {[
            profile.birthYear,
            givenNumberOfDigits(2, profile.birthMonth),
            givenNumberOfDigits(2, profile.birthDay),
          ].join(". ") + "."}
        </h2>
        <h1>Körzet</h1>
        <h2>{profile.zone ? profile.zone : "Nincs megadva"}</h2>
        <h1>ÁNTSZ engedélyszám</h1>
        <h2>{profile.antszLicenseNumber ? profile.antszLicenseNumber : "Nincs megadva"}</h2>
        <h1>Rendelő / kórház címe</h1>
        <h2>
          {profile.officeAddress ? formatAddressString(profile.officeAddress) : "Nincs megadva"}
        </h2>
      </div>
      <PasswordChange />
      <NotificationPreferences />
      <div className={styles.buttonsContainer}>
        <GenericButton
          action={() => navigate({ pathname: urls.editProfilePageUrl })}
          style="whiteBlue"
          text="Profil szerkesztése"
        />
        <GenericButton
          text="Profil törlése"
          action={() => setUserDeletePopupOpen(true)}
          style="red"
        />
      </div>
      {userDeletePopupOpen ? (
        <DeleteUserProfilePopup
          setUserDeletePopupOpen={setUserDeletePopupOpen}
          userToken={userToken}
        />
      ) : null}
    </>
  )
}

export default ProfilePage
