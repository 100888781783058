import { useFormikContext } from "formik"
import React, { useContext, useEffect } from "react"
import FieldDeleteButton from "src/common-components/FieldDeleteButton/FieldDeleteButton"
import FileUpload from "src/common-components/FileUpload/FileUpload"
import GenericButton from "src/common-components/GenericButton/GenericButton"
import RadioButton from "src/common-components/RadioButton/RadioButton"
import Select from "src/common-components/Select/Select"
import MyTextInput from "src/common-components/TextInput/TextInput"
import { useAddForm } from "src/common/hooks/useAddForm"
import { pathProxy } from "src/common/pathProy"
import { DrugForm, CreatePatientDataSixthCard, ImageMeta, NewPatientHistoryType } from "src/types"
import { newPatientFormContext } from "../NewPatientForm"
import styles from "./DiseaseField.module.scss"
import cloneDeep from "lodash/cloneDeep"
import { deleteMediaFromPatientProfile } from "src/apicalls/deleteMediaFromPatientProfile"
import { GlobalContext } from "src/App"
import { Location, useLocation } from "react-router-dom"
import { unitSelectOptions } from "src/common/consts"
import { getAddFormFunctions } from "../../getAddFormFunctions"

interface Props {
  diseaseFieldNumber: number
  wrapperNumOfFields: number[]
  wrapperSetNumOfFields: React.Dispatch<React.SetStateAction<number[]>>
}

export const drugDefaultValue: DrugForm = {
  agentAmount: "",
  agentUnit: "",
  dose: "",
  name: "",
  mediaAtCreation: [],
  media: null,
}

const DiseaseField: React.FC<Props> = ({
  diseaseFieldNumber,
  wrapperNumOfFields,
  wrapperSetNumOfFields,
}) => {
  const { values, setFieldValue } = useFormikContext<CreatePatientDataSixthCard>()
  const { formValues, setFormValues } = useContext(newPatientFormContext)
  const checkBoxValName = `illnesses[${diseaseFieldNumber}][takesDrugs]` //making a var from the long checkbox field name
  let checkBoxValue: boolean | undefined = undefined
  const { userToken, user, setShouldUpdateUserDataFromServer } = useContext(GlobalContext)
  const location = useLocation() as Location & { state: NewPatientHistoryType }
  const patientID = location.state?.patientID
  const patient = user.patients.find(({ id }) => id === patientID)

  if (
    values["illnesses"] !== undefined &&
    values["illnesses"][diseaseFieldNumber] !== undefined &&
    values["illnesses"][diseaseFieldNumber]["takesDrugs"] !== undefined
  ) {
    checkBoxValue = values["illnesses"][diseaseFieldNumber]["takesDrugs"] //setting the checkbox value if it exists in the formik context
  }

  const _ = pathProxy<CreatePatientDataSixthCard>()

  useEffect(() => {
    if (formValues.illnesses?.[diseaseFieldNumber]?.drugs?.length) {
      setFieldValue(checkBoxValName, true)
      setNumOfFields(formValues.illnesses[diseaseFieldNumber].drugs.length)
    }
    return () => {
      if (formValues.illnesses[diseaseFieldNumber]) {
        const formValuesCopy = cloneDeep(formValues)
        formValuesCopy.illnesses[diseaseFieldNumber].drugs =
          values.illnesses[diseaseFieldNumber].drugs
        setFormValues({ ...formValuesCopy })
      }
    }
  }, [])

  const { numOfFields, addButtonRef, setNumOfFields } = useAddForm({
    checkBoxValName,
    checkBoxVal: checkBoxValue,
    initialValues: {
      fieldName: `illnesses[${diseaseFieldNumber}][drugs]`,
      values: {},
    },
    initialNumOfFields: wrapperNumOfFields[diseaseFieldNumber],
  }) //containes core logic, we send in checkboxvalue also, not just the name becuse it is a nested value and the name if not sufficient to find it

  useEffect(() => {
    wrapperSetNumOfFields((s) => {
      const stateCopy = [...s]
      stateCopy[diseaseFieldNumber] = numOfFields
      return stateCopy
    })
  }, [numOfFields])

  const { addField, deleteAllEntries } = getAddFormFunctions(
    setNumOfFields,
    numOfFields,
    setFieldValue,
    `illnesses.${diseaseFieldNumber}.drugs`,
    checkBoxValName,
    [],
  )

  function setDefaultValueToNewField() {
    setFieldValue(
      `illnesses[${diseaseFieldNumber}].drugs[${values.illnesses[diseaseFieldNumber].drugs.length}]`,
      drugDefaultValue,
    )
  }

  const modifiedAddField = () => {
    setDefaultValueToNewField()
    addField()
  }

  const modifiedHandleRadioClick = (value: boolean) => {
    if (!value) deleteAllEntries()
    else if (numOfFields === 0) modifiedAddField()
  }

  let fields = []
  for (let i = 1; i <= numOfFields; i++) {
    const files = values.illnesses[diseaseFieldNumber].drugs[i - 1].mediaAtCreation
    const setFiles = (file: { file: File; meta: ImageMeta }[]) => {
      setFieldValue(_.illnesses[diseaseFieldNumber].drugs[i - 1].mediaAtCreation._PATH_, file)
    }

    const previouslyUploadedFiles =
      formValues.illnesses?.[diseaseFieldNumber]?.drugs?.[i - 1]?.media

    fields.push(
      <div key={i} className={styles.dataCont}>
        <div className={styles.nameDelCont}>
          <MyTextInput
            name={`illnesses[${diseaseFieldNumber}][drugs][${i - 1}][name]`}
            label="Gyógyszer neve"
          />
          <FieldDeleteButton
            fieldArrayName={`illnesses[${diseaseFieldNumber}][drugs]`}
            index={i - 1}
            setNumOfFields={setNumOfFields}
            numOfFields={numOfFields}
            className={styles.deleteButton}
            defaultValue={drugDefaultValue}
            onDeleteAllEntries={deleteAllEntries}
          />
        </div>
        <div className={styles.amountCont}>
          <MyTextInput
            name={`illnesses[${diseaseFieldNumber}][drugs][${i - 1}][agentAmount]`}
            label="Hatóanyagmennyisség"
          />
          <Select
            label="Mértékegység"
            name={`illnesses[${diseaseFieldNumber}][drugs][${i - 1}][agentUnit]`}
            selectOptions={unitSelectOptions}
          />
        </div>
        <div className={styles.dose}>
          <MyTextInput
            name={`illnesses[${diseaseFieldNumber}][drugs][${i - 1}][dose]`}
            label="Adagolás"
            placeholder="pl.: 2 x 3 ml"
          />
        </div>
        <div className={styles.fileUploadContainer}>
          <p>Feltölthet képet a gyógyszer dobozáról</p>
          <FileUpload
            files={files}
            setFiles={setFiles}
            relatedTicketID={null}
            previouslyUploadedFiles={
              previouslyUploadedFiles ? [previouslyUploadedFiles] : undefined
            }
            deletePreviouslyUploadedFiles={
              patient
                ? async () => {
                    await deleteMediaFromPatientProfile(
                      userToken,
                      patient,
                      patient.profile.illnesses[diseaseFieldNumber].drugs[i - 1],
                      `illnesses[${diseaseFieldNumber}].drugs[${i - 1}].media`,
                      setFieldValue,
                    )
                    setShouldUpdateUserDataFromServer(true)
                  }
                : undefined
            }
          />
        </div>
      </div>,
    )
  }

  let additionalButton = null //creating the button that ads another field(group)
  if (numOfFields >= 1) {
    additionalButton = (
      <div className={styles.addButton} ref={addButtonRef}>
        <GenericButton
          text="További gyógyszer megadása"
          action={() => modifiedAddField()}
          style="grey"
        />
      </div>
    )
  }

  return (
    <div className={styles.cont}>
      <p>Szed rá valamilyen gyógyszert?</p>
      <RadioButton
        name={`illnesses[${diseaseFieldNumber}][takesDrugs]`}
        options={[
          { label: "Igen", value: true },
          { label: "Nem", value: false },
        ]}
        callback={modifiedHandleRadioClick}
      />
      <>{fields}</>
      {additionalButton}
    </div>
  )
}

export default DiseaseField
