import { useFormikContext } from "formik"
import React from "react"
import { PatientFormData } from "src/types"
import styles from "./FieldDeleteButton.module.css"
import _get from "lodash/get"

interface Props {
  numOfFields: number
  fieldArrayName: string
  setNumOfFields: (index: number) => void
  index: number
  onDeleteAllEntries?: () => void
  defaultValue?: any
  className?: string
}

const FieldDeleteButton: React.FC<Props> = ({
  numOfFields,
  setNumOfFields,
  fieldArrayName,
  index,
  className,
  defaultValue = "",
  onDeleteAllEntries,
}) => {
  const { values, setFieldValue } = useFormikContext<PatientFormData>()

  const fieldDelete = (index: number) => {
    if (numOfFields === 1) {
      onDeleteAllEntries?.()
      setNumOfFields(0)
    } else {
      for (let i = index; i <= numOfFields; i++) {
        const neededVal = _get(values, fieldArrayName) as any[]
        let nextValue = 0
        nextValue = neededVal ? neededVal[i + 1] : undefined
        setFieldValue(
          //setting all values to come forward
          fieldArrayName + `[${i}]`, //the value we set
          nextValue, //the value after. if there was given a afterfieldvalue we use that, if not we try to use the string
        )
        setFieldValue(fieldArrayName + `[${numOfFields - 1}]`, defaultValue) //set the last field value to "", coz there are one less field
      } //set the field value before setting the state, coz state setting as async and u cant be sure what will be the state when u set the field after
      setNumOfFields(numOfFields - 1)
    }
  }
  return (
    <i onClick={() => fieldDelete(index)} className={`material-icons ${styles.icon} ${className}`}>
      delete_forever
    </i>
  )
}

export default FieldDeleteButton
