import { ReactElement, useEffect, useState } from "react"
import { User, VideoCall } from "src/types"
import CallPopup from "src/user-components/CallPopup/CallPopup"

export const usePopupWhenCalled = (
  user: User,
): [VideoCall | null, React.Dispatch<React.SetStateAction<VideoCall | null>>] => {
  const [activeVideoCall, setActiveVideoCall] = useState<VideoCall | null>(null)
  useEffect(() => {
    const { videoCalls: videoCalls } = user
    if (videoCalls && Object.keys(user).length > 0) {
      const activeVideoCall = videoCalls.find((v) => v.offer && !v.answer)
      setActiveVideoCall(activeVideoCall || null)
    }
  }, [user, user.videoCalls])
  return [activeVideoCall, setActiveVideoCall]
}
