import { LogBookBaseEntryTitles, LogBookEntry, LogBookEntryClient } from "src/types"

const eventStyles: { [colorName: string]: React.CSSProperties } = {
  red: {
    backgroundColor: "#FF3A35",
    color: "white",
    fontWeight: 600,
    paddingTop: "0.5rem",
    border: "1px solid var(--grey)",
  },
  green: {
    backgroundColor: "#4BD354",
    color: "black",
    fontWeight: 600,
    paddingTop: "0.5rem",
    border: "1px solid var(--grey)",
  },
  blue: {
    backgroundColor: "#3359B0",
    color: "white",
    fontWeight: 600,
    paddingTop: "0.5rem",
    border: "1px solid var(--grey)",
  },
  yellow: {
    backgroundColor: "#FFBC35",
    color: "black",
    fontWeight: 600,
    paddingTop: "0.5rem",
    border: "1px solid var(--grey)",
  },
}

const { red, green, blue, yellow } = eventStyles

const eventColors: { [key in LogBookBaseEntryTitles]: React.CSSProperties } = {
  Fejfájás: yellow,
  Gyógyszerezés: green,
  Hasfájás: yellow,
  Hányás: red,
  Kiütés: red,
  Láz: red,
  Orrszívás: green,
  Széklet: blue,
  Vizelet: blue,
}

export function eventStyleGetter(event: LogBookEntryClient) {
  if (!event.title) return { style: blue }
  const style = (eventColors as any)[event.title] ? (eventColors as any)[event.title] : blue
  return { style }
}
