import React, { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { logout } from "src/apicalls/logout"
import { GlobalContext } from "src/App"
import SidebarButton from "src/common-components/SidebarButton/SidebarButton"
import Tooltip from "src/common-components/Tooltip/Tooltip"
import { getDisplayNameFromNames } from "src/common/utilityFunctions"
import urls from "../../common/urls"
import { config } from "../../config"
import logoImg from "../../imgs/logo-new-black.png"
import OverlayOpeningSingleButton from "../SidebarButtonsOverlayOpening/SidebarButtonOverlayOpening"
import styles from "./Sidebar.module.scss"
import { useMobileSidebar } from "./useMobileSidebar"

export interface SidebarProps {
  sidebarOpen: boolean
  hideSidebar: () => void
  menuButtonRef: React.MutableRefObject<HTMLButtonElement | null>
}

const DrSidebar: React.FC<SidebarProps> = ({ sidebarOpen, hideSidebar, menuButtonRef }) => {
  const { doctor, userToken } = useContext(GlobalContext)
  const navigate = useNavigate()

  const sidebarRef = useMobileSidebar(hideSidebar, menuButtonRef)

  return (
    <div
      ref={sidebarRef}
      className={sidebarOpen ? styles.unhiddenContainer : styles.hiddenContainer}
    >
      <h2>
        <img id={styles.logoimg} src={logoImg} alt="logó" />
      </h2>
      <SidebarButton action={() => navigate({ pathname: "/" })} icon="list" text="Eseteim" />
      <OverlayOpeningSingleButton overlay="noti-overlay" />
      <OverlayOpeningSingleButton overlay="msg-overlay" />
      <OverlayOpeningSingleButton overlay="consultations-overlay" />
      <div className={styles.bottomPart}>
        <hr className={styles.texthr} content-text={getDisplayNameFromNames(doctor.userProfile)} />
        <SidebarButton
          action={() => navigate({ pathname: urls.profilePageUrl })}
          icon="person"
          text="Profil"
        />
        <Tooltip tooltipText="Ha javaslata van, elakadt, vagy hibát észlelt, kattintson ide!">
          <SidebarButton
            action={() => {
              window.open("mailto:" + config.SUPPORT_EMAIL, "blank")
            }}
            icon="support"
            text="Segítség"
          />
        </Tooltip>
        <SidebarButton action={() => logout(userToken)} icon="exit_to_app" text="Kijelentkezés" />
      </div>
    </div>
  )
}

export default DrSidebar
