import React, { useState, useEffect } from "react"
import { useMediaQuery } from "react-responsive"
import { Document, Page } from "react-pdf/dist/esm/entry.webpack"

import styles from "./PDFDisplay.module.scss"

interface Props {
  pdfUrl: string
  onLoaded?: () => void
}

const PDFDisplay: React.FC<Props> = ({ pdfUrl, onLoaded }) => {
  const [rem, setRem] = useState<number>(15)
  const isSmall = useMediaQuery({ query: "(max-width: 900px)" })
  const isMedium = useMediaQuery({ query: "(max-width: 1300px)" })
  const isLarge = useMediaQuery({ query: "(min-width: 1301px)" })

  useEffect(() => {
    setRem(parseInt(getComputedStyle(document.documentElement).fontSize))
  }, [isSmall, isMedium, isLarge])

  return (
    <Document
      onLoadError={console.error}
      onLoadSuccess={onLoaded}
      file={pdfUrl}
      className={styles.container}
    >
      <Page pageNumber={1} width={49 * rem} />
    </Document>
  )
}

export default PDFDisplay
