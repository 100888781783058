import { ApiResponse, DoctorProfile, UserProfile } from "src/types"
import { APIBASEURL, headersBase } from "."

export const patchUserProfile = async (
  userToken: string,
  body: Partial<UserProfile | DoctorProfile>,
) => {
  const res = await fetch(APIBASEURL + "/profile", {
    method: "PATCH",
    headers: {
      ...headersBase,
      "User-Token": userToken,
      "content-type": "application/json",
    },
    body: JSON.stringify({ profile: body }),
  })
  const result: ApiResponse = await res.json()
  if (!result.status || result.status?.result !== "ok") {
    console.log("Error at communication with server")
    console.log(result)
  }
  return result
}
