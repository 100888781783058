import React, { useContext, useRef } from "react"
import ReactDOM from "react-dom"
import { Scrollbars } from "react-custom-scrollbars"
import { GlobalContext } from "src/App"
import { Notification } from "src/types"
import Tooltip from "../Tooltip/Tooltip"
import styles from "./Notifications.module.scss"
import SingleNotification from "./SingleNotification"
import NotificationTabWrapper from "../NotificationTabWrapper/NotificationTabWrapper"
import {
  useCloseOverlays,
  useOpenOverlay,
} from "../SidebarButtonsOverlayOpening/sidebarButtonOverlayOpening_utils"

interface Props {
  type: "msg" | "basic"
}

const Notifications: React.FC<Props> = ({ type }) => {
  const { notifications, doctor } = useContext(GlobalContext)
  const content = useRef<JSX.Element[]>([])

  const isDoctor = Object.keys(doctor).length > 0

  const isOpen = useOpenOverlay() === (type === "msg" ? "msg-overlay" : "noti-overlay")

  const closeNotifications = useCloseOverlays()

  const isGoodNotificationType = (notification: Notification) => {
    if (
      (type === "msg" && notification.subject === "new-message") ||
      (type === "basic" && notification.subject !== "new-message")
    ) {
      return true
    } else return false
  }

  const getContent = () => {
    let unseenNotifications: JSX.Element[] = []
    notifications.unseen.forEach((notification, index) => {
      if (isGoodNotificationType(notification)) {
        unseenNotifications.unshift(
          <SingleNotification
            key={index}
            notification={notification}
            closeNotifications={closeNotifications}
          />,
        )
      }
    })
    let seenNotifications: JSX.Element[] = []
    notifications.seen.forEach((notification, index) => {
      if (isGoodNotificationType(notification)) {
        seenNotifications.unshift(
          <SingleNotification
            key={100000 + index}
            notification={notification}
            closeNotifications={closeNotifications}
          />,
        )
      }
    })
    return unseenNotifications.concat(seenNotifications.slice(0, 29))
  }

  content.current = getContent()

  return (
    <NotificationTabWrapper open={isOpen}>
      <div className={styles.topRow}>
        <h1>{type === "msg" ? "Üzenetek" : "Értesítések"}</h1>
        {type === "msg" && !isDoctor ? (
          <Tooltip tooltipText="Üzenetváltást csak az orvosa kezdeményezhet. Ha kérdése merül fel az üzenetek itt fognak megjelenni.">
            <i className={["material-icons", "notranslate"].join(" ")}>info</i>
          </Tooltip>
        ) : null}
      </div>
      <div className={styles.content}>
        <Scrollbars>
          <div className={`${styles.innerContainer}`}>
            {content.current.length > 0 ? (
              content.current
            ) : (
              <p>Még nem érkezett {type === "msg" ? "üzenete" : "értesítése"}</p>
            )}
          </div>
        </Scrollbars>
      </div>
    </NotificationTabWrapper>
  )
}

export default Notifications
