import React, { useContext, useEffect, useState } from "react"
import { patchTicket } from "src/apicalls/patchTicket"
import { GlobalContext } from "src/App"
import { TextField } from "@material-ui/core"
import Checkbox from "src/common-components/Checkbox/Checkbox"
import GenericButton from "src/common-components/GenericButton/GenericButton"
import ShowImageFromServer from "src/common-components/ShowImageFromServer/ShowImageFromServer"
import MyTextInput from "src/common-components/TextInput/TextInput"
import {
  AssignedMedia,
  PrescriptionTaskData,
  ReferralTaskData,
  SickNoteTaskData,
  SickPayTaskData,
  Task,
  Ticket,
} from "src/types"
import { saveImage, setTaskDataToFinished } from "."
import styles from "./FinishFileUploadTask.module.scss"
import SingleDateChooser from "src/common-components/SingleDateChooser/SingleDateChooser"
import { downloadSickNoteDocumentPreview } from "src/apicalls/downloadSickNoteDocumentPreview"
import OVIcon from "src/common-components/Icon/OVIcon"
import { openFileFromResponse } from "src/common/openFileFromResponse"
import { createSickNoteDocument } from "src/apicalls/createSickNoteDocument"
import { documentURL } from "src/apicalls/documentURL"

const updateTaskObject = (
  task: ValidTasks,
  receivable: boolean,
  receivableOnline: boolean,
  assignedMedia: AssignedMedia[],
  documentID?: string,
) => {
  let taskCopy = { ...task }
  taskCopy.doctorData.receivable = receivable
  taskCopy.doctorData.receivableOnline = receivableOnline
  taskCopy.doctorData.files = assignedMedia
  taskCopy.doctorData.documentID = documentID
  setTaskDataToFinished(taskCopy)
  return taskCopy
}

type ValidTasks = PrescriptionTaskData | ReferralTaskData | SickNoteTaskData | SickPayTaskData
const allowedTasknames: ValidTasks["name"][] = ["prescription", "referral", "sickNote", "sickPay"]

interface Props {
  ticket: Ticket
  taskIndex: number
  setOpenFinishTaskWindow: React.Dispatch<React.SetStateAction<"finish" | "reject" | null>>
}

const FinishFileUploadTask: React.FC<Props> = ({ taskIndex, ticket, setOpenFinishTaskWindow }) => {
  if (!(allowedTasknames as any).includes(ticket.ticketData.tasks[taskIndex].name))
    throw "Incorrect task type for finish file upload task dialog"
  let task = ticket.ticketData.tasks[taskIndex] as ValidTasks

  const [file, setFile] = useState<File | null>(null)
  const [receivable, setReceivable] = useState(false)
  const [receivableOnline, setReceivableOnline] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [documentPreviewLoading, setDocumentPreviewLoading] = useState<boolean>(false)
  const [documentLoading, setDocumentLoading] = useState<boolean>(false)
  const { userToken, setShouldUpdateUserDataFromServer } = useContext(GlobalContext)
  const [documentGenerationSettingsVisible, setDocumentGenerationSettingsVisible] = useState(false)
  const [peSuspensionEndDate, setPeSuspensionEndDate] = useState<Date | null>(null)
  const [drComment, setDrComment] = useState<string>("")
  const [documentID, setDocumentID] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (task.doctorData.receivable) setReceivable(true)
    if (task.doctorData.receivableOnline) setReceivableOnline(true)
    if (task.doctorData.documentID) setDocumentID(task.doctorData.documentID)
  }, [])

  useEffect(() => {
    if (!task.doctorData.documentID && documentID) onFinish()
  }, [documentID])

  const canUploadFiles =
    (task.state !== "ready" && !task.doctorData.files) || task.doctorData.files.length === 0
  const canFinish =
    file ||
    receivable ||
    documentID ||
    receivableOnline ||
    (task.doctorData.files && task.doctorData.files.length !== 0)

  const onFinish = async () => {
    if (!canFinish) return
    setLoading(true)
    let newTasksData: Task[] = [...ticket.ticketData.tasks]
    let assignedMedia: AssignedMedia[] = []

    if (file) {
      assignedMedia.push(await saveImage(file, ticket, userToken))
    }

    const updatedTask = updateTaskObject(
      task,
      receivable,
      receivableOnline,
      assignedMedia,
      documentID,
    )
    newTasksData[taskIndex] = updatedTask

    const newTicketData = { ...ticket.ticketData, tasks: newTasksData }

    const ticketPatchResponse = await patchTicket(userToken, {
      //TODO: bad resp check
      ticketID: ticket.ticketID,
      ticketData: newTicketData,
    })

    setShouldUpdateUserDataFromServer(true)
    setOpenFinishTaskWindow(null)
  }

  const onPreviewSickNote = async () => {
    setDocumentPreviewLoading(true)
    const res = await downloadSickNoteDocumentPreview(
      userToken,
      ticket.ticketID,
      task.taskID,
      drComment === "" ? null : drComment,
      peSuspensionEndDate && Math.round(peSuspensionEndDate.getTime() / 1000),
    )
    await openFileFromResponse(res)
    setDocumentPreviewLoading(false)
  }

  const onCreateSickNote = async () => {
    setDocumentLoading(true)
    const res = await createSickNoteDocument(
      userToken,
      ticket.ticketID,
      task.taskID,
      drComment === "" ? null : drComment,
      peSuspensionEndDate && Math.round(peSuspensionEndDate.getTime() / 1000),
    )
    setDocumentLoading(false)
    if (res.status.result === "ok") {
      setDocumentID(res.status.message.documentID)
    } else {
      console.error("Failed to get document ID:", res)
    }
  }

  if (documentGenerationSettingsVisible)
    return (
      <div className={styles.container}>
        <TextField
          className={styles.noteInput}
          value={drComment}
          onChange={(v) => setDrComment(v.target.value)}
          variant="outlined"
          label="Megjegyzés (opc.)"
          multiline
          inputProps={{ maxLength: 160 }}
          rowsMax={6}
        />
        <div className={styles.peDateContainer}>
          <div className={styles.peDateLabel}>Testnevelés alóli felmentés vége (opc.)</div>
          <SingleDateChooser
            date={peSuspensionEndDate}
            setDate={setPeSuspensionEndDate}
            unknownText="Nem javasolt felmentés"
          />
        </div>
        <div className={styles.horizontalBottom}>
          <GenericButton
            text="Mégse"
            action={() => setDocumentGenerationSettingsVisible(false)}
            style="grey"
          />
          <GenericButton
            loading={documentPreviewLoading}
            text="Előnézet"
            style="grey"
            action={onPreviewSickNote}
          />
          <GenericButton
            loading={documentLoading || loading}
            text="Kiállítás"
            style="blue"
            action={onCreateSickNote}
          />
        </div>
      </div>
    )

  return (
    <div className={styles.container}>
      <div className={styles.uploadContainer}>
        {canUploadFiles ? (
          <>
            <div className={styles.buttonContainer}>
              {task.name === "sickNote" && (task.startDate === null || task.endDate === null) ? (
                <label className={[styles.button, styles.warning].join(" ")}>
                  <OVIcon name="error" />
                  Nincs {task.startDate === null ? "kezdő" : "záró"} dátum
                </label>
              ) : null}
              {task.name === "sickNote" &&
              task.endDate !== null &&
              task.startDate !== null &&
              task.doctorData.documentID === undefined ? (
                <>
                  <label
                    onClick={() => setDocumentGenerationSettingsVisible(true)}
                    className={[styles.button, styles.highlighted].join(" ")}
                  >
                    <OVIcon name="playlist_add_check" />
                    Dokumentum generálása
                  </label>
                </>
              ) : null}
              {file ? (
                <div className={styles.fileUploadDisplay}>
                  {file.name} <OVIcon onClick={() => setFile(null)} name="delete" />
                </div>
              ) : (
                <label htmlFor="input" className={styles.button}>
                  <OVIcon name="cloud_upload" />
                  Fájl feltöltése
                </label>
              )}
            </div>
          </>
        ) : null}
        {task.doctorData.documentID && (
          <a
            className={styles.documentDownloadLink}
            href={documentURL(task.doctorData.documentID)}
            target="_blank"
          >
            <OVIcon name="description" />
            Generált dokumentum megnyitása
          </a>
        )}
        {task.doctorData.files.length > 0 ? (
          <ShowImageFromServer file={task.doctorData.files[0]} />
        ) : null}
      </div>
      <input
        id="input"
        name="input"
        type="file"
        onChange={({ target }) => setFile(target.files && target.files[0])}
        accept={".jpeg, .jpg, .pdf"}
      />
      <Checkbox
        label={
          ticket.ticketData.tasks[taskIndex].name === "prescription"
            ? "Patikában átvehető"
            : "Rendelőben átvehető"
        }
        onClick={setReceivable}
        value={receivable}
        disabled={ticket.ready}
        className={styles.checkbox}
      />
      {ticket.ticketData.tasks[taskIndex].name === "referral" ? (
        <Checkbox
          label="E-térben elérhető"
          onClick={setReceivableOnline}
          value={receivableOnline}
          disabled={ticket.ready}
          className={styles.checkbox}
        />
      ) : null}

      <div className={styles.bottom}>
        {ticket.ready ? null : <p className={styles.text}>*Legalább az egyik opció kötelező</p>}
        {ticket.ready ? (
          <GenericButton text="Bezárás" action={() => setOpenFinishTaskWindow(null)} style="blue" />
        ) : (
          <GenericButton
            loading={loading}
            text={ticket.ticketData.tasks[taskIndex].state !== "wip" ? "Módosítás" : "Befejezés"}
            action={onFinish}
            style="blue"
          />
        )}
      </div>
    </div>
  )
}

export default FinishFileUploadTask
