import React, { useRef, useState } from "react"
import { Calendar } from "react-date-range"
import { useOnClickOutside } from "src/common/hooks/useOnClickOutside"
import { getDateFromInt } from "src/common/utilityFunctions"
import styles from "./IntervalChooser.module.scss"
import locale from "date-fns/locale/hu/index.js"
import ReactDOM from "react-dom"

interface Props {
  endDate: Date | null
  setEndDate: (value: Date | null) => void
  startDate: Date
  setStartDate: (value: Date) => void
  unknownText?: string
  maxDate?: Date
}

const Cal = Calendar as any //Props are not typed well

const IntervalChooser: React.FC<Props> = ({
  endDate,
  startDate,
  setStartDate,
  setEndDate,
  unknownText,
  maxDate,
}) => {
  const [visibleCal, setVisibleCal] = useState<"start" | "end" | null>(null)
  const buttonRef = useRef<HTMLDivElement>(null)
  const ref = useOnClickOutside<HTMLDivElement>(() => setVisibleCal(null), buttonRef)

  return (
    <div className={styles.container}>
      <span className={styles.dateContainer}>
        <p
          ref={visibleCal === "start" ? buttonRef : undefined}
          onClick={() => setVisibleCal((s) => (s === "start" ? null : "start"))}
        >
          {`${getDateFromInt(startDate.getTime())}`}
        </p>
        &mdash;
        <p ref={visibleCal === "end" ? buttonRef : undefined} onClick={() => setVisibleCal("end")}>
          {endDate ? getDateFromInt(endDate.getTime()) : unknownText || null}
        </p>
      </span>

      {visibleCal
        ? ReactDOM.createPortal(
            <div ref={ref} className={styles.calendarContainer}>
              <Cal
                date={visibleCal === "start" ? startDate : endDate || new Date()}
                onChange={(value: string) =>
                  visibleCal === "start" ? setStartDate(value as any) : setEndDate(value as any)
                }
                maxDate={maxDate}
                minDate={visibleCal === "end" ? startDate : undefined}
                locale={locale}
              />
              {visibleCal === "end" && endDate ? (
                <p
                  className={styles.resetButton}
                  onClick={() => {
                    setVisibleCal(null)
                    setEndDate(null)
                  }}
                >
                  Még nem tudom
                </p>
              ) : null}
            </div>,
            document.body,
          )
        : null}
    </div>
  )
}

export default IntervalChooser
