import { format, parse, startOfWeek } from "date-fns"
import getDay from "date-fns/getDay"
import React, { Children, CSSProperties, useContext, useEffect, useState } from "react"
import { Calendar, dateFnsLocalizer } from "react-big-calendar"
import { Location, useLocation } from "react-router-dom"
import { getLogs } from "src/apicalls/getLogs"
import { GlobalContext } from "src/App"
import { getDisplayNameFromNames, getMonthBoundary } from "src/common/utilityFunctions"
import { LogBookEntryClient, LogBookPageHistoryType } from "src/types"
import BackButton from "../../common-components/BackButton/BackButton"
import AddEvent from "./AddEvent/AddEvent"
import { eventStyleGetter } from "./EventColors"
import ReactDOM from "react-dom"
import styles from "./LogBookPage.module.scss"
import { useIsMobileSize } from "../../common/hooks/useIsMobileSize"
const locales = {
  hu: require("date-fns/locale/hu/index.js"),
}
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
})

const messages = {
  month: "Hónap",
  day: "Nap",
  year: "Év",
  week: "Hét",
  today: "Ma",
  next: "Következő",
  previous: "Előző",
}

const getEventOnDate = (date: Date, events: LogBookEntryClient[]) => {
  date.setMinutes(0)
  return (
    events.find(
      (e) => e.start && e.start >= date && e.start.getTime() <= date.getTime() + 3500000,
    ) || null
  )
}

const ColoredDateCellWrapperCreator = (selectedDate: Date) => {
  const ColoredDateCellWrapper = ({ children, value }: any) => {
    const isSelectedDay =
      (value as Date).getFullYear() === selectedDate.getFullYear() &&
      (value as Date).getMonth() === selectedDate.getMonth() &&
      (value as Date).getDate() === selectedDate.getDate()

    return React.cloneElement(Children.only(children), {
      style: {
        ...children.style,
        backgroundColor: isSelectedDay ? "var(--blue)" : undefined,
        color: "white",
      },
    })
  }
  return ColoredDateCellWrapper
}

const TimeSlotWrapper = ({ children, value, onSelect, events }: any) => {
  return (
    <div
      key={value}
      style={{ height: "40px", zIndex: getEventOnDate(value, events) ? 0 : 1 }}
      onClick={onSelect}
    >
      {children}
    </div>
  )
}

const desktopStyle: CSSProperties = {
  backgroundColor: "white",
  padding: "1.25rem",
  borderRadius: "1rem",
}

const mobileStyles: CSSProperties = {
  backgroundColor: "white",
  padding: "0.5rem",
  borderRadius: "0",
}

const LogBookPage: React.FC = () => {
  const { userToken, doctor, user } = useContext(GlobalContext)

  const isMobile = useIsMobileSize()

  const [shouldShowNewEventWindow, setShouldShowNewEventWindow] = useState<boolean>(false)
  const [selectedDate, setSelectedDate] = useState<Date>(new Date())
  const [events, setEvents] = useState<LogBookEntryClient[]>([])
  const [selectedEvent, setSelectedEvent] = useState<LogBookEntryClient | null>(null)

  const location = useLocation() as Location & { state: LogBookPageHistoryType }
  const patient = location.state?.patient

  const setEventsToState = async (patientID: number, date: Date) => {
    const { startTime, endTime } = getMonthBoundary(date)
    const events = await getLogs(userToken, patientID, startTime, endTime)
    setEvents(events || [])
  }

  useEffect(() => {
    if (patient) setEventsToState(patient.id, new Date())
  }, [patient])

  if (!patient) return null

  let scrollToDate = new Date()
  scrollToDate.setHours(8)
  scrollToDate.setMinutes(0)

  return (
    <div className={styles.container}>
      <div className={styles.nameContainer}>
        {!isMobile && <BackButton />}
        <p>{getDisplayNameFromNames(patient.profile)}</p>
      </div>
      <div className={styles.calendarContainer}>
        <Calendar
          localizer={localizer}
          events={events}
          culture="hu"
          messages={messages}
          style={isMobile ? mobileStyles : desktopStyle}
          views={["month", "week"]}
          defaultView={doctor.doctor ? "month" : "week"}
          scrollToTime={scrollToDate}
          selectable={true}
          onSelecting={() => false}
          onSelectEvent={(entry) => {
            setSelectedEvent(entry)
            setShouldShowNewEventWindow(true)
          }}
          onNavigate={(date) => setEventsToState(patient.id, date)}
          components={{
            dateCellWrapper: ColoredDateCellWrapperCreator(selectedDate),
            timeSlotWrapper: (props) => (
              <TimeSlotWrapper
                {...props}
                onSelect={() => {
                  if (!doctor.doctor) {
                    setSelectedEvent(null)
                    setSelectedDate(new Date((props as any).value))
                    setShouldShowNewEventWindow(true)
                  }
                }}
                events={events}
              />
            ),
          }}
          eventPropGetter={(event) => eventStyleGetter(event)}
          formats={{ eventTimeRangeFormat: () => "" }}
        />
        {shouldShowNewEventWindow
          ? ReactDOM.createPortal(
              <div className={styles.addEventContainer}>
                <AddEvent
                  selectedDate={selectedDate}
                  event={selectedEvent}
                  onClose={() => setShouldShowNewEventWindow(false)}
                  patientID={patient.id}
                  closeNewEventWindow={() => setShouldShowNewEventWindow(false)}
                  setEvents={setEvents}
                />
              </div>,
              document.body,
            )
          : null}
      </div>
    </div>
  )
}

export default LogBookPage
