import React from "react"
import MyTextInput from "src/common-components/TextInput/TextInput"
import styles from "./AddressFormSlide.module.scss"
const AddressTextInputs: React.FC<{ baseName?: string }> = ({ baseName = "address" }) => {
  return (
    <>
      <MyTextInput label="Ország" name={`${baseName}.country`} type="text" />
      <MyTextInput label="Megye" name={`${baseName}.region`} type="text" />
      <div className={styles.address}>
        <MyTextInput
          label="Irányítószám"
          name={`${baseName}.zipCode`}
          type="text"
          className={styles.cityandzip}
        />
        <MyTextInput
          label="Város"
          name={`${baseName}.city`}
          type="text"
          className={styles.cityandzip}
        />
      </div>
      <MyTextInput label="Közterület" name={`${baseName}.street`} type="text" />
      <MyTextInput
        label="Házszám, emelet, ajtó"
        name={`${baseName}.streetNum`}
        type="text"
        placeholder="pl.: 30, 8. emelet, 50-es ajtó"
      />
    </>
  )
}
export default AddressTextInputs
