import { ApiResponse } from "src/types"
import { APIBASEURL, headersBase } from "."

export const updateEmail = async (
  userToken: string,
  body: {
    email: string
  },
) => {
  const res = await fetch(APIBASEURL + "/modify-email", {
    method: "POST",
    headers: {
      ...headersBase,
      "User-Token": userToken,
      "content-type": "application/json",
    },
    body: JSON.stringify(body),
  })
  const result: ApiResponse<string> = await res.json()
  return result
}
